import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { useEntityStore } from '@/stores'

export function useRangeBulletChart(root) {
    let chart
    let scrollableContainer
    let yAxis
    let xAxis
    let dumbbellSeries
    let boxSeries
    let boxMedianSeries
    let peerBulletSeries
    let legend

    const configureRangeBullet = () => {
        root.setThemes([am5themes_Animated.new(root)])
        root.utc = true
        root.dateFormatter.setAll({
            dateFormat: 'MM-dd-yyyy',
            dateFields: ['valueX', 'openValueX'],
        })

        chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panY: false,
                interactive: false,
                wheelY: 'none',
                layout: root.verticalLayout,
                wheelable: true,
            })
        )

        scrollableContainer = chart.chartContainer.children.unshift(
            am5.Container.new(root, {
                width: am5.p100,
                height: am5.p100,
                verticalScrollbar: am5.Scrollbar.new(root, {
                    orientation: 'vertical',
                }),
                wheelable: true,
            })
        )
    }

    const generateAxes = (mappedData) => {
        const entityStore = useEntityStore()
        let yRenderer = am5xy.AxisRendererY.new(root, {})
        yRenderer.grid.template.setAll({
            minGridDistance: 40,
            forceInactive: true,
        })

        yRenderer.labels.template.setAll({
            fill: am5.color('#5D6D78'),
            fontSize: 14,
            paddingTop: 15,
        })

        yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: 'name',
                forceInactive: true,
                active: false,
                visible: false,
                crisp: true,
                renderer: yRenderer,
                marginRight: 10,
                bullet: function (root, axis, dataItem) {
                    return am5xy.AxisBullet.new(root, {
                        location: 0.5,
                        sprite: am5.Label.new(root, {
                            text: dataItem.dataContext.productDisplayName,
                            fill: am5.color('#82939A'),
                            centerY: am5.p50,
                            centerX: am5.percent(97),
                            dy: -13,
                            fontSize: 12,
                        }),
                    })
                },
            })
        )

        yAxis.get('renderer').labels.template.setAll({
            dy: entityStore?.strategyTabNodeFilter === 'product' ? -7 : 0,
        })

        let xRenderer = am5xy.AxisRendererX.new(root, {})
        xRenderer.grid.template.setAll({
            minGridDistance: 40,
            forceInactive: true,
            cellStartLocation: 0.5,
            cellEndLocation: 0.5,
        })

        xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: 'month', count: 6 },
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {}),
                extraMin: 0.03,
                extraMax: 0.03,
            })
        )

        xAxis.children.push(am5.Label.new(root, { text: 'Years', x: am5.p50, centerX: am5.p50 }))

        xAxis.get('renderer').grid.template.set('visible', true)
        yAxis.data.setAll(mappedData)

        chart.yAxesAndPlotContainer.set('height', mappedData.length * 60)
        chart.yAxesAndPlotContainer.set('paddingBottom', 10)
        scrollableContainer.children.push(chart.yAxesAndPlotContainer)
    }

    const generateSeries = (mappedData) => {
        generateDumbbellSeries(mappedData)
        generateBoxSeries(mappedData)
        generateBoxMedianSeries(mappedData)
        generatePeerSeries(mappedData)
        generateBoxSeries(mappedData)
        generateLegend()
    }

    const generateDumbbellSeries = (mappedData) => {
        dumbbellSeries = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: 'First/Last Filed Patent',
                xAxis: xAxis,
                yAxis: yAxis,
                forceInactive: true,
                openValueXField: 'entry',
                valueXField: 'latest',
                categoryYField: 'name',
                fill: am5.color('#102B47'),
                clustered: false,
            })
        )

        dumbbellSeries.columns.template.setAll({
            height: 3,
            zIndex: 0,
        })

        // Add bullets
        dumbbellSeries.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 0,
                forceInactive: true,
                sprite: am5.Circle.new(root, {
                    fill: am5.color('#102B47'),
                    radius: 8,
                    tooltipText: `[bold]Earliest Filing Date:[/] {openValueX}`,
                    tooltipY: -4,
                }),
            })
        })

        dumbbellSeries.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 1,
                forceInactive: true,
                sprite: am5.Circle.new(root, {
                    fill: am5.color('#102B47'),
                    radius: 8,
                    tooltipText: `[bold]Latest Filing Date:[/] {valueX}`,
                    tooltipY: -4,
                }),
            })
        })

        dumbbellSeries.data.setAll(
            mappedData.map((item) => {
                const allDatesEqual =
                    item.entry === item.p25 &&
                    item.p25 === item.p50 &&
                    item.p50 === item.p75 &&
                    item.p75 === item.latest

                if (allDatesEqual) {
                    // Return only the dumbbell data when all dates are equal
                    return {
                        name: item.name,
                        entry: item.entry,
                        latest: item.latest,
                    }
                }
                // Return full data structure when dates differ
                return item
            })
        )
    }

    const generateBoxSeries = (mappedData) => {
        const dataWithRanges = mappedData.filter(
            (item) =>
                !(
                    item.entry === item.p25 &&
                    item.p25 === item.p50 &&
                    item.p50 === item.p75 &&
                    item.p75 === item.latest
                )
        )

        if (dataWithRanges.length > 0) {
            boxSeries = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: 'Patent Filing Range',
                    xAxis: xAxis,
                    yAxis: yAxis,
                    forceInactive: true,
                    openValueXField: 'p25',
                    valueXField: 'p75',
                    categoryYField: 'name',
                    fill: am5.color('#2A79D2'),
                    stroke: am5.color('#fff'),
                    clustered: false,
                })
            )

            boxSeries.columns.template.setAll({
                height: 20,
                opacity: 0.6,
            })

            boxSeries.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationX: 0, // p25 position
                    forceInactive: true,
                    sprite: am5.Rectangle.new(root, {
                        width: 6,
                        height: 20,
                        centerY: am5.percent(50),
                        fill: am5.color('#F2AF3A'),
                        stroke: am5.color('#FFFFFF'),
                        tooltipText: `[bold]25th percentile:[/] {openValueX}`,
                        tooltipY: -4,
                    }),
                })
            })

            boxSeries.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationX: 1, // p75 position
                    forceInactive: true,
                    sprite: am5.Rectangle.new(root, {
                        width: 6,
                        height: 20,
                        centerY: am5.percent(50),
                        fill: am5.color('#F2AF3A'),
                        stroke: am5.color('#FFFFFF'),
                        tooltipText: `[bold]75th percentile:[/] {valueX}`,
                        tooltipY: -4,
                    }),
                })
            })

            boxSeries.data.setAll(dataWithRanges)
        }
    }

    const generatePeerSeries = (mappedData) => {
        peerBulletSeries = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: 'Peer Filing',
                xAxis: xAxis,
                yAxis: yAxis,
                forceInactive: true,
                fill: am5.color('#359B8E'),
                valueXField: 'peerP50',
                categoryYField: 'name',
            })
        )

        peerBulletSeries.columns.template.set('visible', false)

        peerBulletSeries.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 1,
                sprite: am5.Rectangle.new(root, {
                    width: 6,
                    height: 20,
                    centerY: am5.percent(50),
                    fill: am5.color('#359B8E'),
                    stroke: am5.color('#FFFFFF'),
                    tooltipText: `[bold]Peer 50th Percentile:[/] {valueX}`,
                    tooltipY: -4,
                }),
            })
        })

        peerBulletSeries.data.setAll(mappedData)
    }

    const generateBoxMedianSeries = (mappedData) => {
        const dataWithDifferentMedian = mappedData.filter(
            (item) => item.p50 !== item.entry && item.p50 !== item.latest
        )

        boxMedianSeries = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: 'Peer Filing',
                xAxis: xAxis,
                yAxis: yAxis,
                forceInactive: true,
                fill: am5.color('#359B8E'),
                valueXField: 'p50',
                categoryYField: 'name',
                clustered: false,
            })
        )

        boxMedianSeries.columns.template.set('visible', false)

        boxMedianSeries.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 1,
                sprite: am5.Rectangle.new(root, {
                    width: 6,
                    height: 20,
                    centerY: am5.percent(50),
                    fill: am5.color('#F2AF3A'),
                    stroke: am5.color('#FFFFFF'),
                    tooltipText: `[bold]50th percentile:[/] {valueX}`,
                    tooltipY: -4,
                }),
            })
        })

        boxMedianSeries.data.setAll(dataWithDifferentMedian)
    }

    const generateLegend = () => {
        legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                y: am5.percent(95),
                layout: root.horizontalLayout,
            })
        )

        legend.data.setAll([dumbbellSeries, boxSeries, peerBulletSeries])
    }

    const disposeRangeBullet = () => {
        if (chart) chart.dispose()
        if (scrollableContainer) scrollableContainer.dispose()
        if (yAxis) yAxis.dispose()
        if (xAxis) xAxis.dispose()
        if (dumbbellSeries) dumbbellSeries.dispose()
        if (boxSeries) boxSeries.dispose()
        if (legend) legend.dispose()
    }

    const setRangeBulletData = (data) => {
        dumbbellSeries.data.setAll(data)
        dumbbellSeries.appear(1000, 100)
    }

    return {
        configureRangeBullet,
        generateAxes,
        generateSeries,
        disposeRangeBullet,
        setRangeBulletData,
    }
}
