<template>
    <div class="company-details d-flex" :class="[{ collapsed: collapseHeader }]">
        <AonAlertBox v-if="error" class="mx-auto" type="error" :title="error" />

        <CompanySideNav />

        <div class="tabs-content w-full px-4">
            <KeepAlive>
                <Profile v-if="entityStore.getCurrentCompanyTab === 1" class="h-full" />
            </KeepAlive>

            <KeepAlive>
                <IPBasicsTab v-if="entityStore.getCurrentCompanyTab === 2" class="h-full" />
            </KeepAlive>

            <div v-show="entityStore.getCurrentCompanyTab === 3" class="h-full">
                <div class="sticky w-full grey05 pt-1 pb-5">
                    <MinorTabs
                        class="mt-4"
                        :tabs="[
                            {
                                text: 'Technology Areas',
                                id: 1,
                            },
                            {
                                text: 'Benchmark Peers',
                                id: 2,
                            },
                        ]"
                        :current-minor-tab="activeTab"
                        :show-warning-id="showWarning ? 2 : null"
                        @tab-clicked="onMinorTabClicked"
                    />
                </div>
                <AonSpinner
                    v-if="entityStore.loadingEntity"
                    class="ma-auto"
                    :scale="0.5"
                    color="castleMoat"
                />
                <Moat2ProductTab class="" v-else />
            </div>

            <KeepAlive>
                <RiskTab v-if="entityStore.getCurrentCompanyTab === 4" class="h-full" />
            </KeepAlive>

            <KeepAlive>
                <ValuationsTab v-if="entityStore.getCurrentCompanyTab === 6" class="h-full" />
            </KeepAlive>

            <KeepAlive>
                <InnovationTab
                    v-if="entityStore.getCurrentCompanyTab === 7"
                    :entityId="parseInt(entityStore?.entity?.aon_entity_pk)"
                    class="h-full"
                />
            </KeepAlive>

            <KeepAlive>
                <StrategyTab v-if="entityStore.getCurrentCompanyTab === 8" class="h-full" />
            </KeepAlive>

            <KeepAlive>
                <PatentListTab v-if="isProUser && entityStore.getCurrentCompanyTab === 9" />
            </KeepAlive>
            <KeepAlive>
                <PortfolioManagerTab v-if="isProUser && entityStore.getCurrentCompanyTab === 10" />
            </KeepAlive>
        </div>

        <TechnologyDrillInModal v-if="entityStore.showDrillIn" />
    </div>
</template>

<script setup>
import { inject, onMounted, onUnmounted, ref, watch, computed } from 'vue'
import { useEntityStore, useMoat2ProductStore } from '@/stores'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useFlag } from '@unleash/proxy-client-vue'

import CompanySideNav from './CompanySideNav.vue'
import Profile from '@/components/company/tabs/profile/ProfileTab.vue'
import IPBasicsTab from '@/components/company/tabs/IPBasics/IPBasicsTab.vue'
import Moat2ProductTab from '@/components/company/tabs/moat2Product/Moat2ProductTab.vue'
import RiskTab from '@/components/company/tabs/risk/RiskTab.vue'
import InnovationTab from '@/components/company/tabs/innovation/InnovationTab.vue'
import ValuationsTab from '@/components/company/tabs/valuations/ValuationsTab.vue'
import StrategyTab from '@/components/company/tabs/strategy/StrategyTab.vue'

// Pro Tabs
import PatentListTab from '@/components/company/proTabs/patentList/PatentListTab.vue'
import PortfolioManagerTab from '@/components/company/proTabs/portfolioManager/PortfolioManagerTab.vue'

import MinorTabs from '@/components/helpers/MinorTabs.vue'
import TechnologyDrillInModal from '@/components/company/drillIn/TechnologyDrillInModal.vue'

const props = defineProps({
    entityId: {
        type: String,
        required: true,
    },
})
const router = useRouter()
const logger = inject('logger')
const { t } = useI18n()
const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()

const collapseHeader = ref(false)
const error = ref(false)
const tabUpdater = ref(0)

const showInnovationTab = useFlag('alpha.innovationTab')
const isProUser = useFlag('ipAlpha.proUsers')

watch(
    () => entityStore.entity,
    async (newVal, oldVal) => {
        if (newVal && newVal?.aon_entity_pk !== oldVal?.aon_entity_pk) {
            tabUpdater.value++
        }
    }
)

onMounted(() => {
    getEntity(router.currentRoute.value.params.targetPk)
})

onUnmounted(() => {
    entityStore.resetState()
})

const activeTab = computed((type) => {
    return moat2ProductStore.productAlignmentActiveTab
})

const showWarning = computed((type) => {
    return (
        moat2ProductStore.productAlignmentActiveTab === 1 &&
        !setsAreEqual(
            moat2ProductStore.productAlignmentTechAreaSelectionsOld,
            moat2ProductStore.productAlignmentTechAreaSelections
        ) &&
        moat2ProductStore.productAlignmentTechAreaSelectionsOld.size > 0
    )
})

const tabChange = (val) => {
    router.push({
        name: 'Research Company',
        params: { targetPk: entityStore.entity.aon_entity_pk },
        query: { tabName: val.value },
    })
}

const onMinorTabClicked = (tab) => {
    moat2ProductStore.productAlignmentActiveTab = tab
    setTimeout(() => {
        moat2ProductStore.productAlignmentTechAreaSelectionsOld =
            moat2ProductStore.productAlignmentTechAreaSelections
    }, 1000)
}

const getEntity = async (entityPk) => {
    entityStore.loadingEntity = true

    try {
        await entityStore.getEntity(entityPk)
    } catch (err) {
        logger.error(err)

        const errorType = err?.response?.status === 404 ? 'notFound' : 'general'

        error.value = t(`entityDetails.errors.${errorType}`)
    } finally {
        entityStore.loadingEntity = false
        entityStore.getPatentBreakdown(entityPk)
    }
}

const setsAreEqual = (s1, s2) => {
    return s1.size === s2.size && [...s1].every((x) => s2.has(x))
}
</script>

<style lang="scss" scoped>
@use '@moatmetrics/armory/src/sass/responsive.scss';

.company-details {
    width: 100%;
    height: 100%;
    min-height: 100vh;

    .sticky {
        position: sticky;
        top: 0;
        z-index: 10;
        border-bottom: 1px solid vars.$grey04;
    }

    .header-holder {
        background: vars.$knightsCloak80;
    }

    .tab-holder {
        background: vars.$knightsCloak60;
    }

    .background-helper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}
</style>
