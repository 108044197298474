<template>
    <div class="overview-focus w-full">
        <div class="w-full">
            <p class="mt-2 mb-4">{{ description }}</p>
            <FocusChart
                key="product_violin"
                v-show="entityStore.strategyTabNodeFilter === 'product'"
                chart-type="violin"
                :chart-data="props.dataByYear"
                :chart-overwrite-data="overwriteYearData"
            />
            <FocusChart
                key="tech_violin"
                v-show="entityStore.strategyTabNodeFilter === 'technology'"
                chart-type="treemap"
                :chart-data="props.allData"
                :chart-overwrite-data="overwriteData"
            />
            <FocusTable class="mt-4" :table-data="props.data" @filter-change="onFilterChange" />
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useEntityStore } from '@/stores'

import FocusChart from './shared/FocusChart.vue'
import FocusTable from './shared/FocusTable.vue'

const entityStore = useEntityStore()

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    dataByYear: {
        type: Array,
        default: () => [],
    },
    allData: {
        type: Array,
        default: () => [],
    },
})

const overwriteData = ref([])
const overwriteYearData = ref([])

const nodeTypeToFilterOn = computed(() => {
    return entityStore.strategyTabNodeFilter === 'product' ? 'product' : 'concept'
})

const description = computed(() => {
    return nodeTypeToFilterOn.value === 'product'
        ? `Visualize the Innovation Score over time for ${entityStore.entity.name}, showing the entry, intensity, and decline of patent filings across various products. The thickness of each band represents the relative volume or impact of filings in each product category over time.`
        : `This treemap shows participation across different product and technology classes based on ${entityStore.entity.name}’\s patent holdings. Each box represents a specific technology area, with size indicating the relative Innovation Score and color intensity representing II P/E ratio—darker shades correspond to higher values.`
})

const onFilterChange = (data) => {
    overwriteData.value = data.filter(
        (item) => item.ipNodeType === nodeTypeToFilterOn.value && item.patentCount > 0
    )

    overwriteYearData.value = props.dataByYear.map((yearData) => ({
        ...yearData,
        productTechs: yearData.productTechs.filter((tech) =>
            data.some((filterItem) => filterItem.displayName === tech.displayName)
        ),
    }))
}
</script>

<style lang="scss" scoped>
.overview-focus {
    position: relative;
}
</style>
