<template>
    <div class="content-holder">
        <div class="content-header mb-4">
            <h6 class="a-card-title">Investment Breakdown</h6>
            <p class="a-card-description mt-1">
                View all investments in {{ entityStore.entity.name }}'s product strategy space.
            </p>
        </div>
        <MoatTable
            style="width: 100%; height: 500px"
            :class="themeClass"
            :column-defs="colDefs"
            :suppress-auto-size="true"
            :row-data="props.investmentData"
            :tooltip-interaction="true"
            :tooltip-mouse-track="true"
            :tooltip-show-delay="500"
            tooltip-show-mode="whenTruncated"
            :side-bar="sideBar"
            :initial-state="initialGridState"
            @grid-ready="gridInit"
        />
    </div>
</template>

<script setup>
import { onMounted, ref, inject, nextTick, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { useEntityStore } from '@/stores'

const entityStore = useEntityStore()
const logger = inject('logger')
const filters = inject('filters')
const { t } = useI18n()
const props = defineProps({
    investmentData: {
        type: Array,
        default: [],
    },
})

const loadingData = ref(true)
const tableData = ref([])
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        field: 'investmentType',
        flex: 1,
        minWidth: 250,
        headerName: 'Investment Type',
        tooltipField: 'investmentType',
        valueFormatter: (params) => {
            return t(`investmentTypes.${params.value}`)
        },
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['venture_capital', 'private_equity', 'ipo', 'm_and_a', 'other'],
            valueFormatter: (params) => {
                return t(`investmentTypes.${params.value}`)
            },
        },
    },
    {
        field: 'investmentAmount',
        flex: 1,
        minWidth: 250,
        headerName: 'Investment Amount',
        valueFormatter: (params) => {
            return params.value ? `$${params.value.toLocaleString()}` : '-'
        },
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'investmentDate',
        flex: 1,
        minWidth: 250,
        headerName: 'Investment Date',
        valueFormatter: (p) => {
            if (p) {
                return filters.toUTCString(p.value)
            }
        },
        filter: 'agDateColumnFilter',
        sort: 'desc',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: 'Company Name',
            showFilterButton: true,
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        field: 'entityName',
        headerName: 'Company Name',
        headerTooltip: 'Company Name',
        minWidth: 300,
        sortingOrder: ['asc', 'desc', null],
        filter: 'agTextColumnFilter',
        chartDataType: 'category',
    },
    {
        field: 'productList',
        flex: 2,
        minWidth: 300,
        headerName: 'Products',
        valueGetter: (params) => {
            return params.data.productList ? params.data.productList.split('|') : '-'
        },
        filter: 'agSetColumnFilter',
    },
    {
        field: 'investors',
        flex: 2,
        minWidth: 300,
        headerName: 'Investors',
        valueGetter: (params) => {
            return params.data.investors ? params.data.investors.split('|') : '-'
        },
        filter: 'agSetColumnFilter',
    },
])

const sideBar = ref({
    toolPanels: [
        {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
        },
    ],
})

const initialGridState = ref({
    filter: {
        filterModel: {
            investmentType: {
                type: 'set',
                values: ['venture_capital'],
            },
        },
    },
})

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()

    const filtersToolPanel = gridApi.value.getToolPanelInstance('filters')
    filtersToolPanel.expandFilters(['investmentType'])

    gridApi.value.openToolPanel('filters')
}

watch(
    () => props.investmentData,
    (newVal) => {
        if (newVal.length > 0) {
            loadingData.value = false
        }
    },
    { immediate: true }
)
</script>
