<template>
    <div class="minor-tabs">
        <div class="tabs d-flex align-items-center">
            <div
                v-for="tab in props.tabs"
                :key="tab.id"
                class="tab pa-2 mr-4 d-flex align-items-center"
                :class="{ selected: tab.id === selectedTabId }"
                @click="onTabClicked(tab.id)"
            >
                <p class="tab-title bold grey01--text">{{ tab.text }}</p>
                <font-awesome-icon
                    v-if="props.showWarningId === tab.id"
                    v-tooltip="{
                        content: 'Changes made in Technology Areas will effect the Benchmark Peers',
                    }"
                    :icon="`fas fa-circle-info`"
                    class="notification-icon castle-moat-hover--text ml-2"
                    size="lg"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'

const emit = defineEmits(['tab-clicked'])
const props = defineProps({
    tabs: {
        type: Array,
        default: () => [
            {
                title: 'Tab 1',
                id: 1,
            },
            {
                title: 'Tab 2',
                id: 2,
            },
        ],
    },
    currentMinorTab: {
        type: Number,
        default: 1,
    },
    showWarningId: {
        type: Number,
        default: null,
    },
})

const selectedTabId = computed(() => {
    return props.currentMinorTab ? props.currentMinorTab : props.tabs[0].id
})

const onTabClicked = (id) => {
    emit('tab-clicked', id)
}
</script>

<style lang="scss" scoped>
.minor-tabs {
    .tab {
        background: vars.$grey04;
        cursor: pointer;

        transition: all 0.3s ease-in-out;

        &.selected {
            background: vars.$grey02;

            p {
                color: vars.$white;
            }
        }

        &:hover {
            background: vars.$grey03;
        }
    }
}
</style>
