<template>
    <div class="expiring-portfolio-visuals">
        <div class="grid-container">
            <div class="chart-container">
                <h6>Patent Expiration Forecast</h6>
                <p>
                    This chart shows how many active patents {{ targetEntityName }} and
                    {{ licensingOpportunityData?.entityName }} will have going forward according to
                    the estimated expiration date of their patents.
                </p>
                <YearlyExpirationDateComparison
                    :dataReady="
                        props.dataReady &&
                        ((licensorDataAvailable && licenseeDataAvailable) ||
                            !props.licensingOpportunityData)
                    "
                    :licensor-data="licensorData"
                    :licensee-data="licenseeData"
                    :licensor-name="targetEntityName"
                    :licensee-name="licensingOpportunityData?.entityName"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import YearlyExpirationDateComparison, {
    YearlyExpirationCount,
} from './charts/YearlyExpirationDateComparison.vue'
import { getPatentExpiration } from '@/api/ipNode'

interface LicensingOpportunityData {
    entityPk: number
    nodeId: string
    entityName: string
    [key: string]: any
}

const props = defineProps({
    licensingOpportunityData: {
        type: Object as () => LicensingOpportunityData | undefined,
    },
    targetEntityPk: {
        type: Number,
        required: true,
    },
    targetEntityName: {
        type: String,
    },
    dataReady: {
        type: Boolean,
    },
})

const licensorData = ref<YearlyExpirationCount[]>(null)
const licenseeData = ref<YearlyExpirationCount[]>(null)
const licensorDataAvailable = ref(false)
const licenseeDataAvailable = ref(false)
const fetchExpirationData = async (entityPk: number, nodeId: string) => {
    if (!entityPk || !nodeId) return []

    try {
        const params = {
            targetEntityPk: entityPk,
            numYears: 20,
        }
        const { data } = await getPatentExpiration(nodeId, params)
        return data.map((item) => ({
            year: item.year,
            count: item.remainingCount,
        }))
    } catch (error) {
        console.error('Error fetching expiration data:', error)
        return []
    }
}

// Watch for changes in licensing opportunity data
watch(
    () => [props.licensingOpportunityData, props.targetEntityPk, props.dataReady],
    async ([newLicOpData, newTargetEntityPk, dataReady]) => {
        if (newLicOpData?.nodeId) {
            licenseeDataAvailable.value = false
            licensorDataAvailable.value = false
            licenseeData.value = null
            licensorData.value = null

            // Fetch data for licensee
            licenseeData.value = await fetchExpirationData(
                newLicOpData.entityPk,
                newLicOpData.nodeId
            )

            // Fetch data for licensor
            licensorData.value = await fetchExpirationData(newTargetEntityPk, newLicOpData.nodeId)
        }
        licenseeDataAvailable.value = true
        licensorDataAvailable.value = true
    }
)
</script>

<style scoped>
.expiring-portfolio-visuals {
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.chart-container {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chart-container h3 {
    margin-top: 0;
    color: #2c3e50;
    font-size: 1.2rem;
}
</style>
