<template>
    <div class="product-class-peers">
        <AonCard class="h-full">
            <div class="card-content h-full">
                <AonCoverLoading
                    :loading="loadingPeers"
                    :title="`Loading Top Innovators for ${entityStore.entity.name}'s Products`"
                    message="This might take a few minutes if a company hasn't been analyzed before."
                ></AonCoverLoading>
                <div v-if="!loadingPeers" class="d-flex h-full flex-wrap">
                    <div
                        class="nav-holder mt-3 d-flex align-items-start w-full justify-content-between"
                    >
                        <div class="drop-holder d-flex align-items-center mt-n4">
                            <h6 class="mt-n4 bold" style="min-width: 180px">Top Innovators for:</h6>
                            <AonDropdown
                                :key="chartUpdater"
                                style="min-width: 250px"
                                class="option-dropdown mb-4"
                                :class="{ disabled: loadingProduct }"
                                :items="productListDropdown"
                                label=""
                                :init-selection="
                                    productListDropdown &&
                                    productListDropdown[currentProductIdIndex]
                                        ? productListDropdown[currentProductIdIndex]
                                        : null
                                "
                                @select-option="onSelectOption"
                            />
                        </div>
                        <div
                            class="mt-n3 d-flex align-items-center justify-content-end"
                            :class="{ disabled: loadingProduct }"
                        >
                            <div class="nav-dots d-flex align-items-center">
                                <font-awesome-icon
                                    icon="fas fa-circle-arrow-left"
                                    class="carousel-icon mr-2 castle-moat--text align-self-center cursor-pointer feedback-icon"
                                    size="2xl"
                                    @click="decrementIndex(true)"
                                />
                                <div
                                    v-for="(product, index) in productList"
                                    class="dot mx-1"
                                    :class="{ current: currentProductIdIndex === index }"
                                ></div>
                                <font-awesome-icon
                                    icon="fas fa-circle-arrow-right"
                                    class="carousel-icon ml-2 castle-moat--text align-self-center cursor-pointer feedback-icon"
                                    size="2xl"
                                    @click="incrementIndex(true)"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        class="details-holder d-flex w-full fleex-grow-1"
                        style="position: relative"
                    >
                        <AonCoverLoading
                            :loading="loadingProduct"
                            :title="`Fetching Top Innovators for ${productList[currentProductIdIndex].product}`"
                        ></AonCoverLoading>
                        <div class="chart-holder">
                            <ProductClassPeerChart
                                :key="chartUpdater"
                                :peer-data="peerData[currentProductIdIndex]"
                                @chart-clicked="onUserEvent"
                            />
                        </div>
                        <div class="table-holder w-full d-flex flex-column">
                            <ProductClassPeerTable
                                :peer-data="peerData[currentProductIdIndex]"
                                @table-event="onUserEvent"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AonCard>
    </div>
</template>
<script setup>
import { ref, inject, onMounted, watch, computed, onUnmounted } from 'vue'
import { useEntityStore, useMoat2ProductStore } from '@/stores'
import { useRoute } from 'vue-router'

import { getTopProductsByInnovation } from '@/api/companyDashboard.js'
import { getCompetitorsByProduct } from '@/api/productAlignment.js'
import ProductClassPeerTable from './ProductClassPeerTable.vue'
import ProductClassPeerChart from './ProductClassPeerChart.vue'

import {
    dataTeal,
    dataAqua,
    dataCobalt,
    dataMarine,
    dataMagenta,
    dataRaspberry,
    dataOrange,
    dataYellow,
    dataLime,
    dataGreen,
} from '@/styles/js_variables.module.scss'

const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()
const logger = inject('logger')
const eventBus = inject('eventBus')
const route = useRoute()

const peerData = ref([[], [], [], [], []])
const loadingPeers = ref(true)
const loadingProduct = ref(false)
const productList = ref([])
const currentProductIdIndex = ref(0)
const chartUpdater = ref(0)
const autoTimer = ref(null)
const userStoppedAutoTimer = ref(false)
const productListDropdown = ref(null)

onMounted(() => {
    loadingPeers.value = true
})

onUnmounted(() => {
    if (autoTimer.value) {
        clearInterval(autoTimer.value)
    }
    eventBus.off('set-arena-space-loading')
})

watch(
    () => currentProductIdIndex.value,
    (newVal, oldVal) => {
        getCompetitorData()
    }
)

watch(
    () => moat2ProductStore.productAlignmentTopCompetitors,
    (newVal, oldVal) => {
        getTopProducts()
    }
)

const setColors = computed(() => {
    let baseColors = [
        dataTeal,
        dataRaspberry,
        dataOrange,
        dataYellow,
        dataLime,
        dataGreen,
        dataMagenta,
        dataMarine,
        dataCobalt,
        dataAqua,
    ]
    return baseColors
})

const getTopProducts = async () => {
    loadingPeers.value = true
    try {
        const { data } = await getTopProductsByInnovation(entityStore.entity.aon_entity_pk)
        productList.value = data
        productListDropdown.value = data.map((item, index) => ({
            itemText: item.product,
            value: item.product,
            index: index,
        }))
        getCompetitorData(data)
    } catch (error) {
        logger.error(error)
    }
}

const getCompetitorData = async (products = null) => {
    if (
        peerData.value[currentProductIdIndex.value] === undefined ||
        peerData.value[currentProductIdIndex.value].length === 0
    ) {
        if (products !== null) {
            loadingPeers.value = true
        } else {
            loadingProduct.value = true
        }

        try {
            await getCompetitorsByProduct(
                route.params.jobPk ? route.params.jobPk : moat2ProductStore.alignmentPk,
                [productList.value[currentProductIdIndex.value].nodeId],
                null,
                true,
                6
            )
                .then((response) => {
                    const currentEntityName = entityStore.entity.name
                    peerData.value[currentProductIdIndex.value] = response.data
                        .sort((a, b) => {
                            if (a.aonEntityName === currentEntityName) return -1
                            if (b.aonEntityName === currentEntityName) return 1
                            return b.powerScore - a.powerScore
                        })
                        .map((item, index) => ({
                            ...item,
                            color: setColors.value[index],
                        }))
                })
                .catch((error) => {
                    logger.error(error)
                })
        } catch (err) {
            logger.error(err)
        } finally {
            loadingPeers.value = false
            loadingProduct.value = false
            chartUpdater.value++

            if (!autoTimer.value) {
                if (!userStoppedAutoTimer.value) {
                    startAutoTimer()
                }
            }
        }
    } else {
        chartUpdater.value++

        if (!autoTimer.value) {
            if (!userStoppedAutoTimer.value) {
                startAutoTimer()
            }
        }
    }
}

const onSelectOption = (option) => {
    userStoppedAutoTimer.value = true

    if (userStoppedAutoTimer.value) {
        clearInterval(autoTimer.value)
        autoTimer.value = null
    }

    currentProductIdIndex.value = option.index
}

const incrementIndex = (userClicked = false) => {
    if (userClicked) {
        clearInterval(autoTimer.value)
        autoTimer.value = null
        userStoppedAutoTimer.value = true
    }
    if (autoTimer.value) {
        clearInterval(autoTimer.value)
        autoTimer.value = null
    }
    if (currentProductIdIndex.value < productList.value.length - 1) {
        currentProductIdIndex.value++
    } else {
        currentProductIdIndex.value = 0
    }
}

const decrementIndex = (userClicked = false) => {
    if (userClicked) {
        clearInterval(autoTimer.value)
        autoTimer.value = null
        userStoppedAutoTimer.value = true
    }
    if (autoTimer.value) {
        clearInterval(autoTimer.value)
        autoTimer.value = null
    }

    if (currentProductIdIndex.value > 0) {
        currentProductIdIndex.value--
    } else {
        currentProductIdIndex.value = productList.value.length - 1
    }
}

const startAutoTimer = () => {
    autoTimer.value = setInterval(() => {
        incrementIndex()
    }, 10000)
}

const onUserEvent = (event) => {
    clearInterval(autoTimer.value)
    autoTimer.value = null
    userStoppedAutoTimer.value = true
}
</script>

<style lang="scss" scoped>
.product-class-peers {
    width: 100%;
    height: 420px;
    position: relative;

    :deep(.aon-dropdown) {
        .trigger {
            width: calc(100% + 15px);
        }
    }

    .dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: rgba(vars.$grey01, 0.4);

        &.current {
            background: vars.$knightsCloak;
        }
    }

    .chart-holder {
        width: 20%;
    }

    .table-holder {
        width: 80%;
    }
}
</style>
