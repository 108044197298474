<template>
    <div class="patents-focus w-full">
        <div class="w-full">
            <p>{{ description }}</p>
            <FocusChart
                :key="`bar_chart-${props.updateKey}`"
                chart-type="bar"
                :chart-data="props.data"
                :chart-overwrite-data="overwriteData"
            />
            <FocusTable
                class="mt-4"
                chart-type="bar"
                :table-data="props.data"
                @filter-change="onFilterChange"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useEntityStore } from '@/stores'

import FocusChart from './shared/FocusChart.vue'
import FocusTable from './shared/FocusTable.vue'

const entityStore = useEntityStore()

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    updateKey: {
        type: Number,
        default: 0,
    },
})

const overwriteData = ref([])

const description = computed(() => {
    return entityStore.strategyTabNodeFilter === 'product'
        ? `This chart shows the number of active U.S. patents—both applications and granted patents—held by ${entityStore.entity.name} across each product category, highlighting where its innovation is most concentrated.`
        : `This chart shows the number of active U.S. patents—both applications and granted patents—held by ${entityStore.entity.name} across each product and technology category, highlighting where its innovation is most concentrated.`
})

const onFilterChange = (data) => {
    overwriteData.value = data
}
</script>

<style lang="scss" scoped>
.patents-focus {
    position: relative;
}
</style>
