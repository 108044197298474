<template>
    <div id="company-screener">
        <ScreenerHeader
            :loading="loading"
            :saving="saving"
            :screener="screener"
            :screenerModified="screenerModified"
            @openSaveAsModal="openSaveAsModal"
            @openScreenerModal="openScreenerModal"
            @resetScreener="resetScreener"
            @saveScreener="saveScreener"
        />
        <AonContainer add-page-padding>
            <AonRow>
                <AonCol class="aon-col-12 px-0">
                    <ScreenerCompanyTable v-if="!loading" />
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>

    <!-- <ScreenerCreateCard
        v-if="showSaveAsScreeners"
        :screener="screener"
        :query-filters="queryFilters"
        type="COMPANY"
        @cancel="closeSaveAsModal"
        @submit="closeSaveAsModal"
    /> -->
</template>

<script setup>
import ScreenerHeader from '@/components/screeners/ScreenerHeader.vue'
import ScreenerCompanyTable from '@/components/screeners/ScreenerCompanyTable.vue'
import ScreenerCreateCard from '@/components/screeners/ScreenerCreateCard.vue'
import { useSharedScreenerComposable } from '@/composables/screener'

const {
    loading,
    saving,
    screener,
    screenerModified,
    showSaveAsScreeners,
    queryFilters,
    closeSaveAsModal,
    saveScreener,
    resetScreener,
    openScreenerModal,
    openSaveAsModal,
} = useSharedScreenerComposable('COMPANY')
</script>

<style scoped lang="scss">
#company-screener {
    min-height: 100vh;
    height: 100%; // minus header nav and tabs
    width: 100%;

    .screener-tabs {
        height: 60px;
        border-top: 1px solid vars.$grey05;

        &__aon-col {
            padding: 7px 0px;
        }
    }
}
</style>
