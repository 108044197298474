<template>
    <div class="competitors-output-table">
        <div class="table-quick-filters d-flex mb-5">
            <AonDropdown
                :key="filterUpdater"
                class="mr-5"
                :class="{ disabled: loadingWatchlists }"
                label="Filter By"
                :items="quickFilterOptions"
                :init-selection="quickFilterOptions ? quickFilterOptions[0] : null"
                style="width: 300px"
                @select-option="changeQuickFilterValue"
            />
            <AonInput
                style="width: 500px"
                v-model="searchTerm"
                search-input
                label="Search Contender Table"
                placeholder="Search Contender Table"
                @keyup="searchTable()"
                @clear="clearSearch()"
            />
            <AonToggleSwitch
                v-show="showToggleByUltimateParent"
                label="Group by Ultimate Parent"
                label-end
                class="group-by-parent-toggle"
                :switchToggled="true"
                @switch="toggleGroupByUltimateParent"
            />
        </div>
        <div v-if="columnsReady" class="table-holder">
            <AonCoverLoading :loading="loadingTable" title="Populating Competitors" />
            <MoatTable
                style="width: 100%; height: 600px"
                :class="themeClass"
                :column-defs="colDefs"
                :row-data="props?.allTableData"
                :tooltip-interaction="true"
                :tooltip-mouse-track="true"
                :tooltip-show-delay="500"
                tooltip-show-mode="whenTruncated"
                :autoSizeStrategy="sizingStrategy"
                :getRowClass="getRowClass"
                :is-external-filter-present="shouldApplyQuickFilter"
                :does-external-filter-pass="applyQuickFilter"
                :sorting-order="['desc', 'asc', null]"
                :enable-charts="true"
                :cell-selection="true"
                :sideBar="sideBar"
                :row-selection="rowSelection"
                :selection-column-def="selectionColumnDef"
                @grid-ready="gridInit"
                @row-selected="selectRow"
            />
        </div>
        <OutputCompetitorTechBreakdownModal
            v-if="showTechBreakdown"
            :entity-pk-list="selectedRows"
            :tech-area-ids="props.techAreaIds"
            :alignment-run-pk="props.alignmentRunPk"
            @close-entity-tech-breakdown-modal="showTechBreakdown = false"
        />
    </div>
</template>

<script setup>
// Global
import {
    onMounted,
    ref,
    inject,
    computed,
    watch,
    nextTick,
    onBeforeUnmount,
    onBeforeMount,
    onUpdated,
} from 'vue'
import { useI18n } from 'vue-i18n'
import {
    useEntityStore,
    useArenaOutputStore,
    useDatasetStore,
    usePatentViewerStore,
} from '@/stores'
import { useAuth } from '@/auth/authPlugin'
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-vue'

// local
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator, dateFilterComparator } from '@/components/moatTable/helpers/compare.js'
import { formatTpeRange } from '@/lib/financial.js'
import OutputCompetitorTechBreakdownModal from './OutputCompetitorTechBreakdownModal.vue'
import { getInvestmentGroup } from '@/lib/investments'

// injectors
const eventBus = inject('eventBus')
const { t } = useI18n()
const logger = inject('logger')
const filters = inject('filters')
const { user } = useAuth()

// stores
const entityStore = useEntityStore()
const arenaOutputStore = useArenaOutputStore()
const datasetStore = useDatasetStore()
const patentViewerStore = usePatentViewerStore()

// flags
const showToggleByUltimateParent = useFlag('ipAlpha.groupContendersByUltimateParent')
const showAcquisitionTargets = useFlag('ipAlpha.showAcquisitionTargets')
const showPotentialBuyers = useFlag('ipAlpha.showPotentialBuyers')
const showCrunchbaseArchOnly = useFlag('ipAlpha.archUsersOnly')
const isProUser = useFlag('ipAlpha.proUsers')
const { flagsReady } = useFlagsStatus()

// Refs/Props
const props = defineProps({
    tableData: {
        type: Array,
        default: () => [],
    },
    allTableData: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    everythingDoneLoading: {
        type: Boolean,
        default: false,
    },
    activeFilter: {
        type: String,
        default: 'all',
    },
    pinnedEntities: {
        type: Array,
        default: () => [],
    },
    techAreaIds: {
        type: Array,
        default: () => [],
    },
    alignmentRunPk: {
        type: [Number, String],
        default: undefined,
    },
})

const emit = defineEmits([
    'allow-regenerate',
    'update-competitor-table-data',
    'groom-data',
    'get-competitors-from-watchlist',
    'refresh-competitor-table',
])

const columnsReady = ref(false)
const gridApi = ref(null)
const sideBar = ref(null)
const filterUpdater = ref(0)
const loadingCompetitorData = ref(false)
const loadingWatchlists = ref(true)
const quickFilterOptions = ref([
    {
        itemText: 'All',
    },
    {
        itemText: 'Public',
    },
    {
        itemText: 'Private',
    },
    {
        itemText: 'Gov/Edu',
    },
    {
        itemText: 'Watchlist',
        arrowSide: 'right',
        showSubItems: false,
        items: [],
    },
    {
        itemText: 'Pinned',
    },
])
const showTechBreakdown = ref(false)
const watchlistPkFilter = ref(null)
var externalFilterValue = 'All'
const groupByUltimateParent = ref(true)
const searchTerm = ref(null)
const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref({})
const rowSelection = ref({
    mode: 'multiRow',
    checkboxes: true,
    enableClickSelection: false,
    headerCheckbox: false,
})
const selectionColumnDef = ref({
    pinned: 'left',
    lockPinned: true,
    suppressHeaderMenuButton: true,
    maxWidth: 40,
})
const selectedRows = ref([])
const colDefs = ref([
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.companyName'),
            headerName: 'Company Name',
            showFilterButton: true,
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        field: 'aonEntityName',
        headerName: 'Company Name',
        headerTooltip: 'Company Name',
        minWidth: 300,
        flex: 1,
        sortingOrder: ['asc', 'desc', null],
        filter: 'agTextColumnFilter',
        pinned: 'left',
        lockPosition: true,
        chartDataType: 'category',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: 'Ultimate Parent Company',
            showFilterButton: true,
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        field: 'ultimateAonEntityName',
        headerName: 'Ultimate Parent Name',
        headerTooltip: 'Ultimate Parent Name',
        minWidth: 300,
        flex: 1,
        sortingOrder: ['asc', 'desc', null],
        filter: 'agTextColumnFilter',
        pinned: 'left',
        hide: !groupByUltimateParent.value?.value,
        chartDataType: 'category',
    },
    {
        field: 'competitorRank',
        headerName: 'Rank',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.competitorRank.toLocaleString()}`
            }
        },
        headerTooltip: 'Rank',
        minWidth: 120,
        flex: 1,
        sortingOrder: ['asc', 'desc', null],
        filter: 'agNumberColumnFilter',
        filterParams: {
            inRangeInclusive: true,
        },
        chartDataType: 'category',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innovationScore'),
            headerName: 'Innovation Score',
        },
        field: 'powerScore',
        headerName: 'Innovation Score',
        valueGetter: (p) => {
            if (p.data) {
                return parseInt(p.data.powerScore.toFixed(0))
            }
        },
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.powerScore.toFixed(0)).toLocaleString()}`
            }
        },
        headerTooltip: 'Innovation Score',
        minWidth: 200,
        flex: 1,
        filter: 'agNumberColumnFilter',
        chartDataType: 'series',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
            headerName: 'Momentum',
        },
        cellRenderer: 'MTCustomCellScoreChange',
        cellRendererParams: {
            lowerBound: 40,
            upperBound: 50,
            tooltip: {
                increasing: 'Increasing Innovation',
                decreasing: 'Decreasing Innovation',
                steady: 'Steady Innovation',
            },
        },
        field: 'opportunity',
        headerName: 'Momentum',
        headerTooltip: 'Momentum',
        flex: 1,
        minWidth: 150,
        filter: 'agNumberColumnFilter',
        valueGetter: (p) => {
            if (p.data) {
                return Math.round(p.data.opportunity)
            }
        },
        valueFormatter: (p) => {
            if (p.data) {
                return `${Math.round(p.data.opportunity)}`
            }
        },
        chartDataType: 'series',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.percentOfPortfolio'),
            headerName: 'Focus',
        },
        field: 'portfolioFocusPercentage',
        headerName: 'Focus',
        valueGetter: (p) => {
            if (p.data) {
                return parseInt(p.data.portfolioFocusPercentage.toFixed(0))
            }
        },
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.portfolioFocusPercentage.toFixed(0)}%`
            }
        },
        headerTooltip: 'Focus',
        flex: 1,
        minWidth: 120,
        filter: 'agNumberColumnFilter',
        chartDataType: 'series',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
            headerName: 'II P/E',
        },
        field: 'tpe',
        headerName: 'II P/E',
        headerTooltip: 'Innovation Implied P/E',
        flex: 1,
        minWidth: 120,
        valueGetter: (p) => {
            if (p.data) {
                return parseInt(p.data.tpe.toFixed(0))
            }
        },
        valueFormatter: (p) => {
            if (p.data) {
                return formatTpeRange(p.data.tpe, p.data.tpeLb, p.data.tpeUb)
            }
        },
        filter: 'agNumberColumnFilter',
        chartDataType: 'series',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innovationStart'),
            headerName: 'Innovation Start',
        },
        valueGetter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.entryPriorityDt) === '1/1/1'
                    ? '---'
                    : new Date(p.data.entryPriorityDt).getFullYear()
            }
        },
        filterParams: {
            inRangeInclusive: true,
        },
        field: 'entryPriorityDt',
        headerName: 'Innovation Start',
        headerTooltip: `Innovation Start`,

        flex: 1,
        minWidth: 200,
        filter: 'agNumberColumnFilter',
        chartDataType: 'excluded',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.fiveYear'),
            headerName: 'Expiring Soon',
        },
        field: 'fiveYearExpirePercent',
        headerName: 'Expiring Soon',
        valueGetter: (p) => {
            if (p.data) {
                return parseInt(p.data.fiveYearExpirePercent.toFixed(0))
            }
        },
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.fiveYearExpirePercent.toFixed(0)}%`
            }
        },
        headerTooltip: 'Expiring Soon',
        flex: 1,
        minWidth: 170,
        filter: 'agNumberColumnFilter',
        // chartDataType: 'series'
        chartDataType: 'excluded',
    },
    {
        field: 'hqCountryName',
        headerName: 'HQ Country',
        hide: true,
        filter: 'agSetColumnFilter',
        chartDataType: 'excluded',
    },
    {
        field: 'entityType',
        headerName: 'Ownership Status',
        hide: true,
        filter: 'agSetColumnFilter',
        chartDataType: 'excluded',
    },
    {
        field: 'totalRevenue',
        headerName: 'Revenue',
        hide: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'lessThanOrEqual',
                'greaterThan',
                'greaterThanOrEqual',
                'inRange',
            ],
            defaultOption: 'greaterThan',
        },
        chartDataType: 'excluded',
        valueFormatter: (p) => {
            if (p.data) {
                return filters.abbreviate(p.data.totalRevenue)
            }
        },
    },
    {
        field: 'employeeCount',
        headerName: 'Employee Count',
        hide: true,
        filter: 'agSetColumnFilter',
        chartDataType: 'excluded',
    },
    {
        field: 'avgValidityScore',
        headerName: 'Avg Validity Score',
        hide: true,
        valueGetter: (p) => {
            if (p.data) {
                return parseFloat(p.data.avgValidityScore.toFixed(2))
            }
        },
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.avgValidityScore.toFixed(2)
            }
        },
        filter: 'agNumberColumnFilter',
        chartDataType: 'excluded',
    },
    {
        field: 'priceToEarnings',
        headerName: 'Price to Earnings',
        hide: true,
        filter: 'agNumberColumnFilter',
        chartDataType: 'excluded',
    },
    {
        field: 'yoyRevenueGrowthPct',
        headerName: 'YoY Revenue Growth %',
        hide: true,
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'marketValue',
        headerName: 'Market Cap',
        hide: true,
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'currency',
        headerName: 'Currency',
        hide: true,
        filter: 'agSetColumnFilter',
    },
    {
        field: 'lastFundingRoundInvestmentType',
        headerName: 'Latest Funding Round',
        hide: true,
        valueGetter: (p) => {
            if (p.data) {
                return getInvestmentGroup(p.data.lastFundingRoundInvestmentType)
            }
        },
        filter: 'agSetColumnFilter',
    },
])
const previousTabType = ref(null)

onBeforeMount(() => {})

onMounted(async () => {
    setTimeout(addFeatureFlagColumns, 1000)
    getUserWatchLists()
})

onUpdated(() => {})

onBeforeUnmount(() => {
    eventBus.all.clear()
})

watch(
    () => props.allTableData,
    (newVal, oldVal) => {
        if (
            newVal?.find((n) => n.isCompetitor) &&
            !quickFilterOptions.value.find((n) => n.itemText === 'Direct Competitors')
        ) {
            quickFilterOptions.value.splice(1, 0, { itemText: 'Direct Competitors' })
        }

        if (
            showAcquisitionTargets.value &&
            newVal?.find((n) => n.isAcquisitionTarget) &&
            !quickFilterOptions.value.find((n) => n.itemText === 'Acquisition Target')
        ) {
            quickFilterOptions.value.splice(1, 0, { itemText: 'Acquisition Target' })
        }

        if (
            showPotentialBuyers.value &&
            newVal?.find((n) => n.isPotentialBuyer) &&
            !quickFilterOptions.value.find((n) => n.itemText === 'Potential Buyer')
        ) {
            quickFilterOptions.value.splice(1, 0, { itemText: 'Potential Buyer' })
        }
    },
    { immediate: true }
)

const loadingTable = computed(() => {
    return props.loading || loadingCompetitorData.value
})

const gridInit = (params) => {
    sideBar.value = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivotMode: true,
                },
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
            {
                id: 'actions',
                labelDefault: `Actions`,
                labelKey: 'actions',
                iconKey: 'aggregation',
                toolPanel: 'MTCustomToolPanelActions',
                toolPanelParams: {
                    showTechAreaBreakdownAction: true,
                    tableExportAction: true,
                    techAreaBreakdownAction: (params) => {
                        showTechBreakdown.value = true
                    },
                    tableExportAction: () => {
                        gridApi.value.exportDataAsExcel()
                    },
                    clearSelections: () => {
                        gridApi.value.deselectAll()
                    },
                },
            },
        ],
    }
    gridApi.value = params.api
    loadingWatchlists.value = false
}

const addFeatureFlagColumns = () => {
    if (!flagsReady.value) return

    const crunchbaseColumn = {
        field: 'crunchbaseRank',
        headerName: 'Crunchbase Rank',
        valueFormatter: (p) => p.data?.crunchbaseRank?.toLocaleString() || '--',
        filter: 'agNumberColumnFilter',
        chartDataType: 'category',
    }

    const patentListColumn = {
        suppressHeaderMenuButton: true,
        cellRenderer: 'MTContextMenu',
        cellRendererParams: {
            actionsList: [
                {
                    name: 'View Company Patents',
                    icon: 'fa-square-list',
                    actionName: 'patents',
                    patentsAction: async (params) => {
                        patentViewerStore.nodeIdList.push(...props.techAreaIds)
                        patentViewerStore.entityPkList.push(params.data.aonEntityPk)
                        patentViewerStore.entityName = params.data.aonEntityName
                        await nextTick()
                        patentViewerStore.showPatentViewerList = true
                    },
                },
            ],
            highlightLabel: true,
        },
        pinned: 'right',
        width: 42,
    }

    if (showCrunchbaseArchOnly.value) {
        colDefs.value.splice(3, 0, crunchbaseColumn)
    }

    if (isProUser.value) {
        colDefs.value.push(patentListColumn)
    }

    columnsReady.value = true
}

const getRowClass = (params) => {
    if (entityStore.entity && params.data.aonEntityName === entityStore.entity.name) {
        return 'target-entity'
    }
    if (
        arenaOutputStore.targetCompany.length > 0 &&
        arenaOutputStore.targetCompany[0].aonEntityName === params.data.aonEntityName
    ) {
        return 'target-entity'
    } else {
        return ''
    }
}

// search filter
const searchTable = () => {
    gridApi.value.setGridOption('quickFilterText', searchTerm.value)
}

const clearSearch = () => {
    searchTerm.value = ''
    searchTable()
}

// Quick filter
const shouldApplyQuickFilter = () => {
    // if externalFilterValue is not 'All', then we are filtering
    return externalFilterValue !== 'All'
}

const changeQuickFilterValue = (tab) => {
    if (tab.type === 'watchlist' || previousTabType.value === 'watchlist') {
        var params = {
            groupByUltimateParent: groupByUltimateParent.value,
        }
        if (tab.type === 'watchlist') {
            watchlistPkFilter.value = tab.itemValue
            params = {
                watchlistPk: tab.itemValue,
                groupByUltimateParent: groupByUltimateParent.value,
            }
        } else {
            watchlistPkFilter.value = null
        }
        emit('refresh-competitor-table', params)
    }

    if (tab.type !== 'watchlist') {
        externalFilterValue = tab.itemText
        setTimeout(() => {
            gridApi.value.onFilterChanged()
        }, 1000)
    }
    previousTabType.value = tab.type
}

const applyQuickFilter = (node) => {
    if (node.data) {
        switch (externalFilterValue) {
            case 'Public':
                return node.data.entityType === 'Public Company'
            case 'Private':
                return (
                    node.data.entityType !== 'Government' &&
                    node.data.entityType !== 'College/University' &&
                    node.data.entityType !== 'Public Company'
                )
            case 'Gov/Edu':
                return (
                    node.data.entityType === 'Government' ||
                    node.data.entityType === 'College/University'
                )
            case 'Direct Competitors':
                return node.data.isCompetitor
            case 'Acquisition Target':
                return node.data.isAcquisitionTarget
            case 'Potential Buyer':
                return node.data.isPotentialBuyer
            case 'Pinned':
                return props.pinnedEntities.includes(node.data.aonEntityPk)
            default:
                return true
        }
    }
    return true
}

const getUserWatchLists = async () => {
    loadingWatchlists.value = true

    try {
        let callData = await datasetStore.getDatasets([], user.value.id)
        if (callData && callData.results) {
            let results = callData.results.map((item) => {
                return {
                    itemText: item.dataset_name,
                    itemValue: item.dataset_pk,
                    itemDesc: item.dataset_description,
                    type: 'watchlist',
                }
            })
            quickFilterOptions.value
                .find((item) => item.itemText === 'Watchlist')
                .items.push(...results)
        }
    } catch (err) {
        eventBus.emit('snacktime', {
            type: 'error',
            message: 'Failed to Load competitor Watchlist',
        })
    } finally {
        filterUpdater.value++
        loadingWatchlists.value = false
    }
}

const toggleGroupByUltimateParent = (event) => {
    groupByUltimateParent.value = event.value
    arenaOutputStore.groupByUltimateParent = event.value
    const params = {
        watchlistPk: watchlistPkFilter.value,
        groupByUltimateParent: event.value,
    }
    gridApi.value.setColumnsVisible(['ultimateAonEntityName'], !event.value)
    emit('refresh-competitor-table', params)
}

const selectRow = (params) => {
    eventBus.emit('row-selected')
    selectedRows.value = gridApi.value.getSelectedRows()
    if (selectedRows.value.length >= 1) {
        gridApi.value.openToolPanel('actions')
    } else {
        gridApi.value.closeToolPanel()
    }

    gridApi.value.refreshToolPanel()
}
</script>

<style lang="scss" scoped>
.competitors-output-table {
    width: 100%;
    min-height: 400px;
    position: relative;

    :deep(.target-entity) {
        background: vars.$grey03 !important;
        *:not:deep(.ticker) {
            color: white !important;
        }
        //color: white !important;

        .link {
            color: white !important;
        }
    }

    :deep(.aon-dropdown) {
        ul {
            max-height: 225px !important;
        }
    }
}

.group-by-parent-toggle {
    margin-left: auto;
    align-self: center;
}
</style>
