<template>
    <div class="top-investor-card mt-5">
        <AonCard class="remove-padding">
            <div class="card-content">
                <div class="content-header pa-5">
                    <AonDropdown
                        :key="topListOptionsStartingIndex"
                        class="option-dropdown mb-4"
                        :class="{ disabled: loadingTopList }"
                        :items="topListOptions"
                        label=""
                        :init-selection="
                            topListOptions && topListOptions[topListOptionsStartingIndex]
                                ? topListOptions[topListOptionsStartingIndex]
                                : null
                        "
                        @select-option="selectTopList"
                    />
                    <p>
                        Knowing who’s behind the major investments in
                        <span style="font-weight: bold">{{ nodeData.displayName }}</span>
                        helps you identify the key players driving growth and innovation. The most
                        active investors shape the future of the market, providing the capital and
                        support that fuel expansion and success.
                    </p>
                </div>
                <AonSpinner v-if="loadingTopList" class="ml-auto mr-auto my-5" :scale="0.3" />
                <div
                    class="investor-accordion-holder"
                    style="position: relative; min-height: 200px"
                >
                    <div v-if="!loadingTopList && !noData" class="accordion-inner">
                        <div
                            class="accordion-header px-5 pt-5 pb-2 w-full d-flex align-items-center justify-content-between"
                        >
                            <p class="small bold">Investor Name</p>
                            <p class="small bold" style="min-width: 150px">
                                {{ computedListCountLabel }}
                            </p>
                        </div>
                        <div
                            v-for="investor in accordionList"
                            class="bellow"
                            :class="[
                                { show: investor.showInvestments },
                                { 'ignore-accordion': currentlySelectedTopList === 'ipo' },
                            ]"
                        >
                            <div
                                class="bellow-cta pa-5 d-flex align-items-center justify-content-between"
                                @click="onShowInvestments(investor)"
                            >
                                <div class="label d-flex align-items-center">
                                    <font-awesome-icon
                                        :icon="`fas fa-caret-right`"
                                        class="mr-2 grey04--text bellow-caret"
                                    />
                                    <a :href="entityRouteLink(investor.entityPk)" target="_blank">
                                        <p class="bold link">
                                            {{ investor.entityName }}
                                        </p>
                                    </a>
                                </div>
                                <p v-if="investor.investmentCount" style="min-width: 150px">
                                    {{ investor.investmentCount }}
                                </p>
                                <p v-if="investor.acquireeCount" style="min-width: 150px">
                                    {{ investor.acquireeCount }}
                                </p>
                                <p v-if="investor.valuationPrice" style="min-width: 150px">
                                    {{ $filters.abbreviate(investor.valuationPrice) }}
                                </p>
                            </div>
                            <div class="bellow-content">
                                <div class="overflow-contain pa-5 mt-n4">
                                    <div
                                        v-for="item in investor.investments
                                            ? investor.investments
                                            : investor.acquirees"
                                        class="investment pt-3 pb-2 px-3 mb-2 d-flex allign-items-center justify-content-between"
                                    >
                                        <div class="company-holder">
                                            <p class="extra-small black--text bold uppercase">
                                                Company Name
                                            </p>
                                            <a
                                                :href="entityRouteLink(item.entityPk)"
                                                target="_blank"
                                            >
                                                <p class="link">
                                                    {{ item.entityName }}
                                                </p>
                                            </a>
                                        </div>
                                        <div class="stats-holder d-flex align-items-center">
                                            <div v-if="item.investmentDate" class="stat mr-5">
                                                <p
                                                    class="extra-small black--text bold uppercase mb-1"
                                                >
                                                    Investment Date
                                                </p>
                                                <p class="grey01--text">
                                                    {{ $filters.toUTCString(item.investmentDate) }}
                                                </p>
                                            </div>
                                            <div v-if="item.acquireDate" class="stat mr-5">
                                                <p
                                                    class="extra-small black--text bold uppercase mb-1"
                                                >
                                                    Acquire Date
                                                </p>
                                                <p class="grey01--text">
                                                    {{ $filters.toUTCString(item.acquireDate) }}
                                                </p>
                                            </div>
                                            <div v-if="item.investmentAmount" class="stat">
                                                <p
                                                    class="extra-small black--text bold uppercase mb-1"
                                                >
                                                    Total Raised in Round
                                                </p>
                                                <p class="grey01--text">
                                                    {{ $filters.abbreviate(item.investmentAmount) }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AonCoverLoading
                        v-if="noData"
                        :loading="noData ? true : false"
                        :show-spinner="false"
                        title="No Data Found"
                    >
                        <template #header>
                            <div class="icon">
                                <font-awesome-icon
                                    icon="fas fa-circle-exclamation"
                                    class="grey01--text"
                                    size="2xl"
                                />
                            </div>
                        </template>
                    </AonCoverLoading>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, inject, onMounted, computed } from 'vue'
import { getNodeTopPrivateInvestors, getNodeTopAcquirers, getNodeTopIPOs } from '@/api/ipNode'
import { entityRouteLink } from '@/router/utils'
const $filters = inject('filters')
const logger = inject('logger')

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {
            return {
                displayName: 'Company Name',
                id: '12345',
            }
        },
    },
    type: {
        type: String,
        default: 'pa',
    },
})

const currentlySelectedTopList = ref('private')
const loadingTopList = ref(true)
const accordionList = ref(null)
const noData = ref(false)
const topListOptions = ref([
    {
        itemText: 'Top Private Equity Investors',
        value: 'private',
    },
    {
        itemText: 'Top Venture Capital Investors',
        value: 'capital',
        tooltipText: 'Top Private Equity Investors',
    },
    {
        itemText: 'Top Acquirers',
        value: 'acquirers',
    },
    {
        itemText: "Top IPO's",
        value: 'ipo',
    },
])
const topListOptionsStartingIndex = ref(0)

onMounted(() => {
    checkAvailableData()
})

const computedListCountLabel = computed(() => {
    const labels = {
        private: 'Investments',
        capital: 'Investments',
        acquirers: 'Acquisitions',
        ipo: 'Valuation Price',
    }
    return labels[currentlySelectedTopList.value] || ''
})

const onShowInvestments = (investor) => {
    if (currentlySelectedTopList.value === 'ipo') {
        return
    }

    if (investor.showInvestments) {
        investor.showInvestments = false
    } else {
        accordionList.value.forEach((element) => (element.showInvestments = false))
        investor.showInvestments = true
    }
}

const selectTopList = (type) => {
    currentlySelectedTopList.value = type.value
    noData.value = false
    onGetTopList()
}

const onGetTopList = async () => {
    loadingTopList.value = true
    try {
        const topListActions = {
            private: async () => {
                const params = { investment_type: 'private_equity' }
                return await getNodeTopPrivateInvestors(props.nodeData.id, params)
            },
            capital: async () => {
                const params = { investment_type: 'venture_capital' }
                return await getNodeTopPrivateInvestors(props.nodeData.id, params)
            },
            acquirers: async () => await getNodeTopAcquirers(props.nodeData.id),
            ipo: async () => await getNodeTopIPOs(props.nodeData.id),
        }

        const selectedAction = topListActions[currentlySelectedTopList.value]
        if (selectedAction) {
            const { data } = await selectedAction()
            data.length === 0 ? (noData.value = true) : (accordionList.value = data)
        }
    } catch (error) {
        logger.error(error)
    } finally {
        loadingTopList.value = false
    }
}

const checkAvailableData = async () => {
    try {
        const results = await Promise.all([
            getNodeTopPrivateInvestors(props.nodeData.id, { investment_type: 'private_equity' }),
            getNodeTopPrivateInvestors(props.nodeData.id, { investment_type: 'venture_capital' }),
            getNodeTopAcquirers(props.nodeData.id),
            getNodeTopIPOs(props.nodeData.id),
        ])

        topListOptions.value = topListOptions.value.map((option, index) => ({
            ...option,
            isDisabled: results[index].data.length === 0,
        }))

        const firstAvailableIndex = results.findIndex((result) => result.data.length > 0)
        if (firstAvailableIndex >= 0) {
            currentlySelectedTopList.value = topListOptions.value[firstAvailableIndex].value
            topListOptionsStartingIndex.value = firstAvailableIndex
        }
        onGetTopList()
    } catch (error) {
        logger.error(error)
    }
}
</script>

<style lang="scss" scoped>
.top-investor-card {
    width: 100%;
    min-height: 200px;

    .remove-padding {
        padding: 0 !important;
    }

    .option-dropdown {
        max-width: 300px;
    }

    .accordion-header {
        border-top: 1px solid vars.$grey04;
        border-bottom: 1px solid vars.$grey04;
    }

    .bellow {
        &:last-of-type {
            .bellow-cta {
                border-radius: 0 0 8px 8px;
                border-bottom: none;
            }
        }

        &.show {
            .bellow-cta {
                background: vars.$grey05;
                border-bottom: 1px solid transparent;
            }
            .bellow-content {
                background: vars.$grey05;
                max-height: 500px;
                overflow: auto;
            }

            .bellow-caret {
                transform: rotate(90deg);
                color: black;
            }
        }

        &.ignore-accordion {
            .bellow-caret {
                display: none;
            }

            .bellow-cta {
                cursor: auto;

                &:hover {
                    background: white;
                }
            }
        }
    }

    .bellow-cta {
        cursor: pointer;
        border-bottom: 1px solid vars.$grey04;

        transition: all 0.5s ease-in-out;

        &:hover {
            background-color: vars.$grey05;
        }
    }

    .bellow-content {
        max-height: 0;
        overflow: hidden;

        transition: all 0.5s ease-in-out;

        &:last-of-type {
            .investment {
                margin-bottom: 0;
            }
        }

        .investment {
            background: white;
        }
    }
}
</style>
