<template>
    <AonModal
        class="patent-list"
        :class="[{ inline: props.inline }, { absolute: props.absolute }]"
        message=""
        col-width="10"
        @close="closePatentList"
    >
        <div class="modal-content d-flex flex-column">
            <div class="patent-list-header d-flex align-items-center">
                <div class="source-display py-4 px-4">
                    <div class="basics-wrap d-flex align-items-center">
                        <p v-if="showLitigationTitle" class="inter-font semi-bold grey01--text">
                            Litigation for:
                        </p>
                        <p v-if="showCountry" class="inter-font semi-bold grey01--text mr-1">
                            {{ patentViewerStore.ipBasicsObject?.country }} |
                        </p>
                        <p v-if="showBasicsInfo" class="inter-font semi-bold grey01--text">
                            {{ publicationType }}{{ claimBreadthText }}{{ validityText }}
                        </p>
                    </div>
                    <h4 class="inter-font semi-bold grey01--text mt-1 mb-n4">
                        {{ entityTitle }}
                    </h4>
                </div>
            </div>

            <div class="table-holder d-flex flex-column flex-grow-1 h-full pa-4">
                <div
                    class="actions-parent d-flex align-items-center justify-content-between w-full mb-3"
                >
                    <div v-if="selectedRows.length >= 1" class="left d-flex align-items-center">
                        <div class="total-selected mr-10 ml-1">
                            <p class="geist-font">{{ selectedRows.length }} selected</p>
                        </div>
                        <div class="bulk-actions d-flex align-items-center">
                            <div
                                v-if="props.allowPruning"
                                class="bulk-action d-flex align-items-center mr-4"
                                @click="bulkPrune"
                            >
                                <font-awesome-icon :icon="`fas fa-seedling`" class="pruned mt-n1" />
                                <p class="geist-font success--text bold ml-2">Prune</p>
                            </div>
                            <div
                                v-if="props.allowPruning"
                                class="bulk-action d-flex align-items-center"
                                @click="bulkUnprune"
                            >
                                <font-awesome-icon
                                    :icon="`fas fa-seedling`"
                                    class="grey03--text mt-n1"
                                />
                                <p class="geist-font grey03--text bold ml-2">Unprune</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1"></div>
                    <AonButton
                        label="Export"
                        :loading="exportLoading"
                        :text-with-icon="true"
                        type="prominent"
                        :disabled="noRows || loading"
                        :icon-options="{ iconStyle: 'fas', iconName: 'fa-file-arrow-down' }"
                        @clicked="exportPatents()"
                    />
                </div>
                <MoatTable
                    class="w-full h-full"
                    :class="themeClass"
                    :column-defs="colDefs"
                    :tooltip-interaction="true"
                    :tooltip-mouse-track="true"
                    :tooltip-show-delay="500"
                    tooltip-show-mode="whenTruncated"
                    :side-bar="sideBar"
                    :autoSizeStrategy="sizingStrategy"
                    row-model-type="serverSide"
                    :cache-block-size="100"
                    :max-blocks-in-cache="50"
                    :get-row-id="getRowId"
                    :row-selection="rowSelection"
                    :noRowsOverlayComponent="MTCustomNoRowsOverlay"
                    :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
                    pagination
                    @grid-ready="gridInit"
                    @cell-clicked="onRowSelected"
                    @cell-key-down="onCellKeyDown"
                    @row-selected="addToBulk"
                />
                <div class="total-holder d-flex align-items-center">
                    <p class="total inter-font knightsCloak--text mr-4 pr-4">
                        Showing <span class="bold">{{ filteredPatents.toLocaleString() }}</span> of
                        <span class="bold">{{ totalPatents.toLocaleString() }}</span> patents
                    </p>
                    <p v-if="props.allowPruning" class="inter-font success--text">
                        Total Fees Pruned:
                        <span class="bold">${{ parseInt(totalFeesPruned).toLocaleString() }}</span>
                    </p>
                </div>
            </div>
        </div>
    </AonModal>
</template>

<script setup>
import { ref, inject, onBeforeUnmount, onMounted, computed, watch } from 'vue'
import { usePatentViewerStore, useEntityStore } from '@/stores'
import {
    getPatentList,
    getLitPatentList,
    getPatentListIPBasics,
    getPatentListInventor,
} from '@/api/patentViewer.js'
import {
    submitPruneStatus,
    bulkPrune as helperBulkPrune,
    bulkUnprune as helperBulkUnprune,
    getPruningColumnDef,
} from '@/components/patentViewer/helpers/pruningHelper.js'
import { exportPatents as helperExportPatents } from '@/components/patentViewer/helpers/exportHelper.js'

import { prunePatents, unprunePatents } from '@/api/portfolioManager.js'
import { createReport, patchReport } from '@/api/reports'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator } from '@/components/moatTable/helpers/compare.js'
import { moatParamBuilder } from '@/components/moatTable/helpers/queryBuilder.js'
import MTCustomNoRowsOverlay from '@/components/moatTable/MTCustomNoRowsOverlay.vue'

import { useI18n } from 'vue-i18n'
import { debounce } from 'lodash-es'
import { useAuth } from '@/auth/authPlugin'

const { user } = useAuth()
const logger = inject('logger')
const filters = inject('filters')
const eventBus = inject('eventBus')
const { t } = useI18n()

const patentViewerStore = usePatentViewerStore()
const entityStore = useEntityStore()
const emit = defineEmits(['close-patent-list'])
const props = defineProps({
    inline: {
        type: Boolean,
        default: false,
    },
    absolute: {
        type: Boolean,
        default: false,
    },
    entityPk: {
        type: Number,
        default: null,
    },
    entityOnly: {
        type: Boolean,
        default: false,
    },
    allowPruning: {
        type: Boolean,
        default: false,
    },
    passedFilters: {
        type: Object,
        default: () => ({}),
    },
})

const loading = ref(true)
const exportLoading = ref(false)
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref({
    type: 'fitProvidedWidth',
    width: 3200,
})
const tableParams = ref({})
const noRows = ref(false)
const tablePageSize = ref(100)
const tablePageNum = ref(0)
const totalPatents = ref(0)
const filteredPatents = ref(0)
const selectedPatentIndex = ref(0)
const sideBar = ref(null)
const previousSortModel = ref([])
const noRowsOverlayComponentParams = ref({
    message: 'There are no patents matching your search criteria.',
})
const selectedRows = ref([])
const totalFeesPruned = ref(0)
const hasSetupTotalPruned = ref(false)

onMounted(() => {
    eventBus.on('next-patent', async () => {
        const nextRow = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value + 1)
        if (nextRow?.data?.patentId) {
            gridApi.value.deselectAll()
            gridApi.value.setFocusedCell(selectedPatentIndex.value + 1, 'patentId')
            gridApi.value.setNodesSelected({ nodes: [nextRow], newValue: true })
            await processRowSelection(nextRow.data.publicationPk, nextRow.data)

            selectedPatentIndex.value++
        }
    })
    eventBus.on('previous-patent', async () => {
        const previousRow = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value - 1)
        if (previousRow?.data?.patentId) {
            gridApi.value.deselectAll()
            gridApi.value.setFocusedCell(selectedPatentIndex.value - 1, 'patentId')
            gridApi.value.setNodesSelected({ nodes: [previousRow], newValue: true })
            await processRowSelection(previousRow.data.publicationPk, previousRow.data)
            selectedPatentIndex.value--
        }
    })

    eventBus.on('close-patent', () => {
        patentViewerStore.targetPatentId = null
    })
})

onBeforeUnmount(() => {
    patentViewerStore.resetState()
    eventBus.off('next-patent')
    eventBus.off('previous-patent')
    eventBus.off('close-patent')
})

const colDefs = computed(() => {
    const columns = []

    if (props.allowPruning) {
        columns.push(getPruningColumnDef())
    }

    columns.push(
        {
            field: 'patentId',
            width: 140,
            cellClass: 'link',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear'],
                defaultOption: 'contains',
                filterOptions: ['startsWith', 'equals', 'contains'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'title',
            flex: 2,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear'],
                defaultOption: 'contains',
                filterOptions: ['startsWith', 'equals', 'contains'],
                maxNumConditions: 1,
            },
        }
    )

    if (props.allowPruning) {
        columns.push(
            {
                headerName: 'Next Fee Due Date',
                field: 'nextFeeDueDate',
                width: 220,
                valueGetter: (p) => {
                    if (p.data && p.data.nextFeeDueDate) {
                        let date = filters.toUTCString(p.data.nextFeeDueDate)
                        if (date === '1/1/1') {
                            date = '--'
                        }
                        return `${date}`
                    }
                    return '--'
                },
                comparator: dateComparator,
                filter: 'agDateColumnFilter',
                filterParams: {
                    suppressAndOrCondition: true,
                    buttons: ['clear'],
                    defaultOption: 'inRange',
                    filterOptions: ['inRange'],
                    maxNumConditions: 1,
                },
                sort: 'asc',
            },
            {
                headerName: 'Remaining Fees',
                field: 'remainingFees',
                width: 180,
                valueFormatter: (p) => {
                    if (p.data && p.data.remainingFees !== undefined) {
                        return `$${p.data.remainingFees.toLocaleString()}`
                    }
                    return '--'
                },
                filter: 'agNumberColumnFilter',
                filterParams: {
                    buttons: ['clear'],
                    defaultOption: 'equals',
                    filterOptions: ['equals', 'lessThan', 'greaterThan'],
                    maxNumConditions: 1,
                },
            },
            {
                headerName: 'Products',
                field: 'products',
                width: 150,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: {
                    buttons: ['clear'],
                    defaultOption: 'contains',
                    filterOptions: ['startsWith', 'equals', 'contains'],
                    maxNumConditions: 1,
                },
            }
        )
    }

    columns.push(
        {
            field: 'entities',
            headerName: 'Assignee',
            flex: 2,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear'],
                defaultOption: 'contains',
                filterOptions: ['startsWith', 'equals', 'contains'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'ultimateEntities',
            headerName: 'Ultimate Assignee',
            flex: 2,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear'],
                defaultOption: 'contains',
                filterOptions: ['startsWith', 'equals', 'contains'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'patentType',
            width: 140,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear'],
                defaultOption: 'contains',
                filterOptions: ['startsWith', 'equals', 'contains'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'legalStatus',
            width: 140,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['active', 'inactive'],
                valueFormatter: (p) => {
                    if (p.value) {
                        return p.value === 'active' ? 'Active' : 'Inactive'
                    }
                },
            },
        },
        {
            headerName: 'Filing Date',
            valueGetter: (p) => {
                if (p.data) {
                    let date = filters.toUTCString(p.data.filingDate)

                    if (date === '1/1/1') {
                        date = '--'
                    }

                    return `${date}`
                }
            },
            field: 'filingDate',
            comparator: dateComparator,
            width: 140,
            filter: 'agDateColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                buttons: ['clear'],
                defaultOption: 'inRange',
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            headerName: 'Publication Date',
            valueGetter: (p) => {
                if (p.data) {
                    let date = filters.toUTCString(p.data.publicationDate)

                    if (date === '1/1/1') {
                        date = '--'
                    }

                    return `${date}`
                }
            },
            field: 'publicationDate',
            comparator: dateComparator,
            width: 140,
            filter: 'agDateColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                buttons: ['clear'],
                defaultOption: 'inRange',
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            headerName: 'Priority Date',
            valueGetter: (p) => {
                if (p.data) {
                    let date = filters.toUTCString(p.data.priorityDate)

                    if (date === '1/1/1') {
                        date = '--'
                    }
                    return `${date}`
                }
            },
            field: 'priorityDate',
            comparator: dateComparator,
            width: 140,
            filter: 'agDateColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                buttons: ['clear'],
                defaultOption: 'inRange',
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            headerName: 'Est. Expiration Date',
            valueGetter: (p) => {
                if (p.data) {
                    let date = filters.toUTCString(p.data.estimatedExpirationDate)

                    if (date === '1/1/1') {
                        date = '--'
                    }

                    return `${date}`
                }
            },
            field: 'estimatedExpirationDate',
            comparator: dateComparator,
            width: 150,
            filter: 'agDateColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                buttons: ['clear'],
                defaultOption: 'inRange',
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'applicationNumber',
            width: 150,
            filter: 'agNumberColumnFilter',
        },
        {
            field: 'claimBreadth',
            width: 120,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Very Broad', 'Broad', 'Average', 'Narrow', 'Not Scored'],
            },
        },
        {
            field: 'validity',
            width: 110,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Low', 'Medium', 'High', 'Not Ranked'],
            },
        },
        {
            field: 'litigationCount',
            width: 130,
            valueFormatter: (p) => {
                if (p.data) {
                    return p.data.litigationCount.toLocaleString()
                }
            },
            filter: 'agNumberColumnFilter',
        },
        {
            field: 'jurisdiction',
            width: 130,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear'],
                defaultOption: 'contains',
                filterOptions: ['startsWith', 'equals', 'contains'],
                maxNumConditions: 1,
            },
        }
    )

    return columns
})

const rowSelection = computed(() => {
    if (props.allowPruning) {
        return {
            mode: 'multiRow',
            checkboxes: true,
            suppressRowDeselection: false,
            headerCheckbox: false,
        }
    } else {
        return {
            mode: 'single',
            checkboxes: false,
            enableClickSelection: true,
            suppressRowDeselection: false,
        }
    }
})

const showLitigationTitle = computed(() => patentViewerStore.listViewType === 'lit')

const showCountry = computed(() => patentViewerStore.ipBasicsObject?.country)

const showBasicsInfo = computed(() => patentViewerStore.ipBasicsObject)

const publicationType = computed(() => {
    const type = patentViewerStore.ipBasicsObject?.publicationType
    return type === 'A' ? 'Applications' : type === 'G' ? 'Grants' : 'Total'
})

const claimBreadthText = computed(() => {
    const breadth = patentViewerStore.ipBasicsObject?.claimBreadth
    return breadth ? ` | ${breadth}` : ''
})

const validityText = computed(() => {
    const validity = patentViewerStore.ipBasicsObject?.validity
    return validity ? ` - ${validity}` : ''
})

const entityTitle = computed(() => {
    const tech = patentViewerStore.technologyName
    return `${patentViewerStore.entityName}${tech ? ` - ${tech}` : ''}`
})

watch(
    () => props.passedFilters,
    (newVal, oldVal) => {
        if (newVal !== oldVal) {
            setTimeout(() => {
                applyPassedFilters(newVal)
            }, 500)
        }
    }
)

watch(
    () => filteredPatents.value,
    (newValue) => {
        if (gridApi.value) {
            const showResetButton = newValue === 0 && totalPatents.value !== 0
            const updatedMessage = buildNoRowsMessage(showResetButton)
            if (showResetButton) {
                setTimeout(() => {
                    gridApi.value.setGridOption('noRowsOverlayComponentParams', {
                        message: updatedMessage,
                        showResetFilterButton: showResetButton,
                        resetFilters: () => {
                            gridApi.value.setFilterModel(null)
                            gridApi.value.hideOverlay()
                            gridApi.value.refreshServerSide({ purge: true })
                        },
                    })
                    gridApi.value.showNoRowsOverlay()
                }, 100)
            } else {
                gridApi.value.hideOverlay()
            }
        }
    }
)

const gridInit = (params) => {
    gridApi.value = params.api
    setupServerSideData()
    gridApi.value.closeToolPanel()
    sideBar.value = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivotMode: true,
                },
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
    }
}

const buildNoRowsMessage = (filtersApplied) => {
    const message = patentViewerStore.ipBasicsObject
        ? `There doesn't seem to be any patents for - ${patentViewerStore.entityName} - ${patentViewerStore.ipBasicsObject.country} ${
              patentViewerStore.ipBasicsObject.publicationType === 'A'
                  ? 'Applications'
                  : patentViewerStore.ipBasicsObject.publicationType === 'G'
                    ? 'Grants'
                    : 'Total'
          } ${patentViewerStore.ipBasicsObject.claimBreadth ? ' | ' + '-' + patentViewerStore.ipBasicsObject.claimBreadth : ''} ${patentViewerStore.ipBasicsObject.validity ? patentViewerStore.ipBasicsObject.validity : ''}`
        : `There are no patents matching your search criteria.`

    if (filtersApplied) {
        return message + ', with the current filters applied.'
    }

    return message
}

const getRowId = (params) => {
    return params.data.patentId
}

const setupServerSideData = async () => {
    const datasource = createServerSideDatasource()
    gridApi.value.setGridOption('serverSideDatasource', datasource)
}

const createServerSideDatasource = () => {
    const debouncedGetRows = debounce(async (params) => {
        const currentFilterModel = gridApi.value.getFilterModel()
        params.request.filterModel = currentFilterModel

        let response
        if (patentViewerStore.strategyTabPatentListObj) {
            response = await onGetPatentList(
                params.request,
                patentViewerStore.strategyTabPatentListObj
            )
        } else if (patentViewerStore.ipBasicsObject) {
            response = await onGetPatentListIPBasics(params.request, props.entityPk)
        } else if (props.entityOnly) {
            response = await onGetPatentListIPBasics(params.request, props.entityPk)
            patentViewerStore.entityPkList = [props.entityPk]
        } else if (patentViewerStore.inventorPk) {
            response = await onGetPatentListInventor(params.request)
        } else {
            response = await onGetPatentList(params.request)
        }

        if (response && response.success) {
            params.success({ rowData: response.rows })
        } else {
            params.fail()
        }
    }, 1000)

    return {
        getRows: (params) => {
            debouncedGetRows(params)
        },
    }
}

const onGetPatentList = async (paramsFromTable, strategyTabPatentListObj = null) => {
    const fetchFunction = async (tableParams) => {
        let nodeParams = null
        if (strategyTabPatentListObj) {
            nodeParams = strategyTabPatentListObj
        } else {
            nodeParams = {
                nodeIds: patentViewerStore.nodeIdList,
                entityPks: props.entityPk ? [props.entityPk] : patentViewerStore.entityPkList,
            }
        }

        return patentViewerStore.listViewType === 'lit'
            ? await getLitPatentList(patentViewerStore.nodeIdList[0], tableParams)
            : await getPatentList(nodeParams, tableParams)
    }

    return handlePatentListRequest(paramsFromTable, fetchFunction)
}

const onGetPatentListIPBasics = async (paramsFromTable, entityPk = null) => {
    const fetchFunction = async (tableParams) => {
        return await getPatentListIPBasics(
            entityPk ? entityPk : patentViewerStore.ipBasicsEntityPK,
            entityPk ? {} : patentViewerStore.ipBasicsObject,
            tableParams
        )
    }

    return handlePatentListRequest(paramsFromTable, fetchFunction)
}

const onGetPatentListInventor = async (paramsFromTable) => {
    const fetchFunction = async (tableParams) => {
        return await getPatentListInventor(
            {
                inventorPk: patentViewerStore.inventorPk,
                entityPk: patentViewerStore.entityPk,
            },
            tableParams
        )
    }
    return handlePatentListRequest(paramsFromTable, fetchFunction)
}

const handlePatentListRequest = async (paramsFromTable, fetchFunction) => {
    loading.value = true

    const currentSortModel = paramsFromTable.sortModel || []
    const sortChanged = JSON.stringify(previousSortModel.value) !== JSON.stringify(currentSortModel)

    if (sortChanged) {
        tablePageNum.value = 0
        previousSortModel.value = [...currentSortModel]
        gridApi.value.refreshServerSide({ purge: true })
        return
    }

    try {
        tableParams.value = moatParamBuilder(
            paramsFromTable,
            { page_size: tablePageSize.value, last_row_num: 0, page: tablePageNum.value },
            null
        )

        const data = await fetchFunction(tableParams.value)

        if (!totalPatents.value) {
            totalPatents.value = data.data.rowCount
            filteredPatents.value = data.data.rowCount
        } else {
            filteredPatents.value = data.data.rowCount
        }

        if (!hasSetupTotalPruned.value) {
            hasSetupTotalPruned.value = true
            totalFeesPruned.value = data.data.totalFeesPruned
        }

        if (data.data.results.length === 0) {
            gridApi.value.showNoRowsOverlay()
            noRows.value = true
        }
        tablePageNum.value++
        return {
            success: true,
            rows: data.data.results,
        }
    } catch (error) {
        logger.error(error)
        return {
            success: false,
        }
    } finally {
        loading.value = false
    }
}

const processRowSelection = async (patentId, rowData) => {
    if (patentViewerStore.patentExistsInCache(patentId)) {
        await updateTargetPatentId(patentId)
    } else {
        rowData.isRowData = true
        patentViewerStore.targetPatentId = patentId
        debouncedUpdateTargetPatentData(patentId, rowData)
    }
}

const addToBulk = (params) => {
    selectedRows.value = []
    const selectedNodes = gridApi.value.getSelectedNodes()

    if (selectedNodes && selectedNodes.length > 0) {
        selectedNodes.forEach((node) => {
            if (node.data) {
                selectedRows.value.push(node.data)
            }
        })
    }
}

const onRowSelected = async (params) => {
    if (params.column.colId === 'prune') {
        params.data.prune = !params.data.prune

        const rowNode = gridApi.value.getRowNode(params.data.patentId)
        if (rowNode) {
            rowNode.setData({ ...params.data })
        }

        params.data.prune
            ? (totalFeesPruned.value += params.data.remainingFees)
            : (totalFeesPruned.value -= params.data.remainingFees)

        await submitPruneStatus(params.data, [], null, logger)
    } else {
        selectedPatentIndex.value = params.rowIndex
        if (params?.data?.patentId) {
            await processRowSelection(params.data.publicationPk, params.data)
        }
    }
}

const bulkPrune = async () => {
    let total = selectedRows.value.map((row) => row.remainingFees)
    totalFeesPruned.value += total.reduce((a, b) => a + b, 0)
    await helperBulkPrune(gridApi.value, selectedRows.value, logger)
}

const bulkUnprune = async () => {
    let total = selectedRows.value.map((row) => row.remainingFees)
    totalFeesPruned.value -= total.reduce((a, b) => a + b, 0)
    await helperBulkUnprune(gridApi.value, selectedRows.value, logger)
}

const updateTargetPatentId = async (patentId) => {
    if (patentId) {
        patentViewerStore.targetPatentId = patentId
        await patentViewerStore.fetchPatentDetails(patentId)
    }
}

const updateTargetPatentData = async (patentId, rowData) => {
    if (patentId) {
        patentViewerStore.setCachedPatentRowDetail(patentId, rowData)
        await patentViewerStore.fetchPatentDetails(patentId)
    }
}

const debouncedUpdateTargetPatentData = debounce(updateTargetPatentData, 500)

const onCellKeyDown = async (params) => {
    selectedPatentIndex.value = params.rowIndex
    let newPatentId = null
    let row = null
    if (params.event.key === 'ArrowUp') {
        row = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value - 1)
        if (row?.data?.patentId) {
            newPatentId = row.data.publicationPk
        }
    } else if (params.event.key === 'ArrowDown') {
        row = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value + 1)
        if (row?.data?.patentId) {
            newPatentId = row.data.publicationPk
        }
    }
    await processRowSelection(newPatentId, row.data)
}

const applyPassedFilters = (filters) => {
    if (!gridApi.value || !filters) return
    const filterModel = {}

    // Handle product filter (partial string match)
    if (filters.product || filters.name) {
        filterModel.products = {
            filterType: 'text',
            type: 'contains',
            filter: filters.product || filters.name,
        }
    }

    // Handle breadth filter (exact match)
    if (filters.breadth) {
        filterModel.claimBreadth = {
            filterType: 'set',
            values: [filters.breadth],
        }
    }

    if (filters.dateRange) {
        // ensures that the dates coming in are consistent
        const formatDate = (date) => {
            const newDate = new Date(date)
            newDate.setHours(0, 0, 0, 0)
            const month = String(newDate.getMonth() + 1).padStart(2, '0')
            const day = String(newDate.getDate()).padStart(2, '0')
            const year = newDate.getFullYear()
            return `${year}-${month}-${day} 00:00:00`
        }

        filterModel.nextFeeDueDate = {
            filterType: 'date',
            type: 'inRange',
            dateFrom: formatDate(filters.dateRange.from),
            dateTo: formatDate(filters.dateRange.to),
        }
    }

    if (props.allowPruning) {
        filterModel.prune = {
            filterType: 'set',
            values: ['true'],
        }
    }

    applyFiltersAndRefresh(filterModel)
}

const applyFiltersAndRefresh = (filterModel) => {
    // Preserve any existing filters that should be kept
    const currentFilterModel = gridApi.value.getFilterModel() || {}
    const newFilterModel = { ...currentFilterModel, ...filterModel }

    gridApi.value.setFilterModel(newFilterModel)
    tablePageNum.value = 0
    debouncedGridRefresh()
}

const debouncedGridRefresh = debounce(() => {
    const model = gridApi.value.getFilterModel()
    gridApi.value.refreshServerSide({ purge: true })
}, 300)

const closePatentList = () => {
    patentViewerStore.resetState()
}

const exportPatents = async () => {
    exportLoading.value = true

    try {
        await helperExportPatents({
            patentViewerStore,
            entityStore,
            tableParams: tableParams.value,
            user: user.value,
            logger,
            eventBusEmit: eventBus.emit,
        })
    } catch (err) {
        logger.error(err)
    } finally {
        exportLoading.value = false
    }
}
</script>

<style lang="scss" scoped>
@forward '@/styles/new_styles.scss';

.ag-theme-quartz {
    --ag-checkbox-checked-color: #2a79d2;
    // --ag-active-color: #ffffff;
    --ag-font-family: 'Geist', sans-serif;
    --ag-font-size: 14px;

    :deep(.ag-header-cell-text) {
        font-family: 'Geist', sans-serif;
        font-weight: 800;
    }

    :deep(.ag-cell) {
        font-family: 'Geist', sans-serif;
    }

    :deep(.ag-checkbox-input-wrapper) {
        font-size: 18px !important;
    }
}

:deep(.not-pruned) path {
    fill: vars.$grey04 !important;
}

:deep(.pruned) path {
    fill: vars.$success !important;
}

.patent-list {
    &.inline {
        position: static !important;
        left: 0 !important;
        top: 0 !important;

        transform: translate(0, 0) !important;

        :deep(.a-modal-close) {
            display: none !important;
            pointer-events: none !important;
        }

        :deep(.background) {
            display: none !important;
        }

        :deep(.aon-container) {
            height: 100% !important;
        }

        :deep(.aon-row) {
            height: 100% !important;
        }

        :deep(.aon-col) {
            height: 100% !important;
        }

        :deep(.container) {
            box-shadow: none !important;

            .patent-list-header {
                display: none !important;
            }
        }

        :deep(.aon-col-10) {
            padding: 0 !important;
        }
    }

    .bulk-action {
        padding: 10px;
        padding-bottom: 8px;
        border-radius: vars.$border-radius * 2;
        cursor: pointer;

        transition: all 0.3s ease-in-out;

        &:hover {
            background: vars.$grey07;
        }
    }

    .total-holder {
        position: absolute;
        bottom: 30px;
        left: 30px;

        .total {
            border-right: 2px solid vars.$grey03;
        }
    }

    &.absolute {
        position: absolute !important;
    }

    z-index: 11 !important;

    :deep(.a-modal-close) {
        background: vars.$grey04;
        transform: scale(1.2) !important;
        top: 20px;
        right: 20px;

        svg {
            color: vars.$grey02 !important;
        }
    }

    :deep(.offset-1) {
        margin-left: 0 !important;
    }

    :deep(.aon-col) {
        padding: 10px !important;
    }

    :deep(.aon-col-10) {
        flex: 0 0 100%;
        max-width: 100%;
    }

    :deep(.container) {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        max-height: none !important;
        box-shadow: 0px 0px 35px 10px rgba(0, 0, 0, 0.35) !important;
    }

    :deep(.modal-content) {
        height: 100% !important;
    }

    :deep(.background) {
        width: 110%;
        height: 110%;
        top: -3px;
        left: -3px;
    }

    .patent-list-header {
        min-height: 75px;
        width: 100%;

        .source-display {
            height: 100%;
        }
    }

    .divide {
        width: 2px;
        height: 20px;
        background-color: vars.$grey04;
    }
}
</style>
