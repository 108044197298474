<template>
    <div class="report-creation-type-selection">
        <div class="radio-container d-flex flex-wrap">
            <AonCard
                v-for="item in typeOptions"
                class="type-option mb-5 mx-2"
                :class="{
                    selected: reportGenerationStore.reportSelections[0]?.value === item.value,
                }"
                :key="item.label + '_key'"
                @click="setReportCreationType(item.value, item.label)"
            >
                <div class="content-holder">
                    <div class="icon-holder">
                        <div
                            :id="item.iconID"
                            class="type-option-icon"
                            style="width: 50px; height: 50px"
                        ></div>
                        <img
                            src="@/assets/svgs/report/checkmark-circle.svg"
                            class="type-option-icon checkmark"
                        />
                    </div>
                    <p class="label bold mt-4">{{ item.label }}</p>
                    <p class="mt-1">{{ item.description }}</p>
                </div>
            </AonCard>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, nextTick, onBeforeMount } from 'vue'
import { useReportGenerationStore } from '@/stores'
import { useFlag } from '@unleash/proxy-client-vue'

import moat2ProductTypeUrl from '@/assets/svgs/report/moat2product_type.svg'
import gloves from '@/assets/svgs/report/gloves.svg'
import target from '@/assets/svgs/report/target.svg'

const reportGenerationStore = useReportGenerationStore()
const showHeadToHeadReport = useFlag('ipAlpha.headToHead')
const showCounterReport = useFlag('ipAlpha.counterAssertionReport')
const showRiskReport = useFlag('ipAlpha.riskReport')

const typeOptions = ref([
    {
        label: 'Competitive Analysis Report',
        description:
            'Analysis mapping a target company and its contenders to key Technology Areas, showcasing how their innovations align with current product offerings and identifying potential opportunities for growth and differentiation.',
        iconID: 'ip-type-icon',
        value: 'compAnalysis',
    },
])

onBeforeMount(() => {})

onMounted(async () => {
    if (showHeadToHeadReport.value) {
        typeOptions.value.push({
            label: 'Head-to-head Analysis Report',
            description:
                'Compare your target company against five key competitors by analyzing IP portfolios across selected product classes and technology areas. Customize your comparison by choosing the areas that matter most, and uncover detailed insights through differentiation statements based on each company’s IP participation.',
            iconID: 'head-to-head-icon',
            value: 'headToHead',
        })
        await nextTick()
        document.getElementById('head-to-head-icon').style.backgroundImage = `url("${gloves}")`
    }
    if (showCounterReport.value) {
        typeOptions.value.push({
            label: 'Counter Assertion Report',
            description:
                "The Counter Assertion Report examines how a selected company's patents align with a target's product and technology areas, providing key insights through relevant patent metrics. This report helps identify potential IP leverage points and supports strategic decision-making in competitive scenarios.",
            iconID: 'counter-icon',
            value: 'counter',
        })
        await nextTick()
        document.getElementById('counter-icon').style.backgroundImage = `url("${target}")`
    }
    if (showRiskReport.value) {
        typeOptions.value.push({
            label: 'Risk Analysis Export',
            description:
                'The risk analysis export evaluates intellectual property (IP) infringement risks for a target company and its industry, identifying  vulnerabilities, high-risk areas, and potential challenges.',
            iconID: 'counter-icon-test',
            value: 'risk',
        })
        await nextTick()
        document.getElementById('counter-icon').style.backgroundImage = `url("${target}")`
        document.getElementById('counter-icon-test').style.backgroundImage = `url("${target}")`
    }
    await nextTick()
    document.getElementById('ip-type-icon').style.backgroundImage = `url("${moat2ProductTypeUrl}")`
})

const setReportCreationType = async (val, label) => {
    reportGenerationStore.setReportCreationType(val, label)
}
</script>

<style lang="scss" scoped>
.report-creation-type-selection {
    height: 100%;
    width: 100%;

    .type-option {
        cursor: pointer;
        outline: solid transparent 3px;
        width: calc(50% - 8px);
        height: 250px;

        transition: all 0.3s ease-in-out;

        &:nth-last-child(-n + 2) {
            margin-bottom: 0 !important;
        }

        &:nth-child(odd) {
            margin-left: 0 !important;
        }

        &:nth-child(even) {
            margin-right: 0 !important;
        }

        &:hover {
            background: vars.$grey05;
        }

        &.selected {
            border-color: vars.$success;
            outline-color: vars.$success;

            .label {
                color: vars.$castleMoat;
            }

            .icon-holder {
                .type-option-icon {
                    opacity: 0;
                }

                .checkmark {
                    opacity: 1;
                }
            }
        }
    }

    .icon-holder {
        position: relative;

        .type-option-icon {
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;

            transition: all 0.3s ease-in-out;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;

            opacity: 0;
        }
    }

    .label {
        transition: all 0.3s ease-in-out;
    }
}
</style>
