export const dateComparator = (date1, date2) => {
    var date1Number = date1 && new Date(date1).getTime()
    var date2Number = date2 && new Date(date2).getTime()

    if (date1Number == null && date2Number == null) {
        return 0
    }

    if (date1Number == null) {
        return -1
    } else if (date2Number == null) {
        return 1
    }

    return date1Number - date2Number
}

export const dateRangeComparator = (range1, range2) => {
    const [start1, end1] = range1.split(',').map((date) => new Date(date).getTime())
    const [start2, end2] = range2.split(',').map((date) => new Date(date).getTime())

    // Check if either range is null
    if (!start1 && !end1 && !start2 && !end2) {
        return 0
    }

    if (!start1 && !end1) {
        return -1
    }

    if (!start2 && !end2) {
        return 1
    }

    // Compare start dates
    if (start1 < start2) {
        return -1
    } else if (start1 > start2) {
        return 1
    }

    // If start dates are equal, compare end dates
    if (end1 < end2) {
        return -1
    } else if (end1 > end2) {
        return 1
    }

    // If both start and end dates are equal, the ranges are equal
    return 0
}

export const dateFilterComparator = (filterDate, cellValue) => {
    const cellDate = new Date(cellValue)
    const filterDateMs = filterDate.getTime()
    const cellDateMs = cellDate.getTime()

    if (cellDateMs === filterDateMs) return 0
    if (cellDateMs < filterDateMs) return -1
    return 1
}
