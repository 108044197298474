<template>
    <AonModal
        class="company-hierarchy"
        :title="hierarchyHeadline"
        @close="$emit('cancel')"
        @click:outside="$emit('cancel')"
    >
        <AonSpinner v-if="loading" class="mx-auto" :scale="0.4" />
        <div v-else-if="error" class="error white--text pa-3">
            {{ $t('error.genericError') }}
        </div>
        <!-- TODO make custom transition or use one from the aon_armory to replace the vuetify transition-->
        <template v-else>
            <!-- <v-expand-transition> -->
            <div v-if="entityHierarchy" class="taxonomy-tree">
                <CompanyTreeView
                    :selected-company-id="selectedEntityId"
                    :company="company"
                    :companies="entityHierarchy"
                    :depth="0"
                    @open="scrollToSelected"
                    @cancel="closeDialog"
                />
            </div>
            <!-- </v-expand-transition> -->
        </template>
    </AonModal>
</template>

<script>
import { useEntityStore } from '@/stores'
import CompanyTreeView from '@/components/company/CompanyTreeView'

export default {
    name: 'CompanyHierarchy',
    components: {
        CompanyTreeView,
    },
    props: {
        parentEntityId: {
            type: Number,
            required: true,
        },
        selectedEntityId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            error: false,
            entityHierarchy: null,
            loading: false,
        }
    },
    computed: {
        company() {
            return this.entityHierarchy?.filter((c) => c.aon_entity_pk == this.parentEntityId)[0]
        },
        childCompanies() {
            return this.entityHierarchy?.filter(
                (c) => c.parent_aon_entity_pk === this.company?.aon_entity_pk
            )
        },
        hierarchyHeadline() {
            return this.$t('entityHierarchy.headline', {
                entityName: this.company?.name,
            })
        },
    },
    async created() {
        try {
            const entityStore = useEntityStore()

            this.loading = true
            this.error = false
            this.entityHierarchy = await entityStore.getEntityHierarchy(this.parentEntityId)
        } catch (err) {
            this.$logger.error(err)
            this.error = true
        } finally {
            this.loading = false
        }
    },
    methods: {
        scrollToSelected() {
            this.$nextTick(() =>
                document
                    .querySelector('.company-hierarchy .selected')
                    .scrollIntoView({ behavior: 'smooth', block: 'center' })
            )
        },
        closeDialog() {
            this.$emit('cancel')
        },
    },
}
</script>

<style lang="scss">
.company-hierarchy {
    .taxonomy-tree {
        padding: 1em;
        border: 1px solid vars.$grey03;
        border-radius: 0.25em;
    }
}
</style>
