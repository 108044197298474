<template>
    <div class="counter-assertion-output d-flex flex-column">
        <AonCoverLoading
            :loading="loading"
            title="Loading Counter Assertion Playbook..."
        ></AonCoverLoading>
        <div class="output-header knights-cloak pa-4">
            <h5 class="gold--text">Counter Assertion</h5>
            <p class="white--text mt-2">
                Offers a detailed matrix comparing the responding parties patents to the initiating
                parties products and technologies. Products and Technologies are filtered those of
                which the responding party has coverage.
            </p>
        </div>
        <div class="action-holder d-flex justify-content-between align-items-center my-5">
            <div class="copy" style="min-width: 400px">
                <h6 class="overflow-ellipsis w-full">
                    Initiating Party Product Arena: {{ target }}
                </h6>
                <p>
                    Initial Products and Technology Count:
                    <span style="font-weight: 600">{{ totalNodes }}</span>
                </p>
            </div>
            <div class="buttons d-flex">
                <div
                    class="expand-collapse-button d-flex align-items-center mr-5 pr-3"
                    v-tooltip="{
                        content: 'Expand/Collapse All Rows',
                    }"
                    @click="toggleExpandCollapseAll()"
                >
                    <font-awesome-icon
                        icon="fas fa-list-tree"
                        class="knights-cloak--text ml-3"
                        size="xl"
                    />
                </div>
                <AonButton
                    class="mr-5 pl-5 target-button"
                    label="Change Initiating Party"
                    type="subtle"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-bullseye' }"
                    @clicked="gotoStep(true, 2)"
                />
                <AonButton
                    class="mr-5"
                    label="Change Responding Party"
                    :text-with-icon="true"
                    type="subtle"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-people-group' }"
                    @clicked="gotoStep(true, 3)"
                />
                <AonButton
                    v-if="showPatentData"
                    label="Export Patent Data"
                    :text-with-icon="true"
                    type="prominent"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-file-arrow-down' }"
                    :loading="playbookGenerationStore.generateLoading"
                    @clicked="submitPlaybook()"
                />
            </div>
        </div>
        <MoatTable
            class="table-border w-full flex-grow-1"
            :key="updater"
            :class="themeClass"
            :column-defs="colDefs"
            :row-data="tableData"
            :tooltip-show-delay="500"
            :treeData="true"
            :autoGroupColumnDef="autoGroupColumnDef"
            :groupRemoveLowestSingleChildren="true"
            :groupDisplayType="groupDisplayType"
            :getDataPath="getDataPath"
            @grid-ready="gridInit"
        />
    </div>
</template>

<script setup>
import { ref, inject, onActivated, nextTick, computed, onBeforeMount } from 'vue'
import { usePlaybookGenerationStore, usePatentViewerStore } from '@/stores'
import { getEntityTechBreakdown } from '@/api/productAlignment.js'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { useFlag } from '@unleash/proxy-client-vue'
const showPatentData = useFlag('ipAlpha.patentData')
const isProUser = useFlag('ipAlpha.proUsers')

const logger = inject('logger')
const playbookGenerationStore = usePlaybookGenerationStore()
const patentViewerStore = usePatentViewerStore()

const props = defineProps({
    flexTable: {
        type: Boolean,
        default: false,
    },
})

const updater = ref(0)
const loading = ref(true)
const gridApi = ref(null)
const getDataPath = ref(null)
const groupDisplayType = ref(null)
const autoGroupColumnDef = ref(null)
const themeClass = ref('ag-theme-quartz')
const target = ref(null)
const totalNodes = ref(0)
const tableData = ref([])
const colDefs = ref([])
const rowsExpanded = ref(false)

onBeforeMount(async () => {
    getDataPath.value = (data) => {
        return data.nodeHierarchy.map((item, index) => `${item.displayName}_${index}`)
    }
    groupDisplayType.value = 'singleColumn'
})

onActivated(async () => {
    target.value = playbookGenerationStore.playbookSelections[1].selectionLabel
    onGetEntityTechBreakdown()
    defineTableGroups()
})

const gridInit = (params) => {
    gridApi.value = params.api
    gridApi.value.expandAll()
}

const defineTableGroups = () => {
    autoGroupColumnDef.value = {
        minWidth: 450,
        suppressHeaderMenuButton: true,
        headerName: 'Products & Technologies',
        field: 'displayName',
        valueFormatter: (p) => {
            if (p.value) {
                return p.value.split('_')[0]
            }
            return p.value
        },
        cellRendererParams: {
            suppressCount: true,
        },
        suppressMovable: true,
        lockPosition: 'left',
        sortingOrder: ['asc', 'desc', null],
    }
}

const submitPlaybook = () => {
    playbookGenerationStore.generatePlaybook()
}

const onGetEntityTechBreakdown = async () => {
    loading.value = true
    let alignmentRunPk = playbookGenerationStore.targetCompanyProductAlignmentId
    let targetEntityPk = [parseInt(playbookGenerationStore.playbookSelections[1].value)]
    let entityPks = [
        ...targetEntityPk,
        ...playbookGenerationStore.playbookSelections[2].value.map((entity) => entity.aonEntityPk),
    ]

    try {
        let { data } = await getEntityTechBreakdown(alignmentRunPk, [], entityPks)
        const uniqueEntities = [...new Set(data.map((item) => item.entityName))]
        const uniqueDisplayNames = [...new Set(data.map((item) => item.displayName))].length
        playbookGenerationStore.nodeIds = data.map((item) => item.nodeId)
        totalNodes.value = uniqueDisplayNames
        // This was all CoPilot - BB
        // Group the data by concept/node first
        const groupedByNode = data.reduce((acc, item) => {
            const nodeKey = item.nodeHierarchy.map((h) => h.displayName).join('_')
            if (!acc[nodeKey]) {
                acc[nodeKey] = {
                    displayName: item.displayName,
                    nodeHierarchy: item.nodeHierarchy,
                    nodeType: item.nodeType,
                }
            }
            const cleanEntityName = item.entityName.toLowerCase().replace(/[^a-z0-9]/g, '')
            acc[nodeKey][`patentcount_${cleanEntityName}`] = item.patentCount.toLocaleString()
            return acc
        }, {})

        // Filter out entries where all patent counts are 0
        const filteredGroupedData = Object.entries(groupedByNode).reduce((acc, [key, value]) => {
            const patentCounts = Object.entries(value)
                .filter(([k]) => k.startsWith('patentcount_'))
                .map(([_, v]) => parseInt(v.replace(/,/g, '')))

            if (patentCounts.some((count) => count > 0)) {
                acc[key] = value
            }
            return acc
        }, {})

        // Convert back to array format
        const transformedData = Object.values(filteredGroupedData)

        colDefs.value = uniqueEntities
            .sort((a, b) => (a === target.value ? -1 : b === target.value ? 1 : 0))
            .map((entityName) => ({
                headerName: entityName,
                suppressHeaderMenuButton: true,
                field: `patentcount_${entityName.toLowerCase().replace(/[^a-z0-9]/g, '')}`,
                headerClass: entityName === target.value ? 'target-header' : 'comp-header',
                flex: 1,
                cellRenderer: 'MTCustomCellLinkWContext',
                cellRendererParams: {
                    allowClick: isProUser.value,
                    cellClick: async (params) => {
                        let targetEntityPK =
                            playbookGenerationStore.playbookSelections[2].value.find(
                                (item) => item.aonEntityName === params.colDef.headerName
                            )
                        !targetEntityPK
                            ? (targetEntityPK = playbookGenerationStore.playbookSelections[1].value)
                            : (targetEntityPK = targetEntityPK.aonEntityPk)

                        patentViewerStore.nodeIdList.push(params.data.nodeHierarchy[0].nodeId)
                        patentViewerStore.technologyName = params.data.displayName
                        patentViewerStore.entityPkList.push(targetEntityPK)
                        patentViewerStore.entityName = params.colDef.headerName
                        await nextTick()
                        patentViewerStore.showPatentViewerList = true
                    },
                },
            }))

        tableData.value = transformedData
    } catch (error) {
        logger.error(error)
    } finally {
        loading.value = false
    }
}

const gotoStep = (allowEdit = true, stepNum) => {
    if (!allowEdit) {
        return
    }
    playbookGenerationStore.setCurrentStep(stepNum)
}

const toggleExpandCollapseAll = () => {
    if (gridApi.value) {
        if (rowsExpanded.value) {
            gridApi.value.collapseAll()
            rowsExpanded.value = false
        } else {
            gridApi.value.expandAll()
            rowsExpanded.value = true
        }
    }
}
</script>

<style lang="scss" scoped>
.counter-assertion-output {
    height: 100%;
    width: 100%;

    .table-border {
        border: 1px solid vars.$grey04;
        border-radius: 8px;
    }

    .expand-collapse-button {
        cursor: pointer;
        border: 1px solid vars.$grey01;
        border-radius: 4px;

        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: vars.$grey01;

            svg {
                color: white !important;
            }
        }
    }

    :deep(.target-header) {
        background-color: rgba(vars.$castleMoat, 0.8) !important;
        color: white !important;
    }

    .target-button {
        border-left: 2px solid vars.$grey01;

        :deep(.a-button) {
            background-color: rgba(vars.$castleMoat, 0.8) !important;
            border-radius: 4px;
        }

        :deep(span) {
            color: white !important;
            fill: white !important;
        }

        :deep(svg) {
            color: white !important;
            fill: white !important;
        }
    }

    :deep(.comp-header) {
        background-color: vars.$grey05 !important;
    }
}
</style>
