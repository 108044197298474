<template>
    <div class="output-tech-areas w-full d-flex flex-column flex-grow-1">
        <AonCard v-if="props.type === 'pa'" :title="chartTitle" :borders="false" :desc="chartDesc">
            <template #header-append>
                <AonDropdown
                    v-if="!loadingTechAreas"
                    label=""
                    :items="graphDropdownOptions"
                    :init-selection="graphDropdownOptions[0]"
                    style="width: 250px"
                    @select-option="changeGraph"
                />
            </template>
            <div class="chart-holder">
                <KeepAlive>
                    <DumbbellChartAM
                        v-if="activeGraph === 'filing-trends' && filingDateData.length > 0"
                        :filing-date-data="filingDateData"
                    />
                </KeepAlive>
                <KeepAlive>
                    <TreemapChartAM
                        v-if="
                            activeGraph === 'coverage' &&
                            !loading &&
                            moat2ProductStore.productAlignmentProducts.length > 0
                        "
                    />
                </KeepAlive>
                <KeepAlive>
                    <div v-if="activeGraph === 'focus'" class="sankey-holder">
                        <AonTabs
                            v-if="!normalizeCounts"
                            :tabs="sankeyTypeTabs"
                            button
                            @tab-change="setActiveSankeyTab"
                            :currentTab="currentSankeyTab"
                        />
                        <SankeyChartAM
                            v-if="!loadingTechAreas"
                            :chart-data="sankeyDataAll"
                            :source-node-data="sankeySourceNode"
                            :product-data="productData"
                            :normalize-counts="normalizeCounts"
                            :active-view="currentSankeyTab"
                        />
                    </div>
                </KeepAlive>
            </div>
        </AonCard>
        <AonCard
            class="mt-4 d-flex flex-column flex-grow-1"
            title="Technology Areas"
            :borders="false"
            :desc="getTechAreaDesc"
        >
            <template #header-append>
                <div class="action-wrapper d-flex align-items-center">
                    <div class="button-divide mr-4">
                        <AonButton
                            v-if="showPatentData"
                            label="Export Patent Data"
                            text-with-icon
                            :icon-options="{ iconStyle: 'fas', iconName: 'fa-file-arrow-down' }"
                            :class="{ disabled: !tableData.length }"
                            :loading="exportingPatentData"
                            load-color="white"
                            @clicked="exportPatentData()"
                        />
                    </div>
                    <div v-if="showArenas" class="button-divide">
                        <AonButton
                            v-if="props.type === 'pa'"
                            label="Customize in Arenas"
                            text-with-icon
                            :icon-options="{
                                iconStyle: 'fas',
                                iconName: 'fa-swords',
                            }"
                            :class="{ disabled: !tableData.length }"
                            :loading="arenaSetupLoading"
                            load-color="white"
                            @clicked="setupArena()"
                        />
                    </div>
                </div>
            </template>
            <div
                class="output-tech-area-table-wrapper d-flex flex-column flex-grow-1"
                style="height: inherit"
            >
                <OutputTechAreaTable
                    :type="props.type"
                    :table-data="tableData"
                    :loading="props.loading"
                    :additionalNodeDataIsProcessing="props.additionalNodeDataIsProcessing"
                    :newDataAvailable="props.newDataAvailable"
                    :done-loading-all="everythingDoneLoading"
                    @regenerate="updater++"
                />
            </div>
        </AonCard>

        <!-- These next sections will move - BB  -->
        <AonCard
            v-if="showStrategyFeatures && props.type === 'pa'"
            class="mt-4"
            title="Innovation Over Time"
            :borders="false"
            :desc="`Visualize how ${entityStore.entity ? entityStore.entity.name + '\'s' : ''} Product and Technology investment strategies has evolved over time.`"
        >
            <InnovationOverTime :type="props.type" />
        </AonCard>
        <AonContainer v-if="showStrategyFeatures && props.type === 'pa'">
            <AonRow>
                <AonCol class="aon-col-12 pa-0">
                    <IncreasingInnovation :type="props.type" />
                </AonCol>
            </AonRow>
            <AonRow>
                <AonCol class="aon-col-12 pa-0">
                    <DecreasingInnovation :type="props.type" />
                </AonCol>
            </AonRow>
        </AonContainer>
        <AonCard
            v-if="showStrategyFeatures && props.type === 'pa'"
            class="mt-4"
            title="Innovation Talent"
            :borders="false"
            :desc="`Where has talent, and the associated IP, been gained or lost?`"
        >
            <InnovationTalent :type="props.type" />
        </AonCard>
        <AonCard
            v-if="showStrategyFeatures && props.type === 'pa'"
            class="mt-4"
            title="Return on Innovation"
            :borders="false"
            :desc="`How we measure the innovation investment compared to benchmarked peers.`"
        >
            <ReturnOnInnovation />
        </AonCard>
        <AonCard
            v-if="showProductInvestmentBreakdown && props.type === 'pa'"
            class="mt-4"
            title="Industry Investment by Product"
            :borders="false"
            desc="Breakdown of investment dollars in each product area over the past 4 years by investment type."
        >
            <ProductInvestmentBreakdown />
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, ref, inject, computed, onBeforeUnmount, watch } from 'vue'
import { useArenaOutputStore, useArenaStore, useEntityStore, useMoat2ProductStore } from '@/stores'
import { useRouter } from 'vue-router'
import { useFlag } from '@unleash/proxy-client-vue'
import { useI18n } from 'vue-i18n'
import { requestArena } from '@/api/arena.js'

import OutputTechAreaTable from './OutputTechAreaTable.vue'
import InnovationOverTime from './InnovationOverTime.vue'
import IncreasingInnovation from './IncreasingInnovation.vue'
import DecreasingInnovation from './DecreasingInnovation.vue'
import InnovationTalent from './InnovationTalent.vue'
import ReturnOnInnovation from './ReturnOnInnovation.vue'
import ProductInvestmentBreakdown from './ProductInvestmentBreakdown.vue'
import DumbbellChartAM from '@/components/company/productAlignment/DumbbellChartAM.vue'
import TreemapChartAM from '@/components/company/productAlignment/TreemapChartAM.vue'
import SankeyChartAM from '@/components/company/productAlignment/SankeyChartAM.vue'
import { nextTick } from 'vue'
import { cloneDeep } from 'lodash-es'
import { createAndSubmitReport } from '@/services/reportService'
import { useAuth } from '@/auth/authPlugin.js'

const eventBus = inject('eventBus')
const logger = inject('logger')
const { t } = useI18n()
const entityStore = useEntityStore()
const arenaStore = useArenaStore()
const arenaOutputStore = useArenaOutputStore()
const moat2ProductStore = useMoat2ProductStore()

const router = useRouter()
const showArenas = useFlag('ipAlpha.showArenas')
const showStrategyFeatures = useFlag('ipAlpha.strategyFeatures')
const showProductInvestmentBreakdown = useFlag('ipAlpha.showProductInvestmentBreakdown')
const showPatentData = useFlag('ipAlpha.patentData')
const { user } = useAuth()

const props = defineProps({
    type: {
        type: String,
        default: 'pa',
    },
    allData: {
        type: Array,
        default: () => [],
    },
    allChartData: {
        type: Array,
        default: () => [],
    },
    tableData: {
        type: Array,
        default: () => [],
    },
    patentBreakdown: {
        type: Object,
        default: () => {},
    },
    loading: {
        type: Boolean,
        default: true,
    },
    additionalNodeDataIsProcessing: {
        type: Boolean,
        default: false,
    },
    newDataAvailable: {
        type: Boolean,
        default: false,
    },
})

const updater = ref(0)
const arenaSetupLoading = ref(false)
const everythingDoneLoading = ref(false)
const filingDateData = ref([])
const activeGraph = ref('coverage')
const graphDropdownOptions = ref([
    {
        itemText: 'Coverage Map',
        iconOptions: {
            iconStyle: 'fas',
            iconColor: 'grey01',
            iconName: 'fa-chart-tree-map',
        },
    },
    {
        itemText: 'Filing Trends',
        iconOptions: {
            iconStyle: 'fas',
            iconColor: 'grey01',
            iconName: 'fa-dumbbell',
        },
    },
    {
        itemText: 'Investment Focus',
        iconOptions: {
            iconStyle: 'fas',
            iconColor: 'grey01',
            iconName: 'fa-merge',
        },
    },
])
// Sankey
const sankeyDataAll = ref([])
const sankeySourceNode = ref(null)
const currentSankeyTab = ref(0)
const productData = ref([])
const companySizePatentThreshold = 100
const normalizeCounts = ref(false)
const sankeyTypeTabs = ref([
    {
        id: 0,
        text: 'Current Investment Story',
    },
    {
        id: 1,
        text: 'Innovation Opportunities',
    },
])
const loadingTechAreas = ref(true)
const totalTechAreas = ref(0)
const exportingPatentData = ref(false)

onMounted(() => {
    loadingTechAreas.value = true

    if (props.type === 'pa') {
        parseFilingDateData()
        sankeyDataAll.value = aggregateSankeyData(props.allChartData)
        moat2ProductStore.showPACarousel = false
        moat2ProductStore.productAlignmentLoading = false
        if (
            !props.patentBreakdown ||
            !hasMappedPatents(moat2ProductStore.productAlignmentProducts)
        ) {
            defaultToInnovationOpportunities()
        }
    }
    loadingTechAreas.value = false
})

watch(
    () => loadingTechAreas.value,
    (newVal, oldVal) => {
        if (newVal !== oldVal) {
            setTimeout(() => {
                getTotalTechAreas()
            }, 1000)
        }
    }
)

const hasMappedPatents = (productData) => {
    return productData.some((item) => item.value > 0)
}

const defaultToInnovationOpportunities = () => {
    graphDropdownOptions.value = graphDropdownOptions.value.slice(2, 3)
    changeGraph(graphDropdownOptions.value[0])
    setActiveSankeyTab(sankeyTypeTabs.value[1])
}

const getTotalTechAreas = () => {
    if (props.type === 'pa') {
        totalTechAreas.value = cloneDeep(moat2ProductStore.techAreaIdCollection.length)
    } else {
        totalTechAreas.value = cloneDeep(arenaOutputStore.techAreaIdCollection.length)
    }
}

const chartTitle = computed(() => {
    if (activeGraph.value === 'filing-trends') {
        return 'Filing Trends'
    } else if (activeGraph.value === 'coverage') {
        return 'Coverage Map'
    } else {
        return 'Investment Focus'
    }
})

const chartDesc = computed(() => {
    if (activeGraph.value === 'filing-trends') {
        return 'This maps Product Classes to Technology Areas, highlighting participation and guiding investment decisions.'
    } else if (activeGraph.value === 'coverage') {
        return `Participation across different Product Classes, based on ${entityStore.entity.name}\'s patent holdings, revealing key areas of influence and opportunities for innovation.`
    } else {
        return 'Understand filing behavior over time to help you identify trends and patterns'
    }
})

const getTechAreaDesc = computed(() => {
    if (props.type === 'pa') {
        return `${moat2ProductStore.techAreaIdCollection.length} of ${totalTechAreas.value} Technology Areas Selected`
    } else {
        return `${arenaOutputStore.techAreaIdCollection.length} of ${totalTechAreas.value} Technology Areas Selected`
    }
})

const parseFilingDateData = () => {
    let names = []
    let mappedData = props.allData
        .map((item) => {
            if (item.targetEntityPatentCount > 0 && item.displayName !== 'Other') {
                let newItem = {
                    entry: new Date(
                        item.entryPriorityDt.replace(/-/g, '\/').replace(/T.+/, '')
                    ).getTime(),
                    average: new Date(
                        item.averageFilingDt.replace(/-/g, '\/').replace(/T.+/, '')
                    ).getTime(),
                    latest: new Date(
                        item.maxPriorityDt.replace(/-/g, '\/').replace(/T.+/, '')
                    ).getTime(),
                    targetEntityAverage: new Date(
                        item.targetEntityAverageFilingDt.replace(/-/g, '\/').replace(/T.+/, '')
                    ).getTime(),
                    name: item.displayName,
                    parentName: item.parentDisplayName,
                    rank: props.type === 'pa' ? item.targetTpe : item.tpe,
                }

                if (names.includes(newItem.name)) {
                    let count = 1
                    let baseName = newItem.name
                    while (names.includes(newItem.name)) {
                        newItem.name = baseName + ' '.repeat(count) //only way to keep the name consistent
                        count++
                    }
                }

                names.push(newItem.name)

                return newItem
            }
        })
        .filter((item) => item)
        .reverse()
    let sortedData = mappedData.sort((a, b) => a.rank - b.rank)
    filingDateData.value = sortedData
}

const setupArena = async () => {
    arenaSetupLoading.value = true

    let selectedProducts = moat2ProductStore.productAlignmentProducts.filter((prods) => {
        return [...moat2ProductStore.productAlignmentTechAreaSelections].find(
            (tech) => tech.parentNodeId === prods.nodeId
        )
    })

    try {
        const { data } = await requestArena(arenaStore.rootPAId, [
            ...moat2ProductStore.techAreaIdCollection,
            ...selectedProducts.map((item) => item.nodeId),
        ])

        router.push({ name: 'Arena', params: { arenaSourceId: data.productAlignmentId } })
    } catch (err) {
        logger.error(err)
    } finally {
        arenaSetupLoading.value = false
    }
}

const aggregateSankeyData = (data) => {
    sankeySourceNode.value = data.find((node) => node.parent === null)
    let idKey = data.map((item) => {
        return {
            displayName: item.displayName,
            nodeId: item.nodeId,
            parent: item.parent,
            rootNodeId: item.rootNodeId,
            patentAssociationCount: item.patentAssociationCount,
            recentPatentAssociationCount: item.recentPatentAssociationCount,
            tooltipSettings: {
                to: item.displayName,
                from: item.parent ? item.parent : null,
                mappedPatents: getDistinctPatentCount(item),
            },
        }
    })
    productData.value = data
        .map((item) => {
            if (item.parent === item.rootNodeId) {
                return {
                    parent: item.parent,
                    nodeId: item.nodeId,
                    recentPatentAssociationCount: item.recentPatentAssociationCount,
                    patentAssociationCount: item.patentAssociationCount,
                    from: sankeySourceNode.value.displayName,
                    to: item.displayName,
                    value: getPatentCount(item),
                    tooltipSettings: {
                        to: item.displayName,
                        from: sankeySourceNode.value.displayName,
                        mappedPatents: getDistinctPatentCount(item),
                    },
                }
            }
        })
        .filter((item) => item) // remove tech areas
    moat2ProductStore.productAlignmentProducts = productData.value

    moat2ProductStore.productAlignmentTechAreaNodes = data
        .map((item) => {
            if (item.parent) {
                return {
                    parent: item.parent,
                    nodeId: item.nodeId,
                    recentPatentAssociationCount: item.recentPatentAssociationCount,
                    patentAssociationCount: item.patentAssociationCount,
                    from: idKey.find((prod) => prod.nodeId === item.parent)?.displayName,
                    to: item.displayName,
                    value: getPatentCount(item),
                    tooltipSettings: {
                        to: item.displayName,
                        from: idKey.find((prod) => prod.nodeId === item.parent)?.displayName,
                        mappedPatents: getDistinctPatentCount(item),
                    },
                }
            }
        })
        .filter((item) => item) // remove source node
    return moat2ProductStore.productAlignmentTechAreaNodes
}

const getPatentCount = (sankeyItem) => {
    if (!props.patentBreakdown) {
        return 0
    }
    return props.patentBreakdown.assetCount > companySizePatentThreshold
        ? sankeyItem.recentPatentAssociationCount
        : sankeyItem.patentAssociationCount
}

const getDistinctPatentCount = (sankeyItem) => {
    if (!props.patentBreakdown) {
        return 0
    }
    return props.patentBreakdown.assetCount > companySizePatentThreshold
        ? sankeyItem.recentDistinctPatentAssociationCount
        : sankeyItem.distinctPatentAssociationCount
}

const setCountValue = (count) => {
    if (normalizeCounts.value) {
        return 1
    } else {
        return count // May do something with this later
    }
}

const setActiveSankeyTab = (tab) => {
    currentSankeyTab.value = tab.id
}

const changeGraph = (type) => {
    if (type.itemText === 'Filing Trends') {
        activeGraph.value = 'filing-trends'
    }
    if (type.itemText === 'Coverage Map') {
        activeGraph.value = 'coverage'
    }
    if (type.itemText === 'Investment Focus') {
        activeGraph.value = 'focus'
    }
}
const showPACarousel = () => {
    moat2ProductStore.showPACarousel = true
}
const exportPatentData = async () => {
    exportingPatentData.value = true
    let reportInput = {
        product_alignment_id:
            props.type === 'pa' ? moat2ProductStore.alignmentPk : arenaOutputStore.alignmentPk,
    }
    let reportName =
        props.type === 'pa'
            ? `${entityStore.entity.name} Product Alignment Export`
            : `${arenaStore.boardGlobalDetails.name} Arena Export`
    await createAndSubmitReport(eventBus, user, 'patent_export', reportName, reportInput)
    exportingPatentData.value = false
}
</script>
