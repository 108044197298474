<template>
    <div class="product-investment-breakdown">
        <AonCoverLoading
            :loading="loadingData"
            title="Loading Investment Breakdown..."
        ></AonCoverLoading>
        <AonCoverLoading
            v-if="noData"
            :loading="noData ? true : false"
            :show-spinner="false"
            title="No Investments Identified"
        >
            <template #header>
                <div class="icon">
                    <font-awesome-icon
                        icon="fas fa-circle-exclamation"
                        class="grey01--text"
                        size="2xl"
                    />
                </div>
            </template>
        </AonCoverLoading>
        <div id="amChartProductInvestmentBreakdown"></div>
        <div id="amChartLegendProductInvestmentBreakdown"></div>
        <div class="legend-instructions mt-1">
            <font-awesome-icon icon="fas fa-info-circle" class="grey01--text mr-1" />
            <p>Click investment types to toggle chart data</p>
        </div>
        <ProductInvestmentTable :investmentData="investmentData" />
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, inject, ref, computed } from 'vue'
import { config } from '@/config'
import { useMoat2ProductStore } from '@/stores'
import { getInvestmentsByProduct } from '@/api/ipNode'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import ProductInvestmentTable from './ProductInvestmentTable.vue'

const logger = inject('logger')
const loadingData = ref(true)
const noData = ref(false)
const investmentData = ref([])
const chartData = ref([])
const moat2ProductStore = useMoat2ProductStore()
let root
let chart
let yAxis
let xAxis

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartProductInvestmentBreakdown')
    getAndMapData()
})

onBeforeUnmount(() => {
    root.dispose()
})

const rootNodeId = computed(() => {
    return moat2ProductStore.productAlignmentAllData[0].nodeHierarchy[0].nodeId
})

const getAndMapData = async () => {
    loadingData.value = true
    try {
        let { data } = await getInvestmentsByProduct(rootNodeId.value, { years_back: 4 })

        if (data.length === 0) {
            noData.value = true
            loadingData.value = false
            return
        }

        investmentData.value = data

        chartData.value = data
            .flatMap((investment) => {
                return investment.productList.split('|').map((product) => {
                    return {
                        product: product.trim(),
                        ...investment,
                    }
                })
            })
            .reduce((acc, investment) => {
                const existing = acc.find((item) => item.product === investment.product)
                if (existing) {
                    if (!existing[investment.investmentType]) {
                        existing[investment.investmentType] = investment.investmentAmount
                    } else {
                        existing[investment.investmentType] += investment.investmentAmount
                    }
                    existing.total += investment.investmentAmount
                } else {
                    acc.push({
                        product: investment.product,
                        [investment.investmentType]: investment.investmentAmount,
                        total: investment.investmentAmount,
                    })
                }
                return acc
            }, [])
            .sort((a, b) => a.venture_capital - b.venture_capital)

        setTimeout(() => {
            configureChart()
            loadingData.value = false
        }, 100)
    } catch (error) {
        logger.error(error)
    }
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])
    root.numberFormatter.setAll({
        numberFormat: '#.##a',
        bigNumberPrefixes: [
            { number: 1e6, suffix: 'M' },
            { number: 1e9, suffix: 'B' },
            { number: 1e12, suffix: 'T' },
        ],
    })

    // Create chart
    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: false,
            panY: true,
            wheelX: 'none',
            wheelY: 'panY',
            paddingLeft: 0,
            layout: root.verticalLayout,
        })
    )

    chart.zoomOutButton.set('forceHidden', true)
    let scrollbarY = am5.Scrollbar.new(root, { orientation: 'vertical' })
    scrollbarY.startGrip.set('visible', false)
    scrollbarY.endGrip.set('visible', false)
    chart.set('scrollbarY', scrollbarY)
    chart.set('wheelable', true)

    generateAxes()
    const legend = generateLegend()
    generateSeries('Venture Capital', 'venture_capital', legend)
    generateSeries('Private Equity', 'private_equity', legend, false)
    generateSeries('IPOs', 'ipo', legend, false)
    generateSeries('Mergers and Acquisitions', 'm_and_a', legend, false)
    generateSeries('Other', 'other', legend, false)

    yAxis.zoomToIndexes(Math.max(0, chartData.value.length - 10), chartData.value.length)
    chart.appear(1000, 100)
}

const generateAxes = () => {
    var yRenderer = am5xy.AxisRendererY.new(root, {
        minGridDistance: 5,
    })
    yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'product',
            renderer: yRenderer,
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    yRenderer.grid.template.setAll({
        location: 1,
    })
    yAxis.data.setAll(chartData.value)

    xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 40,
                strokeOpacity: 0.1,
            }),
        })
    )
}

const generateLegend = () => {
    let legendRoot = am5.Root.new('amChartLegendProductInvestmentBreakdown')

    let legend = legendRoot.container.children.push(
        am5.Legend.new(legendRoot, {
            centerX: am5.percent(50),
            x: am5.percent(50),
        })
    )

    legend.events.on('boundschanged', function () {
        document.getElementById('amChartLegendProductInvestmentBreakdown').style.height =
            legend.height() + 'px'
    })

    return legend
}

const generateSeries = (name, investmentType, legend, defaultVisible = true) => {
    var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            name: name,
            stacked: true,
            xAxis: xAxis,
            yAxis: yAxis,
            baseAxis: yAxis,
            valueXField: investmentType,
            categoryYField: 'product',
        })
    )

    series.columns.template.setAll({
        tooltipText: '{name}, {categoryY}: {valueX}',
        tooltipY: am5.percent(90),
    })
    series.data.setAll(chartData.value)

    if (!defaultVisible) series.hide()

    legend.data.push(series)
}
</script>

<style lang="scss" scoped>
.product-investment-breakdown {
    width: 100%;
    position: relative;

    #amChartProductInvestmentBreakdown {
        width: 100%;
        height: 450px;

        div {
            height: 100%;
        }
    }

    .legend-instructions {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
