import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { useEntityStore } from '@/stores'

export function useBarChart(root) {
    let chart
    let scrollableContainer
    let yAxis
    let xAxis
    let barSeries
    let clickCallback = null

    const setClickCallback = (callback) => {
        clickCallback = callback
    }

    const configureBar = (options = { horizontal: true, scrollable: true }) => {
        root.setThemes([am5themes_Animated.new(root)])

        chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                // Use appropriate layout based on orientation
                layout: options.horizontal ? root.verticalLayout : root.horizontalLayout,
                wheelable: true,
            })
        )

        if (options.scrollable) {
            scrollableContainer = chart.chartContainer.children.unshift(
                am5.Container.new(root, {
                    width: am5.p100,
                    height: am5.p100,
                    verticalScrollbar: am5.Scrollbar.new(root, {
                        orientation: 'vertical',
                    }),
                    wheelable: true,
                })
            )
        }

        chart.set(
            'cursor',
            am5xy.XYCursor.new(root, {
                behavior: 'none',
                xAxis: xAxis,
                yAxis: yAxis,
            })
        )
    }

    const generateAxes = (
        mappedData,
        options = {
            showBullets: true,
            horizontal: true,
            formatCurrency: false,
            scrollable: true,
        }
    ) => {
        const entityStore = useEntityStore()

        // Create a currency formatter if needed
        const currencyFormatter = options.formatCurrency
            ? am5.NumberFormatter.new(root, {
                  numberFormat: '$#,###a',
                  bigNumberPrefixes: [
                      { number: 1e3, suffix: 'K' },
                      { number: 1e6, suffix: 'M' },
                      { number: 1e9, suffix: 'B' },
                  ],
              })
            : am5.NumberFormatter.new(root, {
                  numberFormat: '#,###a',
              })

        if (options.horizontal) {
            // Horizontal bar chart
            let yRenderer = am5xy.AxisRendererY.new(root, {
                minGridDistance: 30,
                minorGridEnabled: true,
            })

            yRenderer.grid.template.set('location', 1)

            const yAxisConfig = {
                maxDeviation: 0,
                categoryField: 'name',
                renderer: yRenderer,
                tooltip: am5.Tooltip.new(root, { themeTags: ['axis'] }),
            }

            if (options.showBullets) {
                yAxisConfig.bullet = function (root, axis, dataItem) {
                    return am5xy.AxisBullet.new(root, {
                        location: 0.5,
                        sprite: am5.Label.new(root, {
                            text: dataItem.dataContext.productDisplayName,
                            fill: am5.color('#82939A'),
                            centerY: am5.p50,
                            centerX: am5.percent(97),
                            dy: -8,
                            fontSize: 12,
                        }),
                    })
                }
            }

            yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, yAxisConfig))

            yAxis.get('renderer').labels.template.setAll({
                dy: entityStore.strategyTabNodeFilter === 'product' ? 0 : 9,
            })

            let xRenderer = am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
                minGridDistance: 80,
            })

            xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    maxDeviation: 0,
                    min: 0,
                    numberFormatter: currencyFormatter,
                    extraMax: 0.1,
                    renderer: xRenderer,
                })
            )

            yAxis.data.setAll(mappedData)
            chart.yAxesAndPlotContainer.set('height', mappedData.length * 60)
            chart.yAxesAndPlotContainer.set('paddingBottom', 10)
            if (options.scrollable && scrollableContainer) {
                scrollableContainer.children.push(chart.yAxesAndPlotContainer)
            }
        } else {
            // Vertical bar chart (new implementation)
            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 30,
                minorGridEnabled: true,
            })

            xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    maxDeviation: 0,
                    categoryField: 'name',
                    renderer: xRenderer,
                    tooltip: am5.Tooltip.new(root, { themeTags: ['axis'] }),
                })
            )

            let yRenderer = am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1,
                minGridDistance: 30,
            })

            yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    maxDeviation: 0,
                    min: 0,
                    numberFormatter: currencyFormatter,
                    extraMax: 0.1,
                    renderer: yRenderer,
                })
            )

            xAxis.data.setAll(mappedData)
        }
    }

    const generateSeries = (
        mappedData,
        options = {
            horizontal: true,
            formatCurrency: false,
            clickable: false,
        }
    ) => {
        // Create tooltip text based on formatting options
        const tooltipText = options.formatCurrency
            ? options.horizontal
                ? '${valueX}'
                : '${valueY}'
            : options.horizontal
              ? '{valueX}'
              : '{valueY}'

        if (options.horizontal) {
            // Horizontal bar series
            barSeries = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueXField: 'value',
                    categoryYField: 'name',
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: 'left',
                        labelText: tooltipText,
                    }),
                })
            )
        } else {
            // Vertical bar series
            barSeries = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: 'value',
                    categoryXField: 'name',
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: 'down',
                        labelText: tooltipText,
                    }),
                })
            )
        }

        barSeries.columns.template.adapters.add('fill', function () {
            return am5.color('#102B47')
        })

        barSeries.columns.template.adapters.add('stroke', function () {
            return am5.color('#102B47')
        })

        if (options.clickable && clickCallback) {
            // Add hover state
            barSeries.columns.template.set('cursorOverStyle', 'pointer')
            barSeries.columns.template.states.create('hover', {
                strokeWidth: 3,
                strokeOpacity: 1,
                stroke: am5.color(0x000000),
            })

            // Add click event
            barSeries.columns.template.events.on('click', function (ev) {
                const dataItem = ev.target.dataItem
                if (dataItem && clickCallback) {
                    const cellData = {
                        name: dataItem.dataContext.name,
                        value: dataItem.dataContext.value,
                        date: dataItem.dataContext.date,
                        originalData: dataItem.dataContext,
                    }
                    clickCallback(cellData)
                }
            })
        }

        barSeries.data.setAll(mappedData)
    }

    const disposeBar = () => {
        if (chart) chart.dispose()
        if (scrollableContainer) scrollableContainer.dispose()
        if (yAxis) yAxis.dispose()
        if (xAxis) xAxis.dispose()
        if (barSeries) barSeries.dispose()
    }

    const setBarData = (mappedData) => {
        barSeries.data.setAll(mappedData)
    }

    return {
        configureBar,
        generateAxes,
        generateSeries,
        setBarData,
        disposeBar,
        setClickCallback,
    }
}
