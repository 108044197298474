<template>
    <div class="industry-litigation-card">
        <AonContainer>
            <AonRow class="mt-5">
                <AonCol class="aon-col-12 pa-0 flex-column justify-content-center">
                    <AonCard
                        title="Industry Litigation & Coverage"
                        :desc="`Litigation trends related to the products and technologies of ${entityStore.entity.name}`"
                    >
                        <div class="card-content">
                            <AonCoverLoading
                                :loading="loading"
                                title="Loading Industry Litigation History..."
                                message="Products and Technology Areas within Moat2Product will need to be identified."
                            ></AonCoverLoading>
                            <AonRow class="mt-5 h-full align-items-start">
                                <AonCol
                                    class="aon-col-3 pa-0 flex-column justify-content-center h-full"
                                    style="position: relative; overflow: hidden"
                                >
                                    <IndustryLitigationChart
                                        v-if="props.data.length > 0 && !loading"
                                        :chartData="props.data"
                                    />
                                </AonCol>
                                <AonCol
                                    class="aon-col-9 pa-0 flex-column justify-content-center h-full"
                                >
                                    <IndustryLitigationTable
                                        v-if="props.data.length > 0 && !loading"
                                        :tableData="props.data"
                                    />
                                </AonCol>
                            </AonRow>
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { useEntityStore } from '@/stores'

import IndustryLitigationChart from './IndustryLitigationChart.vue'
import IndustryLitigationTable from './IndustryLitigationTable.vue'

const entityStore = useEntityStore()

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    data: {
        type: Array,
        default: () => [],
    },
})
</script>
<style lang="scss" scoped>
.industry-litigation-card {
    width: 100%;
    position: relative;

    .card-content {
        height: 500px;
    }
}
</style>
