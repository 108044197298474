<template>
    <div
        class="no-rows-overlay d-flex align-items-center justify-content-center align-content-center flex-wrap"
    >
        <h6 class="w-full mb-4">{{ params.message }}</h6>
        <AonButton
            v-if="params.showResetFilterButton"
            label="Clear Filters"
            type="prominent"
            @clicked="resetFiltersAction()"
        />
    </div>
</template>

<script setup>
import { onMounted } from 'vue'

const emit = defineEmits(['setup-arena'])
const props = defineProps({
    params: Object,
})

onMounted(() => {})

const resetFiltersAction = () => {
    props.params.resetFilters()
}
</script>

<style lang="scss">
.no-rows-overlay {
    width: 100%;
    height: 100%;
    background: white;
}
.ag-overlay {
    pointer-events: all !important;
}
</style>
