<template>
    <div class="report-generation-container">
        <AonSpinner v-if="loading" class="ma-auto" :scale="0.5" />
        <div class="parent-holder d-flex" v-else>
            <ReportStepTracker
                overwrite-class="no-substeps"
                allow-step-navigation
                heading="Create a new Report"
                allow-collapse
            />
            <KeepAlive>
                <div
                    v-if="
                        reportGenerationStore.trackStepsFinal.length === 0 ||
                        reportGenerationStore.trackStepsFinal[0].isCurrentStep
                    "
                    class="w-full pa-5"
                >
                    <h5>Select Report Type</h5>
                    <AonCard class="mt-5">
                        <div class="content-holder">
                            <ReportTypeSelection />
                        </div>
                    </AonCard>
                </div>
            </KeepAlive>

            <div v-if="showCompAnalysisReport" class="w-full pa-5">
                <CompetitiveAnalysisReport />
            </div>
            <div v-if="showHeadToHeadReport" class="w-full pa-5">
                <HeadToHeadReport />
            </div>
            <div v-if="showCounterAssertionReport" class="w-full pa-5">
                <CounterAssertionReport />
            </div>
            <div v-if="showRiskAnalysisReport" class="w-full pa-5">
                <RiskAnalysisReport />
            </div>
        </div>
        <AonContainer>
            <AonRow style="height: 75px">
                <AonCol class="aon-col-12 pa-0">
                    <ReportNav />
                </AonCol>
            </AonRow>
        </AonContainer>

        <!-- Product alignment loading -->
        <AonCoverLoading
            :loading="reportGenerationStore.productAlignmentLoading"
            :title="`Please hang tight!`"
            :message="loadingMessage"
        >
            <template #footer>
                <progress
                    v-if="reportGenerationStore.percentComplete !== 0"
                    :value="reportGenerationStore.percentComplete"
                    max="100"
                    class="mt-4 progress-bar"
                />
            </template>
        </AonCoverLoading>
        <!-- Contender List loading -->
        <AonCoverLoading
            :loading="reportGenerationStore.contenderListLoading"
            :title="`Loading contender list for ${reportGenerationStore.targetCompany}`"
            :message="contenderLoadingMessage"
        >
        </AonCoverLoading>
        <!-- Error for product alignment -->
        <AonCoverLoading
            class="error-cover"
            :loading="typeof reportGenerationStore.error === 'string' ? true : false"
            :show-spinner="false"
            :title="`No Products Found for ${reportGenerationStore.targetCompany}`"
            :message="reportGenerationStore.errorMsg"
        >
            <template #header>
                <font-awesome-icon
                    :icon="`fas fa-triangle-exclamation`"
                    style="border-radius: 100%"
                    class="warning--text warning-background pa-4"
                    size="2xl"
                />
            </template>
            <template #footer>
                <ul class="my-4" style="list-style: disc">
                    <li class="my-2">
                        Select another company in the same hierarchy, such as a parent or subsidiary
                        that may be better known.
                    </li>
                    <li class="my-2">Choose a similar company, such as a competitor.</li>
                    <li class="my-2">Build an arena using relevant technology areas.</li>
                </ul>
                <p>
                    If you need further assistance, please contact
                    <a href="mailto:support@moatmetrics.com">support@moatmetrics.com</a>
                </p>
                <div class="button-holder mt-5 w-full d-flex justify-content-center">
                    <AonButton
                        class="mr-5"
                        label="Select a New Target Company"
                        @clicked="resetTargetCompanyStep()"
                    />
                </div>
            </template>
        </AonCoverLoading>
    </div>
</template>

<script setup>
import { computed, ref, onBeforeMount, onMounted } from 'vue'
import { useReportGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'

import ReportStepTracker from '@/components/reports/shared/ReportStepTracker.vue'
import ReportNav from '@/components/reports/shared/ReportNav.vue'
import CompetitiveAnalysisReport from '@/components/reports/reportTypes/competitiveAnalysis/CompetitiveAnalysisReport.vue'
import HeadToHeadReport from '@/components/reports/reportTypes/headToHead/HeadToHeadReport.vue'
import CounterAssertionReport from '@/components/reports/reportTypes/counterAssertion/CounterAssertionReport.vue'
import RiskAnalysisReport from '@/components/reports/reportTypes/riskAnalysis/RiskAnalysisReport.vue'

import ReportTypeSelection from '@/components/reports/shared/ReportTypeSelection.vue'

const reportGenerationStore = useReportGenerationStore()
const router = useRouter()

const loading = ref(true)

onBeforeMount(async () => {
    loading.value = false
})

onMounted(() => {})

const loadingMessage = computed(() => {
    if (reportGenerationStore.needsNewAlignment) {
        return `We are aligning patents to the product and technology areas for ${reportGenerationStore.targetCompany}. This process will take 5-10 minutes if not cached in our system. Please feel free to check out the other tabs and this process will continue in the background.`
    } else {
        return `Loading Moat2Product for ${reportGenerationStore.targetCompany}`
    }
})

const contenderLoadingMessage = computed(() => {
    if (reportGenerationStore.playbookTypeSelected === 'matchUp') {
        return `We'll apply the top 5 contenders for ${reportGenerationStore.targetCompany}`
    } else {
        return 'Populating the list of competitors for comparison'
    }
})

const showCompAnalysisReport = computed(() => {
    return (
        reportGenerationStore.reportTypeSelected === 'compAnalysis' &&
        reportGenerationStore.trackStepsFinal.length > 0 &&
        !reportGenerationStore.trackStepsFinal[0].isCurrentStep
    )
})

const showHeadToHeadReport = computed(() => {
    return (
        reportGenerationStore.reportTypeSelected === 'headToHead' &&
        reportGenerationStore.trackStepsFinal.length > 0 &&
        !reportGenerationStore.trackStepsFinal[0].isCurrentStep
    )
})

const showCounterAssertionReport = computed(() => {
    return (
        reportGenerationStore.reportTypeSelected === 'counter' &&
        reportGenerationStore.trackStepsFinal.length > 0 &&
        !reportGenerationStore.trackStepsFinal[0].isCurrentStep
    )
})

const showRiskAnalysisReport = computed(() => {
    return (
        reportGenerationStore.reportTypeSelected === 'risk' &&
        reportGenerationStore.trackStepsFinal.length > 0 &&
        !reportGenerationStore.trackStepsFinal[0].isCurrentStep
    )
})

const resetTargetCompanyStep = () => {
    reportGenerationStore.error = null
}
</script>

<script>
export default {
    name: 'CreateReport',
}
</script>

<style lang="scss" scoped>
.report-generation-container {
    height: 100%;
    width: 100%;

    .parent-holder {
        height: calc(100% - 78px);
    }

    .content-holder {
        height: 100%;
    }

    .ignore-max-width {
        max-width: none !important;
    }

    .prevent-content-overflow {
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
</style>
