<template>
    <div class="claim-breath-card">
        <AonCard
            :title="`Validity by Claim Breadth`"
            desc="This analysis helps you understand the enforceability and potential market impact of patents."
        >
            <template #header-append>
                <p v-if="!loading" class="grey02--text italic mt-n8 ml-4">
                    Patent totals shown are US only
                </p>
            </template>
            <div class="card-content d-flex justify-content-end" style="position: relative">
                <div class="arrow-side pa-1">
                    <p class="bold small white--text text-center">Breadth (broader = better)</p>
                </div>
                <div class="width-check py-10">
                    <div class="arrow-top pa-1 mb-3">
                        <p class="bold small white--text text-center">
                            Validity Rank (higher = better)
                        </p>
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <td></td>
                                <td v-for="(rank, i) in validityRank" :key="`rank_header_${i}`">
                                    {{ $filters.toLocaleString(rank) }}
                                </td>
                            </tr>
                            <tr v-for="(breadth, i) in claimBreadth" :key="`breadth_row_${i}`">
                                <td>{{ breadth }}</td>
                                <td
                                    v-for="(v, i) in getPatentsByBreadth(breadth)"
                                    :key="`very_broad_${i}`"
                                    :class="[{ highlight: background(v.rank, breadth) }]"
                                    class="value-cell"
                                    style="position: relative"
                                    @click="showPatents(breadth, validityRank[i])"
                                >
                                    {{ $filters.toLocaleString(v.total) }}
                                    <ContextMenu
                                        v-if="isProUser"
                                        class="menu align-context-menu"
                                        trigger="hover"
                                        no-actions-message="View Patents"
                                    />
                                </td>
                            </tr>
                            <tr style="border-top: 2px solid #46535e">
                                <td>Total</td>
                                <td
                                    v-for="(rank, i) in validityRank"
                                    :key="`total_${i}`"
                                    class="value-cell"
                                    style="position: relative"
                                    @click="showPatents(null, validityRank[i])"
                                >
                                    {{ $filters.toLocaleString(getTotalByRank(rank)) }}
                                    <ContextMenu
                                        v-if="isProUser"
                                        class="menu align-context-menu"
                                        trigger="hover"
                                        no-actions-message="View Patents"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="legend d-flex justify-content-center align-items-center">
                    <div class="legend-item d-flex">
                        <div class="chip mr-2"></div>
                        <p class="small">Foundational Patents</p>
                    </div>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from 'vue'
import { useEntityStore, usePatentViewerStore } from '@/stores'
import * as entityApi from '@/api/entities'

import ContextMenu from '@/components/helpers/ContextMenu.vue'

import { useI18n } from 'vue-i18n'
import { useFlag } from '@unleash/proxy-client-vue'

const { t } = useI18n()

const isProUser = useFlag('ipAlpha.proUsers')

const entityStore = useEntityStore()
const patentViewerStore = usePatentViewerStore()

const loading = ref(true)
const portfolioValidity = ref([])
const totalPatentCount = ref(null)
const claimBreadth = ['Very Broad', 'Broad', 'Average', 'Narrow']
const validityRank = ['Low', 'Medium', 'High']

const qualityClaimBreadths = ['Very Broad', 'Broad']
const qualityValidityRanks = ['High', 'Medium']

onMounted(async () => {
    const response = await entityApi.getEntityPortfolioValidity(
        entityStore.entity.aon_entity_pk,
        'US'
    )
    portfolioValidity.value = response.data
    totalPatentCount.value = response.data.map((p) => p.total).reduce((a, b) => a + b, 0)
    loading.value = false
})

const getPatentsByBreadth = (breadth) => {
    const ranksForBreadth = portfolioValidity.value.filter((p) => p.claimBreadth === breadth)
    let totals = []
    validityRank.forEach((rank) => {
        const rankTotal = ranksForBreadth.find((r) => r.validityRank === rank)?.total || '--'
        totals.push({ total: rankTotal, rank: rank })
    })
    return totals
}
const getTotalByRank = (rank) => {
    return portfolioValidity.value
        .filter((p) => p.validityRank === rank && p.claimBreadth !== 'Not Scored')
        .map((p) => p.total)
        .reduce((a, b) => a + b, 0)
}

const background = (rank, breadth) => {
    if (qualityClaimBreadths.includes(breadth) && qualityValidityRanks.includes(rank)) {
        return true
    } else {
        return false
    }
}

const showPatents = async (breadth, validity) => {
    if (isProUser.value) {
        const basicsParams = {
            active: true,
            country: 'US',
        }

        if (breadth) {
            basicsParams.claimBreadth = breadth
        }

        if (validity) {
            basicsParams.validity = validity
        }
        patentViewerStore.entityPkList.push(entityStore.entity.aon_entity_pk)
        patentViewerStore.entityName = entityStore.entity.name
        patentViewerStore.ipBasicsEntityPK = entityStore.entity.aon_entity_pk
        patentViewerStore.ipBasicsObject = basicsParams
        await nextTick()
        patentViewerStore.showPatentViewerList = true
    }
}
</script>

<style lang="scss" scoped>
$displaySize: 20px;
$offset: 40px;
$cardContent: 460px;
$headerWidth: 150px;
$headerHeight: 50px;

.align-context-menu {
    position: absolute;
    right: 5px;
    top: 0;
}

.value-cell {
    cursor: pointer;

    &:hover {
        .menu {
            opacity: 1;
        }
    }

    .menu {
        margin-top: 9px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
}

.claim-breath-card {
    width: 100%;
    position: relative;

    .width-check {
        width: calc(100% - $offset);
    }

    .arrow-top,
    .arrow-side {
        position: absolute;
        background-color: vars.$castleMoat;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border: $displaySize solid transparent;
        }

        &:before {
            border-right: $displaySize solid vars.$castleMoat;
            top: -7px;
            left: -$offset;
        }

        &::after {
            border-left: $displaySize solid vars.$castleMoat;
            top: -7px;
            right: -$offset;
        }
    }

    .arrow-top {
        top: 0;
        width: calc((25% * 3 - 70px)); // 3 blocks at 25% each - width of top arrow - padding
        left: calc((25% + 50px)); // 1 block at 25% + padding
    }

    .arrow-side {
        bottom: calc(50% - 15px); // 1/4 of paddingY
        left: 0;
        width: calc($cardContent - ($offset * 2) - ($headerHeight * 2) - 60px);

        transform-origin: top left;
        transform: rotate(-90deg) translateX(-50%);
    }

    .card-content {
        height: $cardContent;
        min-height: $cardContent;

        table {
            min-width: 100%;
            height: calc(100%);

            tr:first-child {
                max-height: $headerHeight;
                font-weight: 700;
                td {
                    padding: 1em;
                    height: 10%;
                    width: 25%;
                }
            }
            tr {
                td:first-child {
                    font-weight: 700;
                    width: $headerWidth;
                    max-width: $headerWidth;
                }
            }
            td {
                vertical-align: middle;
                text-align: center;
                border: 1px solid vars.$grey04;

                &.highlight {
                    background: vars.$knightsCloak;
                    color: white;
                }
            }
        }
    }

    .legend {
        width: 100%;
        position: absolute;
        bottom: 5px;
        left: 0;

        .chip {
            width: 18px;
            height: 18px;
            border-radius: 4px;
            background: vars.$knightsCloak;
        }
        p {
            font-weight: 500;
            font-size: 15px;
        }
    }
}
</style>
