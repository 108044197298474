<template>
    <div class="product-class-peer-chart">
        <div id="amChartClassPeers"></div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, onActivated } from 'vue'

import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

const emit = defineEmits(['chart-clicked'])
const props = defineProps({
    peerData: {
        type: Array,
        default: () => [],
    },
})

let root = null
let chart = null

onMounted(async () => {
    if (document.getElementById('amChartClassPeers')) {
        am5.addLicense(config.license.AMChartsLicense)
        root = am5.Root.new('amChartClassPeers')
        configureChart()
    }
})

onBeforeUnmount(() => {
    if (root) {
        root.dispose()
    }
})

onActivated(() => {
    if (!root && document.getElementById('amChartClassPeers')) {
        am5.addLicense(config.license.AMChartsLicense)
        root = am5.Root.new('amChartClassPeers')
        configureChart()
    }
})

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])
    chart = root.container.children.push(
        am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
        })
    )
    generateSeries()
}

const generateSeries = () => {
    let series = chart?.series.push(
        am5percent.PieSeries.new(root, {
            valueField: 'powerScore',
            categoryField: 'aonEntityName',
            fillField: 'color', // Add this line to use the color field
            tooltip: am5.Tooltip.new(root, {
                labelText: '{category}: {powerScore.formatNumber("#.")}',
            }),
        })
    )

    series?.slices.template.events.on('click', (ev) => {
        const dataItem = ev.target.dataItem.dataContext
        emit('chart-clicked', dataItem)
    })

    series?.labels.template.set('visible', false)
    series?.ticks.template.set('visible', false)

    series?.data.setAll(props.peerData)

    series?.appear(1000, 100)
}
</script>

<style lang="scss" scoped>
.product-class-peer-chart {
    width: 100%;

    #amChartClassPeers {
        width: 100%;
        height: 303px;

        div {
            height: 100%;
        }
    }
}
</style>
