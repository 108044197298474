<template>
    <div class="litigation-risk-visuals">
        <div class="grid-container">
            <div class="chart-container">
                <h6>Innovation Gap Analysis</h6>
                <InnovationGapChart
                    :dataReady="props.dataReady && innovationDataReady"
                    :peer-score="props.licensingOpportunityData?.innovationScoreRequirement ?? NaN"
                    :licensee-score="props.licensingOpportunityData?.innovationScore ?? NaN"
                    :licensor-score="targetNode?.innovationScore ?? NaN"
                    :licensee-name="licensingOpportunityData?.entityName"
                />
            </div>

            <div class="chart-container">
                <h6>Litigation Cases Over Time</h6>
                <LitigationCaseCountChart
                    :dataReady="props.dataReady && litigationDataReady"
                    :case-data="litigationCaseData?.caseCountHistory ?? null"
                />
            </div>

            <div class="metric-container">
                <h6 class="mb-2">Total Damages (past 10 years)</h6>
                <TotalDamagesMetric
                    :dataReady="props.dataReady && litigationDataReady"
                    :total-damages="totalDamages"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import InnovationGapChart from './charts/InnovationGapChart.vue'
import LitigationCaseCountChart from './charts/LitigationCaseCountChart.vue'
import TotalDamagesMetric from './charts/TotalDamagesMetric.vue'
import { getEntityProductStrategy } from '@/api/entities'
import { getLitigationRisk } from '@/api/risk'
const targetEntityStrategy = ref(null)
const targetNode = ref(null)
const allLitigationCaseData = ref(null)
const litigationCaseData = ref([])
const totalDamages = ref(NaN)
const innovationDataReady = ref(false)
const litigationDataReady = ref(false)
const props = defineProps({
    licensingOpportunityData: {
        type: Object,
    },
    targetEntityPk: {
        type: Number,
    },
    productAlignmentId: {
        type: String,
    },
    dataReady: {
        type: Boolean,
    },
})

const fetchProductStrategy = async (entityPk: number) => {
    try {
        const response = await getEntityProductStrategy(entityPk)
        targetEntityStrategy.value = response.data
        if (props.licensingOpportunityData?.nodeId) {
            updateTargetNode(props.licensingOpportunityData?.nodeId)
        }
        innovationDataReady.value = true
    } catch (error) {
        console.error('Error fetching product strategy:', error)
    }
}

const updateTargetNode = (nodeId: string) => {
    if (targetEntityStrategy.value && nodeId) {
        targetNode.value = targetEntityStrategy.value.find((node) => node.nodeId === nodeId)
    } else {
        targetNode.value = null
    }
}

const fetchLitigationData = async (productAlignmentId: string) => {
    try {
        const response = await getLitigationRisk(productAlignmentId)
        allLitigationCaseData.value = response.data
        updateLitigationData(props.licensingOpportunityData?.nodeId)
        litigationDataReady.value = true
    } catch (error) {
        console.error('Error fetching litigation data:', error)
    }
}

const updateLitigationData = (nodeId: string) => {
    if (allLitigationCaseData.value && nodeId) {
        litigationCaseData.value = allLitigationCaseData.value.find(
            (item) => item.nodeId === nodeId
        )
        totalDamages.value = litigationCaseData.value.totalDamages
    } else {
        litigationCaseData.value = []
        totalDamages.value = NaN
    }
}

watch(
    () => props.targetEntityPk,
    (newEntityPk) => {
        if (newEntityPk) {
            fetchProductStrategy(newEntityPk)
        }
    },
    { immediate: true }
)

watch(
    () => props.licensingOpportunityData?.nodeId,
    (newNodeId) => {
        if (newNodeId) {
            updateTargetNode(newNodeId)
            updateLitigationData(newNodeId)
        }
    }
)

watch(
    () => props.productAlignmentId,
    (newProductAlignmentId) => {
        if (newProductAlignmentId) {
            fetchLitigationData(newProductAlignmentId)
        }
    },
    { immediate: true }
)
</script>

<style scoped>
.litigation-risk-visuals {
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
}

.chart-container:first-child {
    grid-column: span 2;
}

.chart-container:nth-child(2) {
    grid-column: span 2;
}

.metric-container {
    grid-column: span 1;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chart-container {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chart-container h3 {
    margin-top: 0;
    color: #2c3e50;
    font-size: 1.2rem;
}

@media (max-width: 1024px) {
    .grid-container {
        grid-template-columns: 1fr;
    }

    .chart-container:first-child,
    .chart-container:nth-child(2),
    .metric-container {
        grid-column: span 1;
    }
}
</style>
