<template>
    <div class="dumbbell-chart d-flex align-items-center">
        <p class="y-axis-label">Technology Areas</p>
        <div id="amChartBumbbell"></div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, inject } from 'vue'
import { useEntityStore } from '@/stores'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

let root
let yAxis
let xAxis

const entityStore = useEntityStore()

const props = defineProps({
    filingDateData: {
        type: Array,
        default: () => [],
    },
})

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartBumbbell')
    configureChart()
})

onBeforeUnmount(() => {
    root.dispose()
})

const configureChart = (removeZeros = true) => {
    root.setThemes([am5themes_Animated.new(root)])
    root.utc = true
    //ADD INIT ANIMATION

    root.dateFormatter.setAll({
        dateFormat: 'MM-dd-yyyy',
        dateFields: ['valueX', 'openValueX'],
    })

    // Create series
    let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panY: false,
            interactive: false,
            wheelY: 'none',
            layout: root.verticalLayout,
            wheelable: true,
        })
    )

    var scrollableContainer = chart.chartContainer.children.unshift(
        am5.Container.new(root, {
            width: am5.p100,
            height: am5.p100,
            verticalScrollbar: am5.Scrollbar.new(root, {
                orientation: 'vertical',
            }),
            wheelable: true,
        })
    )
    chart.yAxesAndPlotContainer.set('height', props.filingDateData.length * 60)
    chart.yAxesAndPlotContainer.set('paddingBottom', 10)
    scrollableContainer.children.push(chart.yAxesAndPlotContainer)

    generateAxes(chart)
    generateSeries(chart)
}

const generateAxes = (chart) => {
    let yRenderer = am5xy.AxisRendererY.new(root, {})
    yRenderer.grid.template.setAll({
        minGridDistance: 40,
        forceInactive: true,
    })

    yRenderer.labels.template.setAll({
        fill: am5.color('#5D6D78'),
        fontSize: 14,
        paddingTop: 15,
    })

    yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'name',
            forceInactive: true,
            active: false,
            visible: false,
            crisp: true,
            renderer: yRenderer,
            marginRight: 10,
            bullet: function (root, axis, dataItem) {
                return am5xy.AxisBullet.new(root, {
                    location: 0.5,
                    sprite: am5.Label.new(root, {
                        text: dataItem.dataContext.parentName,
                        fill: am5.color('#82939A'),
                        centerY: am5.p50,
                        centerX: am5.percent(97),
                        dy: -13,
                        fontSize: 12,
                    }),
                })
            },
        })
    )

    yAxis.data.setAll(props.filingDateData)

    let xRenderer = am5xy.AxisRendererX.new(root, {})
    xRenderer.grid.template.setAll({
        minGridDistance: 40,
        forceInactive: true,
        cellStartLocation: 0.5,
        cellEndLocation: 0.5,
    })

    xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
            baseInterval: { timeUnit: 'month', count: 6 },
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {}),
            extraMin: 0.03,
            extraMax: 0.03,
        })
    )

    xAxis.children.push(am5.Label.new(root, { text: 'Years', x: am5.p50, centerX: am5.p50 }))

    xAxis.get('renderer').grid.template.set('visible', true)
}

const generateSeries = (chart) => {
    //Dumbbell Series
    let dumbbellSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            forceInactive: true,
            openValueXField: 'entry',
            valueXField: 'latest',
            categoryYField: 'name',
            fill: am5.color('#5D6D78'),
        })
    )

    dumbbellSeries.columns.template.setAll({
        height: 3,
    })

    // Add bullets
    dumbbellSeries.bullets.push(function () {
        return am5.Bullet.new(root, {
            locationX: 0,
            forceInactive: true,
            sprite: am5.Circle.new(root, {
                fill: am5.color('#5D6D78'),
                radius: 10,
                tooltipText: `[bold]Earliest Filing Date:[/] {openValueX}`,
                tooltipY: 3,
            }),
        })
    })

    dumbbellSeries.bullets.push(function () {
        return am5.Bullet.new(root, {
            locationX: 1,
            forceInactive: true,
            sprite: am5.Circle.new(root, {
                fill: am5.color('#5D6D78'),
                radius: 10,
                tooltipText: `[bold]Latest Filing Date:[/] {valueX}`,
                tooltipY: 3,
            }),
        })
    })

    // All average series
    let averageSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: ' Contender Average',
            xAxis: xAxis,
            yAxis: yAxis,
            forceInactive: true,
            valueXField: 'average',
            categoryYField: 'name',
            wheelable: true,
        })
    )

    averageSeries.strokes.template.setAll({
        visible: false,
    })

    // Add bullets
    averageSeries.bullets.push(function () {
        return am5.Bullet.new(root, {
            forceInactive: true,
            sprite: am5.Star.new(root, {
                fill: am5.color('#2A79D2'),
                stroke: am5.color('#FFFFFF'),
                spikes: 4,
                innerRadius: am5.percent(70),
                rotation: 180,
                scale: 1.2,
                tooltipText: `[bold]Contender Average Filing Date:[/] {valueX}`,
                tooltipY: 16,
            }),
        })
    })

    // Target average series
    let averageEntitySeries = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: ` ${entityStore.entity.name} Average`,
            xAxis: xAxis,
            yAxis: yAxis,
            forceInactive: true,
            valueXField: 'targetEntityAverage',
            categoryYField: 'name',
            wheelable: true,
        })
    )

    averageEntitySeries.strokes.template.setAll({
        visible: false,
    })

    // Add bullets
    averageEntitySeries.bullets.push(function () {
        return am5.Bullet.new(root, {
            forceInactive: true,
            sprite: am5.Star.new(root, {
                scale: 1.3,
                fill: am5.color('#F2AF3A'),
                stroke: am5.color('#FFFFFF'),
                spikes: 5,
                innerRadius: am5.percent(50),
                tooltipText: `[bold]${entityStore.entity.name} Average Filing Date:[/] {valueX}`,
                tooltipY: 5,
            }),
        })
    })

    var legendMinMaxSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: ' First/Last Filed Patent',
            xAxis: xAxis,
            yAxis: yAxis,
        })
    )

    legendMinMaxSeries.strokes.template.setAll({
        visible: false,
    })

    // Add bullets
    legendMinMaxSeries.bullets.push(function () {
        return am5.Bullet.new(root, {
            locationX: 0,
            sprite: am5.Circle.new(root, {
                fill: am5.color('#5D6D78'),
                radius: 10,
            }),
        })
    })
    dumbbellSeries.data.setAll(props.filingDateData)
    averageSeries.data.setAll(props.filingDateData)
    averageEntitySeries.data.setAll(props.filingDateData)
    legendMinMaxSeries.data.setAll(props.filingDateData)

    generateLegend(chart, averageSeries, averageEntitySeries, legendMinMaxSeries)
}

const generateLegend = (chart, averageSeries, averageEntitySeries, legendMinMaxSeries) => {
    let legend = chart.children.push(
        am5.Legend.new(root, {
            layout: root.horizontalLayout,
            clickTarget: 'none',
            x: am5.percent(50),
            centerX: am5.percent(50),
            y: am5.percent(100),
            centerY: am5.percent(50),
        })
    )

    legend.data.setAll([legendMinMaxSeries, averageEntitySeries, averageSeries])
}
</script>

<style lang="scss">
.dumbbell-chart {
    width: calc(100% - 20px);
    padding-left: 20px;
    position: relative;

    #amChartBumbbell {
        width: 100%;
        height: 475px;

        div {
            height: 100%;
        }
    }
    .y-axis-label {
        position: absolute;
        left: 10px;
        top: 50%;
        transform-origin: center left;
        transform: translateY(-50%) rotate(-90deg);
    }
}
</style>
