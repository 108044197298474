import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import axios from 'axios'
import FloatingVue from 'floating-vue'
import { plugin as loggingPlugin } from '@moatmetrics/vue-logger'
import mitt from 'mitt'
import VueAxios from 'vue-axios'
import AuthPlugin from '@/auth/authPlugin'

import App from './App'
import * as filters from './lib/filters'

// font awesomee
import armoryFontAwesomeIcons from '@moatmetrics/armory/src/util/fontawesome'
import globalTableFontAwesome from '@moatmetrics/global_table/src/lib/fontawesome'
import localFontAwesomeIcons from './lib/fontAwesome.js'

// Date Picker
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import messages from './i18n'
import router from './router'

import {
    AonAlertBox,
    AonCoverLoading,
    AonButton,
    AonCard,
    AonCheckbox,
    AonCol,
    AonContainer,
    AonDropdown,
    AonInput,
    AonMenu,
    AonModal,
    AonRow,
    AonSkeleton,
    AonSpinner,
    AonTabs,
    AonToggleSwitch,
} from '@moatmetrics/armory/src/components/'
import TableAips from '@moatmetrics/global_table/src/components/tableNew/TableAips'

// Moat table
import { LicenseManager } from '@ag-grid-enterprise/core'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { SparklinesModule } from '@ag-grid-enterprise/sparklines'
import { GridChartsModule } from '@ag-grid-enterprise/charts-enterprise'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'

import '@ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the grid
import '@ag-grid-community/styles/ag-theme-quartz.css' // Optional Theme applied to the grid

import MTCustomColHeader from '@/components/moatTable/MTCustomColHeader.vue'
import MTCustomCell from '@/components/moatTable/MTCustomCell.js'
import MTCustomCellCompany from '@/components/moatTable/MTCustomCellCompany.vue'
import MTCustomCellCheckbox from '@/components/moatTable/MTCustomCellCheckbox.vue'
import MTCustomCellReportLink from '@/components/moatTable/MTCustomCellReportLink.vue'
import MTCustomCellConfigureInArenas from '@/components/moatTable/MTCustomCellConfigureInArenas'
import MTCustomCellScoreChange from '@/components/moatTable/MTCustomCellScoreChange.vue'
import MTCustomStatusBarSum from '@/components/moatTable/MTCustomStatusBarSum.js'
import MTCustomToolPanelActions from '@/components/moatTable/MTCustomToolPanelActions.vue'
import MTContextMenu from '@/components/moatTable/helpers/MTContextMenu.vue'
import MTCustomCellLinkWContext from '@/components/moatTable/MTCustomCellLinkWContext.vue'

LicenseManager.setLicenseKey(config.license.AGGridLicense)
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    RowGroupingModule,
    RangeSelectionModule,
    ServerSideRowModelModule,
    SetFilterModule,
    FiltersToolPanelModule,
    ColumnsToolPanelModule,
    StatusBarModule,
    SparklinesModule,
    GridChartsModule,
    MenuModule,
    ExcelExportModule,
])

import { plugin as unleashPlugin } from '@unleash/proxy-client-vue'
import vClickOutside from 'click-outside-vue3'
import VScrollLock from 'v-scroll-lock'

import 'country-flag-icons/3x2/flags.css'
import 'floating-vue/dist/style.css'
import { LOGIN_CALLBACK } from '@/lib/constants/auth-routes'

const app = createApp(App)
const emitter = mitt()
const i18n = new createI18n({
    fallbackLocale: 'en',
    locale: 'en',
    messages,
    legacy: false,
})

app.use(loggingPlugin, {
    rumEnabled: config.logging.rumEnabled,
    environment: config.env,
    appVersion: import.meta.env.PACKAGE_VERSION,
    loggingConfiguration: {
        level: config.logging.level,
        handlers: config.logging.handlers,
    },
    grafana: {
        appName: config.logging.grafanaAppName,
        url: config.logging.grafanaUrl,
        tracingEnabled: config.logging.tracingEnabled,
        ignoreUrls: [new RegExp(/.*unleash.prod.moatmetrics.com.*/)],
    },
})

app.config.globalProperties.eventBus = emitter
app.config.globalProperties.$filters = filters

app.provide('eventBus', emitter)
app.provide('filters', filters)

const fontAwesomeMergedIcons = {
    ...armoryFontAwesomeIcons,
    ...globalTableFontAwesome,
    ...localFontAwesomeIcons,
}
const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)
app.use(FloatingVue)
app.use(i18n)
app.use(pinia)
app.use(router)
app.use(AuthPlugin, {
    domain: config.auth.domain,
    clientId: config.auth.clientId,
    redirectUri: new URL(router.resolve({ name: LOGIN_CALLBACK }).href, window.location.origin)
        .href,
    audience: config.auth.audience,
})
app.use(unleashPlugin, {
    config: config.unleash,
})
app.use(vClickOutside)
app.use(VScrollLock)
import './api'
import { config } from '@/config'
app.use(VueAxios, axios)

// Make global components
app.component('AonAlertBox', AonAlertBox)
app.component('AonCoverLoading', AonCoverLoading)
app.component('AonButton', AonButton)
app.component('AonCard', AonCard)
app.component('AonCheckbox', AonCheckbox)
app.component('AonCol', AonCol)
app.component('AonContainer', AonContainer)
app.component('AonDropdown', AonDropdown)
app.component('AonInput', AonInput)
app.component('AonMenu', AonMenu)
app.component('AonModal', AonModal)
app.component('AonRow', AonRow)
app.component('AonSkeleton', AonSkeleton)
app.component('AonSpinner', AonSpinner)
app.component('AonTabs', AonTabs)
app.component('AonToggleSwitch', AonToggleSwitch)
app.component('FontAwesomeIcon', fontAwesomeMergedIcons)
app.component('TableAips', TableAips)
app.component('MTCustomColHeader', MTCustomColHeader)
app.component('MTCustomCell', MTCustomCell)
app.component('MTCustomCellCompany', MTCustomCellCompany)
app.component('MTCustomCellCheckbox', MTCustomCellCheckbox)
app.component('MTCustomCellReportLink', MTCustomCellReportLink)
app.component('MTCustomCellScoreChange', MTCustomCellScoreChange)
app.component('MTCustomCellConfigureInArenas', MTCustomCellConfigureInArenas)
app.component('MTCustomStatusBarSum', MTCustomStatusBarSum)
app.component('MTCustomToolPanelActions', MTCustomToolPanelActions)
app.component('MTContextMenu', MTContextMenu)
app.component('MTCustomCellLinkWContext', MTCustomCellLinkWContext)
app.component('VueDatePicker', VueDatePicker)
app.mount('#app')
