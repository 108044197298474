<template>
    <div class="report-creation-track" :class="[overwriteClass, { collapse: collapse }]">
        <div class="headings-holder">
            <h5 v-if="heading" class="heading white--text knights-cloak pa-5">
                {{ heading }}
            </h5>
            <p
                v-if="headingDesc"
                id="headingDesc"
                class="delay-hook heading-desc grey01--text mb-5"
            >
                {{ headingDesc }}
            </p>
        </div>
        <div class="track-holder knights-cloak-80 pa-5">
            <div class="init-step-container">
                <div
                    v-for="(step, index) in reportGenerationSteps"
                    class="step pb-5"
                    :class="[{ current: step.isCurrentStep }, { complete: step.isCompletedStep }]"
                >
                    <div class="wrap-helper d-flex align-items-center">
                        <div class="path-holder">
                            <div class="path"></div>
                        </div>
                        <div class="step-num d-flex justify-content-center align-items-center">
                            <p v-if="!step.isCompletedStep" class="white--text">{{ index + 1 }}</p>
                            <font-awesome-icon
                                v-else
                                icon="fas fa-check"
                                class="white--text"
                                size="lg"
                            />
                        </div>
                        <p
                            class="large bold step-label white--text ml-2"
                            :class="{ 'redacted-regular': step.redacted }"
                        >
                            {{ step.stepName }}
                        </p>
                        <font-awesome-icon
                            v-if="reportGenerationStore.shouldUpdateContenderList && index === 3"
                            icon="fas fa-circle-exclamation"
                            class="castle-moat--text ml-3"
                            size="lg"
                            v-tooltip="{
                                content:
                                    'We\'ve noticed a change in the selected Technology Areas. Updating Contenders...',
                            }"
                        />
                    </div>
                    <p
                        class="white--text step-desc pl-11 mt-1"
                        :class="{ 'redacted-regular': step.redacted }"
                    >
                        {{ step.stepDesc }}
                    </p>
                    <div
                        v-if="
                            reportGenerationStore.trackStepsFinal.length !== 0 &&
                            reportGenerationStore.reportSelections[index]?.value &&
                            reportGenerationStore.trackStepsFinal[index].isCompletedStep
                        "
                        class="step-selection mt-4 ml-5 px-4 d-flex align-items-center justify-content-center"
                        :class="{
                            'allow-edit': reportGenerationStore.reportSelections[index].allowEdit,
                        }"
                        @click="
                            gotoStep(
                                reportGenerationStore.reportSelections[index].allowEdit,
                                step.stepNum
                            )
                        "
                    >
                        <p>
                            {{
                                Array.isArray(reportGenerationStore.reportSelections[index].value)
                                    ? `${reportGenerationStore.reportSelections[index].value.length}  ${reportGenerationStore.reportSelections[index].selectionLabel}`
                                    : reportGenerationStore.reportSelections[index].selectionLabel
                            }}
                        </p>
                        <font-awesome-icon
                            v-if="!reportGenerationStore.reportSelections[index].allowEdit"
                            icon="fas fa-lock"
                            class="grey01--text ml-3"
                            size="lg"
                        />
                        <font-awesome-icon
                            v-else
                            icon="fas fa-gear"
                            class="castle-moat--text ml-3"
                            size="lg"
                        />
                    </div>
                </div>
                <div
                    v-if="reportGenerationStore.trackStepsFinal.length === 0"
                    class="cover-up"
                ></div>
            </div>
        </div>
        <!-- <div
            v-if="allowCollapse"
            class="handle elevation-5 d-flex justify-content-center align-items-center"
            @click="toggleCollapseTrack()"
        >
            <font-awesome-icon icon="fas fa-chevrons-left" class="icon grey03--text" size="lg" />
        </div> -->
    </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue'
import { useReportGenerationStore } from '@/stores'

const reportGenerationStore = useReportGenerationStore()
const emit = defineEmits(['step-clicked'])

const props = defineProps({
    passedSteps: {
        type: Object,
        default: () => {},
    },
    currentStep: {
        type: Number,
        default: null,
    },
    heading: {
        type: String,
        default: '',
    },
    headingDesc: {
        type: String,
        default: '',
    },
    overwriteClass: {
        type: String,
        default: '',
    },
    allowCollapse: {
        type: Boolean,
        default: false,
    },
    collapseOnStep: {
        type: Number,
        default: null,
    },
    allowStepNavigation: {
        type: Boolean,
        default: false,
    },
})

const collapse = ref(false)
const headingsHolderHeight = ref()

onMounted(() => {
    // if (props.allowCollapse) {
    //     calcHeadingsHolderHeight()
    // }
})

// watch(
//     () => props.currentStep,
//     (newVal) => {
//         if (props.allowCollapse) {
//             newVal === props.collapseOnStep ? toggleCollapseTrack(true) : ''
//         }
//     }
// )

const reportGenerationSteps = computed(() => {
    return reportGenerationStore.trackStepsFinal.length > 0
        ? reportGenerationStore.trackStepsFinal
        : reportGenerationStore.trackStepsInit
})

const reportTypeSteps = computed(() => {
    return reportGenerationStore.getSelectedReportTypeSteps()
})

// const steps = computed(() => {
//     return props.passedSteps
// })
// const calcHeadingsHolderHeight = () => {
//     let headingTarget = document.getElementById('heading')
//     let headingDescTarget = document.getElementById('headingDesc')
//     let total =
//         headingTarget.getBoundingClientRect().height +
//         headingDescTarget.getBoundingClientRect().height
//     headingsHolderHeight.value = total + 30
// }

const toggleCollapseTrack = (forceClose = false) => {
    return (collapse.value = !collapse.value)
}

// TODO Need to come up with a better Store action init
const gotoStep = (allowEdit, stepNum) => {
    if (!allowEdit) {
        return
    }
    reportGenerationStore.setCurrentStep(stepNum)
}

// const stepDisabled = (step) => {
//     return props.reportCreationStore.isStepInvalid(step)
// }
</script>

<style lang="scss" scoped>
.report-creation-track {
    width: 100%;
    min-width: 400px;
    max-width: 400px;
    height: calc(100%); //footer margin
    border-right: 1px solid vars.$grey05;
    background: vars.$knightsCloak80;
    overflow: auto;

    position: relative;

    transition: all 0.3s ease-in-out;

    // @for vars.$i from 0 through 20 {
    //     .step-holder .step:nth-of-type(#{vars.$i + 1}) .copy-holder {
    //         transition-delay: 0.1s * vars.$i + 0.1s + 0.2s;
    //     }
    // }
    .init-step-container {
        position: relative;
        .cover-up {
            height: 100%;
            width: 100%;
            background: linear-gradient(0deg, rgba(38, 59, 82, 1) 15%, rgba(38, 59, 82, 0) 100%);

            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }

    .step {
        position: relative;

        // With Current Class applied
        &.current {
            .step-num {
                border-color: vars.$gold;

                p {
                    color: vars.$gold;
                }
            }

            .step-label {
                color: vars.$gold;
            }

            .complete-path {
                top: calc(50% - 9px);
            }

            .path-holder {
                .path {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        &.complete {
            .path-holder {
                .path {
                    border-style: solid;
                }
            }
        }

        &:last-of-type {
            .path-holder {
                .path {
                    pointer-events: none;
                    opacity: 0;
                }
            }
        }

        // Base State
        .step-num {
            width: 36px;
            height: 36px;
            border: solid 2px white;
            border-radius: 50%;
            background: vars.$knightsCloak80;

            position: relative;
            z-index: 1;
        }

        .path-holder {
            height: calc(100% - 36px);

            position: absolute;
            top: 36px;
            left: 17px;
            z-index: 0;

            .path {
                height: calc(100%);
                border-right: dashed 2px white;

                position: relative;

                &:before {
                    content: '';
                    display: block;
                    width: 2px;
                    height: 50%;
                    background: vars.$gold;

                    position: absolute;
                    top: 0;
                    left: 0;

                    opacity: 0;
                }
            }
        }

        .step-selection {
            width: fit-content;
            max-width: 350px;
            height: 50px;
            background: white;
            border-radius: 0 25px 25px 0;
            outline: 1px solid vars.$castleMoat;
            border: 2px solid vars.$castleMoat;

            &.allow-edit {
                cursor: pointer;
                p {
                    color: vars.$castleMoat;
                    text-decoration: underline;
                }
            }
        }
    }

    &.collapse {
        width: 80px !important;

        transition-delay: 0.35s;

        .handle {
            .icon {
                transform: rotate(180deg);
            }
        }
    }

    .handle {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        cursor: pointer;

        position: absolute;
        top: 50%;
        right: -20px;
        background: white;
        transform: translateY(-50%);

        transition: all 0.3s ease-in-out;

        .icon {
            transform: rotate(0);

            transition: all 0.3s ease-in-out;
        }
    }
}
</style>
