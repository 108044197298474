<template>
    <AonCoverLoading
        class="error-cover"
        :loading="typeof moat2ProductStore.productAlignmentError === 'string' ? true : false"
        :show-spinner="false"
        :title="`No Products Found for ${entityStore.entity?.name}`"
        :message="moat2ProductStore.productAlignmentErrorMsg"
    >
        <template #header>
            <font-awesome-icon
                :icon="`fas fa-triangle-exclamation`"
                style="border-radius: 100%"
                class="warning--text warning-background pa-4"
                size="2xl"
            />
        </template>
        <template #footer>
            <ul class="my-4" style="list-style: disc">
                <li class="my-2">
                    Select another company in the same hierarchy, such as a parent or subsidiary
                    that may be better known.
                </li>
                <li class="my-2">Choose a similar company, such as a competitor.</li>
                <li class="my-2">Build an arena using relevant technology areas.</li>
            </ul>
            <p>
                If you need further assistance, please contact
                <a href="mailto:support@moatmetrics.com">support@moatmetrics.com</a>
            </p>
            <div class="button-holder mt-5 w-full d-flex justify-content-center">
                <AonButton
                    class="mr-5"
                    :label="t('productAlignment.error.rerun')"
                    type="subtle"
                    @clicked="getProductAlignment()"
                />
                <AonButton
                    :label="t('productAlignment.error.findLatestRun')"
                    @clicked="getPreviousRun(true)"
                />
            </div>
        </template>
    </AonCoverLoading>
</template>

<script setup>
import { onMounted, inject, ref } from 'vue'
import { useEntityStore, useMoat2ProductStore } from '@/stores'
import { useI18n } from 'vue-i18n'

const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()

const logger = inject('logger')
const { t } = useI18n()

const emit = defineEmits(['get-product-alignment', 'get-previous-run'])

const getProductAlignment = () => {
    emit('get-product-alignment')
}
const getPreviousRun = (requestNew) => {
    emit('get-previous-run')
}
</script>
