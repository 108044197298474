<template>
    <div class="arena-history-table">
        <TableAips
            :key="updater"
            class="table-border company-space-table"
            table-height="85vh"
            table-style="fixed-scrolling"
            table-density="compact"
            disable-filters
            :sort-columns="false"
            sticky-header
            table-name="Arenas"
            :columns="arenaColumns"
            :data="tableData"
            :loading-data="loadingArenas"
            :page-size="20"
            :table-collapse="false"
            :use-page-data="false"
            :default-filter="defaultFilter"
            :default-sort="defaultSort"
            @load-data="loadTableData"
        >
            <template #name="{ data }">
                <div class="edit d-flex align-item-center">
                    <font-awesome-icon
                        icon="fas fa-trash-can"
                        class="castle-moat--text edit-icon"
                        size="lg"
                        @click="showDeleteConfirmation(data)"
                    />
                    <font-awesome-icon
                        icon="fas fa-pencil"
                        class="castle-moat--text mx-3 edit-icon"
                        size="lg"
                        @click="showEditModal(data)"
                    />
                    <p>{{ data.name }}</p>
                </div>
            </template>
            <template #createdDt="{ data }">
                <p>{{ $filters.toUTCStringPlusTime(data.createdDt) }}</p>
            </template>
            <template #status="{ data }">
                <div v-if="data.status === 'processing'" class="status">
                    <p>Processing</p>
                </div>
                <div v-else class="status link" @click="goTo(data.status, data.productAlignmentId)">
                    <p>{{ humanStatusName(data.status) }}</p>
                </div>
            </template>
        </TableAips>
        <ArenaBoardEditModal :product-alignment-pk-overwrite="selectedRowPk" :name="arenaName" />
        <ArenaBoardDeleteConfirmation
            :product-alignment-pk-overwrite="selectedRowPk"
            :name="arenaName"
        />
    </div>
</template>

<script setup>
// Global
import { ref, watch } from 'vue'
import { useArenaStore } from '@/stores'
import { getArenaRuns } from '@/api/arena.js'
import { useRouter } from 'vue-router'

import ArenaBoardEditModal from '@/components/arenaBoard/ArenaBoardEditModal.vue'
import ArenaBoardDeleteConfirmation from '@/components/arenaBoard/ArenaBoardDeleteConfirmation.vue'

// injectors
const router = useRouter()
const arenaStore = useArenaStore()

const loadingArenas = ref(false)
const tableData = ref([])
const allData = ref(0)
const selectedRowPk = ref(null)
const arenaName = ref('')
const updater = ref(0)
const arenaColumns = ref([
    {
        value: 'name',
        locale: 'Arena Name',
        filterType: 'string',
        showColumn: true,
        useSlot: true,
    },
    {
        value: 'createdDt',
        locale: 'Date Created',
        filterType: 'date',
        showColumn: true,
        width: '250px',
        disableTooltip: true,
        useSlot: true,
    },
    {
        value: 'status',
        locale: 'Status',
        showColumn: true,
        useSlot: true,
        disableTooltip: true,
        width: '250px',
    },
])
const defaultFilter = ref([
    {
        filterColumn: 'product_alignment_type',
        filterValue: 'arena',
        filterType: 'Equals',
        filterLabel: 'Arenas',
    },
])
const defaultSort = ref([
    {
        sortColumn: 'created_dt',
        sortDirection: -1,
        sortPriority: 1,
    },
])

watch(
    () => arenaStore.showDeleteConfirmation,
    (newVal, oldData) => {
        if (!newVal && oldData) {
            updater.value++
        }
    }
)

watch(
    () => arenaStore.showEditModal,
    (newVal, oldData) => {
        if (!newVal && oldData) {
            updater.value++
        }
    }
)

const loadTableData = ({ params, paramsChanged }) => {
    if (paramsChanged) {
        allData.value = 0
        tableData.value = []
    }

    getPrompts(params)
}

const getPrompts = async (params) => {
    loadingArenas.value = true
    try {
        let { data } = await getArenaRuns(params)
        tableData.value = [...tableData.value, ...data.results]
    } catch (err) {
        // TODO why not use the Global SnackBar here? The AonAlertBox is displayed underneath the table??
        errorMessage.value = err.message
        showAlert.value = true
    } finally {
        loadingArenas.value = false
    }
}

const goTo = (status, id) => {
    if (status === 'reportComplete') {
        router.push({ name: 'Arena Output', params: { jobPk: id } })
    } else {
        router.push({ name: 'Arena', params: { arenaSourceId: id } })
    }
}

const humanStatusName = (status) => {
    if (status === 'reportComplete') {
        return 'Completed'
    } else {
        return 'Draft'
    }
}

const showEditModal = (row) => {
    arenaName.value = row.name
    selectedRowPk.value = row.productAlignmentId
    arenaStore.showEditModal = true
}

const showDeleteConfirmation = (row) => {
    arenaName.value = row.name
    selectedRowPk.value = row.productAlignmentId
    arenaStore.showDeleteConfirmation = true
}
</script>

<style lang="scss" scoped>
.arena-history-table {
    width: 100%;
    min-height: 400px;
    position: relative;

    .edit-icon {
        cursor: pointer;
    }

    :deep(.table-aips-latest) {
        .aips-table-wrap {
            border: none !important;
            padding-bottom: 100px;
        }
        .table-header,
        .table-filters-container {
            display: none;
        }

        th {
            background: white !important;
            &:first-of-type {
                border-radius: 8px 0 0 0;
            }

            &:last-of-type {
                border-radius: 0 8px 0 0;
            }
        }
    }
}
</style>
