import esb from 'elastic-builder'

export function getEntitySearchQuery(queryPayload) {
    if (!queryPayload.page) queryPayload.page = 1
    if (!queryPayload.page_size) queryPayload.page_size = 20
    if (queryPayload.FV.length > 1) throw new Error('Only one search term allowed')

    let requestBodySearch = esb
        .requestBodySearch()
        .from(queryPayload.page_size * (queryPayload.page - 1))
        .size(queryPayload.page_size)

    let searchTerm = queryPayload.FV[0]

    if (searchTerm) {
        requestBodySearch.query(
            esb
                .boolQuery()
                .must(
                    esb
                        .disMaxQuery()
                        .queries([
                            esb
                                .functionScoreQuery()
                                .query(esb.matchPhraseQuery('stock_ticker', searchTerm))
                                .function(esb.weightScoreFunction().weight(5)),
                            esb
                                .functionScoreQuery()
                                .query(esb.matchPhraseQuery('name', searchTerm))
                                .function(esb.weightScoreFunction().weight(1.5)),
                            esb
                                .functionScoreQuery()
                                .query(
                                    esb
                                        .queryStringQuery(`${searchTerm}*`)
                                        .field('name')
                                        .analyzeWildcard(true)
                                        .defaultOperator('AND')
                                )
                                .function(esb.weightScoreFunction().weight(1.25)),
                            esb
                                .functionScoreQuery()
                                .query(esb.wildcardQuery('homepage_url', `*${searchTerm}*`))
                                .function(esb.weightScoreFunction().weight(1)),
                        ])
                        .tieBreaker(1)
                )
                .should(
                    esb
                        .disMaxQuery()
                        .queries([
                            esb
                                .functionScoreQuery()
                                .functions([
                                    esb
                                        .fieldValueFactorFunction('patent_count_us')
                                        .modifier('log1p')
                                        .missing(0)
                                        .weight(1.5),
                                    esb
                                        .fieldValueFactorFunction('total_revenue')
                                        .modifier('log1p')
                                        .factor(0.00001)
                                        .missing(0)
                                        .weight(1.25),
                                    esb
                                        .fieldValueFactorFunction('total_subsidiary_count')
                                        .modifier('log1p')
                                        .missing(0)
                                        .weight(1.25),
                                    esb
                                        .weightScoreFunction()
                                        .filter(esb.wildcardQuery('stock_ticker', '*'))
                                        .weight(5),
                                ])
                                .scoreMode('sum')
                                .boostMode('sum'),
                        ])
                        .tieBreaker(1)
                )
        )
    } else {
        requestBodySearch.sorts([
            esb.sort('total_revenue', 'desc'),
            esb.sort('total_subsidiary_count', 'desc'),
            esb.sort('patent_count_us', 'desc'),
        ])
    }

    return requestBodySearch.toJSON()
}

export function getSectorSearchQuery(queryPayload) {
    if (!queryPayload.page) queryPayload.page = 1
    if (!queryPayload.page_size) queryPayload.page_size = 500
    if (queryPayload.FV.length > 1) throw new Error('Only one search term allowed')

    let requestBodySearch = esb
        .requestBodySearch()
        .from(queryPayload.page_size * (queryPayload.page - 1))
        .size(queryPayload.page_size)

    let searchTerm = queryPayload.FV[0]

    if (searchTerm) {
        requestBodySearch.query(
            esb
                .boolQuery()
                .must(
                    esb
                        .disMaxQuery()
                        .queries([
                            esb
                                .functionScoreQuery()
                                .query(esb.matchPhrasePrefixQuery('aon_sector_name', searchTerm))
                                .function(esb.weightScoreFunction().weight(5)),
                            esb
                                .functionScoreQuery()
                                .query(esb.matchPhraseQuery('aon_sector_name', searchTerm))
                                .function(esb.weightScoreFunction().weight(3)),
                            esb
                                .functionScoreQuery()
                                .query(esb.wildcardQuery('description', `*${searchTerm}*`))
                                .function(esb.weightScoreFunction().weight(2)),
                        ])
                        .tieBreaker(1)
                )
                .should(
                    esb
                        .disMaxQuery()
                        .queries([
                            esb
                                .functionScoreQuery()
                                .functions([
                                    esb
                                        .fieldValueFactorFunction('calculated_total_revenue')
                                        .modifier('log1p')
                                        .factor(0.00001)
                                        .missing(0)
                                        .weight(1),
                                    esb
                                        .fieldValueFactorFunction('participating_company_count')
                                        .modifier('log1p')
                                        .missing(0)
                                        .weight(0.9),
                                ])
                                .scoreMode('sum')
                                .boostMode('sum'),
                        ])
                        .tieBreaker(1)
                )
        )
    } else {
        requestBodySearch.sort(esb.sort('participating_company_count', 'desc'))
    }

    return requestBodySearch.toJSON()
}

export function getOpenSearchQuery(queryPayload) {
    if (!queryPayload.page) queryPayload.page = 1
    if (!queryPayload.page_size) queryPayload.page_size = 500

    let requestBodySearch = esb.requestBodySearch()
    let query = esb.boolQuery()

    if (queryPayload.FC) {
        const values = queryPayload.FV
        let minimumShouldMatch = 0
        for (let i = 0; i < queryPayload.FC.length; i++) {
            let col = queryPayload.FC[i]
            let typ = queryPayload.FT[i]
            let val = values[i]

            switch (typ) {
                case 'terms':
                    query.filter(esb.termsQuery(col, values))
                    break
                case 'termsParam':
                    query.filter(esb.termsQuery(col, val))
                    break
                case 'eq':
                    query.filter(esb.matchQuery(col, val))
                    break
                case 'lt':
                    query.filter(esb.rangeQuery(col).lt(val))
                    break
                case 'gt':
                    query.filter(esb.rangeQuery(col).gt(val))
                    break
                case 'range':
                case 'dt_range': {
                    if (val.includes('OR')) {
                        const ranges = val.split('OR')
                        ranges.forEach((splitVal) => {
                            const range = splitVal.split('~')
                            query.should(esb.rangeQuery(col).gte(range[0]).lte(range[1]))
                        })
                        minimumShouldMatch++
                    } else {
                        const range = val.split('~')
                        query.filter(esb.rangeQuery(col).gte(range[0]).lte(range[1]))
                    }
                    break
                }
                case 'complex_text': {
                    query.must(
                        esb
                            .wildcardQuery(
                                col,
                                `*${val.replace(/[-[\]{}()<>+?!.=\\^&|"~:/]/g, '\\$&')}*`
                            )
                            .caseInsensitive(true)
                    )
                    break
                }
                case 'complex_start':
                    query.must(
                        esb.matchPhrasePrefixQuery(
                            col,
                            val.replace(/[-[\]{}()<>+?!.=\\^&|"~:/]/g, '\\$&')
                        )
                    )
                    break
                case 'complex_word':
                    query.must(
                        esb.matchPhraseQuery(
                            col,
                            val.replace(/[-[\]{}()<>+?!.=\\^&|"~:/]/g, '\\$&')
                        )
                    )
                    break
                case 'empty':
                    query.filter(esb.matchQuery(col, ''))
                    break
                default:
                    break
            }
        }
        if (minimumShouldMatch) {
            query.minimumShouldMatch(minimumShouldMatch)
        }
    }

    if (queryPayload.SC) {
        for (let i = 0; i < queryPayload.SC.length; i++) {
            let col = queryPayload.SC[i]
            const dir = queryPayload.SD[i] === -1 ? 'desc' : 'asc'
            const textFields = [
                'aon_sector_name',
                'employee_count',
                'homepage_url',
                'hq_country',
                'hq_country_name',
                'investment_types',
                'name',
                'org_status',
                'ownership_status',
                'ultimate_aon_entity_name',
                'ultimate_aon_sector_name',
                'last_funding_on',
            ]

            if (textFields.includes(col)) {
                //.keyword is added to sorting on text fields because they are not sortable by default
                col = col + '.keyword'
            }
            requestBodySearch = requestBodySearch.sort(esb.sort(col, dir))
        }
    }

    return requestBodySearch
        .from(queryPayload.page_size * (queryPayload.page - 1))
        .size(queryPayload.page_size)
        .query(query)
        .toJSON()
}
