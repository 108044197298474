<template>
    <div class="filings-focus w-full">
        <div class="w-full">
            <p>{{ description }}</p>
            <FocusChart
                :key="`range-bullet_chart-${props.updateKey}`"
                chart-type="range-bullet"
                :chart-data="props.data"
                :chart-overwrite-data="overwriteData"
            />
            <FocusTable
                class="mt-4"
                chart-type="range-bullet"
                :table-data="props.data"
                @filter-change="onFilterChange"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useEntityStore } from '@/stores'

import FocusChart from './shared/FocusChart.vue'
import FocusTable from './shared/FocusTable.vue'

const entityStore = useEntityStore()

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    updateKey: {
        type: Number,
        default: 0,
    },
})

const overwriteData = ref([])

const description = computed(() => {
    return entityStore.strategyTabNodeFilter === 'product'
        ? `This chart displays the patent filing timelines for ${entityStore.entity.name} across various products. It highlights the first and last patent filed by ${entityStore.entity.name}, the range of its filing activity, and the average filing date for each product. For context, the average filing date of peer companies is also shown for comparison.`
        : `This chart displays the patent filing timelines for ${entityStore.entity.name} across various products and technologies. It highlights the first and last patent filed by ${entityStore.entity.name}, the range of its filing activity, and the average filing date for each technology. For context, the average filing date of peer companies is also shown for comparison.`
})

const onFilterChange = (data) => {
    overwriteData.value = data
}
</script>

<style lang="scss" scoped>
.filings-focus {
    position: relative;
}
</style>
