<template>
    <div class="arena-board-node-assignment">
        <h6 class="grey01--text mb-2 px-5 pt-4" style="font-weight: 800">Element Properties</h6>
        <div class="linkout d-flex align-items-center px-5">
            <p class="bold grey01--text mb-1 mr-3">Parent Element</p>
            <a
                :href="entityRouteLink(parseInt(selectedNodeParent?.internalIdentifier))"
                target="_blank"
            >
                <font-awesome-icon
                    v-if="selectedNodeParent && selectedNodeParent?.internalIdentifier"
                    class="grey02--text link-icon mb-1"
                    :icon="`fas fa-external-link`"
                />
            </a>
        </div>
        <div
            class="spinner text-align-center d-flex align-items-center align-content-center flex-wrap"
            v-if="assignmentLoading"
        >
            <p class="grey01--text mb-10 bold w-full">{{ assignmentLoadingText }}</p>
            <AonSpinner class="ml-auto mr-auto" :scale="0.3" />
        </div>
        <div class="parent-node-selection px-5">
            <div
                class="dropdown-holder d-flex align-items-center w-full"
                @click="toggleShowParentOptions()"
            >
                <div
                    v-if="selectedNodeParent"
                    class="node-content-parent pa-2 d-flex align-items-center"
                >
                    <div class="labels w-full">
                        <div class="top-icon-label d-flex mb-1">
                            <font-awesome-icon
                                class="grey03--text pr-2"
                                :icon="`fas ${processType(selectedNodeParent.nodeType ? selectedNodeParent.nodeType : selectedNodeParent.type)}`"
                                size="sm"
                            />
                            <p class="capital extra-small bold grey03--text w-full">
                                {{
                                    selectedNodeParent.nodeType
                                        ? selectedNodeParent.nodeType
                                        : selectedNodeParent.type
                                }}
                            </p>
                        </div>
                        <p
                            v-tooltip="{
                                content: selectedNodeParent.name
                                    ? selectedNodeParent.name
                                    : selectedNodeParent.displayName,
                            }"
                            class="grey01--text overflow-ellipsis w-full"
                        >
                            {{
                                selectedNodeParent.name
                                    ? selectedNodeParent.name
                                    : selectedNodeParent.displayName
                            }}
                        </p>
                    </div>
                    <font-awesome-icon
                        class="grey02--text parent-icon pr-1"
                        :class="{ open: showParentOptions }"
                        :icon="`fas fa-caret-down`"
                        size="2x"
                    />
                </div>
                <div
                    v-if="parentListData"
                    class="parent-option-list pa-3"
                    :class="{ open: showParentOptions }"
                >
                    <AonRadioGroup
                        :options="parentListData"
                        :init-selection="initParent"
                        @clicked="changeParent"
                    />
                </div>
            </div>
        </div>
        <div
            class="selected-label-holder d-flex justify-content-between align-items-center mb-1 mt-3 pl-5 pr-5"
        >
            <div class="linkout d-flex align-items-center">
                <p class="bold grey01--text">Selected Element</p>
                <a :href="entityRouteLink(parseInt(selectedNode.data.entityPk))" target="_blank">
                    <font-awesome-icon
                        v-if="selectedNode && selectedNode.data.entityPk"
                        class="grey02--text link-icon ml-2"
                        :icon="`fas fa-external-link`"
                        size="sm"
                    />
                </a>
            </div>
            <font-awesome-icon
                class="grey02--text mb-1 trash-icon"
                :icon="`fas fa-trash-can`"
                @click="removeNode()"
            />
        </div>
        <div class="selected-node px-5" @click="focusNode()">
            <div class="node-content pa-2 d-flex align-items-center">
                <div class="labels">
                    <div class="top-icon-label d-flex mb-1">
                        <font-awesome-icon
                            class="grey03--text pr-2"
                            :icon="`fas ${processType(selectedNode.data.nodeType)}`"
                            size="sm"
                        />
                        <p class="capital extra-small bold grey03--text w-full">
                            {{ selectedNode.data.nodeType }}
                        </p>
                    </div>
                    <p
                        v-tooltip="{
                            content: selectedNode.data.name,
                        }"
                        class="grey01--text overflow-ellipsis w-full"
                    >
                        {{ selectedNode.data.name }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, ref, computed, nextTick } from 'vue'
import { updateNodes } from '@/api/arena.js'
import { useArenaStore } from '@/stores'
import { useRouter } from 'vue-router'
import { entityRouteLink } from '../../router/utils'
import { AonRadioGroup } from '@moatmetrics/armory/src/components'
import { useAuth } from '@/auth/authPlugin'

const emit = defineEmits(['update-arena'])
const eventBus = inject('eventBus')
const logger = inject('logger')
const arenaStore = useArenaStore()
const router = useRouter()

const { user } = useAuth()

const props = defineProps({
    productAlignmentPkOverwrite: {
        type: String,
        default: null,
    },
})

const parentAssignmentLoading = ref(false)
const deleteLoading = ref(false)
const showParentOptions = ref(false)

const selectedNode = computed(() => {
    return arenaStore.selectedNode
})

const selectedNodeParent = computed(() => {
    return arenaStore.selectedNodeParent
})

const initParent = computed(() => {
    return arenaStore.selectedNodeParent?.id
})

const assignmentLoading = computed(() => {
    return parentAssignmentLoading.value || !arenaStore.selectedNodeChildren || deleteLoading.value
})

const assignmentLoadingText = computed(() => {
    if (!arenaStore.selectedNodeChildren) {
        return 'Populating Children'
    }

    if (parentAssignmentLoading.value) {
        return 'Reassigning Parent'
    }

    if (deleteLoading.value) {
        return 'Deleting Element'
    }
    return
})

const parentListData = computed(() => {
    if (arenaStore.selectedNodeChildren) {
        // Get all nodes except selected node and its descendants
        const remainingNodes = arenaStore.boardData.filter((node) => {
            if (node.id === selectedNode.value.id) return false // exclude selected node

            let parent = node.parent
            while (parent) {
                if (parent === selectedNode.value.id) return false // exclude descendants
                parent = arenaStore.boardData.find((n) => n.id === parent)?.parent
            }

            return true
        })

        // Add root node
        remainingNodes.unshift({
            id: arenaStore.boardGlobalDetails.rootNodeId,
            displayName: 'Arena Root',
        })

        // Map to label/value format
        const parentOptions = remainingNodes.map((node) => ({
            label: node.displayName,
            value: node.id,
        }))

        return parentOptions
    } else {
        return []
    }
})

const processType = (type) => {
    return type === 'product' ? 'fa-buildings' : 'fa-memo'
}

const focusNode = () => {
    arenaStore.focusedNode = arenaStore.selectedNode.id
}

const toggleShowParentOptions = () => {
    showParentOptions.value = !showParentOptions.value
}

const changeParent = async (id) => {
    showParentOptions.value = true
    parentAssignmentLoading.value = true
    let bodyObj = {
        nodes: [
            {
                nodeId: arenaStore.selectedNode.id,
                include: true,
                parent: id,
            },
        ],
        excludeCascade: false,
    }

    try {
        let { data } = await updateNodes(bodyObj)
        eventBus.emit('node-added', data)
    } catch (err) {
        handleError('And error occurred')
        error.value = true
    }

    let targetParent = arenaStore.boardData.find((node) => {
        return node.id === id
    })

    if (targetParent) {
        arenaStore.selectedNodeParent = targetParent
    } else {
        arenaStore.selectedNodeParent = {
            id: arenaStore.boardData[0].rootNodeId,
            displayName: 'Arena Root',
            type: 'concept',
        }
    }

    await nextTick()
    parentAssignmentLoading.value = false
    await nextTick()
}

const removeNode = async (id) => {
    deleteLoading.value = true

    let bodyObj = {
        nodes: [
            {
                nodeId: arenaStore.selectedNode.id,
                include: false,
                displayName: arenaStore.selectedNode.displayName,
            },
        ],
        excludeCascade: true,
    }

    try {
        let { data } = await updateNodes(bodyObj)
        eventBus.emit('node-added', data)
    } catch (err) {
        handleError('And error occurred')
        error.value = true
    }

    await nextTick()
    deleteLoading.value = false
    await nextTick()
    arenaStore.selectedNode = null
    eventBus.emit('node-delete')
}

const handleError = (err) => {
    logger.error(err)

    eventBus.emit('snacktime', {
        type: 'error',
        message: err,
    })

    error.value = true
}
</script>

<style lang="scss" scoped>
.arena-board-node-assignment {
    .spinner {
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .parent-node-selection {
        cursor: pointer;
        position: relative;

        .node-content-parent {
            width: 100%;
            border: solid vars.$grey01 1px;
            border-radius: 4px;
            position: relative;
        }

        .parent-option-list {
            background: white;
            border: solid 1px vars.$grey01;
            max-height: 300px;
            width: calc(100% - 20px);
            overflow: auto;

            position: absolute;
            top: calc(100% - 2px);
            left: 20px;
            z-index: 1;

            opacity: 0;
            transform: translateY(20px);
            pointer-events: none;
            transition: all 0.3s ease-in-out;

            &.open {
                opacity: 1;
                transform: translateY(0);
                pointer-events: all;
            }
        }

        .parent-icon {
            transition: all 0.3s ease-in-out;

            &.open {
                transform: rotate(180deg);
            }
        }
    }

    .selected-node {
        cursor: pointer;
    }

    .node-content,
    .node-content-parent {
        border: solid vars.$castleMoat 3px;
        border-radius: 4px;

        .node-icon {
            width: 44px;
        }

        .labels {
            width: 90%;
        }
    }

    .capital {
        text-transform: capitalize;
    }

    .link-icon,
    .trash-icon {
        cursor: pointer;
    }
}
</style>
