<template>
    <div class="custom-node" :class="{ 'node-selected': isSelected }">
        <div class="node-holder d-flex align-items-center w-full">
            <img class="type-icon mr-2" :src="computedImage" alt="node" />
            <div class="text-holder d-flex flex-wrap w-full">
                <p class="node-type extra-small bold grey01--text w-full">{{ data.nodeType }}</p>
                <p
                    v-tooltip="{
                        content: data.name,
                    }"
                    class="node-type small grey01--text overflow-ellipsis"
                >
                    {{ data.name }}
                </p>
            </div>
        </div>
        <Handle type="target" :position="Position.Left" style="opacity: 0; pointer-events: none" />
        <Handle type="source" :position="Position.Right" style="opacity: 0; pointer-events: none" />
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { Handle, Position } from '@vue-flow/core'
import { useArenaStore } from '@/stores'

const props = defineProps(['data', 'position'])
const arenaStore = useArenaStore()

const computedImage = computed(() => {
    const type = props.data.nodeType === 'product' ? 'concept' : props.data.nodeType
    return new URL(`/src/assets/img/arenaBoard/${type}.png`, import.meta.url)
})

const isSelected = computed(() => {
    if (!arenaStore.selectedNode) return false
    return arenaStore.selectedNode.id === props.data?.id
})
</script>

<style lang="scss" scoped>
.custom-node {
    outline: 1px solid vars.$castleMoat;
    padding: 10px;
    width: 250px;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;

    transition: all 0.1s ease-in-out;

    &.node-selected {
        outline: 3px solid vars.$castleMoat;
    }

    .type-icon {
        width: 35px;
        height: 35px;
    }

    .node-type {
        text-transform: capitalize;
    }

    .text-holder {
        flex: 1;
        min-width: 0;
    }
}
</style>
