<template>
    <div class="company-summary-tab">
        <AonContainer>
            <AonRow class="mt-2">
                <AonSpinner
                    v-if="entityStore.loadingEntity || !entityStore.entity"
                    class="ma-auto"
                    :scale="0.5"
                    color="castleMoat"
                />
                <template v-else>
                    <AonCol class="aon-col-12 px-0" v-if="showInsights">
                        <Insights class="px-0" :insightTypes="allInsightTypes" />
                    </AonCol>
                    <AonCol class="aon-col-12 px-0">
                        <SummaryCard class="px-0" />
                    </AonCol>
                    <AonCol class="aon-col-7 pl-0">
                        <TPECard unique-id="profile-tpe" />
                    </AonCol>
                    <AonCol class="aon-col-5 pr-0">
                        <ProductStrategy />
                    </AonCol>
                    <AonCol class="aon-col-12 px-0">
                        <ProductClassPeers />
                    </AonCol>
                    <AonCol class="aon-col-12 px-0">
                        <FundingRoundsCard
                            v-if="entityStore.entity.entity_type !== 'Public Company'"
                        />
                    </AonCol>
                </template>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'
import { useEntityStore } from '@/stores'
import SummaryCard from './SummaryCard.vue'

import { useFlag, useVariant } from '@unleash/proxy-client-vue'

import TPECard from '@/components/company/tabs/valuations/TPECard.vue'
import ProductStrategy from './ProductStrategy.vue'
import ProductClassPeers from './productClassPeers/ProductClassPeers.vue'

//remove after flag is removed
import ExecutiveTeamCard from '@/components/company/tabs/strategy/talentTabs/ExecutiveTeamCard.vue'
import FundingCard from '@/components/company/tabs/valuations/FundingCard.vue'
import FundingRoundsCard from '@/components/company/tabs/valuations/FundingRoundsCard.vue'
import Insights from '@/components/insights/Insights.vue'

const entityStore = useEntityStore()
const showInsightsFlag = useFlag('ipAlpha.companyInsights')
const availableInsights = useVariant('ipAlpha.availableInsights')
const experimentalInsights = useVariant('ipAlpha.experimentalInsights')

const showInsights = ref(false)

const allInsightTypes = computed(() => {
    return [
        ...(JSON.parse(availableInsights.value?.payload?.value ?? '[]') ?? []),
        ...(JSON.parse(experimentalInsights.value?.payload?.value ?? '[]') ?? []),
    ]
})

watchEffect(async () => {
    if (entityStore.entity && allInsightTypes.value.length > 0) {
        const insights = await entityStore.onGetInsights(
            entityStore.entity.aon_entity_pk,
            allInsightTypes.value
        )
        showInsights.value = showInsightsFlag.value && insights && insights.length > 0
    }
})
</script>

<style lang="scss" scoped>
.company-summary-tab {
    width: 100%;
    position: relative;
}
</style>
