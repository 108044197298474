<template>
    <div class="startup-opportunities-visuals">
        <div class="grid-container">
            <div class="chart-container">
                <h6>Innovation Gap Analysis</h6>
                <InnovationGapChart
                    :dataReady="props.dataReady && innovationDataReady"
                    :peer-score="props.licensingOpportunityData?.innovationScoreRequirement ?? NaN"
                    :licensee-score="props.licensingOpportunityData?.innovationScore ?? NaN"
                    :licensor-score="targetNode?.innovationScore ?? NaN"
                    :licensee-name="licensingOpportunityData?.entityName"
                />
            </div>

            <div class="chart-container">
                <h6>Yearly Active Patent Comparison</h6>
                <YearlyPatentFilingsComparison
                    :dataReady="
                        props.dataReady &&
                        licensorDataAvailable &&
                        (licenseeDataAvailable || !props.licensingOpportunityData)
                    "
                    :licensor-data="licensorData"
                    :licensee-data="licenseeData"
                    :licensor-name="targetEntityName"
                    :licensee-name="licensingOpportunityData?.entityName"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import InnovationGapChart from './charts/InnovationGapChart.vue'
import YearlyPatentFilingsComparison, {
    YearlyPatentCount,
} from './charts/YearlyPatentFilingsComparison.vue'
import { getEntityProductStrategy, getEntityProductStrategyYearly } from '@/api/entities'

interface LicensingOpportunityData {
    entityPk: number
    nodeId: string
    entityName: string
    [key: string]: any
}

const props = defineProps({
    licensingOpportunityData: {
        type: Object as () => LicensingOpportunityData | undefined,
    },
    targetEntityPk: {
        type: Number,
        required: true,
    },
    targetEntityName: {
        type: String,
    },
    rootNodeContext: {
        type: String,
    },
    dataReady: {
        type: Boolean,
    },
})

const targetEntityStrategy = ref(null)
const targetNode = ref(null)
const licensorYearlyStrategy = ref([])
const licenseeEntityPk = ref<number | null>(null)
const licensorData = ref<YearlyPatentCount[]>([])
const licenseeData = ref<YearlyPatentCount[]>([])
const innovationDataReady = ref(false)
const licensorDataAvailable = ref(false)
const licenseeDataAvailable = ref(false)
const fetchProductStrategy = async (entityPk: number) => {
    try {
        const response = await getEntityProductStrategy(entityPk)
        targetEntityStrategy.value = response.data
        if (props.licensingOpportunityData?.nodeId) {
            updateTargetNode(props.licensingOpportunityData?.nodeId)
        }
        innovationDataReady.value = true
    } catch (error) {
        console.error('Error fetching product strategy:', error)
    }
}

const updateTargetNode = (nodeId: string) => {
    if (targetEntityStrategy.value && nodeId) {
        targetNode.value = targetEntityStrategy.value.find((node) => node.nodeId === nodeId)
    } else {
        targetNode.value = null
    }
}

const fetchPatentData = async (entityPk) => {
    if (!entityPk) return []

    try {
        const response = await getEntityProductStrategyYearly(entityPk, props.rootNodeContext)
        return response.data
    } catch (error) {
        console.error('Error fetching patent data:', error)
        return []
    }
}

const formatPatentData = (yearlyStrategyData, nodeId) => {
    return yearlyStrategyData.map((item) => {
        return {
            year: item.year,
            count: item.productTechs.find((tech) => tech.conceptNodeId == nodeId)?.patentCount,
        }
    })
}

// Watch for changes in target entity
watch(
    () => props.targetEntityPk,
    (newEntityPk) => {
        if (newEntityPk) {
            fetchProductStrategy(newEntityPk)
        }
    },
    { immediate: true }
)

// Watch for changes in licensing opportunity data
watch(
    () => props.licensingOpportunityData?.nodeId,
    (newNodeId) => {
        if (newNodeId) {
            updateTargetNode(newNodeId)
        }
    }
)

// Watch for changes in licensing opportunity data and root node context
watch(
    () => [props.licensingOpportunityData, props.rootNodeContext],
    async ([newLicOpData, newRootNodeContext]) => {
        if (newLicOpData?.entityPk) {
            licenseeDataAvailable.value = false
            licenseeEntityPk.value = newLicOpData.entityPk
            licenseeData.value = []
            licenseeData.value = formatPatentData(
                await fetchPatentData(licenseeEntityPk.value),
                newLicOpData.nodeId
            )
            licenseeDataAvailable.value = true
        }
    }
)

// Watch for changes in target entity and root node context
watch(
    () => [props.targetEntityPk, props.rootNodeContext],
    async ([newEntityPk, newRootNodeContext]) => {
        licensorYearlyStrategy.value = await fetchPatentData(newEntityPk)
    },
    { immediate: true }
)

// Watch for changes in licensor strategy and node ID
watch(
    () => [licensorYearlyStrategy.value, props.licensingOpportunityData?.nodeId],
    ([newValue, newNodeId]) => {
        licensorDataAvailable.value = false
        licensorData.value = formatPatentData(newValue, newNodeId)
        licensorDataAvailable.value = true
    }
)
</script>

<style scoped>
.startup-opportunities-visuals {
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.chart-container {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chart-container h3 {
    margin-top: 0;
    color: #2c3e50;
    font-size: 1.2rem;
}

@media (max-width: 1024px) {
    .grid-container {
        grid-template-columns: 1fr;
    }
}
</style>
