import { defineStore } from 'pinia'
import { computed, ref, inject } from 'vue'
import { useRouter } from 'vue-router'

import * as EntityApi from '@/api/entities'
import * as SearchApi from '@/api/opensearch'
import { logger } from '@moatmetrics/vue-logger'
import { getOpenSearchQuery } from '@/lib/openSearchQueryBuilder'
import { getExecutiveTeam } from '../api/companytabs/execution'
import {
    getEntityMoatQualityMetrics,
    getEntityValidityByClaimScore,
    computeEntityCompetitors,
    computeEntityAcquisitionTargets,
    computePotentialBuyers,
    getInsights,
} from '../api/entities'

export const useEntityStore = defineStore('entity', () => {
    const loadingEntity = ref(true)
    const entity = ref(null)
    const summaryEntity = ref(null)
    const entityHierarchy = ref([])
    const entityHistory = ref([])
    const entityPatentFamilyStats = ref(null)
    const patentBreakdown = ref(null)
    const ipBasicsActivePatents = ref(true)
    const validityByClaimScore = ref(null)
    const ipValue = ref(null)
    const ipRebuildCost = ref(null)
    const financialOverview = ref(null)
    const executiveTeam = ref(null)
    const moatQualityMetrics = ref(null)
    const competitors = ref(null)
    const acquisitionTargets = ref(null)
    const potentialBuyers = ref(null)
    const showDrillIn = ref(false)
    const drillInTargetId = ref(null)
    const insights = ref(null)

    // Strategy Tab Specific
    const strategyTabHighLevel = ref('innovation')
    const strategyTabNodeFilter = ref('product')
    const strategyTabFilterFocus = ref('overview')
    const strategyTabPatentListObj = ref(null)
    // End Strategy Tab Specific

    const router = useRouter()

    const getCurrentCompanyTab = computed((state) => {
        if (router) {
            let tabName = router.currentRoute.value.query.tabName
            if (tabName === 'profile') {
                return 1
            } else if (tabName === 'ipBasics') {
                return 2
            } else if (tabName === 'moat2Product') {
                return 3
            } else if (tabName === 'risk') {
                return 4
            } else if (tabName === 'value') {
                return 6
            } else if (tabName === 'innovation') {
                return 7
            } else if (tabName === 'strategy') {
                return 8
            } else if (tabName === 'patentList') {
                return 9
            } else if (tabName === 'portfolioManager') {
                return 10
            } else {
                return 1
            }
        }
    })

    const resetState = () => {
        loadingEntity.value = true
        entity.value = null
        summaryEntity.value = null
        entityHierarchy.value = []
        entityHistory.value = []
        entityPatentFamilyStats.value = null
        patentBreakdown.value = null
        ipBasicsActivePatents.value = true
        validityByClaimScore.value = null
        ipValue.value = null
        ipRebuildCost.value = null
        financialOverview.value = null
        executiveTeam.value = null
        moatQualityMetrics.value = null
        competitors.value = null
        acquisitionTargets.value = null
        potentialBuyers.value = null
        showDrillIn.value = false
        drillInTargetId.value = null
        insights.value = null
        strategyTabHighLevel.value = 'innovation'
        strategyTabNodeFilter.value = 'product'
        strategyTabFilterFocus.value = 'overview'
        strategyTabPatentListObj.value = null
    }

    const setEntity = (entityData) => {
        entity.value = entityData
    }

    const setSummaryEntity = (entityData) => {
        summaryEntity.value = entityData
    }

    const setEntityHierarchy = (hierarchyData) => {
        entityHierarchy.value = hierarchyData
    }

    const setEntityHistory = (historyData) => {
        entityHistory.value = historyData
    }

    const setEntityPatentFamilyStats = (stats) => {
        entityPatentFamilyStats.value = stats
    }

    const setFinancialOverview = (overview) => {
        financialOverview.value = overview
    }

    const setIpValue = (value) => {
        ipValue.value = value
    }

    const setIpRebuildCost = (cost) => {
        ipRebuildCost.value = cost
    }

    const getEntity = async (entityId) => {
        try {
            const dslQuery = getOpenSearchQuery({
                FC: ['aon_entity_pk'],
                FT: ['eq'],
                FV: [entityId],
            })
            const { data } = await SearchApi.searchEntities(dslQuery)
            const entityData = data.hits.hits.map((h) => h._source)[0]
            setEntity(entityData)
            return entityData
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const getEntityHierarchy = async (entityId) => {
        try {
            const dslQuery = getOpenSearchQuery({
                FC: ['ultimate_aon_entity_pk'],
                FT: ['eq'],
                FV: [entityId],
                SC: ['name'],
                SD: [1],
                page_size: 3000,
            })
            const { data } = await SearchApi.searchEntities(dslQuery)
            const entitiesInHierarchy = data.hits.hits.map((h) => h._source)

            setEntityHierarchy(entitiesInHierarchy)

            return entitiesInHierarchy
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const getEntityPatentFamilyStats = async (entityId) => {
        try {
            const { data } = await EntityApi.getEntityPatentFamilyStats(entityId)

            setEntityPatentFamilyStats(data)

            return data
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const getFinancialOverview = async (entityId) => {
        try {
            if (financialOverview.value) return financialOverview.value
            const { data } = await EntityApi.getFinancialOverview(entityId)
            setFinancialOverview(data)
            return data
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const getIpValue = async (entityId) => {
        try {
            const { data } = await EntityApi.getIpValue(entityId)
            setIpValue(data.ipValuationBin)
            return data.ipValuationBin
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const getIpRebuildCost = async (entityId) => {
        try {
            const { data } = await EntityApi.getIpRebuildCost(entityId)
            setIpRebuildCost(data.ipRebuildCostBin)
            return data.ipRebuildCostBin
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const getPatentBreakdown = async (entityId) => {
        try {
            if (patentBreakdown.value) return patentBreakdown.value

            const { data } = await EntityApi.getPatentBreakdown(entityId)
            patentBreakdown.value = data
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const getValidityByClaimScore = async (entityId) => {
        try {
            if (validityByClaimScore.value != null) return validityByClaimScore.value

            const { data } = await getEntityValidityByClaimScore(entityId)
            validityByClaimScore.value = data
            return data
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const onGetExecutiveTeam = async (entityId) => {
        try {
            if (executiveTeam.value) return executiveTeam.value
            const { data } = await getExecutiveTeam(entityId)
            executiveTeam.value = data
            return data
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const getMoatQualityMetrics = async (entityId) => {
        try {
            if (moatQualityMetrics.value) return moatQualityMetrics.value
            const { data } = await getEntityMoatQualityMetrics(entityId)
            moatQualityMetrics.value = data
            return data
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const getCompetitors = async (entityId) => {
        try {
            if (competitors.value) return competitors.value
            const { data } = await computeEntityCompetitors(entityId)
            if (data.Status === 'complete') {
                competitors.value = data
            }
            return data
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const getAcquisitionTargets = async (entityId) => {
        try {
            if (acquisitionTargets.value) return acquisitionTargets.value
            const { data } = await computeEntityAcquisitionTargets(entityId)
            if (data.Status === 'complete') {
                acquisitionTargets.value = data
            }
            return data
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const getPotentialBuyers = async (entityId) => {
        try {
            if (potentialBuyers.value) return potentialBuyers.value
            const { data } = await computePotentialBuyers(entityId)
            if (data.Status === 'complete') {
                potentialBuyers.value = data
            }
            return data
        } catch (err) {
            logger.error(err)
            throw err
        }
    }

    const onGetInsights = async (entityId, insightTypes) => {
        try {
            if (insights.value)
                return insights.value.results.filter((insight) =>
                    insightTypes.includes(insight.insightType)
                )
            const { data } = await getInsights(entityId)
            insights.value = data
            return data.results
        } catch (err) {
            logger.error(err)
            throw err
        }
    }
    return {
        // State
        loadingEntity,
        entity,
        summaryEntity,
        entityHierarchy,
        entityHistory,
        entityPatentFamilyStats,
        patentBreakdown,
        ipBasicsActivePatents,
        validityByClaimScore,
        ipValue,
        ipRebuildCost,
        financialOverview,
        executiveTeam,
        moatQualityMetrics,
        competitors,
        acquisitionTargets,
        potentialBuyers,
        showDrillIn,
        drillInTargetId,
        insights,
        // State Strategy Specific
        strategyTabHighLevel,
        strategyTabNodeFilter,
        strategyTabFilterFocus,
        strategyTabPatentListObj,
        // End State Strategy Specific

        // Getters
        getCurrentCompanyTab,

        // Actions
        resetState,
        setEntity,
        setSummaryEntity,
        setEntityHierarchy,
        setEntityHistory,
        setEntityPatentFamilyStats,
        setFinancialOverview,
        setIpValue,
        setIpRebuildCost,
        getEntity,
        getEntityHierarchy,
        getEntityPatentFamilyStats,
        getFinancialOverview,
        getIpValue,
        getIpRebuildCost,
        getPatentBreakdown,
        getValidityByClaimScore,
        getMoatQualityMetrics,
        getCompetitors,
        getAcquisitionTargets,
        getPotentialBuyers,
        getExecutiveTeam,
        onGetInsights,
        onGetExecutiveTeam,
    }
})
