import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { useEntityStore } from '@/stores'

export function useHeatMapChart(root) {
    let chart
    let yAxis
    let xAxis
    let heatLegend
    let scrollableContainer
    let clickCallback = null

    const setClickCallback = (callback) => {
        clickCallback = callback
    }

    const configureHeatmap = (options = { scrollable: false }) => {
        root.setThemes([am5themes_Animated.new(root)])

        chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                paddingLeft: 0,
                layout: root.verticalLayout,
            })
        )

        // Add scrollable container if option is enabled
        if (options.scrollable) {
            scrollableContainer = chart.chartContainer.children.unshift(
                am5.Container.new(root, {
                    width: am5.p100,
                    height: am5.p100,
                    verticalScrollbar: am5.Scrollbar.new(root, {
                        orientation: 'vertical',
                    }),
                    wheelable: true,
                })
            )
        }

        heatLegend = chart.bottomAxesContainer.children.push(
            am5.HeatLegend.new(root, {
                orientation: 'horizontal',
                startColor: am5.color('#E9EDF5'),
                endColor: am5.color('#1F589A'),
                startText: 'Low Fees',
                endText: 'High Fees',
                showValue: false,
                paddingTop: 50,
            })
        )

        let tooltip = am5.Tooltip.new(root, {})
        tooltip.label.adapters.add('text', function (text, target) {
            if (text && !text.startsWith('$')) {
                return '$' + text
            }
            return text
        })

        heatLegend.set('tooltip', tooltip)
    }

    const generateAxes = (data, options = { scrollable: false }) => {
        let yRenderer = am5xy.AxisRendererY.new(root, {
            visible: false,
            minGridDistance: 20,
            inversed: true,
            minorGridEnabled: true,
        })

        yRenderer.grid.template.set('visible', false)

        yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                renderer: yRenderer,
                categoryField: 'product',
            })
        )

        let xRenderer = am5xy.AxisRendererX.new(root, {
            visible: true,
            minGridDistance: 30,
            opposite: true,
            minorGridEnabled: true,
        })

        // Hide grid lines but keep the axis labels
        xRenderer.grid.template.set('visible', false)

        // Create X-axis with the renderer
        xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: xRenderer,
                categoryField: 'breadth',
            })
        )

        xAxis.get('renderer').labels.template.setAll({
            fontWeight: 'bold',
            fontSize: 14,
        })
    }

    const generateSeries = (data, options = { scrollable: false }) => {
        let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                calculateAggregates: true,
                stroke: am5.color(0xffffff),
                clustered: false,
                xAxis: xAxis,
                yAxis: yAxis,
                categoryXField: 'breadth',
                categoryYField: 'product',
                valueField: 'value',
            })
        )

        // Actions
        let columnTemplate = series.columns.template

        // Hover state
        columnTemplate.set('cursorOverStyle', 'pointer')
        columnTemplate.states.create('hover', {
            strokeWidth: 3,
            strokeOpacity: 1,
            stroke: am5.color(0x000000),
        })

        // click event
        columnTemplate.events.on('click', function (ev) {
            const dataItem = ev.target.dataItem
            if (dataItem && clickCallback) {
                const cellData = {
                    product: dataItem.dataContext.product,
                    breadth: dataItem.dataContext.breadth,
                    value: dataItem.dataContext.value,
                }
                if (cellData.value !== 0) {
                    clickCallback(cellData)
                }
            }
        })

        series.bullets.push(function () {
            let labelBullet = am5.Bullet.new(root, {
                locationX: 0.5,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    fill: am5.color(0xffffff),
                    centerX: am5.p50,
                    centerY: am5.p50,
                    populateText: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                }),
            })

            labelBullet.get('sprite').adapters.add('text', function (text, target) {
                let dataItem = target.dataItem
                if (dataItem && dataItem.dataContext) {
                    let value = dataItem.dataContext.value

                    // Hide if value is 0
                    if (value === 0) {
                        return ''
                    }

                    // Format
                    if (value >= 1000000) {
                        return '$' + (value / 1000000).toFixed(1) + 'M'
                    } else if (value >= 1000) {
                        return '$' + (value / 1000).toFixed(0) + 'k'
                    } else {
                        return '$' + value
                    }
                }
                return text
            })

            // Add adapter for the label color
            labelBullet.get('sprite').adapters.add('fill', function (fill, target) {
                let dataItem = target.dataItem
                if (dataItem && dataItem.dataContext) {
                    let value = dataItem.dataContext.value

                    if (value === 0) {
                        return am5.color(0x000000, 0) // Transparent color
                    }

                    // Calculate relative position in the range
                    let min = series.getPrivate('valueLow', 0)
                    let max = series.getPrivate('valueHigh', 1)
                    let position = (value - min) / (max - min)

                    // Use white text for darker cells, black text for lighter cells
                    return position > 0.5 ? am5.color(0xffffff) : am5.color(0x000000)
                }
                return fill
            })

            return labelBullet
        })

        series.columns.template.events.on('pointerover', function (event) {
            let di = event.target.dataItem
            if (di) {
                heatLegend.showValue(di.get('value', 0))
            }
        })

        series.events.on('datavalidated', function () {
            heatLegend.set('startValue', series.getPrivate('valueLow'))
            heatLegend.set('endValue', series.getPrivate('valueHigh'))
        })

        series.set('heatRules', [
            {
                target: series.columns.template,
                min: am5.color('#E9EDF5'),
                max: am5.color('#1F589A'),
                dataField: 'value',
                key: 'fill',
            },
        ])

        series.data.setAll(data)

        populateAxes(data)

        // Set height based on data length and add to scrollable container if needed
        if (options.scrollable && scrollableContainer) {
            const productCount = [...new Set(data.map((item) => item.product))].length
            chart.yAxesAndPlotContainer.set('paddingTop', 10)
            chart.yAxesAndPlotContainer.set('height', productCount * 60)
            chart.yAxesAndPlotContainer.set('paddingBottom', 10)
            scrollableContainer.children.push(chart.yAxesAndPlotContainer)
        }

        chart.appear(1000, 100)
    }

    const populateAxes = (data) => {
        let products = []
        let claims = []

        am5.array.each(data, function (row) {
            if (products.indexOf(row.product) == -1) {
                products.push(row.product)
            }
            if (claims.indexOf(row.breadth) == -1) {
                claims.push(row.breadth)
            }
        })

        yAxis.data.setAll(
            products.map(function (item) {
                return { product: item }
            })
        )

        xAxis.data.setAll(
            claims.map(function (item) {
                return { breadth: item }
            })
        )
    }

    const disposeHeatMap = () => {
        if (chart) chart.dispose()
        if (yAxis) yAxis.dispose()
        if (xAxis) xAxis.dispose()
        if (scrollableContainer) scrollableContainer.dispose()
    }

    return {
        configureHeatmap,
        generateAxes,
        generateSeries,
        disposeHeatMap,
        setClickCallback,
    }
}
