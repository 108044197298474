<template>
    <div style="position: relative">
        <AonCoverLoading :loading="loading" />
        <div class="litigation-case-chart">
            <div v-if="props.dataReady && !props.caseData" class="no-data">
                <p>No data available</p>
            </div>
            <div
                v-show="props.dataReady && props.caseData"
                class="litigation-case-chart"
                ref="chartDiv"
            ></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, onUnmounted, watch } from 'vue'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

interface CaseData {
    year: number
    caseCount: number
}

const props = defineProps<{
    caseData: CaseData[] | null
    dataReady: boolean
}>()
const loading = ref(true)
const chartDiv = ref(null)
let root: am5.Root | null = null
let chart: am5xy.XYChart | null = null
let xAxis: am5xy.CategoryAxis | null = null
let series: am5xy.ColumnSeries | null = null
onMounted(() => {
    loading.value = true
    // Create root element
    root = am5.Root.new(chartDiv.value!)
    root.numberFormatter.setAll({
        numberFormat: '#.',
    })
    // Set themes
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none',
            paddingBottom: 30,
        })
    )

    // Create axes
    xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'year',
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 30,
                fontSize: 13,
                visible: true,
                paddingTop: 20,
            }),
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    xAxis.set(
        'tooltip',
        am5.Tooltip.new(root, {
            themeTags: ['axis'],
        })
    )

    const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                minGridDistance: 30,
                fontSize: 13,
                visible: true,
                paddingTop: 20,
            }),
        })
    )

    yAxis.children.unshift(
        am5.Label.new(root, {
            rotation: -90,
            text: 'Case Count',
            y: am5.p50,
            centerX: am5.p50,
        })
    )

    xAxis.children.push(
        am5.Label.new(root, {
            text: 'Year',
            x: am5.p50,
            centerX: am5.p50,
        })
    )

    // Create series
    series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            name: 'Cases',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'caseCount',
            categoryXField: 'year',
            tooltip: am5.Tooltip.new(root, {
                labelText: '{valueY} cases',
            }),
        })
    )

    updateChartData()

    // Add cursor
    chart.set('cursor', am5xy.XYCursor.new(root, {}))
})

const updateChartData = () => {
    loading.value = true
    // Check if any props are null before proceeding
    if (!props.caseData) {
        if (props.dataReady) {
            loading.value = false
            return
        }
        return
    }

    // Update the data for both axis and series
    if (xAxis && series) {
        xAxis.data.setAll(props.caseData)
        series.data.setAll(props.caseData)
    }
    loading.value = false
}

// Watch for changes in caseData
watch(
    [() => props.caseData, () => props.dataReady],
    (newData) => {
        if (chart && newData) {
            updateChartData()
        }
    },
    { deep: true, immediate: true }
)

onUnmounted(() => {
    if (root) {
        root.dispose()
    }
})
</script>

<style scoped>
.litigation-case-chart {
    width: 100%;
    height: 250px;
}

.no-data {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>
