import { ref, computed, inject } from 'vue'
import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import {
    requestProductAlignment,
    getProductAlignmentRuns,
    pollForJobData,
    getJobData,
} from '@/api/productAlignment.js'
import { useEntityStore, useArenaStore } from '@/stores'

export const useMoat2ProductStore = defineStore('moat2Product', () => {
    const logger = inject('logger')
    const { t } = useI18n()
    const entityStore = useEntityStore()
    const arenaStore = useArenaStore()
    const usPatentBreakdown = ref(null)
    const allChartData = ref(null)
    let interval = ref(0)

    const initLoad = ref(false)
    const productAlignmentLoading = ref(true)
    const productAlignmentActiveTab = ref(1)
    const alignmentPk = ref(null)
    const needsNewAlignment = ref(false)
    const percentComplete = ref(0)
    const productAlignmentProducts = ref([])
    const productAlignmentAllData = ref([])
    const productAlignmentTechAreaNodes = ref(new Set())
    const productAlignmentTechAreaSelections = ref(new Set())
    const productAlignmentTechAreaSelectionsOld = ref(new Set())
    const productAlignmentTopCompetitors = ref(null)
    const allCompetitorData = ref([])
    const showPACarousel = ref(true)

    const productAlignmentError = ref(false)
    const productAlignmentErrorMsg = ref('')

    const techAreaIdCollection = computed(() => {
        let filteredProductsByNodeIds = [...productAlignmentTechAreaSelections.value].filter(
            (item) =>
                !productAlignmentProducts.value.some((product) => product.nodeId === item.nodeId)
        )
        return filteredProductsByNodeIds.map((item) => item.nodeId)
    })

    const topCompetitorCollection = computed(() => {
        let mappedCompetitors = productAlignmentTopCompetitors.value?.map(
            (competitor) => competitor.aonEntityPk
        )

        return mappedCompetitors
    })

    const resetState = async () => {
        initLoad.value = false
        productAlignmentLoading.value = true
        productAlignmentActiveTab.value = 1
        alignmentPk.value = null
        productAlignmentProducts.value = []
        productAlignmentAllData.value = []
        productAlignmentTechAreaNodes.value = new Set()
        productAlignmentTechAreaSelections.value = new Set()
        productAlignmentTechAreaSelectionsOld.value = new Set()
        productAlignmentTopCompetitors.value = null
        allCompetitorData.value = []
        needsNewAlignment.value = false
        percentComplete.value = 0
        showPACarousel.value = true
        productAlignmentError.value = false
        productAlignmentErrorMsg.value = ''
        usPatentBreakdown.value = null
        allChartData.value = null

        setupPatentBreakdownWatcher()
    }

    const setupPatentBreakdownWatcher = () => {
        let checkInterval = setInterval(() => {
            if (entityStore.patentBreakdown?.length > 0) {
                clearInterval(checkInterval)
                usPatentBreakdown.value = entityStore.patentBreakdown.find(
                    (pb) => pb.country === 'US'
                )
            }
        }, 100)
    }

    const getPreviousRunOrRequestNew = async (findLatestSuccess = false) => {
        productAlignmentLoading.value = true
        productAlignmentError.value = null

        try {
            const { data } = await getProductAlignmentRuns(entityStore.entity.aon_entity_pk)
            if (data.results.length > 0) {
                const targetData = findLatestSuccess
                    ? data.results.find((run) => run.status === 'reportComplete')
                    : data.results[0]
                // arenaStore.rootPAId = targetData.rootNodeId
                alignmentPk.value = targetData.productAlignmentId
                if (targetData.isStale) {
                    needsNewAlignment.value = true
                    alignmentPk.value = null
                } else {
                    needsNewAlignment.value = false
                }
            } else {
                needsNewAlignment.value = true
                alignmentPk.value = null
            }
        } catch (err) {
            handleError(err.status, err.statusReason)
        }
        await getProductSegmentationData()
    }

    const getProductSegmentationData = async () => {
        productAlignmentLoading.value = true
        productAlignmentError.value = null

        try {
            if (!alignmentPk.value) {
                const { data } = await requestProductAlignment(entityStore.entity.aon_entity_pk)
                alignmentPk.value = data.productAlignmentId
            }
        } catch (err) {
            handleError(err.status, err.statusReason)
        }

        interval = setInterval(
            (function paHandler() {
                getPAStatus(alignmentPk.value)
                return paHandler
            })(),
            5000
        )
    }

    const getPAStatus = async () => {
        await pollForJobData(
            alignmentPk.value,
            true,
            async (data) => {
                // success
                clearInterval(interval)
                let paResponse = await getJobData(alignmentPk.value, false)

                arenaStore.rootPAId = paResponse.data.rootNodeId
                productAlignmentTechAreaNodes.value = new Set(paResponse.data.nodes)
                productAlignmentTechAreaSelections.value = new Set(paResponse.data.nodes)
                allChartData.value = paResponse.data.nodes

                productAlignmentLoading.value = false

                // Continue polling for processing nodes
                if (paResponse.data.nodes.find((node) => node.dataStatus === 'processing')) {
                    interval = setInterval(
                        (function paHandler() {
                            getProcessingNodes(alignmentPk.value)
                            return paHandler
                        })(),
                        5000
                    )
                }
            },
            (err) => {
                // failure
                handleError(err.status, err.statusReason)
                clearInterval(interval)
            },
            (progress) => {
                percentComplete.value = progress
            }
        )
    }

    const getProcessingNodes = async () => {
        await pollForJobData(
            alignmentPk.value,
            true,
            async (data) => {
                if (!data.nodes.find((node) => node.dataStatus === 'processing')) {
                    clearInterval(interval)
                    let paResponse = await getJobData(alignmentPk.value, false)
                    allChartData.value = paResponse.data.nodes
                    eventBus.emit('snacktime', {
                        type: 'success',
                        message:
                            'Additional technology areas have been classified under "other". Refresh to see full results.',
                    })
                }
            },
            (err) => {
                logger.error(err)
                clearInterval(interval)
            }
        )
    }

    const handleError = (err, reason) => {
        logger.error(err)
        percentComplete.value = 0

        switch (reason) {
            case 'noPatents':
                productAlignmentErrorMsg.value = t('productAlignment.error.noPatents', {
                    entity: entityStore.entity.name,
                })
                break

            case 'unknownCompany':
            case 'noProducts':
                productAlignmentErrorMsg.value = t('productAlignment.error.noProducts', {
                    entity: entityStore.entity.name,
                })
                break

            case 'systemError':
            default:
                productAlignmentErrorMsg.value = t('productAlignment.error.msg')
        }

        productAlignmentLoading.value = false
        productAlignmentError.value = reason
    }

    return {
        initLoad,
        productAlignmentLoading,
        productAlignmentActiveTab,
        alignmentPk,
        productAlignmentProducts,
        productAlignmentAllData,
        productAlignmentTechAreaNodes,
        productAlignmentTechAreaSelections,
        productAlignmentTechAreaSelectionsOld,
        productAlignmentTopCompetitors,
        allCompetitorData,
        showPACarousel,
        techAreaIdCollection,
        topCompetitorCollection,
        needsNewAlignment,
        percentComplete,
        resetState,
        productAlignmentError,
        productAlignmentErrorMsg,
        usPatentBreakdown,
        allChartData,
        // actions
        getPreviousRunOrRequestNew,
        getProductSegmentationData,
    }
})
