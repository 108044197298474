<template>
    <div class="decreasing-innovation">
        <AonCard
            style="position: relative"
            class="mt-4"
            title="Decreasing Innovation Investment"
            desc="This section identifies technology areas where the company’s innovation activity is slowing down. These areas show a decline in patent filings, signaling reduced focus or resource allocation."
            :borders="false"
        >
            <AonCoverLoading
                :loading="loadingData"
                title="Loading Decreasing Innovations..."
            ></AonCoverLoading>
            <div class="content-holder">
                <MoatTable
                    style="width: 100%; height: 261px"
                    :class="themeClass"
                    :column-defs="colDefs"
                    :suppress-auto-size="true"
                    :row-data="tableData"
                    :tooltip-interaction="true"
                    :tooltip-mouse-track="true"
                    :tooltip-show-delay="500"
                    tooltip-show-mode="whenTruncated"
                    @grid-ready="gridInit"
                    @cell-clicked="onCellClicked"
                />
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, ref, inject, nextTick } from 'vue'
import { useMoat2ProductStore, useEntityStore, usePatentViewerStore } from '@/stores'
import { getInnovationInvestment } from '@/api/productAlignment.js'

import { useFlag } from '@unleash/proxy-client-vue'

import { useI18n } from 'vue-i18n'
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'

const isProUser = useFlag('ipAlpha.proUsers')

const logger = inject('logger')
const { t } = useI18n()

const moat2ProductStore = useMoat2ProductStore()
const entityStore = useEntityStore()
const patentViewerStore = usePatentViewerStore()

const loadingData = ref(true)
const tableData = ref([])

const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        field: 'technology',
        flex: 2,
        minWidth: 350,
        cellClass: 'link',
        pinned: 'left',
    },
    {
        field: 'targetEntityPatentCount',
        headerTooltip: 'Patent Count',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.strategyTooltips.patentCount'),
            headerName: 'Patent Count',
        },
        flex: 1,
        cellRenderer: 'MTCustomCellLinkWContext',
        cellRendererParams: {
            allowClick: isProUser.value,
            cellClick: async (params) => {
                patentViewerStore.nodeIdList.push(params.data.nodeId)
                patentViewerStore.technologyName = params.data.technology
                if (entityStore.entity) {
                    patentViewerStore.entityPkList.push(entityStore.entity.aon_entity_pk)
                    patentViewerStore.entityName = entityStore.entity.name
                }
                await nextTick()
                patentViewerStore.showPatentViewerList = true
            },
        },
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.targetEntityPatentCount
                    ? p.data.targetEntityPatentCount.toLocaleString()
                    : '--'
            }
        },
    },
    {
        field: 'opportunity',
        headerTooltip: 'Momentum',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.strategyTooltips.momentum'),
            headerName: 'Momentum',
        },
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.opportunity.toFixed(2)
            }
        },
    },
    {
        field: 'opportunityBenchmarkPercentDelta',
        headerTooltip: 'Innovation Gap',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.strategyTooltips.innovationGap'),
            headerName: 'Innovation Gap',
        },
        flex: 1,
        cellRenderer: 'MTCustomCellScoreChange',
        cellRendererParams: {
            colorValue: true,
        },
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.opportunityBenchmarkPercentDelta.toFixed()}%`
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.strategyTooltips.ipRepoCost'),
            headerName: 'IP Reproduction Cost',
        },
        field: 'ipRebuildCostBin',
        flex: 1,
    },
    {
        suppressHeaderMenuButton: true,
        cellRenderer: 'MTContextMenu',
        cellRendererParams: {
            actionsList: [
                {
                    name: 'View Details',
                    icon: 'fa-boxes-stacked',
                    actionName: 'details',
                    detailsAction: (params) => {
                        entityStore.drillInTargetId = null
                        setTimeout(() => {
                            entityStore.drillInTargetId = params.data.nodeId
                            entityStore.showDrillIn = true
                        }, 100)
                    },
                },
                isProUser.value
                    ? {
                          name: 'View Patents',
                          icon: 'fa-square-list',
                          actionName: 'patents',
                          patentsAction: async (params) => {
                              patentViewerStore.nodeIdList.push(params.data.nodeId)
                              patentViewerStore.technologyName = params.data.technology
                              if (entityStore.entity) {
                                  patentViewerStore.entityPkList.push(
                                      entityStore.entity.aon_entity_pk
                                  )
                                  patentViewerStore.entityName = entityStore.entity.name
                              }
                              await nextTick()
                              patentViewerStore.showPatentViewerList = true
                          },
                      }
                    : null,
            ],
            highlightLabel: true,
        },
        pinned: 'right',
        width: 42,
    },
])

onMounted(() => {
    getTopInnovations()
})

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()
}

const getTopInnovations = async () => {
    loadingData.value = true
    try {
        let { data } = await getInnovationInvestment(moat2ProductStore.alignmentPk)
        tableData.value = data['decreasingInnovationInvestments']
    } catch (error) {
        logger.error(error)
    } finally {
        loadingData.value = false
    }
}
const onCellClicked = async (params) => {
    if (params.column.colId !== 'technology') {
        return
    }

    entityStore.drillInTargetId = null
    setTimeout(() => {
        entityStore.drillInTargetId = params.data.nodeId
        entityStore.showDrillIn = true
    }, 100)
}
</script>

<style lang="scss" scoped>
.decreasing-innovation {
    width: 100%;
    position: relative;

    .ag-theme-quartz {
        --ag-active-color: #dddddd;
    }
}
</style>
