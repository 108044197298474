<template>
    <div class="output-arena-space">
        <AonCard
            :borders="false"
            title="Trending Data Visualization"
            desc="Visualize how the target company compares against the selected contenders."
        >
            <template #header-append>
                <div
                    class="chart-action-wrapper d-flex align-items-end"
                    :class="{ disabled: !everythingDoneLoading }"
                >
                    <AonDropdown
                        class="mr-5"
                        label="Chart Type"
                        :items="graphDropdownOptions"
                        :init-selection="graphDropdownOptions[0]"
                        style="width: 250px"
                        @select-option="changeGraph"
                    />
                    <AonButton
                        label="Edit Contender List"
                        style="min-width: 220px"
                        text-with-icon
                        :iconOptions="{
                            iconStyle: 'fas',
                            iconName: 'fa-gear',
                        }"
                        @clicked="showCompetitorSelection = true"
                    />
                </div>
            </template>
            <div class="competitor-content">
                <div class="chart-holder-parent d-flex">
                    <div
                        class="chart-holder"
                        :class="[
                            { expand: activeGraph === 'timeline' },
                            { normal: activeGraph === 'both' },
                        ]"
                    >
                        <AonCoverLoading
                            :loading="
                                (loadingViolin && activeGraph === 'timeline') ||
                                (loadingViolin && activeGraph === 'both')
                            "
                            title="Generating Timeline"
                        />
                        <TrendingDataViolin
                            key="trending_violin_chart"
                            class="violin-chart"
                            v-if="competitorViolinChartData.length > 0 && !loadingViolin"
                            :chart-data="competitorViolinChartData"
                        />
                    </div>
                    <div
                        class="chart-holder"
                        :class="[
                            { expand: activeGraph === 'quadrant' },
                            { normal: activeGraph === 'both' },
                        ]"
                    >
                        <AonCoverLoading
                            :loading="
                                (loadingTimeline && activeGraph === 'quadrant') ||
                                (loadingTimeline && activeGraph === 'both')
                            "
                            title="Generating Quadrant Chart"
                        />
                        <TimelineXYChartAM
                            class="timeline-chart"
                            v-if="
                                competitorTimelineChartData.length > 0 &&
                                !loadingTimeline &&
                                moat2ProductStore.productAlignmentTopCompetitors
                            "
                            :chart-data="competitorTimelineChartData"
                            :loading="loadingTimeline"
                            :entityId="props.entityId"
                        />
                    </div>
                </div>
                <div
                    v-if="!loadingViolin.value || !loadingTimeline.value"
                    class="chart-legend w-full d-flex justify-content-center flex-wrap mt-5"
                >
                    <div
                        class="legend-item d-flex align-items-center mr-5 mt-2"
                        v-for="comp in moat2ProductStore.productAlignmentTopCompetitors"
                    >
                        <div class="chip mr-2" :style="getLegendColor(comp)"></div>
                        <p class="small">{{ comp.aonEntityName }}</p>
                    </div>
                </div>
            </div>
        </AonCard>
        <AonCard
            class="mt-4"
            :borders="false"
            title="Benchmark Contenders"
            style="overflow: hidden"
        >
            <div class="table-holder">
                <OutputCompetitorTable
                    :table-data="competitorTableData"
                    :all-table-data="allCompetitorTableData"
                    :loading="competitorsLoading"
                    :everything-done-loading="everythingDoneLoading"
                    :updater="competitorTableUpdater"
                    :pinnedEntities="topCompetitorsEntityPks"
                    :tech-area-ids="techIds"
                    :alignmentRunPk="activeStoreAlignmentPk"
                    @refresh-competitor-table="refreshCompetitorTable"
                />
            </div>
        </AonCard>

        <OutputCompetitorSelection
            v-if="showCompetitorSelection"
            :table-data="competitorTableData"
            :all-table-data="allCompetitorTableData"
            :loading="competitorsLoading"
            :everything-done-loading="everythingDoneLoading"
            :updater="competitorTableUpdater"
            :defaultGroupByUltimateToggle="groupByUltimateParent"
            @show-competitor-modal="showCompetitorSelection = false"
            @refresh-arena-space="refreshSpace"
        />
    </div>
</template>

<script setup>
import { onMounted, ref, inject, computed, watch, nextTick } from 'vue'
import { useArenaOutputStore, useMoat2ProductStore } from '@/stores'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
    getCompetitors,
    getCompetitorsTimeline,
    getCompetitorsBreakdown,
} from '@/api/productAlignment.js'

import OutputCompetitorTable from './OutputCompetitorTable'
import OutputCompetitorSelection from './OutputCompetitorSelection'
import TrendingDataViolin from './charts/TrendingDataViolin'
import TimelineXYChartAM from './charts/Timeline'

import {
    dataPlum,
    dataTeal,
    dataBlue,
    dataAqua,
    dataCobalt,
    dataMarine,
    dataMagenta,
    dataRaspberry,
    dataOrange,
    dataYellow,
    dataLime,
    dataGreen,
} from '@/styles/js_variables.module.scss'
import { cloneDeep } from 'lodash-es'
import { debounce } from 'lodash-es'

const emit = defineEmits(['everything-done-loading'])
const eventBus = inject('eventBus')
const logger = inject('logger')
const { t } = useI18n()
const moat2ProductStore = useMoat2ProductStore()
const arenaOutputStore = useArenaOutputStore()
const route = useRoute()

const props = defineProps({
    type: {
        type: String,
        default: 'pa',
    },
    entityId: {
        type: [Number, String],
        default: undefined,
    },
    entityName: {
        type: String,
        default: '',
    },
    loadingJob: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: true,
    },
})

const topCompetitorColors = ref([
    dataTeal,
    dataPlum,
    dataBlue,
    dataRaspberry,
    dataOrange,
    dataYellow,
    dataLime,
    dataGreen,
    dataMagenta,
    dataMarine,
    dataCobalt,
    dataAqua,
])
const topCompetitors = ref([])
const topCompetitorsEntityPks = computed(() => {
    return topCompetitors.value.map((item) => item.aonEntityPk)
})
const activeGraph = ref('both')
const graphDropdownOptions = ref([
    {
        itemText: 'Both',
        iconOptions: {
            iconStyle: 'fas',
            iconColor: 'grey01',
            iconName: 'fa-sparkles',
        },
    },
    {
        itemText: 'Timeline',
        iconOptions: {
            iconStyle: 'fas',
            iconColor: 'grey01',
            iconName: 'fa-timeline',
        },
    },
    {
        itemText: 'Quadrant',
        iconOptions: {
            iconStyle: 'fas',
            iconColor: 'grey01',
            iconName: 'fa-grid-2',
        },
    },
])
const currentChartTab = ref(0)
const localGroupingSelections = ref(null)
const showCompetitorSelection = ref(false)

const competitorsLoading = ref(false)
const loadingViolin = ref(false)
const loadingTimeline = ref(false)
const competitorTableUpdater = ref(0)
const competitorViolinChartData = ref([])
const competitorTimelineChartData = ref([])
const competitorTableData = ref([])
const allCompetitorTableData = ref([])
const watchlistPkFilter = ref(null)
const techIds = computed(() => {
    return props.type === 'pa'
        ? [...moat2ProductStore.techAreaIdCollection]
        : [...arenaOutputStore.techAreaIdCollection]
})
const activeStore = props.type === 'pa' ? moat2ProductStore : arenaOutputStore
const activeStoreAlignmentPk = activeStore.alignmentPk

const everythingDoneLoading = computed(() => {
    return !competitorsLoading.value && !loadingViolin.value && !loadingTimeline.value
})
const groupByUltimateParent = computed(() => {
    return arenaOutputStore.groupByUltimateParent
})

watch(
    () => everythingDoneLoading.value,
    async (newVal, oldVal) => {
        if (newVal) {
            emit('everything-done-loading', true)
        }
        if (!newVal) {
            emit('everything-done-loading', false)
        }
    }
)

watch(
    () => arenaOutputStore.arenaOutputActiveTab,
    async (newVal, oldVal) => {
        if (
            newVal === 2 &&
            !setsAreEqual(
                arenaOutputStore.arenaOutputTechAreaSelectionsOld,
                arenaOutputStore.arenaOutputTechAreaSelections
            )
        ) {
            debouncedTechChange.cancel()
            debouncedTechChange()
        }
    }
)

watch(
    () => moat2ProductStore.productAlignmentActiveTab,
    async (newVal, oldVal) => {
        if (
            newVal === 2 &&
            !setsAreEqual(
                moat2ProductStore.productAlignmentTechAreaSelectionsOld,
                moat2ProductStore.productAlignmentTechAreaSelections
            )
        ) {
            debouncedTechChange.cancel()
            debouncedTechChange()
        }
    }
)

watch(
    () => arenaOutputStore.initLoad,
    async (newVal, oldVal) => {
        if (newVal) {
            debouncedTechChange.cancel()
            arenaOutputStore.initLoad = false
            debouncedTechChange()
        }
    }
)

watch(
    () => moat2ProductStore.initLoad,
    async (newVal, oldVal) => {
        if (newVal) {
            debouncedTechChange.cancel()
            moat2ProductStore.initLoad = false
            debouncedTechChange()
        }
    }
)

const refreshCompetitorTable = (params) => {
    topCompetitors.value = []
    watchlistPkFilter.value = params.watchlistPk
    getCompetitorData(techIds.value ? techIds.value : null, watchlistPkFilter.value)
}

const debouncedTechChange = debounce(() => {
    topCompetitors.value = []
    getCompetitorData(techIds.value ? techIds.value : null, watchlistPkFilter.value)
}, 200)

const getCompetitorData = async (newIds = false, watchlistPk = null) => {
    eventBus.emit('set-arena-space-loading', true)
    loadingViolin.value = true
    loadingTimeline.value = true
    competitorsLoading.value = true
    localGroupingSelections.value = newIds

    try {
        await getCompetitors(
            route.params.jobPk ? route.params.jobPk : moat2ProductStore.alignmentPk,
            newIds ? newIds : techIds.value,
            watchlistPk,
            groupByUltimateParent.value
        )
            .then((response) => {
                allCompetitorTableData.value = cloneDeep(response.data)
                moat2ProductStore.allCompetitorData = cloneDeep(response.data)
                arenaOutputStore.allCompetitorData = cloneDeep(response.data)

                if (
                    arenaOutputStore.targetCompany.length > 0 &&
                    !arenaOutputStore.targetCompany[0].name
                ) {
                    let targetCompany = arenaOutputStore.allCompetitorData.find(
                        (item) => item.aonEntityPk === arenaOutputStore.targetCompany[0].aonEntityPk
                    )
                    arenaOutputStore.targetCompany = [targetCompany]
                    arenaOutputStore.targetCompanyLoading = false
                }

                groomTableData(response.data)

                eventBus.emit('set-arena-space-loading', false)
                getCompetitorsByYear(localGroupingSelections.value)
                getCompetitorsCumulativePatentSummaryByYear(localGroupingSelections.value)
                competitorTableUpdater.value++
                competitorsLoading.value = false

                if (props.type === 'pa') {
                    moat2ProductStore.productAlignmentTechAreaSelectionsOld =
                        moat2ProductStore.productAlignmentTechAreaSelections
                } else {
                    arenaOutputStore.arenaOutputTechAreaSelectionsOld =
                        arenaOutputStore.arenaOutputTechAreaSelections
                }
            })
            .catch((error) => {
                // Catch if call is canceled
                eventBus.emit('set-arena-space-loading', true)
                loadingViolin.value = true
                loadingTimeline.value = true
                competitorsLoading.value = true
                logger.error(error)
            })
    } catch (err) {
        // catch if call fails
        logger.error(err)
    }
}

const getCompetitorsByYear = async (newIds = false) => {
    loadingViolin.value = true
    try {
        const { data } = await getCompetitorsBreakdown(
            route.params.jobPk ? route.params.jobPk : moat2ProductStore.alignmentPk,
            newIds ? newIds : techIds.value,
            moat2ProductStore.topCompetitorCollection
        )

        let sortedData = data.sort((a, b) =>
            a.filingYear == b.filingYear
                ? a.patentCount - b.patentCount
                : a.filingYear - b.filingYear
        )
        competitorViolinChartData.value = sortedData
    } catch (err) {
        logger.error(err)
    } finally {
        loadingViolin.value = false
    }
}

const getCompetitorsCumulativePatentSummaryByYear = async (newIds = false) => {
    loadingTimeline.value = true
    try {
        const { data } = await getCompetitorsTimeline(
            route.params.jobPk ? route.params.jobPk : moat2ProductStore.alignmentPk,
            newIds ? newIds : techIds.value,
            moat2ProductStore.topCompetitorCollection
        )
        competitorTimelineChartData.value = data.filter((item) =>
            moat2ProductStore.topCompetitorCollection.includes(item.aonEntityPk)
        )
    } catch (err) {
        logger.error(err)
    } finally {
        loadingTimeline.value = false
    }
}

const groomTableData = (data, topList = false) => {
    // change to "if target entity is not null"
    if ((props.type !== 'arenas') & (topCompetitors.value.length === 0)) {
        let targetEntityIndex = data.findIndex((entity) => entity.aonEntityPk === props.entityId)
        let returnedTop = data.slice(0, 8)
        let targetInTopList = returnedTop.find((entity) => entity.aonEntityPk === props.entityId)
        if (targetEntityIndex !== -1 && targetInTopList === undefined) {
            topCompetitors.value.push(data.find((entity) => entity.aonEntityPk === props.entityId))
        }
    }

    if (!topList) {
        data = data.slice(0, 20)
        topCompetitors.value.length === 1
            ? topCompetitors.value.push(data.slice(0, 7))
            : topCompetitors.value.push(data.slice(0, 8))
        topCompetitors.value = topCompetitors.value.flat()
    } else {
        topCompetitors.value = topList
    }
    moat2ProductStore.productAlignmentTopCompetitors = topCompetitors.value

    let mergedData = []
    for (let i = 0; i < data.length; i++) {
        let found = false
        for (let j = 0; j < topCompetitors.value.length; j++) {
            if (data[i].aonEntityPk === topCompetitors.value[j].aonEntityPk) {
                found = true
                break
            }
        }
        if (!found) {
            mergedData.push(data[i])
        }
    }
    mergedData = [...topCompetitors.value, ...mergedData]

    competitorTableData.value = mergedData.map((item) => {
        return {
            ...item,
            background:
                topCompetitorColors.value[
                    topCompetitors.value.findIndex(
                        (violinItem) => violinItem.aonEntityName === item.aonEntityName
                    )
                ],
            pinned: topCompetitors.value.find(
                (violinItem) => violinItem.aonEntityName === item.aonEntityName
            )
                ? true
                : false,
        }
    })
}

const refreshSpace = () => {
    groomTableData(allCompetitorTableData.value, moat2ProductStore.productAlignmentTopCompetitors)
    getCompetitorsByYear(localGroupingSelections.value)
    getCompetitorsCumulativePatentSummaryByYear(localGroupingSelections.value)

    setTimeout(() => {
        eventBus.emit('set-arena-space-loading', false)
    }, 1000)
}

const setActiveChartTab = (id) => {
    currentChartTab.value = id
    eventBus.emit('set-active-chart-tab', id)
}

const changeGraph = (type) => {
    if (type.itemText === 'Both') {
        activeGraph.value = 'both'
    }
    if (type.itemText === 'Timeline') {
        activeGraph.value = 'timeline'
    }
    if (type.itemText === 'Quadrant') {
        activeGraph.value = 'quadrant'
    }
}

const getLegendColor = (comp) => {
    let targetColor =
        topCompetitorColors.value[
            topCompetitors.value.findIndex(
                (violinItem) => violinItem.aonEntityName === comp.aonEntityName
            )
        ]
    return `background: ${targetColor}`
}

const setsAreEqual = (s1, s2) => {
    return s1.size === s2.size && [...s1].every((x) => s2.has(x))
}
</script>

<style lang="scss" scoped>
.output-arena-space {
    width: 100%;
    height: 100%;
    position: relative;

    .header-holder {
        border-bottom: 1px solid vars.$grey05;
    }

    .chart-holder-parent {
        height: 500px;
        border-bottom: 1px solid vars.$grey05;
        position: relative;
    }

    .chart-legend {
        .chip {
            width: 16px;
            height: 16px;
            border-radius: 50%;
        }
    }

    .chart-holder {
        position: relative;

        .violin-chart,
        .timeline-chart {
            display: none;
        }

        &.normal {
            width: 50%;
            .violin-chart,
            .timeline-chart {
                display: block;
            }
        }

        &.expand {
            width: 100%;
            .violin-chart,
            .timeline-chart {
                display: block;
            }
        }

        &:first-of-type {
            border-right: solid 1px vars.$grey05;
        }
        .violin-toggle {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    .table-holder {
        position: relative;
        min-height: 400px;
        width: 100%;
        border-bottom: 1px solid vars.$grey05;
    }

    .charts-cover {
        width: 100%;
        height: 100%;
        background: rgba(38, 40, 54, 0.6);
        backdrop-filter: blur(10px);

        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
    }

    :deep(.table-aips-latest) {
        border: none !important;
        .aips-table-wrap {
            border: none !important;
        }
        .table-header {
            display: none;
        }

        th {
            // box-shadow: none !important;
            pointer-events: none;

            &:first-of-type {
                pointer-events: all;
                border-radius: 4px 0 0 0;
            }

            &:last-of-type {
                border-radius: 0 4px 0 0;
            }
        }
        .company-chip {
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }

        .table-initial-state {
            .background-cover {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                background-color: rgba(white, 0.1);
                backdrop-filter: blur(10px);
            }

            .a-button,
            p {
                position: relative;
                z-index: 3;
                transform: translateY(-60px);
            }
        }
    }
}
</style>
