<template>
    <div class="licensing-opportunities-output d-flex flex-column w-full flex-grow-1">
        <AonCoverLoading
            :loading="loading"
            title="Loading Licensing Opportunities Playbook..."
            message="This may take a few minutes. If you leave this page, we will continue to search for licensing opportunities in the background."
        ></AonCoverLoading>
        <div class="output-header knights-cloak pa-4">
            <h5 class="gold--text">Licensing Opportunities - {{ target }}</h5>
            <p class="white--text mt-2">
                <span style="font-weight: 700">{{ licTitle }}</span
                >: {{ licDescription }}
            </p>
        </div>
        <div class="action-holder d-flex align-items-center mt-5">
            <div class="buttons d-flex align-items-center">
                <AonButton
                    class="mr-4 filter-button"
                    label="Litigation Risks"
                    :type="selectedLicType === 'litigation_risk' ? 'prominent' : 'subtle'"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-gavel' }"
                    @clicked="selectLicensingOpType('litigation_risk')"
                />
                <AonButton
                    class="mr-4 filter-button"
                    label="Emerging Competitors"
                    :type="selectedLicType === 'emerging_competitor' ? 'prominent' : 'subtle'"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-shuffle' }"
                    @clicked="selectLicensingOpType('emerging_competitor')"
                />
                <AonButton
                    class="mr-4 filter-button"
                    label="Startup Opportunities"
                    :type="selectedLicType === 'startup' ? 'prominent' : 'subtle'"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-seedling' }"
                    @clicked="selectLicensingOpType('startup')"
                />
                <AonButton
                    class="mr-5 filter-button"
                    label="Expiring Portfolios"
                    :type="selectedLicType === 'expiring_portfolio' ? 'prominent' : 'subtle'"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-hourglass-end' }"
                    @clicked="selectLicensingOpType('expiring_portfolio')"
                />
                <AonButton
                    class="px-5 target-button"
                    label="Change Licensing Party"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-bullseye' }"
                    @clicked="gotoStep(true, 2)"
                />
            </div>
            <div class="search d-flex align-items-center ml-5 flex-grow-1">
                <AonInput
                    style="width: 100%"
                    v-model="searchTerm"
                    search-input
                    placeholder="Company Name"
                    @keyup="searchTable()"
                    @clear="clearSearch()"
                />
            </div>
        </div>

        <AonCard
            style="position: relative"
            class="mt-4"
            title="Licensing Opportunities"
            desc="Select a company to analyze from the following list of strategic licensing opportunities."
        >
            <MoatTable
                class="table-border w-full flex-grow-1"
                style="width: 100%; height: 475px"
                :key="updater"
                :class="themeClass"
                :column-defs="colDefs"
                :row-data="tableData"
                :tooltip-show-delay="500"
                :row-selection="rowSelection"
                :selection-column-def="selectionColumnDef"
                @row-selected="onSelectionChanged"
                @grid-ready="gridInit"
            />
        </AonCard>
        <AonCard
            style="position: relative"
            class="mt-4"
            :title="
                selectedRow
                    ? 'Licensing Rationale for ' +
                      selectedRow.entityName +
                      ' - ' +
                      selectedRow.displayName
                    : ''
            "
            :desc="selectedRow ? selectedRow.justification : ''"
            :borders="false"
        >
            <div>
                <LitigationRiskVisuals
                    v-if="selectedLicType === 'litigation_risk'"
                    :licensing-opportunity-data="selectedRow"
                    :target-entity-pk="targetEntityPk"
                    :product-alignment-id="productAlignmentId"
                    :data-ready="!loading"
                />
                <EmergingCompetitorsVisuals
                    v-if="selectedLicType === 'emerging_competitor'"
                    :licensing-opportunity-data="selectedRow"
                    :target-entity-pk="targetEntityPk"
                    :root-node-context="productAlignment.rootNodeId"
                    :target-entity-name="target"
                    :data-ready="!loading"
                />
                <StartupOpportunitiesVisuals
                    v-if="selectedLicType === 'startup'"
                    :licensing-opportunity-data="selectedRow"
                    :target-entity-pk="targetEntityPk"
                    :root-node-context="productAlignment.rootNodeId"
                    :target-entity-name="target"
                    :data-ready="!loading"
                />
                <ExpiringPortfolioVisuals
                    v-if="selectedLicType === 'expiring_portfolio'"
                    :licensing-opportunity-data="selectedRow"
                    :target-entity-pk="targetEntityPk"
                    :target-entity-name="target"
                    :data-ready="!loading"
                />
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, inject, onActivated, nextTick, computed, onBeforeUnmount, watch } from 'vue'
import { usePlaybookGenerationStore, usePatentViewerStore } from '@/stores'
import { getEntityLicensingOpportunitiesAsync } from '@/api/entities'
import { getInvestmentGroup } from '@/lib/investments'
import LitigationRiskVisuals from './LicOpTypeVisuals/LitigationRiskVisuals.vue'
import EmergingCompetitorsVisuals from './LicOpTypeVisuals/EmergingCompetitorsVisuals.vue'
import StartupOpportunitiesVisuals from './LicOpTypeVisuals/StartupOpportunitiesVisuals.vue'
import ExpiringPortfolioVisuals from './LicOpTypeVisuals/ExpiringPortfolioVisuals.vue'
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { useFlag } from '@unleash/proxy-client-vue'
import { useI18n } from 'vue-i18n'

const isProUser = useFlag('ipAlpha.proUsers')

const filters = inject('filters')
const { t } = useI18n()
const logger = inject('logger')

const playbookGenerationStore = usePlaybookGenerationStore()
const patentViewerStore = usePatentViewerStore()

const props = defineProps({
    flexTable: {
        type: Boolean,
        default: false,
    },
})

const updater = ref(0)
const loading = ref(true)
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const target = ref(null)
const targetEntityPk = ref(null)
const productAlignmentId = ref(null)
const productAlignment = ref(null)
const allLicensingOpportunities = ref([])
const selectedLicType = ref(null)
const tableData = ref([])
const colDefs = ref([])
const searchTerm = ref(null)
let interval = ref(0)
const selectedRow = ref(null)
const rowSelection = {
    checkboxes: true,
    mode: 'singleRow',
    enableClickSelection: true,
}
const selectionColumnDef = {
    pinned: 'left',
    lockPosition: true,
    width: 40,
}

const baseColDefs = ref([
    {
        field: 'licenseeRank',
        headerName: 'Rank',
        width: 100,
        minWidth: 100,
        pinned: 'left',
        lockPosition: true,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: 'Company Name',
            showFilterButton: true,
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        field: 'entityName',
        headerName: 'Company Name',
        headerTooltip: 'Company Name',
        minWidth: 300,
        flex: 1,
        sortingOrder: ['asc', 'desc', null],
        filter: 'agTextColumnFilter',
        pinned: 'left',
        lockPosition: true,
        chartDataType: 'category',
    },
    {
        field: 'displayName',
        headerName: 'Product',
        filter: 'agSetColumnFilter',
        flex: 3,
        minWidth: 250,
    },
])

const litigationColDefs = ref([
    {
        field: 'nodeRiskScore',
        headerName: 'Risk Score',
        minWidth: 130,
        width: 130,
    },
    {
        field: 'nodeCaseCount',
        headerName: 'Case Count',
        filter: 'agNumberColumnFilter',
        minWidth: 160,
        width: 160,
    },
    {
        field: 'innovationScore',
        headerName: 'Innovation Score',
        filter: 'agNumberColumnFilter',
        flex: 2,
        minWidth: 200,
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.innovationScore.toFixed(0)).toLocaleString()}`
            }
        },
    },
    {
        field: 'innovationScoreRequirement',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t(`licensingOpportunities.peer_innovation_benchmark`),
            headerName: 'Peer Innovation Benchmark',
        },
        filter: 'agNumberColumnFilter',
        flex: 2,
        minWidth: 250,
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.innovationScoreRequirement.toFixed(0)).toLocaleString()}`
            }
        },
    },
])

const emergingContenderColDefs = ref([
    {
        field: 'portfolioFocusPercent',
        headerName: 'Focus',
        filter: 'agNumberColumnFilter',
        minWidth: 120,
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.portfolioFocusPercent.toFixed(0)}%`
            }
        },
    },
    {
        field: 'opportunity',
        headerName: 'Momentum',
        filter: 'agNumberColumnFilter',
        valueFormatter: (p) => {
            if (p.data) {
                return Math.round(p.data.opportunity)
            }
        },
    },
    {
        field: 'innovationScore',
        headerName: 'Innovation Score',
        filter: 'agNumberColumnFilter',
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.innovationScore.toFixed(0)).toLocaleString()}`
            }
        },
    },
])

const startupColDefs = ref([
    {
        field: 'portfolioFocusPercent',
        headerName: 'Focus',
        filter: 'agNumberColumnFilter',
        minWidth: 120,
        width: 120,
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.portfolioFocusPercent.toFixed(0)}%`
            }
        },
    },
    {
        field: 'opportunity',
        headerName: 'Momentum',
        filter: 'agNumberColumnFilter',
        minWidth: 160,
        width: 160,
        valueFormatter: (p) => {
            if (p.data) {
                return Math.round(p.data.opportunity)
            }
        },
    },
    {
        field: 'innovationScore',
        headerName: 'Innovation Score',
        filter: 'agNumberColumnFilter',
        minWidth: 200,
        width: 200,
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.innovationScore.toFixed(0)).toLocaleString()}`
            }
        },
    },
    {
        field: 'ventureCapitalInvestmentAmount',
        headerName: 'Venture Capital Investment',
        filter: 'agNumberColumnFilter',
        minWidth: 275,
        valueFormatter: (p) => {
            if (p.data) {
                return `$${parseInt(p.data.ventureCapitalInvestmentAmount.toFixed(0)).toLocaleString()}`
            }
        },
    },
    {
        field: 'lastFundingOn',
        headerName: 'Last Funding Date',
        filter: 'agDateColumnFilter',
        minWidth: 200,
        valueGetter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.lastFundingOn)
            }
        },
    },
    {
        field: 'lastFundingRoundInvestmentType',
        headerName: 'Last Funding Investment Type',
        filter: 'agSetColumnFilter',
        minWidth: 300,
        valueGetter: (p) => {
            if (p.data) {
                return getInvestmentGroup(p.data.lastFundingRoundInvestmentType)
            }
        },
    },
])

const expiringColDefs = ref([
    {
        field: 'portfolioFocusPercent',
        headerName: 'Focus',
        filter: 'agNumberColumnFilter',
        minWidth: 120,
        width: 120,
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.portfolioFocusPercent.toFixed(0)}%`
            }
        },
    },
    {
        field: 'innovationScore',
        headerName: 'Innovation Score',
        filter: 'agNumberColumnFilter',
        minWidth: 200,
        width: 200,
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.innovationScore.toFixed(0)).toLocaleString()}`
            }
        },
    },
    {
        field: 'fiveYearExpirationPercent',
        headerName: '5 Year Expiration %',
        filter: 'agNumberColumnFilter',
        minWidth: 250,
        valueGetter: (p) => {
            if (p.data) {
                return `${p.data.fiveYearExpirationPercent.toFixed(0)}%`
            }
        },
    },
])

const licOpTypes = {
    litigation_risk: {
        label: 'Litigation Risks',
        colDefs: litigationColDefs,
    },
    emerging_competitor: {
        label: 'Emerging Competitors',
        colDefs: emergingContenderColDefs,
    },
    startup: {
        label: 'Startup Opportunities',
        colDefs: startupColDefs,
    },
    expiring_portfolio: {
        label: 'Expiring Portfolios',
        colDefs: expiringColDefs,
    },
}

const pollLicensingOpportunities = async () => {
    loading.value = true
    try {
        let { data } = await getEntityLicensingOpportunitiesAsync(targetEntityPk.value)

        switch (data.status) {
            case 'requested':
                break
            case 'processing':
                break
            case 'complete':
                clearInterval(interval)
                allLicensingOpportunities.value = data.results
                selectLicensingOpType('litigation_risk')

                loading.value = false
                break
            case 'error':
                clearInterval(interval)
                loading.value = false
                break
            default:
                break
        }
    } catch (err) {
        clearInterval(interval)
        loading.value = false
    }
}

onActivated(async () => {
    selectLicensingOpType('litigation_risk')
})

watch(
    () => playbookGenerationStore.playbookSelections[1],
    async (newValue) => {
        target.value = newValue.selectionLabel
        targetEntityPk.value = parseInt(newValue.value)

        await pollLicensingOpportunities()
        if (allLicensingOpportunities.value.length === 0 && loading.value) {
            interval = setInterval(async () => {
                pollLicensingOpportunities()
            }, 5000)
        }
    },
    { immediate: true }
)

onBeforeUnmount(() => {
    clearInterval(interval)
})

watch(
    () => playbookGenerationStore.targetCompanyProductAlignmentId,
    (newValue) => {
        productAlignmentId.value = newValue
    },
    { immediate: true }
)

watch(
    () => playbookGenerationStore.targetCompanyProductAlignment,
    (newValue) => {
        productAlignment.value = newValue
    },
    { immediate: true }
)

const licDescription = computed(() => {
    if (!selectedLicType.value) {
        return null
    }
    return t(`licensingOpportunities.${selectedLicType.value}.description`)
})

const licTitle = computed(() => {
    if (!selectedLicType.value) {
        return null
    }
    return t(`licensingOpportunities.${selectedLicType.value}.title`)
})

const gridInit = (params) => {
    gridApi.value = params.api

    // Prevent row deselection
    params.api.addEventListener('rowSelected', (event) => {
        if (!params.api.getSelectedRows().length) {
            event.node.setSelected(true)
        }
    })

    // Select first row when data changes
    params.api.addEventListener('modelUpdated', () => {
        const firstRow = params.api.getDisplayedRowAtIndex(0)
        if (firstRow && !params.api.getSelectedRows().length) {
            firstRow.setSelected(true)
        }
    })
}

const onSelectionChanged = () => {
    const selectedNodes = gridApi.value.getSelectedNodes()
    selectedRow.value = selectedNodes.length > 0 ? selectedNodes[0].data : null
}

const pushContextMenuToTable = (params) => {
    if (isProUser.value) {
        colDefs.value.push({
            suppressHeaderMenuButton: true,
            cellRenderer: 'MTContextMenu',
            cellRendererParams: {
                actionsList: [
                    {
                        name: `View ${target.value} Patents`,
                        icon: 'fa-square-list',
                        actionName: 'patents',
                        patentsAction: async (params) => {
                            patentViewerStore.nodeIdList = [params.data.nodeId]
                            patentViewerStore.technologyName = params.data.displayName
                            patentViewerStore.entityPkList = [targetEntityPk.value]
                            patentViewerStore.entityName = target.value
                            await nextTick()
                            patentViewerStore.showPatentViewerList = true
                        },
                    },
                    {
                        name: 'View Contender Patents',
                        icon: 'fa-square-list',
                        actionName: 'contenderPatents',
                        contenderPatentsAction: async (params) => {
                            patentViewerStore.nodeIdList = [params.data.nodeId]
                            patentViewerStore.technologyName = params.data.displayName
                            patentViewerStore.entityPkList = [params.data.entityPk]
                            patentViewerStore.entityName = params.data.entityName
                            await nextTick()
                            patentViewerStore.showPatentViewerList = true
                        },
                    },
                ],
                highlightLabel: true,
            },
            pinned: 'right',
            width: 42,
        })
    }
}

const selectLicensingOpType = (licOpType) => {
    selectedRow.value = null
    selectedLicType.value = licOpType
    colDefs.value = baseColDefs.value.concat(licOpTypes[licOpType].colDefs.value)
    tableData.value = allLicensingOpportunities.value.filter(
        (item) => item.licenseeReason === licOpType
    )
    pushContextMenuToTable()
    // Ensure a row is selected after data changes
    nextTick(() => {
        if (gridApi.value && !gridApi.value.getSelectedRows().length) {
            const firstRow = gridApi.value.getDisplayedRowAtIndex(0)
            if (firstRow) {
                firstRow.setSelected(true)
            }
        }
    })
}

const gotoStep = (allowEdit = true, stepNum) => {
    if (!allowEdit) {
        return
    }
    playbookGenerationStore.setCurrentStep(stepNum)
}

const searchTable = () => {
    gridApi.value.setGridOption('quickFilterText', searchTerm.value)
}

const clearSearch = () => {
    searchTerm.value = ''
    searchTable()
}
</script>

<style lang="scss" scoped>
.licensing-opportunities-output {
    height: 100%;
    width: 100%;

    .table-border {
        border: 1px solid vars.$grey04;
        border-radius: 8px;
    }

    .expand-collapse-button {
        cursor: pointer;
        border: 1px solid vars.$grey01;
        border-radius: 4px;

        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: vars.$grey01;

            svg {
                color: white !important;
            }
        }
    }

    .target-button {
        border-left: 2px solid vars.$grey01;
        border-right: 2px solid vars.$grey01;
    }

    :deep(.comp-header) {
        background-color: vars.$grey05 !important;
    }
}
</style>
