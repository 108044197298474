<template>
    <div class="patent-list-tab flex-column-grow pt-4">
        <AonContainer class="flex-column-grow">
            <AonRow class="flex-column-grow">
                <AonSpinner
                    v-if="entityStore.loadingEntity || !entityStore.entity"
                    class="ma-auto"
                    :scale="0.5"
                    color="castleMoat"
                />
                <div v-else class="grid-holder w-full flex-column-grow">
                    <h5 class="flex-column-grow">Patent List</h5>
                    <p class="mb-4 mt-1 flex-column-grow">
                        Explore all patents associated with {{ entityStore.entity.name }}. Use the
                        available filters to refine the list and export as needed.
                    </p>
                    <AonCol class="aon-col-12 pa-0 flex-column-grow">
                        <PatentList
                            inline
                            entity-only
                            :entity-pk="parseInt(entityStore.entity.aon_entity_pk)"
                        />
                    </AonCol>
                </div>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'
import { useEntityStore } from '@/stores'

import PatentList from '@/components/patentViewer/PatentList.vue'

const entityStore = useEntityStore()
</script>
<style lang="scss" scoped>
.patent-list-tab {
    width: 100%;
    height: calc(100% - 150px);
}

.flex-column-grow {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
</style>
