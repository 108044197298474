<template>
    <div class="strategy-tab">
        <AonCoverLoading
            :loading="moat2ProductStore.productAlignmentLoading"
            :title="`Please hang tight!`"
            :message="loadingMessage"
        >
            <template #footer>
                <progress
                    v-if="moat2ProductStore.percentComplete !== 0"
                    :value="moat2ProductStore.percentComplete"
                    max="100"
                    class="mt-4 progress-bar"
                />
            </template>
        </AonCoverLoading>
        <AonContainer
            v-if="
                !moat2ProductStore.productAlignmentLoading &&
                !moat2ProductStore.productAlignmentError
            "
        >
            <AonRow class="mt-4">
                <AonSpinner
                    v-if="entityStore.loadingEntity || !entityStore.entity"
                    class="ma-auto"
                    :scale="0.5"
                    color="castleMoat"
                />
                <div v-else class="w-full">
                    <AonCol class="aon-col-12 pl-0 py-0">
                        <AonTabs
                            class="light"
                            :tabs="highLevelTabs"
                            :current-tab="highLevelCurrentTabId"
                            bottom-border
                            @tab-change="onHighLevelTabClicked"
                        />
                        <MinorTabs
                            v-if="highLevelCurrentTabId === 1"
                            class="mt-4"
                            :tabs="nodeFocusTabs"
                            :current-minor-tab="nodeFilterCurrentTabId"
                            @tab-clicked="onMinorTabClicked"
                        />
                    </AonCol>
                    <KeepAlive>
                        <div v-if="highLevelCurrentTabId === 1">
                            <AonCol class="aon-col-12 pa-0 mt-4">
                                <KeepAlive>
                                    <InnovationPath
                                        v-if="nodeFilterCurrentTabId === 1"
                                        :update-watch="updater"
                                    />
                                </KeepAlive>
                                <KeepAlive>
                                    <InnovationPath
                                        v-if="nodeFilterCurrentTabId === 2"
                                        key="technology"
                                        :update-watch="updater"
                                    />
                                </KeepAlive>
                                <AonCard
                                    class="my-4"
                                    title="Innovation Over Time"
                                    :desc="innovationOverTimeDesc"
                                >
                                    <InnovationOverTime
                                        tab="strategy"
                                        :key="`iot_${entityStore.strategyTabNodeFilter}`"
                                        :node-type="entityStore.strategyTabNodeFilter"
                                    />
                                </AonCard>
                            </AonCol>
                        </div>
                    </KeepAlive>
                    <KeepAlive>
                        <div v-if="highLevelCurrentTabId === 2">
                            <KeepAlive>
                                <AonCol class="aon-col-12 pa-0 mt-4">
                                    <TalentPath key="overview" />
                                </AonCol>
                            </KeepAlive>
                        </div>
                    </KeepAlive>
                </div>
            </AonRow>
        </AonContainer>
        <ErrorWithProductAlignment
            @get-product-alignment="onGetProductSegmentationData"
            @get-previous-run="onGetPreviousRunOrRequestNew(true)"
        />
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useEntityStore, useMoat2ProductStore } from '@/stores'

import InnovationPath from './InnovationPath.vue'
import InnovationOverTime from '@/components/alignmentOutput/techAreas/InnovationOverTime.vue'
import TalentPath from './TalentPath.vue'
import MinorTabs from '@/components/helpers/MinorTabs.vue'

import ErrorWithProductAlignment from '@/components/helpers/ErrorWithProductAlignment.vue'

const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()

const highLevelTabs = ref([
    {
        text: 'Innovation',
        id: 1,
    },
    {
        text: 'Talent',
        id: 2,
    },
])
const nodeFocusTabs = ref([
    {
        text: 'Products',
        id: 1,
    },
    {
        text: 'Technology',
        id: 2,
    },
])
const updater = ref(0)

const highLevelCurrentTabId = computed(() => {
    return entityStore.strategyTabHighLevel === 'innovation' ? 1 : 2
})

const nodeFilterCurrentTabId = computed(() => {
    return entityStore.strategyTabNodeFilter === 'product' ? 1 : 2
})

const loadingMessage = computed(() => {
    if (moat2ProductStore.needsNewAlignment) {
        return `We are aligning patents to the product and technology areas for ${entityStore.entity?.name}. This process will take 5-10 minutes if not cached in our system. Please feel free to check out the other tabs and this process will continue in the background.`
    } else {
        return `Loading Moat2Product for ${entityStore.entity?.name}`
    }
})

const innovationOverTimeDesc = computed(() => {
    return entityStore.strategyTabNodeFilter === 'product'
        ? `Visualize how ${entityStore.entity.name} Product investment strategy has evolved over time.`
        : `Visualize how ${entityStore.entity.name} Product and Technology investment strategies has evolved over time.`
})

const onHighLevelTabClicked = (tab) => {
    if (tab.id === 1) {
        entityStore.strategyTabHighLevel = 'innovation'
    } else if (tab.id === 2) {
        entityStore.strategyTabHighLevel = 'talent'
    }
}

const onMinorTabClicked = (tabId) => {
    if (tabId === 1) {
        entityStore.strategyTabNodeFilter = 'product'
    } else if (tabId === 2) {
        entityStore.strategyTabNodeFilter = 'technology'
    }
    // updater.value++
}

const onGetPreviousRunOrRequestNew = async (getPreviousRun = false) => {
    await moat2ProductStore.getPreviousRunOrRequestNew(getPreviousRun)
}

const onGetProductSegmentationData = async (alignmentPk) => {
    await moat2ProductStore.getProductSegmentationData(alignmentPk)
}
</script>

<style lang="scss" scoped>
.strategy-tab {
    position: relative;
    .light {
        border-color: vars.$grey04 !important;
    }
}
</style>
