import axios from 'axios'

const baseUrl = 'api/entities'
const prodAlignmentUrl = 'api/productalignment'

export function getLitigationHistorySummary(entityId) {
    return axios.get(`${baseUrl}/${entityId}/litigation/summary `)
}

export function getLitigationRisk(alignmentPk) {
    return axios.get(`${prodAlignmentUrl}/${alignmentPk}/litigation_risk`)
}

export function getLitigationRiskGaps(alignmentPk) {
    return axios.get(`${prodAlignmentUrl}/${alignmentPk}/litigation_risk?limitToInnovationOps=true`)
}
