<template>
    <div class="focus-chart">
        <div
            :id="`chartHook_${props.chartType}_${entityStore.strategyTabNodeFilter}`"
            class="w-full"
        ></div>
    </div>
</template>

<script setup>
import { ref, inject, onMounted, onBeforeUnmount, computed, watch } from 'vue'
import { useEntityStore } from '@/stores'
import { focusChart } from '@/composables/focusChart.js'

const entityStore = useEntityStore()

const props = defineProps({
    chartType: {
        type: String,
        default: 'treemap',
    },
    chartData: {
        type: Array,
        default: () => [],
    },
    chartOverwriteData: {
        type: Array,
        default: () => [],
    },
})

watch(
    () => props.chartOverwriteData,
    () => {
        mapDataForChart(targetDataSource.value, true)
    }
)

const targetDataSource = computed(() => {
    return props.chartOverwriteData.length > 0 ? props.chartOverwriteData : props.chartData
})

const { mappedData, mapDataForChart } = focusChart(props)
</script>

<style lang="scss" scoped>
.focus-chart {
    [id^='chartHook_'] {
        // id that starts with chartHook_
        width: 100%;
        min-height: 400px;

        div {
            height: 100%;
        }
    }
}
</style>
