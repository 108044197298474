<template>
    <div class="create-playbook-page">
        <PlaybookGenerationContainer />
    </div>
</template>

<script setup>
import PlaybookGenerationContainer from '@/components/playbooks/PlaybookGenerationContainer.vue'
import { onUnmounted } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'

const playbookGenerationStore = usePlaybookGenerationStore()

onUnmounted(() => {
    playbookGenerationStore.resetState()
})
</script>

<style lang="scss" scoped>
.create-playbook-page {
    width: 100%;
}
</style>
