<template>
    <div class="yearly-expiration-date-comparison">
        <AonCoverLoading :loading="loading" />
        <div class="chart">
            <div
                v-if="
                    props.dataReady && (!props.licensorData?.length || !props.licenseeData?.length)
                "
                class="no-data"
            >
                <p>No data available</p>
            </div>
            <div
                v-show="props.dataReady && props.licensorData?.length && props.licenseeData?.length"
                ref="chartRef"
                class="chart"
            ></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, onBeforeUnmount } from 'vue'
import * as am5 from '@amcharts/amcharts5'
import {
    XYChart,
    LineSeries,
    CategoryAxis,
    ValueAxis,
    AxisRendererX,
    AxisRendererY,
    XYCursor,
} from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

export interface YearlyExpirationCount {
    year: number
    count: number
}

interface CombinedData {
    year: number
    licensorCount: number
    licenseeCount: number
}

const props = defineProps({
    licensorData: {
        type: Array as () => YearlyExpirationCount[],
    },
    licenseeData: {
        type: Array as () => YearlyExpirationCount[],
    },
    licensorName: {
        type: String,
    },
    licenseeName: {
        type: String,
    },
    dataReady: {
        type: Boolean,
    },
})

const chartRef = ref(null)
const loading = ref(true)
let root: am5.Root | null = null
let chart: XYChart | null = null
let series: LineSeries[] = []
let xAxis: CategoryAxis | null = null
let yAxis: ValueAxis | null = null
let legend: am5.Legend | null = null

const makeSeries = (name: string, valueField: string) => {
    if (!root || !chart) return null

    const newSeries = chart.series.push(
        LineSeries.new(root, {
            name: name,
            xAxis: xAxis!,
            yAxis: yAxis!,
            valueYField: valueField,
            categoryXField: 'year',
            tooltip: am5.Tooltip.new(root, {
                labelText: '{name}: {valueY}',
            }),
        })
    )

    newSeries.bullets.push(function () {
        return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
                radius: 4,
                fill: newSeries.get('fill'),
                stroke: newSeries.get('stroke'),
                strokeWidth: 2,
            }),
        })
    })

    newSeries.strokes.template.setAll({
        strokeWidth: 2,
    })

    newSeries.adapters.add('name', (text) => {
        if (text === 'Licensor') {
            return props.licensorName
        } else if (text === 'Licensee') {
            return props.licenseeName
        }
        return text
    })

    series.push(newSeries)
    return newSeries
}

const createChart = () => {
    loading.value = true
    if (!chartRef.value) return

    // Create root element
    root = am5.Root.new(chartRef.value)
    root.setThemes([am5themes_Animated.new(root)])
    root.numberFormatter.setAll({
        numberFormat: '#.',
    })

    // Create chart
    chart = root.container.children.push(
        XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none',
            layout: root.verticalLayout,
            cursor: XYCursor.new(root, {
                behavior: 'none',
                xAxis: xAxis,
                yAxis: yAxis,
            }),
        })
    )

    // Create axes
    xAxis = chart.xAxes.push(
        CategoryAxis.new(root, {
            categoryField: 'year',
            renderer: AxisRendererX.new(root, {
                minGridDistance: 30,
                fontSize: 13,
                visible: true,
                paddingTop: 20,
            }),
        })
    )

    yAxis = chart.yAxes.push(
        ValueAxis.new(root, {
            renderer: AxisRendererY.new(root, {}),
        })
    )

    // Create series
    makeSeries('Licensor', 'licensorCount')
    makeSeries('Licensee', 'licenseeCount')

    // Add legend
    legend = chart.children.push(
        am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
        })
    )

    updateChartData()
}

const updateChartData = () => {
    loading.value = true
    if (!props.licensorData || !props.licenseeData) {
        if (props.dataReady) {
            loading.value = false
        }
        return
    }

    // Process and combine data
    const combinedData =
        props.licensorData
            ?.map((licensorItem) => {
                const licenseeItem = props.licenseeData?.find(
                    (item) => item.year === licensorItem.year
                )
                return {
                    year: licensorItem.year,
                    licensorCount: licensorItem.count,
                    licenseeCount: licenseeItem ? licenseeItem.count : 0,
                }
            })
            ?.sort((a, b) => a.year - b.year) ?? []

    // Filter out trailing years where both counts are 0
    let lastNonZeroIndex = combinedData.length - 1
    for (let i = combinedData.length - 1; i >= 0; i--) {
        if (combinedData[i].licensorCount > 0 || combinedData[i].licenseeCount > 0) {
            lastNonZeroIndex = i
            break
        }
    }
    const filteredData = combinedData.slice(0, lastNonZeroIndex + 1)

    // Update chart data
    if (series.length > 0 && xAxis && legend) {
        series.forEach((s) => {
            s.data.setAll(filteredData)
        })
        xAxis.data.setAll(filteredData)
        legend.data.setAll(chart.series.values)
    }
    loading.value = false
}

watch(
    () => [props.licensorData, props.licenseeData, props.dataReady],
    () => {
        updateChartData()
    },
    { deep: true }
)

onMounted(() => {
    createChart()
    updateChartData()
})

onBeforeUnmount(() => {
    if (root) {
        root.dispose()
    }
})
</script>

<style scoped>
.yearly-expiration-date-comparison {
    width: 100%;
    height: 100%;
    position: relative;
}

.chart {
    width: 100%;
    height: 300px;
}

.no-data {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>
