<template>
    <font-awesome-icon
        icon="fas fa-swords"
        class="align-items-center"
        @click="handleClick"
        style="cursor: pointer"
    />
</template>

<script setup>
import { inject } from 'vue'

const eventBus = inject('eventBus')
const emit = defineEmits(['setup-arena'])
const props = defineProps({
    params: Object,
})

const handleClick = () => {
    eventBus.emit('setup-arena', props.params)
}
</script>
