import axios from 'axios'

const baseUrl = 'api/ipnode'
const baseUrlEntity = 'api/entities'
const baseUrlInventor = 'api/inventor'

let patentDetailsController = null

export function getPatentList(nodeParams, tableParams) {
    return axios.post(`${baseUrl}/patents`, nodeParams, {
        params: tableParams,
    })
}

export function getPatentListIPBasics(entityPK, basicParams, tableParams) {
    console.log(entityPK, basicParams, tableParams)
    return axios.post(`${baseUrlEntity}/${entityPK}/patents`, basicParams, {
        params: tableParams,
    })
}

export function getLitPatentList(nodePk, tableParams) {
    return axios.get(`${baseUrl}/${nodePk}/litigation/patents`, {
        params: tableParams,
    })
}

export function getPatentDetails(patentId) {
    if (patentDetailsController) {
        patentDetailsController.abort()
    }

    if (patentId === null) {
        return
    }

    patentDetailsController = new AbortController()

    return axios.get(`api/patents/${patentId}`, {
        signal: patentDetailsController.signal,
    })
}

export function getPatentListInventor(params, tableParams) {
    let url = `${baseUrlInventor}/${params.inventorPk}`
    if (params.entityPk) {
        url += `/entity/${params.entityPk}`
    }
    url += '/patents'
    return axios.get(url, {
        params: tableParams,
    })
}
