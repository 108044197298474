<template>
    <div class="context-menu d-flex justify-content-center mt">
        <VDropdown
            class="ml-2"
            placement="left"
            :distance="8"
            :triggers="['click']"
            :popper-triggers="['click']"
        >
            <div class="cta-holder d-flex justify-content-center align-items-center">
                <font-awesome-icon :icon="`fas fa-ellipsis-vertical`" />
            </div>
            <template #popper>
                <div class="popover-container pa-2">
                    <div
                        v-for="(item, index) in params.actionsList"
                        class="context-action"
                        @click="onActionClick(`${item?.actionName}`)"
                    >
                        <div v-if="item?.name" class="action-inner pa-2 d-flex align-items-center">
                            <font-awesome-icon :icon="`fas ${item?.icon}`" />
                            <p class="small ml-2">{{ item?.name }}</p>
                        </div>
                    </div>
                </div>
            </template>
        </VDropdown>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
})

const onActionClick = (actionName) => {
    const actionTarget = props.params.actionsList.find((action) => action.actionName === actionName)
    actionTarget[`${actionName}Action`](props.params)
}
</script>

<style lang="scss">
.context-menu {
    margin-top: 5px;
    margin-left: -6px;
}

.context-action {
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;

    p,
    svg {
        color: vars.$grey01;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        background-color: vars.$grey03;

        p,
        svg {
            color: white;
        }
    }
}

.cta-holder {
    background: white;
    width: 28px;
    height: 28px;
    border-radius: 50%;

    cursor: pointer;

    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: vars.$grey05;
    }
}
</style>
