<template>
    <div class="product-and-tech-focus w-full">
        <AonCard>
            <AonTabs
                class="light mb-4"
                :tabs="highLevelTabs"
                :current-tab="focusCurrentTabId"
                bottom-border
                @tab-change="onTabClicked"
            />
            <AonCoverLoading :loading="loadingStrategy" title="Fetching Strategy Data" />
            <div v-if="!loadingStrategy" class="tab-wrap">
                <div v-if="focusCurrentTabId === 1">
                    <OverviewFocus
                        :update-key="props.updateWatch"
                        :data="filteredData"
                        :data-by-year="fetchedDataByYear"
                        :all-data="fetchedDataProductsTechs"
                    />
                </div>
                <div v-if="focusCurrentTabId === 2">
                    <FilingsFocus :update-key="props.updateWatch" :data="filteredData" />
                </div>
                <div v-if="focusCurrentTabId === 3">
                    <PatentsFocus :update-key="props.updateWatch" :data="filteredData" />
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, inject, onMounted, computed, watch } from 'vue'
import { getEntityProductStrategy, getEntityProductStrategyYearly } from '@/api/entities.js'
import { useEntityStore } from '@/stores'
import { cloneDeep } from 'lodash-es'

import OverviewFocus from './innovationTabs/OverviewFocus.vue'
import FilingsFocus from './innovationTabs/FilingsFocus.vue'
import PatentsFocus from './innovationTabs/PatentsFocus.vue'

const entityStore = useEntityStore()

const logger = inject('logger')

const props = defineProps({
    updateWatch: {
        type: Number,
        default: 0,
    },
})

const loadingStrategy = ref(true)
const loadingStrategyByYear = ref(true)
const fetchedData = ref([])
const fetchedDataProductsTechs = ref([])
const fetchedDataByYear = ref([])
const highLevelTabs = ref([
    {
        text: 'Overview',
        value: 'overview',
        id: 1,
    },
    {
        text: 'Filings',
        value: 'filings',
        id: 2,
    },
    {
        text: 'Patents',
        value: 'patents',
        id: 3,
    },
])

onMounted(() => {
    onGetStrategy()
    onGetStrategyByYear()
})

const focusCurrentTabId = computed(() => {
    let focusTab = entityStore.strategyTabFilterFocus
    if (focusTab === 'overview') {
        return 1
    } else if (focusTab === 'filings') {
        return 2
    } else if (focusTab === 'patents') {
        return 3
    } else {
        return 1
    }
})

const nodeTypeToFilterOn = computed(() => {
    return entityStore.strategyTabNodeFilter === 'product' ? 'product' : 'concept'
})

const filteredData = computed(() => {
    return fetchedData.value.filter(
        (item) => item.ipNodeType === nodeTypeToFilterOn.value && item.patentCount > 0
    )
})

watch(
    () => nodeTypeToFilterOn.value,
    () => {
        onGetStrategyByYear()
    }
)

const onGetStrategy = async () => {
    loadingStrategy.value = true
    try {
        const { data } = await getEntityProductStrategy(entityStore.entity.aon_entity_pk)
        fetchedData.value = cloneDeep(data)
        fetchedDataProductsTechs.value = cloneDeep(
            data.filter((item) => item.ipNodeType === 'product' || item.ipNodeType === 'concept')
        )
    } catch (error) {
        logger.error(error)
    } finally {
        loadingStrategy.value = false
    }
}

const onGetStrategyByYear = async () => {
    loadingStrategyByYear.value = true
    try {
        const { data } = await getEntityProductStrategyYearly(
            entityStore.entity.aon_entity_pk,
            null,
            { ipNodeType: nodeTypeToFilterOn.value }
        )
        fetchedDataByYear.value = data
    } catch (error) {
        logger.error(error)
    } finally {
        loadingStrategyByYear.value = false
    }
}

const onTabClicked = (tab) => {
    entityStore.strategyTabFilterFocus = tab.value
}
</script>

<style lang="scss" scoped>
.product-and-tech-focus {
    width: 100%;
    min-height: 700px;
    position: relative;

    .tab-wrap {
        min-height: 700px;
    }
}
</style>
