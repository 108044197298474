<template>
    <div class="product-class-peer-table d-flex flex-column h-full">
        <MoatTable
            class="flex-grow-1"
            style="width: 100%"
            :class="themeClass"
            :column-defs="colDefs"
            :row-data="peerData"
            :tooltip-interaction="true"
            :tooltip-mouse-track="true"
            :tooltip-show-delay="500"
            tooltip-show-mode="whenTruncated"
            :autoSizeStrategy="sizingStrategy"
            :getRowClass="getRowClass"
            :sorting-order="['desc', 'asc', null]"
            :selection-column-def="selectionColumnDef"
            @grid-ready="gridInit"
            @row-selected="selectRow"
        />
    </div>
</template>

<script setup>
import { ref, inject, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useEntityStore, useArenaOutputStore } from '@/stores'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { formatTpeRange } from '@/lib/financial.js'
// import OutputCompetitorTechBreakdownModal from '@/components/alignmentOutput/benchmarkPeers/OutputCompetitorTechBreakdownModal.vue'

const eventBus = inject('eventBus')
const { t } = useI18n()
const entityStore = useEntityStore()
const arenaOutputStore = useArenaOutputStore()

const emit = defineEmits(['table-event'])
const props = defineProps({
    peerData: {
        type: Array,
        default: () => [],
    },
})

const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref({})
const selectionColumnDef = ref({
    suppressHeaderMenuButton: true,
    maxWidth: 40,
})
const selectedRows = ref([])
const colDefs = ref([
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.companyName'),
            headerName: 'Company Name',
            showFilterButton: true,
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        field: 'aonEntityName',
        headerName: 'Company Name',
        headerTooltip: 'Company Name',
        minWidth: 400,
        flex: 1,
        sortingOrder: ['asc', 'desc', null],
        // filter: 'agTextColumnFilter',
        // pinned: 'left',
        // lockPosition: true,
        // chartDataType: 'category',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innovationScore'),
            headerName: 'Innovation Score',
        },
        field: 'powerScore',
        headerName: 'Innovation Score',
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.powerScore.toFixed(0)).toLocaleString()}`
            }
        },
        headerTooltip: 'Innovation Score',
        minWidth: 200,
        flex: 1,
        // filter: 'agNumberColumnFilter',
        // chartDataType: 'series',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
            headerName: 'Momentum',
        },
        cellRenderer: 'MTCustomCellScoreChange',
        cellRendererParams: {
            lowerBound: 40,
            upperBound: 50,
            tooltip: {
                increasing: 'Increasing Innovation',
                decreasing: 'Decreasing Innovation',
                steady: 'Steady Innovation',
            },
        },
        field: 'opportunity',
        headerName: 'Momentum',
        headerTooltip: 'Momentum',
        flex: 1,
        minWidth: 150,
        valueFormatter: (p) => {
            if (p.data) {
                return `${Math.round(p.data.opportunity)}`
            }
        },
        // filter: 'agNumberColumnFilter',
        // chartDataType: 'series',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.percentOfPortfolio'),
            headerName: 'Focus',
        },
        field: 'portfolioFocusPercentage',
        headerName: 'Focus',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.portfolioFocusPercentage.toFixed(0)}%`
            }
        },
        headerTooltip: 'Focus',
        flex: 1,
        minWidth: 120,
        // filter: 'agNumberColumnFilter',
        // chartDataType: 'series',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
            headerName: 'Market Appeal',
        },
        field: 'tpe',
        headerName: 'Market Appeal',
        headerTooltip: 'Market Appeal',
        flex: 1,
        minWidth: 120,
        valueFormatter: (p) => {
            if (p.data) {
                return formatTpeRange(p.data.tpe, p.data.tpeLb, p.data.tpeUb)
            }
        },
        // filter: 'agNumberColumnFilter',
        // chartDataType: 'series',
    },
])

const gridInit = (params) => {
    gridApi.value = params.api

    gridApi.value.addGlobalListener(function (type, event) {
        if (type === 'cellMouseDown' || type === 'sortChanged') {
            emit('table-event', { type, event })
        }
    })
}

const getRowClass = (params) => {
    if (entityStore.entity && params.data.aonEntityName === entityStore.entity.name) {
        return 'target-entity'
    }
    if (
        arenaOutputStore.targetCompany.length > 0 &&
        arenaOutputStore.targetCompany[0].aonEntityName === params.data.aonEntityName
    ) {
        return 'target-entity'
    } else {
        return ''
    }
}

const selectRow = () => {
    eventBus.emit('row-selected')
    selectedRows.value = gridApi.value.getSelectedRows()
    if (selectedRows.value.length >= 1) {
        gridApi.value.openToolPanel('actions')
    } else {
        gridApi.value.closeToolPanel()
    }

    gridApi.value.refreshToolPanel()
}
</script>

<style scoped lang="scss">
.product-class-peer-table {
    width: 100%;

    :deep(.target-entity) {
        background: vars.$grey07 !important;
        *:not:deep(.ticker) {
            color: white !important;
        }
    }
}
</style>
