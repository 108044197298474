<template>
    <div
        id="context-target-slide-out"
        class="arena-board-slide-out elevation-5"
        :class="{ open: selectedNode }"
    >
        <div class="overflow-wrapper d-flex flex-column">
            <div class="top-container">
                <div class="selected-holder">
                    <ArenaBoardNodeAssignment :key="assignmentUpdater" />
                </div>
                <div class="add-concept-holder border">
                    <ConceptInput
                        type="arena"
                        alt-layout
                        @add-node-to-arena="addNodeToArena"
                        :key="relatedUpdater"
                    />
                </div>
            </div>
            <div class="related-concepts-holder flex-grow-1">
                <ArenaBoardRelatedConcepts :key="relatedUpdater" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, inject, computed, nextTick } from 'vue'
import { addNode, updateNodes } from '@/api/arena.js'
import { useArenaStore } from '@/stores'
import { useI18n } from 'vue-i18n'

import ConceptInput from '@/components/home/ConceptInput'
import ArenaBoardRelatedConcepts from './ArenaBoardRelatedConcepts'
import ArenaBoardNodeAssignment from './ArenaBoardNodeAssignment'

const arenaStore = useArenaStore()
const { t } = useI18n()
const eventBus = inject('eventBus')
const logger = inject('logger')

const error = ref(null)
const relatedUpdater = ref(0)
const assignmentUpdater = ref(0)

onMounted(async () => {
    await nextTick()
})

watch(
    () => arenaStore.selectedNodeParent,
    (newVal, oldData) => {
        assignmentUpdater.value++
    }
)

watch(
    () => arenaStore.selectedNode,
    (newVal, oldVal) => {
        if (newVal !== oldVal) {
            relatedUpdater.value++
        }
    }
)

const selectedNode = computed(() => {
    return arenaStore.selectedNode
})

const addNodeToArena = async (passed) => {
    arenaStore.arenaBoardLoading = true

    let bodyObj = {
        displayName: passed.data,
        type: 'concept',
        include: true,
        parent: arenaStore.selectedNode.id,
    }

    try {
        let { data } = await addNode(bodyObj)
        eventBus.emit('node-added', data)
        relatedUpdater.value++
    } catch (err) {
        handleError('And error occurred')
        error.value = true
    }
}

const handleError = (err) => {
    logger.error(err)

    eventBus.emit('snacktime', {
        type: 'error',
        message: err,
    })

    error.value = true
}
</script>

<style lang="scss" scoped>
.arena-board-slide-out {
    width: 350px;
    height: 100%;
    background: white;
    border-left: solid 1px vars.$grey04;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    transform: translateX(100%);
    transition: all 0.3s ease-in-out;

    .overflow-wrapper {
        height: 100%;
        overflow: auto;
    }

    &.open {
        transform: translateX(0);
    }

    .top-container {
        .selected-holder {
            padding-bottom: 10px;
            height: 250px;
            position: relative;
        }
    }

    .related-concepts-holder {
        width: 100%;
        height: 100%;
        min-height: 330px;
    }

    .border {
        border-bottom: solid 1px vars.$grey04;
        border-top: solid 1px vars.$grey04;
    }

    .capital {
        text-transform: capitalize;
    }
}
</style>
