<template>
    <div class="competitor-selection-split h-full">
        <div class="competitor-selection-holder d-flex flex-wrap">
            <div class="tables-parent d-flex w-full h-full">
                <div
                    class="selected-competitors-holder d-flex flex-column"
                    :class="{ 'single-selection': props.maxSelectedCompetitors === 1 }"
                >
                    <h6 class="border">{{ headingText }}</h6>
                    <p class="pb-3 border">
                        {{ headingSubText }}
                    </p>
                    <div class="table-quick-filters d-flex pb-5 border">
                        <AonDropdown
                            class="mr-5"
                            label="Filter By"
                            :items="quickFilterOptions"
                            :init-selection="quickFilterOptions[0]"
                            style="width: 300px"
                            @select-option="changeQuickFilterValue"
                        />
                        <AonInput
                            class="pr-5"
                            style="width: 350px"
                            v-model="searchTerm"
                            search-input
                            label="Search Contender Table"
                            placeholder="Search Contender Table"
                            @keyup="searchTable()"
                            @clear="clearSearch()"
                        />
                    </div>
                    <MoatTable
                        class="border pr-5"
                        :class="[themeClass, { 'disabled-checkbox': setDisabledCheckboxes }]"
                        style="width: 100%; height: calc(100% - 40px)"
                        :column-defs="arenaContenderColDef"
                        :row-data="groomedData"
                        :tooltip-interaction="true"
                        :tooltip-mouse-track="true"
                        :tooltip-show-delay="500"
                        :sideBar="sideBar"
                        tooltip-show-mode="whenTruncated"
                        :autoSizeStrategy="sizingStrategy"
                        :suppress-call-focus="true"
                        :row-selection="rowSelection"
                        :is-external-filter-present="shouldApplyQuickFilter"
                        :does-external-filter-pass="applyQuickFilter"
                        @row-selected="addSelection"
                        @grid-ready="gridInit"
                        :getRowId="getRowId"
                    />
                </div>
                <div
                    v-show="props.maxSelectedCompetitors > 1"
                    class="selected-competitors-holder ml-5 selected-offset d-flex flex-column"
                >
                    <h6 class="selected-header">Selected Contenders</h6>
                    <p class="mb-3">
                        {{ selectedTableData.length }} of {{ maxSelectedCompetitors }} Selected
                    </p>
                    <MoatTable
                        style="width: 100%; height: calc(100% - 40px)"
                        :class="themeClass"
                        :column-defs="selectedContenderColDef"
                        :row-data="selectedTableData"
                        :tooltip-interaction="true"
                        :tooltip-mouse-track="true"
                        :tooltip-show-delay="500"
                        :sideBar="sideBar"
                        tooltip-show-mode="whenTruncated"
                        :row-selection="rowSelectionSelected"
                        :autoSizeStrategy="sizingStrategy"
                        :getRowId="getRowId"
                        @grid-ready="gridInitSelected"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// Global
import { onMounted, ref, inject, computed, watch, nextTick, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFlag } from '@unleash/proxy-client-vue'

// local
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator, dateFilterComparator } from '@/components/moatTable/helpers/compare.js'
import { formatTpeRange } from '@/lib/financial.js'
import { getInvestmentGroup } from '@/lib/investments'

// injectors
const { t } = useI18n()
const filters = inject('filters')
const eventBus = inject('eventBus')

const showAcquisitionTargets = useFlag('ipAlpha.showAcquisitionTargets')
const showPotentialBuyers = useFlag('ipAlpha.showPotentialBuyers')

// Refs/Props
const emit = defineEmits(['add-selection', 'delete-selection'])
const props = defineProps({
    headingText: {
        type: String,
        default: '',
    },
    headingSubText: {
        type: String,
        default: '',
    },
    allCompetitorData: {
        type: Array,
        default: () => [],
    },
    selectedTableData: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    activeFilter: {
        type: String,
        default: 'all',
    },
    defaultGroupByUltimateToggle: {
        type: Boolean,
        default: true,
    },
    maxSelectedCompetitors: {
        type: Number,
        default: 12,
    },
    stepNum: {
        type: Number,
        default: 1,
    },
    filterNameList: {
        type: Array,
        default: () => [],
    },
    preSelectedContenders: {
        type: Array,
        default: () => [],
    },
})

const selectedRows = new Set()
const searchTerm = ref(null)
const gridApi = ref(null)
const gridApiSelected = ref(null)
const sideBar = ref(null)
const quickFilterOptions = ref([
    {
        itemText: 'All',
    },
    {
        itemText: 'Public',
    },
    {
        itemText: 'Private',
    },
    {
        itemText: 'Gov/Edu',
    },
])
const quickFilterMappings = ref([
    {
        condition: (val) => val?.find((n) => n.isCompetitor),
        itemText: 'Direct Competitors',
    },
    {
        condition: (val) => showAcquisitionTargets.value && val?.find((n) => n.isAcquisitionTarget),
        itemText: 'Acquisition Target',
    },
    {
        condition: (val) => showPotentialBuyers.value && val?.find((n) => n.isPotentialBuyer),
        itemText: 'Potential Buyer',
    },
])
const rowSelection = ref({
    checkboxes: true,
})
const rowSelectionSelected = ref({
    enableClickSelection: true,
    checkboxes: false,
})
var externalFilterValue = 'All'
const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref({})

const getRowId = (params) => {
    if (params.data) {
        return String(params.data.aonEntityPk)
    } else {
        return String(params.aonEntityPk)
    }
}
const baseColDefs = ref([
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: 'Ultimate Parent Company',
            showFilterButton: true,
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        field: 'ultimateAonEntityName',
        headerName: 'Ultimate Parent Name',
        headerTooltip: 'Ultimate Parent Name',
        minWidth: 300,
        flex: 1,
        sortingOrder: ['asc', 'desc', null],
        filter: 'agTextColumnFilter',
        hide: props.defaultGroupByUltimateToggle,
    },
    {
        field: 'competitorRank',
        headerName: 'Rank',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.competitorRank.toLocaleString()}`
            }
        },
        headerTooltip: 'Rank',
        minWidth: 150,
        flex: 1,
        sortingOrder: ['asc', 'desc', null],
        filter: 'agNumberColumnFilter',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innovationScore'),
            headerName: 'Innovation Score',
        },
        headerName: 'Innovation Score',
        field: 'powerScore',
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.powerScore.toFixed(0)).toLocaleString()}`
            }
        },
        headerTooltip: 'Innovation Score',
        minWidth: 200,
        flex: 1,
        filter: 'agNumberColumnFilter',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
            headerName: 'Momentum',
        },
        headerName: 'Momentum',
        field: 'opportunity',
        valueFormatter: (p) => {
            if (p.data) {
                return Math.round(p.data.opportunity)
            }
        },
        headerTooltip: 'Momentum',
        minWidth: 150,
        flex: 1,
        filter: 'agNumberColumnFilter',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.percentOfPortfolio'),
            headerName: 'Focus',
        },
        headerName: 'Focus',
        field: 'portfolioFocusPercentage',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.portfolioFocusPercentage.toFixed(0)}%`
            }
        },
        headerTooltip: 'Focus',
        minWidth: 120,
        flex: 1,
        filter: 'agNumberColumnFilter',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
            headerName: 'II P/E',
        },
        headerName: 'II P/E',
        field: 'tpe',
        headerTooltip: 'Innovation Implied P/E',
        minWidth: 120,
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return formatTpeRange(p.data.tpe, p.data.tpeLb, p.data.tpeUb)
            }
        },
        filter: 'agNumberColumnFilter',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innovationStart'),
            headerName: 'Innovation Start',
        },
        headerName: 'Innovation Start',
        valueGetter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.entryPriorityDt) === '1-1-1'
                    ? '---'
                    : new Date(p.data.entryPriorityDt).getFullYear()
            }
        },
        field: 'entryPriorityDt',
        headerTooltip: `Innovation Start`,
        comparator: dateComparator,
        minWidth: 200,
        flex: 1,
        filter: 'agDateColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            buttons: ['clear'],
            defaultOption: 'inRange',
            filterOptions: ['inRange'],
            maxNumConditions: 1,
            comparator: dateFilterComparator,
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.fiveYear'),
            headerName: 'Expiring Soon',
        },
        headerName: 'Expiring Soon',
        field: 'fiveYearExpirePercent',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.fiveYearExpirePercent.toFixed(0)}%`
            }
        },
        headerTooltip: 'Expiring Soon',
        minWidth: 170,
        flex: 1,
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'hqCountryName',
        headerName: 'HQ Country',
        hide: true,
        filter: 'agSetColumnFilter',
    },
    {
        field: 'totalRevenue',
        headerName: 'Revenue',
        hide: true,
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'employeeCount',
        headerName: 'Employee Count',
        hide: true,
        filter: 'agSetColumnFilter',
    },
    {
        field: 'priceToEarnings',
        headerName: 'Price to Earnings',
        hide: true,
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'yoyRevenueGrowthPct',
        headerName: 'YoY Revenue Growth %',
        hide: true,
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'marketValue',
        headerName: 'Market Cap',
        hide: true,
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'currency',
        headerName: 'Currency',
        hide: true,
        filter: 'agSetColumnFilter',
    },
    {
        field: 'lastFundingRoundInvestmentType',
        headerName: 'Latest Funding Round',
        hide: true,
        valueGetter: (p) => {
            if (p.data) {
                return getInvestmentGroup(p.data.lastFundingRoundInvestmentType)
            }
        },
        filter: 'agSetColumnFilter',
    },
])
const arenaContenderColDef = ref([
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.companyName'),
            headerName: 'Company Name',
        },
        headerName: 'Company Name',
        field: 'aonEntityName',
        headerTooltip: 'Company Name',
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        width: 200,
        minWidth: 350,
        filter: 'agTextColumnFilter',
    },
    ...baseColDefs.value,
])
const selectedContenderColDef = ref([
    {
        cellRenderer: 'MTCustomCellCheckbox',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
            selectAction: (params) => {
                emit('delete-selection', params.data)
            },
        },
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.companyName'),
            headerName: 'Company Name',
        },
        headerName: 'Company Name',
        field: 'aonEntityName',
        headerTooltip: 'Company Name',
        width: 200,
        minWidth: 350,
        pinned: 'left',
        filter: 'agTextColumnFilter',
    },
    ...baseColDefs.value,
])

onMounted(() => {})

watch(
    () => props.allCompetitorData,
    (newVal) => {
        quickFilterMappings.value.forEach(({ condition, itemText }) => {
            if (
                condition(newVal) &&
                !quickFilterOptions.value.find((n) => n.itemText === itemText)
            ) {
                quickFilterOptions.value.splice(1, 0, { itemText })
            }
        })
    },
    { immediate: true }
)
const setDisabledCheckboxes = computed(() => {
    return (
        props.maxSelectedCompetitors > 1 &&
        props.selectedTableData.length >= props.maxSelectedCompetitors
    )
})

const groomedData = computed(() => {
    let result = props.allCompetitorData?.filter(
        (item) =>
            (props.maxSelectedCompetitors === 1 ||
                !props.selectedTableData?.some(
                    (competitor) => competitor.aonEntityName === item.aonEntityName
                )) &&
            (!props.filterNameList?.length || !props.filterNameList.includes(item.aonEntityName))
    )
    return result
})

const gridInit = async (params) => {
    gridApi.value = params.api
    sideBar.value = {
        toolPanels: [
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
    }

    if (props.preSelectedContenders?.length) {
        await nextTick(() => {
            props.preSelectedContenders.forEach((contender) => {
                const node = params.api.getRowNode(String(contender))
                if (node) {
                    node.setSelected(true)
                    selectedRows.add(contender.aonEntityPk)
                    emit('add-selection', node.data)

                    eventBus.emit('snacktime', {
                        type: 'success',
                        message:
                            "We've detected that an enterprise user and have selected the corresponding responding party automatically.",
                    })
                }
            })
        })
    }
}

const gridInitSelected = (params) => {
    gridApiSelected.value = params.api
}

const addSelection = async (params) => {
    if (!params.data) return
    // Need to stop race condition for computed table data
    const entityKey = params.data.aonEntityPk
    if (selectedRows.has(entityKey)) {
        selectedRows.delete(entityKey)

        if (props.maxSelectedCompetitors === 1) {
            emit('delete-selection', params.data)
        }
    } else {
        selectedRows.add(entityKey)
        emit('add-selection', params.data)
    }
}

// Quick filter
const shouldApplyQuickFilter = () => {
    // if externalFilterValue is not 'All', then we are filtering
    return externalFilterValue !== 'All'
}

const changeQuickFilterValue = (tab) => {
    externalFilterValue = tab.itemText
    setTimeout(() => {
        gridApi.value.onFilterChanged()
    }, 1000)
}

const applyQuickFilter = (node) => {
    if (node.data) {
        switch (externalFilterValue) {
            case 'Public':
                return node.data.entityType === 'Public Company'
            case 'Private':
                return (
                    node.data.entityType !== 'Government' &&
                    node.data.entityType !== 'College/University' &&
                    node.data.entityType !== 'Public Company'
                )
            case 'Gov/Edu':
                return (
                    node.data.entityType === 'Government' ||
                    node.data.entityType === 'College/University'
                )
            case 'Direct Competitors':
                return node.data.isCompetitor
            case 'Acquisition Target':
                return node.data.isAcquisitionTarget
            case 'Potential Buyer':
                return node.data.isPotentialBuyer
            default:
                return true
        }
    }
    return true
}

// search filter
const searchTable = () => {
    gridApi.value.setGridOption('quickFilterText', searchTerm.value)
}

const clearSearch = () => {
    searchTerm.value = ''
    searchTable()
}
</script>

<style lang="scss" scoped>
.competitor-selection-split {
    .competitor-selection-holder {
        height: 100%;

        .selected-competitors-holder {
            width: 50%;
            height: 100%;

            .border {
                border-right: 1px solid vars.$grey02;
            }

            &.single-selection {
                width: 100%;

                .border {
                    border-right: none;
                }

                .ag-theme-quartz {
                    padding-right: 0 !important;
                }
            }
        }
    }

    :deep(.ag-selection-checkbox) {
        margin-right: -50px !important;
    }

    :deep(.disabled-checkbox) {
        .ag-selection-checkbox {
            opacity: 0.2;
            pointer-events: none;
        }

        .fa-circle-minus {
            opacity: 0.2;
            pointer-events: none;
        }
    }

    :deep(.a-modal) {
        .container {
            padding: 0 !important;
            border-radius: 12px !important;
            max-height: 800px !important;
            height: 80% !important;
        }
    }

    .group-by-parent-toggle {
        margin-left: auto;
        align-self: center;
    }
}
</style>
