export const moatParamBuilder = (params, pagingParams, defaultFilter) => {
    let currentPage = pagingParams.page
    console.log(params)
    params.startRow === 0 ? (currentPage = 1) : currentPage++
    let localParams = {
        FC: [],
        FT: [],
        FV: [],
    }
    if (params.sortModel.length > 0) {
        localParams.SC = [params.sortModel[0].colId]
        localParams.SD = [params.sortModel[0].sort === 'asc' ? 1 : -1]
        localParams.SP = [params.sortModel[0].priority ? params.sortModel[0].priority : 1]
    }

    if (Object.keys(params.filterModel).length > 0) {
        localParams['FC'] = Object.keys(params.filterModel)
        localParams['FT'] = Object.values(params.filterModel).map((st) => {
            // Special case for prune filter with set type - use 'eq' instead of 'incl'... Change this with new advanced filters
            if (st.filterType === 'set' && params.filterModel.prune) {
                return 'eq'
            }
            return formatFilterType(st.type ? st.type : st.filterType)
        })
        localParams['FV'] = Object.values(params.filterModel).map((st) => {
            if (st.filterType === 'set') {
                return st.values.join('~')
            } else if (st.filterType === 'date') {
                return formatFilterValue(
                    formatFilterType(st.type),
                    st.dateTo ? st.dateFrom + '|' + st.dateTo : st.dateFrom
                )
            } else {
                return formatFilterValue(formatFilterType(st.type), st.filter)
            }
        })
    }

    if (defaultFilter) {
        localParams['FC'].push(defaultFilter.FC)
        localParams['FT'].push(defaultFilter.FT)
        localParams['FV'].push(defaultFilter.FV)
    }

    localParams.page_size = pagingParams.page_size
    localParams.last_row_num = params.startRow
    localParams.page = currentPage
    return localParams
}
// Not all of these types are valid at this time
const formatFilterType = (type) => {
    console.log(type)
    if (!type) {
        throw new Error('No filter type to format')
    }
    // type can be passed with various casing. Normalize to lower to match map key casing.
    const typeMap = {
        'date range': 'dt_range',
        'date-time range': 'dt_range',
        equals: 'eq',
        greaterThan: 'gt',
        includes: 'incl',
        lessThan: 'lt',
        inRange: 'dt_range',
        startsWith: 'complex_start',
        contains: 'complex_text',
        empty: 'empty',
        set: 'incl',
        // notIncludes: 'not_incl',
    }
    // assign to avoid repeat map lookup on the happy path.
    const typeString = typeMap[type]

    if (typeString) return typeString

    throw new Error(`No type mapped for '${type}'`)
}

// Not all of these values are valid at this time
const formatFilterValue = (filterType, filterValue) => {
    switch (filterType.toLowerCase()) {
        case 'dt_range':
            const vals = filterValue.split('|')
            const first = vals[0].includes('T') ? vals[0].split('T')[0] : vals[0]
            const second = vals[1].includes('T') ? vals[1].split('T')[0] : vals[1]
            return first + '~' + second
        // case 'date-time range': {
        //     const vals = filterValue.split('|')
        //     return vals[0] + '~' + vals[1]
        // }
        case 'includes':
        case 'range':
            return filterValue.replace('|', '~')
        default:
            return filterValue
    }
}
