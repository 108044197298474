<template>
    <div class="homepage-container mb-5">
        <AonContainer add-page-padding class="knights-cloak-80 pt-8 pb-6">
            <AonRow>
                <AonCol class="aon-col-12">
                    <h2 class="white--text w-full text-center my-2" data-cy="home-sub-title">
                        {{ t('home.subTitle') }}&#8482;
                    </h2>
                </AonCol>
                <AonCol class="aon-col-6 offset-3 justify-content-center">
                    <GlobalSearch />
                </AonCol>
            </AonRow>
        </AonContainer>
        <AonContainer add-page-padding class="knights-cloak-active py-2">
            <AonRow>
                <AonCol
                    v-for="(card, index) in cardData"
                    :key="`car_${index}`"
                    class="discover-container aon-col-6"
                    :data-cy="card.dataCy"
                >
                    <AonCard>
                        <div class="card-content">
                            <router-link
                                class="text-decoration-none d-flex align-items-center"
                                :to="{
                                    name: card.linkName,
                                }"
                            >
                                <font-awesome-icon
                                    :icon="`fas ${card.icon}`"
                                    class="white--text icon-bordered knights-cloak"
                                    size="3x"
                                />
                                <div
                                    class="link-content d-flex flex-column justify-content-between"
                                >
                                    <div class="head mb-2 d-flex align-items-center">
                                        <h4 class="px-4 knights-cloak--text">
                                            {{ card.title }}
                                        </h4>
                                        <font-awesome-icon
                                            icon="fas fa-chevron-right"
                                            class="knights-cloak--text mt-1"
                                            size="lg"
                                        />
                                    </div>
                                    <p class="grey01--text px-4" style="height: 50px">
                                        {{ card.desc }}
                                    </p>
                                </div>
                            </router-link>
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>

        <AonContainer add-page-padding>
            <AonRow>
                <AonCol class="aon-col-12">
                    <AonCard class="pa-5" title="Recent Activity">
                        <div class="tab-holder w-full">
                            <AonTabs
                                class="home-tabs mb-5 align-self-center light"
                                :current-tab="recentActivityCurrentTab"
                                :tabs="recentActivityTabData"
                                bottom-border
                                @tab-change="recentActivityTabChange"
                            />
                        </div>
                        <div class="recent-activity">
                            <WatchlistList v-if="recentActivityCurrentTab === 1" :key="updater" />
                            <PromptHistoryTable v-if="recentActivityCurrentTab === 2" />
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { inject, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAppStore } from '@/stores'
import { useI18n } from 'vue-i18n'

import WatchlistList from '@/components/Watchlists/WatchlistList'
import GlobalSearch from '@/components/GlobalSearch'
import PromptHistoryTable from '@/components/home/PromptHistoryTable'

const { t } = useI18n()
const eventBus = inject('eventBus')

const updater = ref(0)
const recentActivityCurrentTab = ref(1)
const recentActivityTabData = ref([
    {
        text: 'Watchlists',
        id: 1,
    },
    {
        text: 'Prompt History',
        id: 2,
    },
])

const cardData = ref([
    {
        title: t('home.discoverCompaniesLink'),
        desc: t('home.discoverCompaniesDesc'),
        icon: 'fa-building',
        linkName: 'Companies',
        dataCy: 'discover-companies',
    },
    {
        title: t('home.defineArenaLink'),
        desc: t('home.defineArenaDesc'),
        icon: 'fa-swords',
        linkName: 'Arena',
        dataCy: 'define-arena',
    },
])

onMounted(() => {
    eventBus.on('updateWatchlistPage', async () => {
        updater.value++
    })
})

const recentActivityTabChange = (tab) => {
    recentActivityCurrentTab.value = tab.id
}
</script>

<style lang="scss" scoped>
@use '@moatmetrics/armory/src/sass/mixins.scss';
@use '@moatmetrics/armory/src/sass/responsive.scss' as responsive;

.homepage-container {
    min-height: 100vh;
    height: 100%;

    :deep(.aon-container) {
        max-width: none;

        @include responsive.overSized {
            max-width: none;
        }
    }

    .a-card {
        border-radius: 8px;
        // padding: 0 !important;

        .card-content {
            display: flex;
            align-items: center;

            .head {
                width: 100%;
                display: flex;
                align-items: center;
            }
        }
    }
}
</style>
