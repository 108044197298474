<template>
    <div id="login-callback-content" class="align-center justify-content-center">
        <AonCard v-if="authError">
            <img class="mt-1 mb-2" src="@/assets/moat-logo.png" alt="Aon (Logo)" />
            <hr />
            <p class="mt-3 extra-large">
                {{ t('auth.error.unauthorizedTitle') }}
            </p>
            <p class="my-3 knights-cloak--text large">
                {{ t('auth.error.unauthorizedMessage') }}
            </p>
        </AonCard>
        <AonSpinner v-else class="mx-auto my-auto" :scale="0.5" />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useAuth } from '@/auth/authPlugin'

const route = useRoute()
const authClient = useAuth()
const router = useRouter()

const authError = ref(false)
const { t } = useI18n()

onMounted(async () => {
    try {
        let state = await authClient.handleRedirectCallback()
        router.replace(state?.appState?.target ?? '/')
    } catch (e) {
        switch (e.error_description) {
            case 'invitation not found or already used':
                router.replace({ name: 'Home' })
                break
            default:
                authError.value = true
                window.history.replaceState(
                    {},
                    '',
                    document.location.origin + document.location.pathname
                )
                break
        }
    }
})
</script>

<style scoped lang="scss">
#login-callback-content {
    display: flex;
    height: 100%;

    .a-card {
        width: 30vw;
        margin: auto;
        text-align: center;

        hr {
            width: 100%;
            color: vars.$grey06;
        }
    }
}
</style>
