<template>
    <div class="tool-panel-actions pa-3 d-flex flex-column justify-content-between">
        <div class="tool-header">
            <div class="tool-panel-header d-flex align-items-end">
                <p class="bold small">{{ selectedRows.length }} Companies Selected</p>
                <p class="extra-small ml-2">(Limit 25)</p>
            </div>
            <p
                class="small link mt-2"
                :class="{ disabled: selectedRows.length === 0 }"
                @click="clearSelections()"
            >
                Clear Selections
            </p>
        </div>
        <div class="actions mt-4 d-flex flex-column justify-content-between h-full">
            <div class="upper-actions">
                <div
                    v-if="params.showRemoveFromWatchlistAction"
                    class="action d-flex align-items-center mt-2"
                    :class="{ disabled: selectedRows.length === 0 }"
                    @click="onActionSelection('remove')"
                >
                    <font-awesome-icon class="grey01--text icon mr-2" icon="fas fa-xmark" />
                    <p class="small grey01--text cursor">Remove to Watchlist</p>
                </div>
                <div
                    v-if="params.showTechAreaBreakdownAction"
                    class="action d-flex align-items-center mt-2"
                    :class="{ disabled: selectedRows.length === 0 }"
                    @click="onActionSelection('techAreaBreakdown')"
                >
                    <font-awesome-icon class="grey01--text icon mr-2" icon="fas fa-list-tree" />
                    <p class="small grey01--text cursor">Tech Area Breakdown</p>
                </div>
            </div>
            <div class="lower-actions w-full d-flex pt-2">
                <div
                    v-if="params.tableExportAction"
                    class="action d-flex align-items-center"
                    @click="onActionSelection('exportTable')"
                >
                    <font-awesome-icon class="grey01--text icon mr-2" icon="fas fa-download" />
                    <p class="small grey01--text cursor">Export Table</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, onMounted, onUnmounted, ref } from 'vue'

const eventBus = inject('eventBus')
const emit = defineEmits(['setup-arena'])
const props = defineProps({
    params: Object,
})
const selectedRows = ref([])

onMounted(() => {
    eventBus.on('row-selected', () => {
        selectedRows.value = props.params.api.getSelectedRows()
    })
})

onUnmounted(() => {
    eventBus.off('row-selected')
})

const onActionSelection = (actionType) => {
    if (actionType === 'remove') {
        props.params.removeFromWatchlistAction(props.params)
    } else if (actionType === 'techAreaBreakdown') {
        props.params.techAreaBreakdownAction(props.params)
    } else if (actionType === 'exportTable') {
        props.params.tableExportAction(props.params)
    }
}
const clearSelections = () => {
    props.params.clearSelections(props.params)
}
</script>

<style lang="scss" scoped>
.action {
    p {
        &:hover {
            text-decoration: underline;
        }
    }
}

.lower-actions {
    border-top: 1px solid vars.$grey01;
}
</style>
