<template>
    <div style="position: relative">
        <AonCoverLoading :loading="loading" />
        <div class="innovation-gap-chart">
            <div v-if="props.dataReady && !hasData" class="no-data">
                <p>No data available</p>
            </div>
            <div v-show="props.dataReady && hasData" class="innovation-gap-chart" ref="chartDiv" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, onUnmounted, watch } from 'vue'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const chartDiv = ref(null)
const hasData = ref(true)
let root: am5.Root | null = null
let chart: am5xy.XYChart | null = null
let series: am5xy.ColumnSeries[] = []
let yAxis: am5xy.CategoryAxis<any> | null = null
let xAxis: am5xy.ValueAxis<any> | null = null
const loading = ref(true)
const props = defineProps<{
    peerScore: number
    licenseeScore: number
    licensorScore: number
    dataReady: boolean
    licenseeName: string
}>()

const makeSeries = (name: string, valueField: string, color?: string) => {
    if (!root || !chart) return null

    const newSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            name: name,
            stacked: true,
            xAxis: xAxis!,
            yAxis: yAxis!,
            valueXField: valueField,
            categoryYField: 'category',
            fill: color ? am5.color(color) : root.interfaceColors.get('primaryButton'),
        })
    )

    newSeries.columns.template.setAll({
        tooltipText: '{name}: {valueX}',
        height: 50,
        maxHeight: 50,
    })

    series.push(newSeries)
    return newSeries
}

const updateChartData = () => {
    loading.value = true

    // Check if any props are NaN before proceeding
    if (isNaN(props.peerScore) || isNaN(props.licenseeScore) || isNaN(props.licensorScore)) {
        if (props.dataReady) {
            hasData.value = false
            loading.value = false
        }
        return
    }

    hasData.value = true
    const data = [
        {
            category: 'Licensee Innovation Score',
            peerScore: 0,
            licenseeScore: Math.round(props.licenseeScore),
            licensorScore: Math.round(props.licensorScore),
        },
        {
            category: 'Peer Innovation Benchmark',
            peerScore: Math.round(props.peerScore),
            licenseeScore: 0,
            licensorScore: 0,
        },
    ]

    if (yAxis) {
        yAxis.data.setAll(data)
        series.forEach((s) => {
            s.data.setAll(data)
        })
    }
    loading.value = false
}

watch(
    [
        () => props.peerScore,
        () => props.licenseeScore,
        () => props.licensorScore,
        () => props.dataReady,
    ],
    () => {
        updateChartData()
    }
)

onMounted(() => {
    loading.value = true
    // Create root element
    root = am5.Root.new(chartDiv.value!)

    // Set themes
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panY: false,
            layout: root.horizontalLayout,
            paddingRight: 20,
        })
    )

    // Create axes
    let yRenderer = am5xy.AxisRendererY.new(root, {})

    yRenderer.labels.template.adapters.add('tooltipText', function (text, target) {
        if (target.dataItem?.dataContext?.category === 'Peer Innovation Benchmark') {
            return t('licensingOpportunities.peer_innovation_benchmark')
        }
        return '{category}'
    })

    yRenderer.labels.template.adapters.add('html', function (html, target) {
        if (target.dataItem?.dataContext?.category === 'Peer Innovation Benchmark') {
            return "<div class='tooltip-indicator axis-label'>{category}</div>"
        } else if (target.dataItem?.dataContext?.category === 'Licensee Innovation Score') {
            return `<p class='axis-label'>${props.licenseeName}</p>`
        }
        return '{category}'
    })

    yRenderer.labels.template.setup = function (target) {
        target.set(
            'background',
            am5.Rectangle.new(root, {
                fill: am5.color(0x000000),
                fillOpacity: 0,
            })
        )
    }

    yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'category',
            renderer: yRenderer,
        })
    )

    var tooltip = yAxis.labelsContainer.set(
        'tooltip',
        am5.Tooltip.new(root, {
            pointerOrientation: 'down',
            position: 'fixed',
        })
    )

    tooltip.label.setAll({
        maxWidth: 550,
        oversizedBehavior: 'wrap',
    })

    xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {}),
        })
    )

    // Create series
    const defaultColor = am5.ColorSet.new(root, {}).next()
    makeSeries('Peer Innovation Benchmark', 'peerScore', defaultColor)
    makeSeries('Licensee Innovation Score', 'licenseeScore', defaultColor)
    makeSeries('Potential Innovation Score Gain', 'licensorScore', '#4CAF50')

    updateChartData()
})

onUnmounted(() => {
    if (root) {
        root.dispose()
    }
})
</script>

<style scoped>
.innovation-gap-chart {
    width: 100%;
    height: 250px;
}

.no-data {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>
