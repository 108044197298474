<template>
    <div id="aon-app" ref="captureRef">
        <div class="main-content">
            <div class="header-container w-full" id="header-container">
                <AonApplicationHeader
                    v-show="showNav"
                    v-bind="$attrs"
                    class="header-hook"
                    :user="{ name: user?.email }"
                    title=""
                    :nav-items="navItems"
                    aon-logo-named-route="Home"
                    @select-option="handleDropdownSelect"
                    style="z-index: 11 !important"
                    dark-mode
                >
                    <template #headerImage>
                        <img
                            style="height: 40px; margin-top: -2px"
                            :src="logoImport"
                            alt="Moat Metrics Home"
                        />
                    </template>
                    <template #rightAppend>
                        <div class="global-search-holder d-flex align-items-center">
                            <GlobalSearch
                                style="min-width: 400px"
                                ignore-enter
                                enable-click-outside
                            />
                            <AonButton
                                v-if="allowPortfolioManager"
                                class="ml-4 port-manager-button"
                                style="min-width: 191px"
                                type="transparent"
                                text-with-icon
                                :iconOptions="{
                                    iconStyle: 'fas',
                                    iconName: 'fa-gear',
                                }"
                                label="Portfolio Manager"
                                @clicked="gotoPortfolioManager()"
                            />
                            <div
                                class="dropdown-menu-holder d-flex align-items-center ml-4 pl-12 py-3 pr-3"
                            >
                                <AonDropdown
                                    class="ml-0"
                                    :items="myAcountItems"
                                    dropdownPos="right"
                                    icon-only-dropdown
                                    :icon-only-dropdown-options="{
                                        iconStyle: 'fas',
                                        iconColor: 'grey04',
                                        iconColorActive: 'castle-moat',
                                        iconName: 'fa-bars',
                                    }"
                                    @select-option="handleDropdownSelect"
                                />
                                <p class="grey04--text text-center" style="min-width: 88px">
                                    My Account
                                </p>
                            </div>
                        </div>
                    </template>
                </AonApplicationHeader>
            </div>
            <PatentList v-if="patentViewerStore.showPatentViewerList" />
            <PatentDetails v-show="patentViewerStore.patentDetailsOpen" />
            <router-view />
        </div>
        <AppFooter v-if="isAuthenticated" />
        <Snackbar />
        <AonModal
            v-if="showGlobalModal"
            :title="globalModalObj.title"
            message=""
            col-width="4"
            @close="closeModal()"
        >
            <div class="modal-content">
                <div v-if="globalModalObj.definition" class="definition mb-4">
                    <h6 class="bold navy01--text mb-1">Definition</h6>
                    <p>{{ globalModalObj.definition }}</p>
                </div>
                <div v-if="globalModalObj.moreInfo" class="more-info mb-4">
                    <h6 class="bold navy01--text mb-1">Why this is important</h6>
                    <p>{{ globalModalObj.moreInfo }}</p>
                </div>
                <div v-if="globalModalObj.zScoreInfo" class="score-info">
                    <h6 class="bold navy01--text mb-1">What is a clipped z-score</h6>
                    <p>{{ globalModalObj.zScoreInfo }}</p>
                </div>
            </div>
        </AonModal>
        <ScreenersModal v-if="showScreeners" />
        <EditWatchlistModal v-if="datasetToEdit" />
        <CompanySummary />
        <WatchlistAddOrCreateModal v-if="idsToAdd || datasetStore.showOnlyCreateWatchlists" />
    </div>
</template>

<script setup>
import { ref, computed, watch, inject, onBeforeMount, onMounted } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'
import {
    useAppStore,
    useScreenerStore,
    useDatasetStore,
    useEntityStore,
    usePatentViewerStore,
} from '@/stores'

import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { hideAllPoppers } from 'floating-vue'
import logoImport from '@/assets/moat_alpha.png'

import { AonApplicationHeader } from '@moatmetrics/armory/src/components'
import GlobalSearch from '@/components/GlobalSearch'
import AppFooter from './components/AppFooter'
import Snackbar from './components/Snackbar'
import ScreenersModal from '@/components/screeners/ScreenersModal'

import WatchlistAddOrCreateModal from '@/components/Watchlists/WatchlistActions/WatchlistAddOrCreateModal.vue'
import EditWatchlistModal from '@/components/Watchlists/WatchlistActions/EditWatchlistModal.vue'
import CompanySummary from '@/components/helpers/CompanySummary.vue'
import PatentList from '@/components/patentViewer/PatentList.vue'
import PatentDetails from '@/components/patentViewer/PatentDetails.vue'

import { useFlag, useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-vue'
import { useAuth } from '@/auth/authPlugin'

const updateUnleashContext = useUnleashContext()
const { t } = useI18n()

const datasetStore = useDatasetStore()
const { idsToAdd, datasetToEdit } = storeToRefs(datasetStore)

const appStore = useAppStore()
const { showGlobalModal, globalModalObj, showTOS } = storeToRefs(appStore)
const { user, isAuthenticated, logout } = useAuth()

const entityStore = useEntityStore()
const patentViewerStore = usePatentViewerStore()
const router = useRouter()
const route = useRoute()
const captureRef = ref(null)
const rumAgent = inject('rumAgent')
const isProUser = useFlag('ipAlpha.proUsers')
const pruningFeatureFlag = useFlag('ipAlpha.pruningFeature')

const navItems = ref([
    {
        type: 'links',
        links: [
            {
                linkLabel: 'Company Screener',
                linkNamedRoute: 'Companies',
            },
            {
                linkLabel: 'Playbooks',
                linkNamedRoute: 'Playbooks',
            },
            {
                linkLabel: 'Define Competitive Arena',
                linkNamedRoute: 'Arena',
            },
        ],
    },
    {
        type: 'dropdowns',
        links: [],
    },
])

const myAcountItems = ref([
    {
        itemText: 'My Arenas',
        itemValue: 'my-arenas',
        iconOptions: {
            iconName: 'fa-swords',
            iconStyle: 'fas',
            iconColor: 'grey03',
        },
    },
    {
        itemText: 'My Watchlists',
        itemValue: 'my-watchlists',
        iconOptions: {
            iconName: 'fa-square-list',
            iconStyle: 'fas',
            iconColor: 'grey03',
        },
    },
    {
        itemText: 'My Reports',
        itemValue: 'my-report',
        iconOptions: {
            iconName: 'fa-book',
            iconStyle: 'fas',
            iconColor: 'grey03',
        },
    },
    {
        itemText: t('headerNav.support.email'),
        itemValue: 'support-email',
        iconOptions: {
            iconName: 'fa-envelope',
            iconStyle: 'fas',
            iconColor: 'grey03',
        },
    },
    {
        itemText: t('headerNav.user.signOut'),
        itemValue: 'Logout',
        iconOptions: {
            iconName: 'fa-arrow-left-from-line',
            iconStyle: 'fas',
            iconColor: 'grey03',
        },
    },
])

const entityName = computed(() => {
    return entityStore.entity ? entityStore.entity.name : ''
})

const allowPortfolioManager = computed(() => {
    return user.value?.entity_pk && isProUser.value && pruningFeatureFlag.value
})

watch(
    () => router,
    (newVal) => {
        if (newVal.currentRoute.value.meta.tabName) {
            document.title = `Alpha ${newVal.currentRoute.value.meta.tabName ? '- ' + newVal.currentRoute.value.meta.tabName : ''}`
        }
    },
    { flush: 'pre', immediate: true, deep: true }
)

watch(
    () => entityName.value,
    (newVal) => {
        document.title = `Alpha - ${newVal}`
    }
)

watch(showTOS, (val) => {
    if (val) {
        hideAllPoppers()
    }
})

watch(user, async () => {
    if (user.value?.id) {
        await setUserContexts()
    }
})

watch(
    () => patentViewerStore.showPatentViewerList,
    (isOpen) => {
        if (isOpen) {
            document.documentElement.style.position = 'fixed'
        } else {
            document.documentElement.style.position = 'static'
        }
    }
)

onBeforeMount(async () => {
    await setUserContexts()
})

onMounted(() => {
    const leftNav = document.querySelector('.left-nav')
    if (leftNav) {
        leftNav.id = 'left-nav'
    }

    const headerContainer = document.querySelector('.header-container')
    if (headerContainer) {
        const aonDropdown = headerContainer.querySelector('.aon-dropdown')
        if (aonDropdown) {
            aonDropdown.id = 'menu'
        }
    }
})

const setUserContexts = async () => {
    if (!user.value?.id) return

    await updateUnleashContext({
        userId: user.value.email,
    })

    rumAgent.setUser({
        id: user.value.id,
        email: user.value.email,
        tenant_id: user.value.org_id,
        tenant_name: user.value.org_name,
    })
}

const handleDropdownSelect = async (params) => {
    if (params.itemValue === 'support-email') {
        window.open(`mailto:${t('headerNav.support.email')}`)
    }

    if (params.itemValue === 'support-tos') {
        appStore.setShowTOS(true)
    }

    if (params.itemValue === 'my-arenas') {
        router.push({ name: 'Arena List View' })
    }
    if (params.itemValue === 'my-watchlists') {
        router.push({ name: 'Watchlist List' })
    }
    if (params.itemValue === 'my-report') {
        router.push({ name: 'Reports' })
    }

    if (params.itemValue === 'Logout') {
        await logout({
            async openUrl() {
                window.location.href = document.location.origin
            },
        })
    }
}

const closeModal = () => {
    appStore.setShowGlobalModal(false)
}

const showScreeners = computed(() => {
    let screenerStore = useScreenerStore()
    return screenerStore.screenersModalOpen
})

const showNav = computed(() => {
    if (!isAuthenticated.value || route.meta.fullScreen) {
        return false
    }

    return true
})

const gotoPortfolioManager = () => {
    router.push({
        name: 'Research Company',
        params: { targetPk: user.value?.entity_pk },
        query: { tabName: 'portfolioManager' },
    })
}
</script>

<style lang="scss">
@use '@moatmetrics/armory/src/sass/main.scss';
@use '@moatmetrics/armory/src/sass/responsive.scss' as responsive;
@use '@/styles/projectMain.scss';

.port-manager-button {
    .a-button {
        border-color: vars.$grey04;
    }
    .a-button-label {
        color: vars.$grey04;
        font-weight: 400;
    }
    border-color: green !important;
}

a {
    cursor: pointer;
}

.overflow-hide {
    overflow: hidden;
}

.dropdown-menu-holder {
    border: 1px solid vars.$grey04;
    border-radius: 4px;
    cursor: pointer;

    position: relative;

    &:hover {
        .aon-dropdown {
            background: vars.$knightsCloak80 !important;

            .dropdown-icon {
                background: transparent !important;

                svg {
                    color: vars.$grey04 !important;
                }
            }
        }
    }

    p {
        position: relative;
        pointer-events: none;
        z-index: 1;
        color: vars.$grey04 !important;
    }

    .aon-dropdown {
        position: absolute !important;
        left: 0;
        border-radius: 4px;

        transition: all 0.3s ease-in-out;

        .dropdown-icon {
            border-radius: 0 !important;
        }

        &.open {
            background: vars.$knightsCloak80 !important;

            .dropdown-icon {
                background: transparent !important;

                svg {
                    color: vars.$grey04 !important;
                }
            }
        }

        .items {
            border: 1px solid vars.$grey04;
            box-shadow: 0px 6px 15px 0px rgba(0, 17, 53, 0.2);
        }
    }
}

.aon-container {
    max-width: none;

    @include responsive.overSized {
        max-width: none;
    }
}

#aon-app {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .main-content {
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 190px); // height of footer
        background: vars.$grey05;
        position: relative;
        z-index: 1;
    }
}

// REVAMP VIA ARMORY - BB
.header-hook {
    .container {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    .open {
        .dropdown-icon {
            background: rgba(vars.$grey04, 0.3);
            color: vars.$grey04 !important;
        }

        .main-items {
            transform: translateY(10px) !important;
        }
    }
    .main-items {
        ul {
            overflow-y: auto !important;
            max-height: 250px !important;
        }

        .items {
            max-height: 230px !important;
        }
    }
}

.moat-application-header .table-aips-latest {
    position: relative;
    z-index: 0;
}

.feedback {
    position: fixed;
    z-index: 10010;
    bottom: 30px;
    right: 30px;
}
</style>
