<template>
    <div class="fees-by-product w-full d-flex flex-column">
        <!-- Original product-based chart -->
        <AonCol class="aon-col-12 px-0">
            <AonCard title="Upcoming Fees By Product">
                <div class="card-content">
                    <div id="feesMonthlyBarChart"></div>
                    <div id="feesBarChart" class="mt-4"></div>
                    <AonCoverLoading
                        :loading="props.data.length === 0 && !props.loading"
                        :show-spinner="false"
                        :title="`No Fees By Product`"
                        :message="`No fees were found for ${entityStore.entity?.name} between ${portfolioManagerStore.dateRange[0]} and ${portfolioManagerStore.dateRange[1]}`"
                    />
                </div>
            </AonCard>
        </AonCol>
    </div>
</template>

<script setup>
import { watch, computed, nextTick, onUnmounted } from 'vue'
import { useEntityStore, usePortfolioManagerStore } from '@/stores'

import * as am5 from '@amcharts/amcharts5'
import { useBarChart } from '@/composables/charts/barChart'
import { config } from '@/config'

import moment from 'moment'

const entityStore = useEntityStore()
const portfolioManagerStore = usePortfolioManagerStore()

let rootProduct
let rootMonthly
let barChartProduct
let barChartMonthly

const emit = defineEmits(['item-clicked'])
const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    showProductLabels: {
        type: Boolean,
        default: true,
    },
})

watch(
    () => props.data,
    () => {
        initializeCharts()
    }
)

onUnmounted(() => {
    if (rootProduct) rootProduct.dispose()
    if (rootMonthly) rootMonthly.dispose()
})

const productData = computed(() => {
    // Aggregate fees by product across all dates
    const productTotals = new Map()

    props.data.forEach((entry) => {
        entry.productFees.forEach((fee) => {
            const product = fee.product
            const currentTotal = productTotals.get(product) || 0
            productTotals.set(product, currentTotal + fee.upcomingFeeAmount)
        })
    })

    // Convert to the format expected by the bar chart
    return Array.from(productTotals.entries())
        .map(([product, total]) => {
            return {
                name: product,
                value: total,
            }
        })
        .sort((a, b) => a.value - b.value)
})

const productDataByMonth = computed(() => {
    if (!props.data || props.data.length === 0) return []

    return props.data
        .map((entry) => {
            const date = new Date(entry.date)
            const dateInMs = date.getTime()

            // Calculate first day of the month
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
            const from = `${firstDay.getFullYear()}-${String(firstDay.getMonth() + 1).padStart(2, '0')}-${String(firstDay.getDate()).padStart(2, '0')} 00:00:00`

            // Calculate last day of the month
            const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
            const to = `${lastDay.getFullYear()}-${String(lastDay.getMonth() + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')} 00:00:00`

            const totalAmount = entry.productFees.reduce((sum, fee) => {
                return sum + (fee.upcomingFeeAmount || 0)
            }, 0)

            return {
                date: dateInMs,
                name: moment(entry.date).format('MMM YYYY'), // This will be the x-axis category
                value: totalAmount,
                dateRange: {
                    from: from,
                    to: to,
                },
            }
        })
        .sort((a, b) => a.date - b.date)
})

const initializeCharts = async () => {
    // Initialize product chart
    if (rootProduct) {
        rootProduct.dispose()
    }

    // Initialize monthly chart
    if (rootMonthly) {
        rootMonthly.dispose()
    }

    await nextTick()

    am5.addLicense(config.license.AMChartsLicense)

    // Create product chart
    if (productData.value.length > 0) {
        rootProduct = am5.Root.new('feesBarChart')
        barChartProduct = useBarChart(rootProduct)

        barChartProduct.setClickCallback(handleBarClick)

        barChartProduct.configureBar({ horizontal: true, scrollable: true })
        barChartProduct.generateAxes(productData.value, {
            showBullets: props.showProductLabels,
            horizontal: true,
            formatCurrency: true,
            scrollable: true,
        })
        barChartProduct.generateSeries(productData.value, {
            horizontal: true,
            formatCurrency: true,
            clickable: true,
        })
    }

    // Create monthly chart
    if (productDataByMonth.value.length > 0) {
        rootMonthly = am5.Root.new('feesMonthlyBarChart')
        barChartMonthly = useBarChart(rootMonthly)

        barChartMonthly.setClickCallback(handleBarClick)

        barChartMonthly.configureBar({ horizontal: false, scrollable: false })
        barChartMonthly.generateAxes(productDataByMonth.value, {
            showBullets: false,
            horizontal: false,
            formatCurrency: true,
            scrollable: false,
        })
        barChartMonthly.generateSeries(productDataByMonth.value, {
            horizontal: false,
            formatCurrency: true,
            clickable: true,
        })
    }
}

const handleBarClick = (barData) => {
    emit('item-clicked', barData)
}
</script>

<style lang="scss" scoped>
.fees-by-product {
    width: 100%;
    position: relative;

    .card-content {
        width: 100%;
    }

    #feesMonthlyBarChart {
        width: 100%;
        min-height: 200px;
        border-bottom: 1px solid vars.$grey03;

        div {
            height: 100%;
        }
    }

    #feesBarChart {
        width: 100%;
        min-height: 400px;

        div {
            height: 100%;
        }
    }
}
</style>
