import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

export function useViolinChart(root, violinRow, chartData) {
    let chart
    let xAxis
    let scrollableContainer

    const configureViolin = (options) => {
        root.setThemes([am5themes_Animated.new(root)])

        chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                pinchZoomX: false,
                pinchZoomY: false,
                maxTooltipDistance: 0,
            })
        )

        if (options?.scrollContainer) {
            scrollableContainer = chart.chartContainer.children.unshift(
                am5.Container.new(root, {
                    width: am5.p100,
                    height: am5.p100,
                    verticalScrollbar: am5.Scrollbar.new(root, {
                        orientation: 'vertical',
                    }),
                    wheelable: true,
                })
            )

            chart.yAxesAndPlotContainer.set('height', violinRow.length * 60)
            chart.yAxesAndPlotContainer.set('paddingBottom', 10)
            scrollableContainer.children.push(chart.yAxesAndPlotContainer)
        }
    }

    const generateXAxes = (xData) => {
        xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: 'year',
                renderer: am5xy.AxisRendererX.new(root, {
                    minGridDistance: 20,
                }),
                tooltip: am5.Tooltip.new(root, {}),
            })
        )

        let xRenderer = xAxis.get('renderer')

        xRenderer.labels.template.setAll({
            rotation: -45,
            location: 0.5,
            multiLocation: 0.5,
            centerX: am5.p100,
            centerY: am5.p50,
            fontSize: 12,
        })

        xRenderer.grid.template.setAll({
            location: 0.5,
            multiLocation: 0.5,
        })

        xAxis.data.setAll(xData)
        chart.leftAxesContainer.setAll({
            layout: root.verticalLayout,
        })
    }

    const createAllSeries = (options) => {
        const xData = processData()
        generateXAxes(xData)
        const globalBounds = findGlobalBounds()

        violinRow.forEach((row) => {
            const series = createSeries(row, globalBounds, options)
            series.data.setAll(processData(row.displayName))
        })

        addCursor()
    }

    const createSeries = (row, globalBounds, options) => {
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                maxDeviation: 0,
                strictMinMax: true,
                extraMin: 0.05,
                extraMax: 0.05,
                renderer: am5xy.AxisRendererY.new(root, {}),
                min: globalBounds.low,
                max: globalBounds.high,
            })
        )

        configureYAxisRenderer(yAxis)
        const series = createAndConfigureSeries(yAxis, row)
        addLabel(yAxis, row, series, options)

        return series
    }

    const configureYAxisRenderer = (yAxis) => {
        let yRenderer = yAxis.get('renderer')
        yRenderer.labels.template.setAll({
            forceHidden: true,
        })
        yRenderer.grid.template.setAll({
            forceHidden: true,
        })
    }

    const createAndConfigureSeries = (yAxis, row) => {
        let series = chart.series.push(
            am5xy.SmoothedXLineSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'high',
                openValueYField: 'low',
                categoryXField: 'year',
                fill: am5.color(row.color),
                tooltip: am5.Tooltip.new(root, {}),
            })
        )

        series
            .get('tooltip')
            .label.set('text', `[bold]${row.displayName}[/]\nInnovation Score: [bold]{count}[/]`)

        series.fills.template.setAll({
            fillOpacity: 1,
            visible: true,
        })

        return series
    }

    const addLabel = (yAxis, row, series, options) => {
        const displayText = row.displayName
        let truncatedText = displayText
        if (options?.truncate) {
            truncatedText =
                displayText.length > 15
                    ? displayText.substring(0, 15) + '...'
                    : displayText.substring(0, 15)
        }

        yAxis.children.unshift(
            am5.Label.new(root, {
                text: truncatedText,
                y: am5.percent(16),
                x: am5.percent(100),
                centerX: am5.percent(100),
                fill: am5.color(0xffffff),
                fontWeight: '600',
                background: am5.RoundedRectangle.new(root, {
                    fill: series.get('fill'),
                }),
            })
        )
    }

    const addCursor = () => {
        chart.set(
            'cursor',
            am5xy.XYCursor.new(root, {
                behavior: 'none',
                xAxis: xAxis,
            })
        )
        chart.appear(1000, 100)
    }

    const processData = (entityIdentifier = null) => {
        const filteredData = entityIdentifier
            ? chartData.filter(
                  (item) =>
                      item.aonEntityName === entityIdentifier ||
                      item.displayName === entityIdentifier
              )
            : chartData

        return filteredData
            .reduce((acc, curr) => {
                const existingYear = acc.find((item) => item.filingYear === curr.filingYear)
                if (existingYear) {
                    existingYear.count += curr.powerScore
                } else {
                    acc.push({
                        filingYear: curr.filingYear,
                        count: curr.powerScore,
                    })
                }
                return acc
            }, [])
            .map((item) => ({
                year: item.filingYear.toString(),
                count: Math.ceil(item.count),
                high: Math.ceil(item.count),
                low: Math.ceil(item.count) * -1,
            }))
    }

    const findGlobalBounds = () => {
        const powerScores = Math.max(...chartData.map((item) => item.powerScore))
        return {
            high: Math.ceil(powerScores),
            low: Math.ceil(powerScores) * -1,
        }
    }

    const disposeViolin = () => {
        if (chart) {
            root.setThemes([])
            chart.series.clear()
            chart.yAxes.clear()
            chart.get('cursor')?.dispose()
            scrollableContainer?.dispose()
            xAxis?.dispose()
            chart.dispose()
        }
    }

    return {
        configureViolin,
        createAllSeries,
        disposeViolin,
    }
}
