<template>
    <div class="overview">
        <AonCoverLoading
            :loading="feesLoading"
            :title="`Updating values based off of date range`"
        />
        <div class="d-flex flex-column">
            <FeeSummary :data="feeSummaryData" @send-filters="passFiltersToPatentList" />
            <FeesByProduct
                :data="feesByProductData"
                :loading="loadingProductFees"
                :showProductLabels="false"
                @item-clicked="passFiltersToPatentList"
            />
            <FeesByProductClaim
                :data="feesByProductClaimData"
                :loading="loadingProductFeesByClaim"
                @item-clicked="passFiltersToPatentList"
            />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, inject, watch, computed, onActivated } from 'vue'
import { usePortfolioManagerStore, useEntityStore } from '@/stores'
import { getFeeSummary, getProductFees, getProductFeesByClaim } from '@/api/portfolioManager'

import FeeSummary from '@/components/company/proTabs/portfolioManager/overview/FeeSummary.vue'
import FeesByProduct from '@/components/company/proTabs/portfolioManager/overview/FeesByProduct.vue'
import FeesByProductClaim from '@/components/company/proTabs/portfolioManager/overview/FeesByProductClaim.vue'

const portfolioManagerStore = usePortfolioManagerStore()
const entityStore = useEntityStore()

const logger = inject('logger')

const loadingFeeSummary = ref(true)
const loadingProductFees = ref(true)
const loadingProductFeesByClaim = ref(true)

const feeSummaryData = ref([])
const feesByProductData = ref([])
const feesByProductClaimData = ref([])

const emit = defineEmits(['pass-filters'])

onMounted(() => {
    onGetFeeSummary()
    onGetFeesByProduct()
    onGetFeesByProductClaim()
})

onActivated(() => {
    onGetFeeSummary()
    onGetFeesByProduct()
    onGetFeesByProductClaim()
})

watch(
    () => portfolioManagerStore.dateRange,
    () => {
        onGetFeeSummary()
        onGetFeesByProduct()
        onGetFeesByProductClaim()
    }
)

const feesLoading = computed(() => {
    return loadingFeeSummary.value || loadingProductFees.value || loadingProductFeesByClaim.value
})

const onGetFeeSummary = async () => {
    loadingFeeSummary.value = true
    try {
        let { data } = await getFeeSummary(
            entityStore.entity.aon_entity_pk,
            portfolioManagerStore.dateRange
        )
        feeSummaryData.value = Object.values(data)
    } catch (err) {
        logger.error(err)
    } finally {
        loadingFeeSummary.value = false
    }
}

const onGetFeesByProduct = async () => {
    loadingProductFees.value = true
    try {
        let { data } = await getProductFees(
            entityStore.entity.aon_entity_pk,
            portfolioManagerStore.dateRange
        )
        feesByProductData.value = data
    } catch (err) {
        logger.error(err)
    } finally {
        loadingProductFees.value = false
    }
}

const onGetFeesByProductClaim = async () => {
    loadingProductFeesByClaim.value = true
    try {
        let { data } = await getProductFeesByClaim(
            entityStore.entity.aon_entity_pk,
            portfolioManagerStore.dateRange
        )
        feesByProductClaimData.value = data
    } catch (err) {
        logger.error(err)
    } finally {
        loadingProductFeesByClaim.value = false
    }
}

const passFiltersToPatentList = (data) => {
    emit('pass-filters', data)
}
</script>
<style lang="scss" scoped>
.overview {
    width: 100%;
    min-height: 500px;
    position: relative;
}
</style>
