import { prunePatents, unprunePatents } from '@/api/portfolioManager.js'

/**
 * Handles pruning or unpruning of patents
 * @param {Object|null} rowData - Single patent row data or null for bulk operation
 * @param {Array} selectedRows - Array of selected rows for bulk operations
 * @param {string|null} type - 'prune', 'unprune', or null (determined from rowData)
 * @param {Object} logger - Logger instance for error handling
 * @returns {Object} - Updated total fees pruned and success status
 */
export const submitPruneStatus = async (rowData, selectedRows = [], type = null, logger) => {
    let publicationPks = []
    let feesToUpdate = 0
    let currentTotalFeesPruned = 0

    if (rowData) {
        publicationPks = [parseInt(rowData.publicationPk)]
        if (!type) {
            type = rowData.prune ? 'prune' : 'unprune'
        }
        feesToUpdate = rowData.remainingFees || 0
    } else {
        publicationPks = selectedRows.map((row) => parseInt(row.publicationPk))
        feesToUpdate = selectedRows.reduce((total, row) => total + (row.remainingFees || 0), 0)
    }

    try {
        // Execute the appropriate action based on type
        if (type === 'prune') {
            await prunePatents(publicationPks)
            currentTotalFeesPruned += feesToUpdate
        } else if (type === 'unprune') {
            await unprunePatents(publicationPks)
            currentTotalFeesPruned = Math.max(0, currentTotalFeesPruned - feesToUpdate)
        } else {
            logger.error('Invalid prune action type')
            return { success: false, totalFeesPruned: currentTotalFeesPruned }
        }

        return { success: true, totalFeesPruned: currentTotalFeesPruned }
    } catch (err) {
        logger.error(err)
        return { success: false, totalFeesPruned: currentTotalFeesPruned }
    }
}

/**
 * Updates row data for pruning status in the grid
 * @param {Object} gridApi - AG Grid API reference
 * @param {Array} rows - Rows to update
 * @param {boolean} pruneStatus - Whether to prune or unprune
 */
export const updateRowPruneStatus = (gridApi, rows, pruneStatus) => {
    if (!rows || !gridApi) return

    rows.forEach((row) => {
        row.prune = pruneStatus
        const rowNode = gridApi.getRowNode(row.patentId)
        if (rowNode) {
            rowNode.setData({ ...row })
        }
    })
}

/**
 * Handles bulk pruning operation
 * @param {Object} gridApi - AG Grid API reference
 * @param {Array} selectedRows - Selected rows to prune
 * @param {Object} logger - Logger instance
 * @returns {Promise<Object>} - Result of the operation
 */
export const bulkPrune = async (gridApi, selectedRows, logger) => {
    updateRowPruneStatus(gridApi, selectedRows, true)
    return await submitPruneStatus(null, selectedRows, 'prune', logger)
}

/**
 * Handles bulk unpruning operation
 * @param {Object} gridApi - AG Grid API reference
 * @param {Array} selectedRows - Selected rows to unprune
 * @param {Object} logger - Logger instance
 * @returns {Promise<Object>} - Result of the operation
 */
export const bulkUnprune = async (gridApi, selectedRows, logger) => {
    updateRowPruneStatus(gridApi, selectedRows, false)
    return await submitPruneStatus(null, selectedRows, 'unprune', logger)
}

/**
 * Creates pruning-related column definition for AG Grid
 * @returns {Object} - Column definition for pruning column
 */
export const getPruningColumnDef = () => {
    return {
        headerName: '',
        field: 'prune',
        width: 44,
        cellRenderer: (params) => {
            if (params.data) {
                const iconColorClass = params.data.prune ? 'pruned' : 'not-pruned'
                return `
                    <div class="pt-1 d-flex justify-content-center pointer prune-icon" style="margin-top: 8px">
                        <svg class="${iconColorClass}" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                            <path 
                                d="M5.33333 6.68333V12H6.66667V8.66667C7.37778 8.66667 8.05833 8.53333 8.70833 8.26667C9.35833 8 9.93333 7.61667 10.4333 7.11667C10.9333 6.61667 11.3194 6.03889 11.5917 5.38333C11.8639 4.72778 12 4.04444 12 3.33333V2H10.6667C9.95556 2 9.27222 2.13611 8.61667 2.40833C7.96111 2.68056 7.38333 3.06667 6.88333 3.56667C6.80556 3.64444 6.73333 3.71944 6.66667 3.79167C6.6 3.86389 6.53889 3.93889 6.48333 4.01667C6.36111 3.55 6.18611 3.11111 5.95833 2.7C5.73056 2.28889 5.44444 1.91111 5.1 1.56667C4.6 1.06667 4.02222 0.680556 3.36667 0.408333C2.71111 0.136111 2.03333 0 1.33333 0H0V1.33333C0 2.04444 0.133333 2.72778 0.4 3.38333C0.666667 4.03889 1.05 4.61667 1.55 5.11667C2.05 5.61667 2.62778 6.00278 3.28333 6.275C3.93889 6.54722 4.62222 6.68333 5.33333 6.68333Z"
                                fill="#000000"
                            />
                        </svg>
                    </div>
                `
            }
            return ''
        },
        filter: 'agSetColumnFilter',
        filterParams: {
            values: [true, false],
            valueFormatter: (p) => {
                return p.value === true ? 'Pruned' : 'Not Pruned'
            },
        },
        suppressMenu: true,
    }
}
