<template>
    <div class="company-popover">
        <VDropdown
            class="link-wrapper h-full"
            :placement="props.popperOptions.placement"
            boundary=".popper-hook"
            :distance="20"
            :skidding="20"
            :popperShowTriggers="['click']"
            :popperHideTriggers="['click']"
            :shown="showPopover"
            style="width: fit-content"
            @show="showCompanySummary()"
            @hide="emit('close-popover')"
        >
            <template #popper>
                <div
                    class="popper-content d-flex flex-wrap pa-5"
                    style="width: 800px; position: relative"
                >
                    <AonSpinner v-if="loadingSummary" class="ml-auto mr-auto my-5" :scale="0.3" />
                    <div v-else class="company-summary-details w-full">
                        <div class="close">
                            <font-awesome-icon
                                icon="far fa-xmark"
                                class="icon dungeon-depths--text"
                            />
                        </div>
                        <EntityTitle v-if="companyDetails" :entity="companyDetails" small />
                        <div class="wrap d-flex align-items-center mt-5">
                            <div
                                v-if="companyDetails?.ultimate_aon_entity_name && !loadingSummary"
                                class="d-flex align-items-center mr-5"
                            >
                                <font-awesome-icon
                                    icon="fas fa-buildings"
                                    class="grey01--text d-inline metric-icon mr-3"
                                />
                                <p class="grey01--text">
                                    <span style="font-weight: 600">Ultimate Parent:</span>
                                    {{ companyDetails.ultimate_aon_entity_name }}
                                </p>
                            </div>
                            <div
                                v-if="passedCompanyDetails.entityType && !loadingSummary"
                                class="d-flex align-items-center"
                            >
                                <font-awesome-icon
                                    icon="fas fa-building"
                                    class="grey01--text d-inline metric-icon mr-3"
                                />
                                <p class="grey01--text">
                                    {{ passedCompanyDetails.entityType }}
                                </p>
                            </div>
                        </div>
                        <p v-if="companyDetails" class="my-5">{{ companyDetails.description }}</p>
                        <div class="meta-holder d-flex w-full mb-4">
                            <div
                                v-if="companyDetails?.address"
                                class="mr-5 d-flex pl-1"
                                style="max-width: 230px; min-width: 230px"
                            >
                                <font-awesome-icon
                                    icon="fas fa-map-marker-alt"
                                    class="fa-fw mr-2 d-inline"
                                />
                                <span class="caption grey01--text">{{
                                    companyDetails.address.replace(/,/g, ', ')
                                }}</span>
                            </div>
                            <div
                                v-if="companyDetails?.direct_subsidiary_count"
                                class="mr-5 d-flex pl-1"
                                style="min-width: 200px"
                            >
                                <font-awesome-icon
                                    icon="fas fa-sitemap"
                                    class="fa-fw mr-2 d-inline"
                                />
                                <span class="caption grey01--text"
                                    >{{
                                        parseInt(
                                            companyDetails.direct_subsidiary_count
                                        ).toLocaleString()
                                    }}
                                    Subsidiaries</span
                                >
                            </div>
                            <a
                                v-if="companyDetails?.homepage_url"
                                :href="companyDetails.homepage_url"
                                target="_blank"
                                class="grey01--text"
                            >
                                <font-awesome-icon icon="fas fa-globe" class="fa-fw mr-2" />
                                <span class="caption">{{ companyDetails.homepage_url }}</span>
                            </a>
                        </div>
                        <div class="meta-holder d-flex w-full mt-">
                            <div class="meta mr-5" style="min-width: 200px">
                                <p class="bold grey01--text mb-1">Patent Count</p>
                                <p>
                                    {{ parseInt(companyDetails?.patent_count_us).toLocaleString() }}
                                </p>
                            </div>
                            <div class="meta mr-5" style="min-width: 200px">
                                <p class="bold grey01--text mb-1">Innovation Score</p>
                                <p>{{ parseInt(companyDetails?.power_score).toLocaleString() }}</p>
                            </div>
                            <div class="meta mr-5">
                                <p class="bold grey01--text mb-1">Avg. Filing Date</p>
                                <p>
                                    {{
                                        passedCompanyDetails.averageFilingDt
                                            ? filters.toUTCString(
                                                  passedCompanyDetails.averageFilingDt
                                              )
                                            : 'No Date Available'
                                    }}
                                </p>
                            </div>
                        </div>
                        <div class="button-holder d-flex align-items-center w-full mt-5">
                            <AddToWatchlistButton
                                v-if="!hideAddToWatchlist"
                                class="watchlist-button-hook mr-5"
                                :id-values="[passedCompanyDetails.aonEntityPk]"
                                id-type="Entity"
                                @click.stop
                            />
                            <a :href="entityRouteLink(getEntityId())" target="_blank">
                                <AonButton
                                    class="mr-5"
                                    label="Open Company In New Tab"
                                    text-with-icon
                                    :icon-options="{
                                        iconStyle: 'fas',
                                        iconName: 'fa-arrow-up-right-from-square',
                                    }"
                                />
                            </a>
                            <AonButton class="mr-5" label="View Company" @clicked="openCompany()" />
                        </div>
                    </div>
                </div>
            </template>
        </VDropdown>
    </div>
</template>

<script setup>
import { inject, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { entityRouteLink } from '../../router/utils'
import { getOpenSearchQuery } from '@/lib/openSearchQueryBuilder'
import * as osApi from '@/api/opensearch'

import AddToWatchlistButton from '@/components/Watchlists/WatchlistActions/AddToWatchlistButton.vue'
import EntityTitle from '@/components/company/EntityTitle'

const emit = defineEmits(['close-popover'])
const props = defineProps({
    showPopover: {
        type: Boolean,
        default: false,
    },
    passedCompanyDetails: {
        type: Object,
        default: () => {},
    },
    popperOptions: {
        type: Object,
        default: () => {
            return {
                placement: 'top',
            }
        },
    },
})

const logger = inject('logger')
const filters = inject('filters')
const router = useRouter()

const loadingSummary = ref(true)
const companyDetails = ref(null)
const hideAddToWatchlist = ref(false)

onMounted(() => {
    if (router.currentRoute.value.params.datasetPk) {
        hideAddToWatchlist.value = true
    }
})

const getEntityId = () =>
    props.passedCompanyDetails.aonEntityPk ||
    props.passedCompanyDetails.aon_entity_pk ||
    props.passedCompanyDetails.entityPk ||
    props.passedCompanyDetails.entityId

const showCompanySummary = async () => {
    loadingSummary.value = true
    try {
        const entityId = getEntityId()
        const dslQuery = getOpenSearchQuery({
            FC: ['aon_entity_pk'],
            FT: ['eq'],
            FV: [entityId],
        })
        const { data } = await osApi.searchEntities(dslQuery)

        let company = data.hits.hits.map((h) => h._source)[0]
        companyDetails.value = company
    } catch (err) {
        logger.error(err)
    } finally {
        loadingSummary.value = false
    }
}

const openCompany = () => {
    const entityId = getEntityId()
    router.push({
        name: 'Research Company',
        params: { targetPk: entityId },
        query: { tabName: 'profile' },
    })
    emit('close-popover')
}
</script>

<style lang="scss" scoped>
.close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
