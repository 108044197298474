<template>
    <div class="report-creation-nav w-full py-4 white d-flex justify-content-between">
        <AonButton
            :class="{
                disabled:
                    playbookGenerationStore.trackStepsFinal?.length === 0 ||
                    playbookGenerationStore.trackStepsFinal[0].isCurrentStep,
            }"
            class="mr-4"
            label="Back"
            type="ghost"
            @clicked="previousStep()"
        />
        <div
            v-if="
                !playbookGenerationStore.trackStepsFinal[
                    playbookGenerationStore.trackStepsFinal.length - 1
                ]?.isCurrentStep
            "
            class="btn-holder d-flex"
        >
            <AonButton
                class="align-self-end mr-5"
                :label="nextButtonLabel"
                @clicked="nextStep()"
                :loading="playbookGenerationStore.generateLoading"
                :class="{ disabled: playbookGenerationStore.nextButtonDisabled }"
            />
            <!-- Example if we want to jump to output -->
            <!-- <AonButton
                v-if="playbookGenerationStore.isPlaybookConfigComplete"
                class="align-self-end"
                label="Show Playbook"
                @clicked="gotoOutput()"
            /> -->
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'

const playbookGenerationStore = usePlaybookGenerationStore()
const router = useRouter()

const props = defineProps({
    currentReportPk: {
        type: String,
        default: '',
    },
    shouldShowSave: {
        type: Boolean,
        default: false,
    },
})

const nextButtonLabel = computed(() => {
    if (playbookGenerationStore.trackStepsFinal.length === 0) {
        return 'Next'
    }

    return playbookGenerationStore.isReportConfigComplete ? 'Generate' : 'Next'
})

const nextStep = async () => {
    playbookGenerationStore.setCompletedStep()
}

const previousStep = () => {
    return playbookGenerationStore.gotoPreviousStep()
}

// const gotoOutput = (allowEdit, stepNum) => {
//     if (!allowEdit) {
//         return
//     }
//     playbookGenerationStore.setCurrentStep(stepNum)
// }
</script>

<style lang="scss" scoped>
.report-creation-nav {
    width: 100%;
    height: 100%;
    border-top: 1px solid vars.$grey05;
    border-bottom: 1px solid vars.$grey05;
}
</style>
