<template>
    <div class="focus-table">
        <MoatTable
            class="w-full h-full"
            :class="themeClass"
            :column-defs="combinedColDefs"
            :row-data="props.tableData"
            :side-bar="sideBar"
            :tooltip-show-delay="500"
            @grid-ready="gridInit"
            @cell-clicked="onCellClicked"
            @filter-changed="onFilterChanged"
        />
    </div>
</template>

<script setup>
import { ref, inject, watch, nextTick, computed, onMounted } from 'vue'
import { useEntityStore, usePatentViewerStore } from '@/stores'

import { useI18n } from 'vue-i18n'
import { useFlag } from '@unleash/proxy-client-vue'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { formatTpeRange } from '@/lib/financial.js'
import { dateComparator, dateFilterComparator } from '@/components/moatTable/helpers/compare.js'
import moment from 'moment'

const isProUser = useFlag('ipAlpha.proUsers')

const { t } = useI18n()
const filters = inject('filters')

const entityStore = useEntityStore()
const patentViewerStore = usePatentViewerStore()

const emit = defineEmits(['filter-change'])
const props = defineProps({
    chartType: {
        type: String,
        default: 'overview',
    },
    tableData: {
        type: Array,
        default: () => [],
    },
})

const sideBar = ref(null)
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')

const allColumnDefs = ref([
    // Common columns
    {
        headerName: entityStore.strategyTabNodeFilter === 'product' ? 'Product' : 'Technology',
        headerTooltip: entityStore.strategyTabNodeFilter === 'product' ? 'Product' : 'Technology',
        field: 'displayName',
        minWidth: 280,
        flex: 1,
        filter: 'agTextColumnFilter',
        cellClass: 'link',
        pinned: 'left',
    },
    {
        headerName: entityStore.strategyTabNodeFilter === 'product' ? 'Company' : 'Product',
        headerTooltip: entityStore.strategyTabNodeFilter === 'product' ? 'Company' : 'Product',
        field: 'parentDisplayName',
        minWidth: 280,
        flex: 1,
        filter: 'agTextColumnFilter',
        cellClass: 'link',
        pinned: 'left',
        hide: entityStore.strategyTabNodeFilter === 'product', // Hide for product view
    },

    // Overview columns
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innovationScore'),
            headerName: 'Innovation Score',
        },
        field: 'innovationScore',
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.innovationScore.toFixed(0)).toLocaleString()}`
            }
        },
        minWidth: 195,
        flex: 1,
        filter: 'agNumberColumnFilter',
        hide: computed(() => props.chartType !== 'overview'),
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
            headerName: 'Momentum',
        },
        field: 'momentum',
        valueFormatter: (p) => {
            if (p.data) {
                return Math.round(p.data.momentum)
            }
        },
        minWidth: 155,
        flex: 1,
        filter: 'agNumberColumnFilter',
        hide: computed(() => props.chartType !== 'overview'),
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
            headerName: 'Market Appeal',
        },
        field: 'tpe',
        headerName: 'Market Appeal',
        headerTooltip: 'Market Appeal',
        minWidth: 180,
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return formatTpeRange(p.data.tpe, p.data.tpeLb, p.data.tpeUb)
            }
        },
        filter: 'agNumberColumnFilter',
        hide: computed(() => props.chartType !== 'overview'),
    },
    {
        field: 'patentCount',
        headerName: 'Patents',
        headerTooltip: 'Patents',
        minWidth: 155,
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.patentCount.toFixed(0)).toLocaleString()}`
            }
        },
        filter: 'agNumberColumnFilter',
        cellRenderer: 'MTCustomCellLinkWContext',
        cellRendererParams: {
            allowClick: true,
            cellClick: async (params) => {
                onShowPatentList(params, params.data.nodeId)
            },
        },
        sort: 'desc',
        sortIndex: 0,
        hide: computed(() => props.chartType !== 'overview'),
    },
    {
        field: 'peerPatentCount',
        headerName: 'Total Patents',
        headerTooltip: 'Total Patents',
        minWidth: 180,
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.peerPatentCount.toFixed(0)).toLocaleString()}`
            }
        },
        filter: 'agNumberColumnFilter',
        cellRenderer: 'MTCustomCellLinkWContext',
        cellRendererParams: {
            allowClick: true,
            cellClick: async (params) => {
                onShowPatentList(params, params.data.nodeId, true)
            },
        },
        sort: 'desc',
        sortIndex: 1,
        hide: computed(() => props.chartType !== 'overview'),
    },
    {
        field: 'entityCount',
        headerName: 'Innovators',
        headerTooltip: 'Innovators',
        minWidth: 160,
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.entityCount.toFixed(0)).toLocaleString()}`
            }
        },
        filter: 'agNumberColumnFilter',
        hide: computed(() => props.chartType !== 'overview'),
    },

    // Range columns
    {
        headerName: `${entityStore.entity.name} 50th Percentile Filing Date`,
        valueGetter: (p) => {
            if (p.data) {
                let date = filters.toUTCString(p.data.p50FilingDt)
                return date === '1/1/1' ? '--' : date
            }
        },
        field: 'p50FilingDt',
        comparator: dateComparator,
        flex: 1,
        filter: 'agDateColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            buttons: ['clear'],
            defaultOption: 'inRange',
            filterOptions: ['inRange'],
            maxNumConditions: 1,
            comparator: dateFilterComparator,
        },
        hide: computed(() => props.chartType !== 'range-bullet'),
    },
    {
        headerName: `${entityStore.entity.name} 75th Percentile Filing Date`,
        valueGetter: (p) => {
            if (p.data) {
                let date = filters.toUTCString(p.data.p75FilingDt)
                return date === '1/1/1' ? '--' : date
            }
        },
        field: 'p75FilingDt',
        comparator: dateComparator,
        flex: 1,
        filter: 'agDateColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            buttons: ['clear'],
            defaultOption: 'inRange',
            filterOptions: ['inRange'],
            maxNumConditions: 1,
            comparator: dateFilterComparator,
        },
        hide: true, // Always hidden by default
    },
    {
        headerName: 'Product Space Median Filing Date',
        valueGetter: (p) => {
            if (p.data) {
                let date = filters.toUTCString(p.data.peerP50FilingDt)

                if (date === '1/1/1') {
                    date = '--'
                }

                return `${date}`
            }
        },
        field: 'peerP50FilingDt',
        comparator: dateComparator,
        flex: 1,
        filter: 'agDateColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            buttons: ['clear'],
            defaultOption: 'inRange',
            filterOptions: ['inRange'],
            maxNumConditions: 1,
            comparator: dateFilterComparator,
        },
        hide: computed(() => props.chartType !== 'range-bullet'),
    },
    {
        headerName: 'Difference',
        valueGetter: (p) => {
            if (p.data && p.data.p50FilingDt && p.data.peerP50FilingDt) {
                const companyDate = moment(p.data.p50FilingDt)
                const peerDate = moment(p.data.peerP50FilingDt)

                if (
                    p.data.p50FilingDt === '0001-01-01T00:00:00+00:00' ||
                    p.data.peerP50FilingDt === '0001-01-01T00:00:00+00:00'
                ) {
                    return '--'
                }

                const yearDiff = companyDate.diff(peerDate, 'years')
                const monthDiff = companyDate.diff(peerDate, 'months') % 12

                let output = []
                if (yearDiff !== 0)
                    output.push(`${Math.abs(yearDiff)} year${Math.abs(yearDiff) !== 1 ? 's' : ''}`)
                if (monthDiff !== 0)
                    output.push(
                        `${Math.abs(monthDiff)} month${Math.abs(monthDiff) !== 1 ? 's' : ''}`
                    )

                return output.length
                    ? (companyDate > peerDate ? '+ ' : '- ') + output.join(', ')
                    : 'Same time'
            }
            return '--'
        },
        field: 'diffFilingDt',
        comparator: dateComparator,
        flex: 1,
        sortable: false,
        hide: computed(() => props.chartType !== 'range-bullet'),
    },

    // Bar columns
    {
        headerName: 'Patent Count',
        headerTooltip: 'Patent Count',
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.patentCount.toFixed(0)).toLocaleString()}`
            }
        },
        cellRenderer: 'MTCustomCellLinkWContext',
        cellRendererParams: {
            allowClick: isProUser.value,
            cellClick: async (params) => {
                onShowPatentList(params, params.data.nodeId)
            },
        },
        field: 'patentCount',
        flex: 1,
        filter: 'agNumberColumnFilter',
        sort: 'desc',
        hide: computed(() => props.chartType !== 'bar'),
    },
    {
        headerName: 'Breadth - Narrow',
        headerTooltip: 'Breadth - Narrow',
        valueGetter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.claimBreadthCounts.narrow.toFixed(0)).toLocaleString()}`
            }
        },
        cellRenderer: 'MTCustomCellLinkWContext',
        cellRendererParams: {
            allowClick: isProUser.value,
            cellClick: async (params) => {
                onShowPatentList(params, params.data.nodeId, false, 'Narrow')
            },
        },
        flex: 1,
        filter: 'agNumberColumnFilter',
        hide: computed(() => props.chartType !== 'bar'),
    },
    {
        headerName: 'Breadth - Broad',
        headerTooltip: 'Breadth - Broad',
        valueGetter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.claimBreadthCounts.broad.toFixed(0)).toLocaleString()}`
            }
        },
        cellRenderer: 'MTCustomCellLinkWContext',
        cellRendererParams: {
            allowClick: isProUser.value,
            cellClick: async (params) => {
                onShowPatentList(params, params.data.nodeId, false, 'Broad')
            },
        },
        flex: 1,
        filter: 'agNumberColumnFilter',
        hide: computed(() => props.chartType !== 'bar'),
    },
    {
        headerName: 'Breadth - Average',
        headerTooltip: 'Breadth - Average',
        valueGetter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.claimBreadthCounts.average.toFixed(0)).toLocaleString()}`
            }
        },
        cellRenderer: 'MTCustomCellLinkWContext',
        cellRendererParams: {
            allowClick: isProUser.value,
            cellClick: async (params) => {
                onShowPatentList(params, params.data.nodeId, false, 'Average')
            },
        },
        flex: 1,
        filter: 'agNumberColumnFilter',
        hide: computed(() => props.chartType !== 'bar'),
    },
    {
        headerName: 'Breadth - Very Broad',
        headerTooltip: 'Breadth - Very Broad',
        valueGetter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.claimBreadthCounts.veryBroad.toFixed(0)).toLocaleString()}`
            }
        },
        cellRenderer: 'MTCustomCellLinkWContext',
        cellRendererParams: {
            allowClick: isProUser.value,
            cellClick: async (params) => {
                onShowPatentList(params, params.data.nodeId, false, 'Very Broad')
            },
        },
        flex: 1,
        filter: 'agNumberColumnFilter',
        hide: computed(() => props.chartType !== 'bar'),
    },
    {
        headerName: 'Breadth - Not Scored',
        headerTooltip: 'Breadth - Not Scored',
        valueGetter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.claimBreadthCounts.notScored.toFixed(0)).toLocaleString()}`
            }
        },
        flex: 1,
        filter: 'agNumberColumnFilter',
        hide: computed(() => props.chartType !== 'bar'),
    },
])

watch(
    () => entityStore.strategyTabNodeFilter,
    (newFilter) => {
        const headerText = newFilter === 'product' ? 'Product' : 'Technology'

        if (gridApi.value) {
            const displayNameCol = gridApi.value.getColumnDef('displayName')
            if (displayNameCol) {
                displayNameCol.headerName = headerText
                displayNameCol.headerTooltip = headerText
                gridApi.value.refreshHeader()
            }

            gridApi.value.setColumnVisible('parentDisplayName', newFilter !== 'product')
        }
    }
)

// Add a watch for chartType to update column visibility
watch(
    () => props.chartType,
    (newChartType) => {
        updateColumnVisibility(newChartType)
    }
)

const combinedColDefs = computed(() => {
    return allColumnDefs.value.map((col) => {
        // If hide is a computed property, evaluate it
        if (col.hide && typeof col.hide === 'object' && col.hide.value !== undefined) {
            return { ...col, hide: col.hide.value }
        }
        return col
    })
})

const gridInit = (params) => {
    gridApi.value = params.api

    sideBar.value = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivotMode: true,
                },
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
    }

    updateColumnVisibility(props.chartType)
}

const updateColumnVisibility = (chartType) => {
    if (!gridApi.value) return

    gridApi.value.getColumnDefs().forEach((colDef) => {
        const field = colDef.field || colDef.colId

        // Always show the displayName column
        if (field === 'displayName') return

        // Show parentDisplayName only when not in product view
        if (field === 'parentDisplayName') {
            const visible = entityStore.strategyTabNodeFilter !== 'product'
            gridApi.value.setColumnVisible(field, visible)
            return
        }

        // For overview columns
        if (
            [
                'innovationScore',
                'momentum',
                'tpe',
                'patentCount',
                'peerPatentCount',
                'entityCount',
            ].includes(field)
        ) {
            gridApi.value.setColumnVisible(field, chartType === 'overview')
        }

        // For range-bullet columns
        else if (['p50FilingDt', 'peerP50FilingDt', 'diffFilingDt'].includes(field)) {
            gridApi.value.setColumnVisible(field, chartType === 'range-bullet')
        }

        // For bar columns
        else if (field === 'patentCount' || field.startsWith('claimBreadth')) {
            gridApi.value.setColumnVisible(field, chartType === 'bar')
        }
    })
}

const onCellClicked = async (params) => {
    if (params.column.colId !== 'displayName' && params.column.colId !== 'parentDisplayName') {
        return
    }
    entityStore.drillInTargetId = null
    setTimeout(() => {
        entityStore.drillInTargetId =
            params.column.colId === 'displayName' ? params.data.nodeId : params.data.parentNodeId
        entityStore.showDrillIn = true
    }, 100)
}

const onFilterChanged = async () => {
    const filteredRows = []
    gridApi.value.forEachNodeAfterFilter((node) => filteredRows.push(node.data))

    emit('filter-change', filteredRows)
}

const onShowPatentList = async (params, node, corpus = false, breadth = null) => {
    patentViewerStore.nodeIdList.push(node)
    patentViewerStore.entityName = entityStore.entity.name
    patentViewerStore.technologyName = params.data.technology
        ? params.data.technology
        : params.data.displayName

    if (!corpus) {
        patentViewerStore.entityPkList.push(entityStore.entity.aon_entity_pk)
    }

    if (breadth) {
        const basicsParams = {
            country: 'US',
            entityPks: patentViewerStore.entityPkList,
            nodeIds: patentViewerStore.nodeIdList,
            claimBreadth: breadth,
        }

        patentViewerStore.strategyTabPatentListObj = basicsParams
    }

    await nextTick()
    patentViewerStore.showPatentViewerList = true
}
</script>

<style lang="scss" scoped>
.focus-table {
    width: 100%;
    height: 400px;
}
</style>
