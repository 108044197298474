<template>
    <div class="company-search-result wrap d-flex align-items-center">
        <div class="new-tab-icon" v-tooltip="{ content: 'Open company in a new tab' }">
            <a :href="entityRouteLink(props.company.aon_entity_pk)" target="_blank">
                <font-awesome-icon
                    class="grey01--text mr-2"
                    icon="fas fa-arrow-up-right-from-square"
                    size="lg"
                />
            </a>
        </div>
        <div class="ultimate-parent-icon" v-tooltip="{ content: t('companies.ultimateParent') }">
            <font-awesome-icon
                class="grey01--text mr-2"
                v-if="isUltimateParent()"
                :icon="['far', 'diagram-subtask']"
                size="lg"
            />
        </div>
        <p
            class="d-flex overflow-ellipsis"
            v-tooltip="{ content: company.name }"
            @click="showCompanySummary"
        >
            <AonSearchResult class="link" :search-term="searchTerm" :value="company.name" />
        </p>
        <div v-if="!!company.stock_ticker" class="stock-ticker overflow-ellipsis mx-2">
            <template v-if="canHighlight">
                {{ prefix }}<strong>{{ highlighted }}</strong
                >{{ suffix }}
            </template>
            <template v-else>
                {{ company.stock_ticker }}
            </template>
        </div>
        <div
            v-if="props.type !== 'arena'"
            class="cursor-pointer"
            v-tooltip="{ content: t('entitySummary.showHierarchy') }"
            @click="setHierarchyCompany"
        >
            <font-awesome-icon class="fa-lg ml-2 knights-cloak--text" :icon="['far', 'sitemap']" />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { AonSearchResult } from '@moatmetrics/armory/src/components'
import { useRouter } from 'vue-router'
import { entityRouteLink } from '../../router/utils'
const router = useRouter()

const props = defineProps({
    company: {
        type: Object,
        required: true,
    },
    searchTerm: {
        type: String,
        default: null,
    },
    type: {
        type: String,
        default: 'standard',
    },
})

const emit = defineEmits(['set-hierarchy-company', 'show-company-summary'])
const { t } = useI18n()

const canHighlight = computed(() => {
    if (!props.searchTerm) {
        return false
    }

    return !(searchTermIndex.value === -1)
})
const highlighted = computed(() => {
    return props.company.stock_ticker.slice(
        searchTermIndex.value,
        searchTermIndex.value + searchTermLength.value
    )
})
const prefix = computed(() => {
    return props.company.stock_ticker.slice(0, searchTermIndex.value)
})
const searchTermIndex = computed(() => {
    return props.company.stock_ticker.toLowerCase().indexOf(props.searchTerm.toLowerCase())
})
const searchTermLength = computed(() => {
    return props.searchTerm.length
})
const suffix = computed(() => {
    return props.company.stock_ticker.slice(
        searchTermIndex.value + searchTermLength.value,
        props.company.stock_ticker.length
    )
})

const isUltimateParent = () => {
    return props.company.aon_entity_pk === props.company.ultimate_aon_entity_pk
}
const setHierarchyCompany = () => {
    emit('set-hierarchy-company')
}
const showCompanySummary = () => {
    emit('show-company-summary')
}
</script>

<style lang="scss" scoped>
.company-search-result {
    width: 100%;

    .ultimate-parent-icon,
    .new-tab-icon {
        cursor: pointer;
    }

    .stock-ticker {
        display: flex;
        height: 2.25em;
        padding: 5px 4px;
        border-radius: 4px;
        color: vars.$grey01;
        background-color: vars.$grey05;
        transition: all ease-in-out 0.3s;

        &:hover {
            color: vars.$white;
            background-color: vars.$grey01;
            transition: all ease-in-out 0.3s;
        }
    }
}
</style>
