<template>
    <div class="link-wrapper h-full d-flex align-items-center" @click="handleClick">
        <p class="small bold">{{ params.value.toLocaleString() }}</p>
        <ContextMenu
            v-if="isProUser"
            class="menu align-context-menu"
            trigger="hover"
            no-actions-message="View Patents"
        />
    </div>
</template>

<script setup>
import { inject, onMounted } from 'vue'
import { useFlag } from '@unleash/proxy-client-vue'

import ContextMenu from '@/components/helpers/ContextMenu.vue'

const isProUser = useFlag('ipAlpha.proUsers')

const eventBus = inject('eventBus')

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
})

const handleClick = () => {
    if (props.params.allowClick) {
        props.params.cellClick(props.params)
    }
}
</script>

<style lang="scss" scoped>
.link-wrapper {
    position: relative;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;

    transition: all 0.3s ease-in-out;

    &:hover {
        border-left: 1px solid vars.$grey04;
        border-right: 1px solid vars.$grey04;

        .menu {
            opacity: 1;
        }
    }

    .menu {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .align-context-menu {
        position: absolute;
        right: 20px;
        top: -5px;
    }
}
</style>
