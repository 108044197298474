<template>
    <div class="fee-summary w-full d-flex mt-2">
        <AonCol v-for="item in feeSummaryData" class="aon-col-4 pl-0">
            <AonCard :title="item?.title">
                <div class="card-content">
                    <p v-if="item.filterRange" class="small link filter-link" @click="sendFilters">
                        View Patents
                    </p>
                    <h5 :class="`${item?.color}--text`">
                        {{ item?.color === 'error' ? '-' : '' }}${{ item?.value.toLocaleString() }}
                    </h5>
                    <p class="bold small mt-2">{{ item?.scope }}</p>
                </div>
            </AonCard>
        </AonCol>
    </div>
</template>

<script setup>
import { computed, inject, ref, watchEffect } from 'vue'
import { usePortfolioManagerStore } from '@/stores'

const portfolioManagerStore = usePortfolioManagerStore()

const filters = inject('filters')

const emit = defineEmits(['send-filters'])
const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
})

const feeSummaryData = computed(() => {
    if (!props.data) return []
    return [
        {
            title: 'Upcoming Patent Fees',
            value: props.data[0] ? props.data[0] : 0,
            color: 'error',
            scope: `Due from ${filters.toUTCString(portfolioManagerStore.dateRange[0])} to ${filters.toUTCString(portfolioManagerStore.dateRange[1])}`,
            filterRange: true,
        },
        {
            title: 'Total Fees Pruned',
            value: props.data[1] ? props.data[1] : 0,
            color: 'success',
            scope: `${(props.data[1] / props.data[0]).toFixed(2)}% of total fees saved`,
            filterRange: true,
        },
        {
            title: 'Lifetime Savings',
            value: props.data[2] ? props.data[2] : 0,
            color: 'success',
        },
    ]
})

const sendFilters = () => {
    emit('send-filters', {
        dateRange: {
            from: portfolioManagerStore.dateRange[0],
            to: portfolioManagerStore.dateRange[1],
        },
    })
}
</script>

<style lang="scss" scoped>
.fee-summary {
    width: 100%;

    .card-content {
        position: relative;
    }

    .filter-link {
        position: absolute;
        top: -40px;
        right: -0px;
    }
}
</style>
