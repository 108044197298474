<template>
    <div class="context-menu d-flex justify-content-center">
        <VDropdown
            class="ml-2"
            :placement="props.placement"
            :distance="8"
            :triggers="[props.trigger]"
            :popper-triggers="[props.trigger]"
        >
            <font-awesome-icon
                class="pointer menu-icon"
                :icon="`fas grey04--text fa-square-list`"
            />
            <template #popper>
                <div class="popover-container pa-2">
                    <div
                        v-if="props.actionsList && props.actionsList.length > 0"
                        v-for="(item, index) in props.actionsList"
                        class="context-action"
                        @click="onActionClick(`${item?.actionName}`)"
                    >
                        <div v-if="item?.name" class="action-inner pa-2 d-flex align-items-center">
                            <font-awesome-icon :icon="`fas ${item?.icon}`" />
                            <p class="small ml-2">{{ item?.name }}</p>
                        </div>
                    </div>
                    <div v-else class="no-actions-holder">
                        <p>{{ props.noActionsMessage }}</p>
                    </div>
                </div>
            </template>
        </VDropdown>
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from 'vue'

const emit = defineEmits(['patentsClicked'])

const props = defineProps({
    actionsList: {
        type: Object,
    },
    trigger: {
        type: String,
        default: 'click',
    },
    placement: {
        type: String,
        default: 'top',
    },
    noActionsMessage: {
        type: String,
        default: 'No actions available',
    },
})

const onActionClick = (actionName) => {
    emit(`${actionName}Clicked`)
}
</script>

<style lang="scss">
.context-action {
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;

    p,
    svg {
        color: vars.$grey01;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        background-color: vars.$grey03;

        p,
        svg {
            color: white;
        }
    }
}

.menu-icon {
    padding: 6px;
    border: solid 1px vars.$grey04;
    border-radius: 4px;
    margin-top: 7px;
}

.cta-holder {
    width: 28px;
    height: 28px;
    border-radius: 50%;

    cursor: pointer;

    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: vars.$grey05;
    }
}
</style>
