<template>
    <div class="risk-tab w-full">
        <AonSpinner
            v-if="entityStore.loadingEntity || !moat2ProductStore.alignmentPk"
            class="ma-auto"
            :scale="0.5"
            color="castleMoat"
        />
        <div v-else class="card-holder">
            <LitigationHistoryCard />
            <div class="node-loading-holder">
                <AonContainer>
                    <AonRow class="mt-5">
                        <AonCol
                            class="aon-col-12 pa-0"
                            style="position: relative; min-height: 300px"
                        >
                            <AonCoverLoading
                                :loading="isProductLoading"
                                title="Gathering Company Details..."
                            />
                            <CaseHighlightsCard
                                v-if="
                                    !moat2ProductStore.productAlignmentLoading &&
                                    moat2ProductStore.productAlignmentProducts.length > 0
                                "
                                title="Infringement Summary"
                                description="This accounts for industry-wide litigation history"
                                :nodeData="{
                                    id: moat2ProductStore.productAlignmentProducts[0].parent,
                                }"
                                hide-options
                                class="grey05"
                            />
                        </AonCol>
                    </AonRow>
                </AonContainer>
            </div>
            <div class="node-loading-holder">
                <AonContainer>
                    <AonRow class="mt-5">
                        <AonCol
                            class="aon-col-12 pa-0"
                            style="position: relative; min-height: 300px"
                        >
                            <AonCoverLoading
                                :loading="isProductLoading"
                                title="Gathering Company Details..."
                            />
                            <PeerHistory
                                v-if="
                                    !moat2ProductStore.productAlignmentLoading &&
                                    moat2ProductStore.productAlignmentProducts.length > 0
                                "
                                title="Infringement Summary"
                                description="This accounts for industry-wide litigation history"
                                :nodeData="{
                                    id: moat2ProductStore.productAlignmentProducts[0].parent,
                                }"
                            />
                        </AonCol>
                    </AonRow>
                </AonContainer>
            </div>
            <IndustryLitigationCard
                :key="updater"
                :loading="loadingData || moat2ProductStore.productAlignmentLoading"
                :data="litigationData"
            />
            <InnovationTrendsRiskCard />
            <div class="node-loading-holder">
                <AonContainer>
                    <AonRow class="mt-5">
                        <AonCol
                            class="aon-col-12 pa-0"
                            style="position: relative; min-height: 300px"
                        >
                            <AonCoverLoading
                                :loading="isProductLoading"
                                title="Gathering Company Details..."
                            />
                        </AonCol>
                    </AonRow>
                </AonContainer>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, watch, onMounted, computed } from 'vue'
import { useEntityStore, useMoat2ProductStore } from '@/stores'
import { getLitigationRisk } from '@/api/risk.js'

import LitigationHistoryCard from '@/components/company/tabs/risk/LitigationHistoryCard.vue'
import IndustryLitigationCard from '@/components/company/tabs/risk/industryLitigation/IndustryLitigationCard.vue'
import InnovationTrendsRiskCard from '@/components/company/tabs/risk/InnovationTrendsRiskCard.vue'

// From DrillIn > risk
import CaseHighlightsCard from '@/components/company/drillIn/risk/CaseHighlightsCard.vue'
import PeerHistory from './PeerHistory.vue'

const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()

const logger = inject('logger')

const litigationData = ref([])
const loadingData = ref(true)
const updater = ref(0)

onMounted(async () => {
    if (moat2ProductStore.alignmentPk) {
        fetchLitigationRisk()
    }
})

watch(
    () => moat2ProductStore.alignmentPk,
    (newVal, oldVal) => {
        if (newVal) {
            loadingData.value = true
            fetchLitigationRisk()
        }
    }
)
watch(
    () => moat2ProductStore.productAlignmentLoading,
    (newVal, oldVal) => {
        if (!newVal) {
            loadingData.value = true
            fetchLitigationRisk()
        }
    }
)

const isProductLoading = computed(
    () =>
        moat2ProductStore.productAlignmentLoading ||
        moat2ProductStore.productAlignmentProducts.length === 0
)

const fetchLitigationRisk = async () => {
    if (moat2ProductStore.productAlignmentLoading) {
        return
    }
    try {
        const { data } = await getLitigationRisk(moat2ProductStore.alignmentPk)
        if (data.length === 0) {
            litigationData.value = []
            return
        }

        litigationData.value = data
        updater.value++
        loadingData.value = false
    } catch (error) {
        logger.error(error)
    }
}
</script>

<style lang="scss" scoped>
.risk-tab {
    width: 100%;
    position: relative;
}
</style>
