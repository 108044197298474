<template>
    <div class="yearly-patent-filings-comparison">
        <AonCoverLoading :loading="loading" />
        <div class="chart">
            <div
                v-if="
                    props.dataReady &&
                    (props.licensorData.length == 0 || props.licenseeData.length == 0)
                "
                class="no-data"
            >
                <p>No data available</p>
            </div>
            <div
                v-show="
                    props.dataReady &&
                    props.licensorData.length > 0 &&
                    props.licenseeData.length > 0
                "
                class="chart-container"
            >
                <div ref="chartRef" class="chart"></div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import * as am5 from '@amcharts/amcharts5'
import {
    XYChart,
    XYSeries,
    ColumnSeries,
    CategoryAxis,
    ValueAxis,
    AxisRendererX,
    AxisRendererY,
} from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
export interface YearlyPatentCount {
    year: number
    count: number
}

interface CombinedData {
    year: number
    licensorCount: number
    licenseeCount: number
}

const props = defineProps({
    licensorData: {
        type: Array as () => YearlyPatentCount[],
        required: true,
    },
    licenseeData: {
        type: Array as () => YearlyPatentCount[],
        required: true,
    },
    licensorName: {
        type: String,
    },
    licenseeName: {
        type: String,
    },
    dataReady: {
        type: Boolean,
    },
})

const chartRef = ref(null)
const loading = ref(true)
let root: am5.Root | null = null
let chart: XYChart | null = null
let series: ColumnSeries[] = []
let xAxis: CategoryAxis | null = null
let yAxis: ValueAxis | null = null
let legend: am5.Legend | null = null

const makeSeries = (name: string, valueField: string) => {
    if (!root || !chart) return null

    const newSeries = chart.series.push(
        ColumnSeries.new(root, {
            name: name,
            xAxis: xAxis!,
            yAxis: yAxis!,
            valueYField: valueField,
            categoryXField: 'year',
            clustered: true,
        })
    )

    newSeries.columns.template.setAll({
        tooltipText: '{name}: {valueY}',
        tooltipY: am5.percent(90),
    })

    newSeries.adapters.add('name', (text) => {
        if (text === 'Licensor') {
            return props.licensorName
        } else if (text === 'Licensee') {
            return props.licenseeName
        }
        return text
    })

    series.push(newSeries)
    return newSeries
}

const createChart = () => {
    loading.value = true
    if (!chartRef.value) return

    // Create root element
    root = am5.Root.new(chartRef.value)
    root.setThemes([am5themes_Animated.new(root)])
    root.numberFormatter.setAll({
        numberFormat: '#.',
    })

    // Create chart
    chart = root.container.children.push(
        XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none',
            layout: root.verticalLayout,
        })
    )

    // Create axes
    xAxis = chart.xAxes.push(
        CategoryAxis.new(root, {
            categoryField: 'year',
            renderer: AxisRendererX.new(root, {
                minGridDistance: 30,
                fontSize: 13,
                visible: true,
                paddingTop: 20,
            }),
        })
    )

    xAxis.get('renderer').labels.template.set('rotation', -45)
    xAxis.get('renderer').labels.template.set('centerY', am5.p50)
    xAxis.get('renderer').labels.template.set('centerX', am5.p100)

    yAxis = chart.yAxes.push(
        ValueAxis.new(root, {
            renderer: AxisRendererY.new(root, {}),
        })
    )

    // Create series
    makeSeries('Licensor', 'licensorCount')
    makeSeries('Licensee', 'licenseeCount')

    // Add legend
    legend = chart.children.push(
        am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
        })
    )

    // Add cursor
    //chart.set('cursor', XYCursor.new(root, {}))

    updateChartData()
}

const updateChartData = () => {
    loading.value = true

    if (props.licensorData.length == 0 || props.licenseeData.length == 0) {
        if (props.dataReady) {
            loading.value = false
        }
        return
    }

    // Process and combine data
    const combinedData =
        props.licensorData
            ?.map((licensorItem) => {
                const licenseeItem = props.licenseeData?.find(
                    (item) => item.year === licensorItem.year
                )
                return {
                    year: licensorItem.year,
                    licensorCount: licensorItem.count,
                    licenseeCount: licenseeItem ? licenseeItem.count : 0,
                }
            })
            ?.sort((a, b) => a.year - b.year) ?? []

    // Update chart data
    if (series.length > 0 && xAxis && legend) {
        series.forEach((s) => {
            s.data.setAll(combinedData)
        })
        xAxis.data.setAll(combinedData)
        legend.data.setAll(chart.series.values)
    }
    loading.value = false
}

watch(
    () => [props.licensorData, props.licenseeData, props.dataReady],
    () => {
        updateChartData()
    },
    { deep: true }
)

onMounted(() => {
    createChart()
    updateChartData()
})
</script>

<style scoped>
.yearly-patent-filings-comparison {
    width: 100%;
    height: 100%;
    position: relative;
}

.chart {
    width: 100%;
    height: 300px;
}

.no-data {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>
