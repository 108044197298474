<template>
    <div class="moat-date-range-picker">
        <VueDatePicker
            v-model="date"
            class="date-range-hook"
            range
            multi-calendars
            :enable-time-picker="false"
            :preset-dates="presetDates"
            :min-date="today"
            @update:model-value="handleDateChange"
        >
            <template #preset-date-range-button="{ label, value, presetDate }">
                <div class="preset-label">
                    <span
                        :tabindex="0"
                        @click="presetDate(value)"
                        @keyup.enter.prevent="presetDate(value)"
                        @keyup.space.prevent="presetDate(value)"
                    >
                        {{ label }}
                    </span>
                </div>
            </template>
        </VueDatePicker>
    </div>
</template>
<script setup>
import { computed, ref, onMounted } from 'vue'
import { usePortfolioManagerStore } from '@/stores'

import {
    endOfMonth,
    endOfYear,
    startOfMonth,
    startOfYear,
    startOfQuarter,
    endOfQuarter,
    addQuarters,
    addYears,
} from 'date-fns'

const portfolioManagerStore = usePortfolioManagerStore()

const date = ref()
const today = ref(new Date())

const presetDates = ref([
    { label: 'This month', value: [today.value, endOfMonth(new Date())] },
    { label: 'This quarter', value: [today.value, endOfQuarter(new Date())] },
    {
        label: 'Next quarter',
        value: [
            startOfQuarter(addQuarters(new Date(), 1)),
            endOfQuarter(addQuarters(new Date(), 1)),
        ],
    },
    { label: 'This year', value: [today.value, endOfYear(new Date())] },
    {
        label: 'Next year',
        value: [startOfYear(addYears(new Date(), 1)), endOfYear(addYears(new Date(), 1))],
    },
])

onMounted(() => {
    const startDate = new Date()
    const endDate = endOfYear(new Date())
    date.value = [startDate, endDate]
})

const handleDateChange = (value) => {
    const formatDate = (date) => {
        const newDate = new Date(date)
        newDate.setHours(0, 0, 0, 0)
        return newDate.toISOString().split('T')[0]
    }

    portfolioManagerStore.dateRange = [formatDate(value[0]), formatDate(value[1])]
}
</script>

<style lang="scss" scoped>
.moat-date-range-picker {
    width: 425px;
    height: 100%;

    .date-range-hook {
        border-radius: 8px;
        border: 3px solid vars.$castleMoat;

        :deep(.dp__theme_light) {
            --dp-arrow-left: 75% !important;
        }

        :deep(.dp--preset-range) {
            padding: 10px;
            margin: 2px 0;
            text-align: center;

            transition: all 0.3s ease-in-out;

            &:hover {
                background: rgba(vars.$castleMoat, 0.2) !important;
            }
        }

        :deep(.dp__action_button) {
            height: 40px;
            font-size: 16px;
            margin: 0 4px;
            padding: 4px 16px;
        }

        :deep(.dp--menu-wrapper) {
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
        }
    }

    .preset-label {
    }
}
</style>
