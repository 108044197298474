<template>
    <div class="violin-chart">
        <div id="amChartViolin" ref="amChartViolin"></div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import { useMoat2ProductStore } from '@/stores'
import { config } from '@/config'
import * as am5 from '@amcharts/amcharts5'

import { useViolinChart } from '@/composables/charts/violinChart'
import {
    dataPlum,
    dataTeal,
    dataBlue,
    dataAqua,
    dataCobalt,
    dataMarine,
    dataMagenta,
    dataRaspberry,
    dataOrange,
    dataYellow,
    dataLime,
    dataGreen,
} from '@/styles/js_variables.module.scss'

const moat2ProductStore = useMoat2ProductStore()
const props = defineProps({
    chartData: {
        type: Array,
        default: () => [],
    },
})

let root

const setColors = computed(() => {
    let baseColors = [
        dataTeal,
        dataPlum,
        dataBlue,
        dataRaspberry,
        dataOrange,
        dataYellow,
        dataLime,
        dataGreen,
        dataMagenta,
        dataMarine,
        dataCobalt,
        dataAqua,
    ]
    return baseColors
})

const competitors = computed(() =>
    moat2ProductStore.productAlignmentTopCompetitors.map((comp, index) => ({
        displayName: comp.aonEntityName,
        color: setColors.value[index],
    }))
)

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartViolin')
    const violinChart = useViolinChart(root, competitors.value, props.chartData)

    violinChart.configureViolin()
    violinChart.createAllSeries()
})

onBeforeUnmount(() => {
    if (root) {
        root.dispose()
    }
})
</script>

<style lang="scss" scoped>
.violin-chart {
    width: 100%;
    height: 100%;
    position: relative;

    #amChartViolin {
        width: 100%;
        height: 100%;

        div {
            height: 100%;
        }
    }
}
</style>
