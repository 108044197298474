<template>
    <div class="report-creation-nav pa-4 white d-flex justify-content-between">
        <AonButton label="Cancel" type="subtle" @clicked="cancelReportCreation()" />
        <div class="creation-local-btns d-flex">
            <AonButton
                v-if="
                    reportGenerationStore.trackStepsFinal.length > 0 &&
                    !reportGenerationStore.trackStepsFinal[0].isCurrentStep &&
                    !reportGenerationStore.trackStepsFinal[1].isCurrentStep
                "
                class="mr-4"
                label="Back"
                type="ghost"
                @clicked="previousStep()"
            />
            <AonButton
                :label="nextButtonLabel"
                @clicked="nextStep()"
                :loading="reportGenerationStore.generateLoading"
                :class="{ disabled: reportGenerationStore.nextButtonDisabled }"
            />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useReportGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'

const reportGenerationStore = useReportGenerationStore()
const router = useRouter()

const props = defineProps({
    currentReportPk: {
        type: String,
        default: '',
    },
    shouldShowSave: {
        type: Boolean,
        default: false,
    },
})

const nextButtonLabel = computed(() => {
    if (reportGenerationStore.trackStepsFinal.length === 0) {
        return 'Next'
    }

    if (reportGenerationStore.reportTypeSelected === 'compAnalysis') {
        if (reportGenerationStore.contenderSource === 'auto') {
            return 'Generate'
        }
    }

    return reportGenerationStore.isReportConfigComplete ? 'Generate' : 'Next'
})

const cancelReportCreation = () => {
    router.go(-1)
}

const nextStep = async () => {
    if (nextButtonLabel.value === 'Generate') {
        reportGenerationStore.generateReport()
    } else {
        reportGenerationStore.setCompletedStep()
    }
}

const previousStep = () => {
    return reportGenerationStore.gotoPreviousStep()
}
</script>

<style lang="scss" scoped>
.report-creation-nav {
    width: 100%;
    height: 100%;
    border-top: 1px solid vars.$grey05;
    border-bottom: 1px solid vars.$grey05;
}
</style>
