<template>
    <div class="treemap-chart d-flex align-items-center pb-12">
        <div id="amChartsTreemap"></div>
        <div
            class="legend-holder"
            v-tooltip="{
                content: $t('productAlignment.definitionTooltips.innImpliedPE'),
            }"
        >
            <div class="legend-labels d-flex justify-content-between w-full mb-1">
                <p>Low Innovation Implied P/E</p>
                <p>High Innovation Implied P/E</p>
            </div>
            <div class="legend"></div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, shallowRef } from 'vue'
import { useMoat2ProductStore } from '@/stores'
import { useTreemapChart } from '@/composables/charts/treemapChart'
import * as am5 from '@amcharts/amcharts5'
import { config } from '@/config'

let root
const treeData = shallowRef()
const moat2ProductStore = useMoat2ProductStore()

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartsTreemap')
    const { configureTreemap, generateTreemapSeries, setTreemapData } = useTreemapChart(root)

    configureTreemap()
    generateTreemapSeries()
    configureData()
    setTreemapData(treeData.value[0])
})

onBeforeUnmount(() => {
    root.dispose()
})

const configureData = () => {
    let techAreas = [...moat2ProductStore.productAlignmentAllData].filter(
        (item) => item.targetEntityPatentCount > 0
    )
    let products = moat2ProductStore.productAlignmentProducts.map((prod) => {
        return {
            name: prod.to,
            productId: prod.nodeId,
            heat: 0,
            children: [],
        }
    })

    techAreas.forEach((item) => {
        let targetProd = products.find((prod) => prod.productId === item.parentNodeId)

        targetProd.children.push({
            name: item.displayName,
            value: parseInt(item.targetEntityPowerScore.toFixed(0)),
            heat: parseInt(item.targetTpe?.toFixed(0)),
        })
    })

    treeData.value = [
        {
            name: '',
            children: products.filter((item) => item.children.length > 0),
        },
    ]
}
</script>

<style lang="scss" scoped>
.treemap-chart {
    width: 100%;
    position: relative;

    #amChartsTreemap {
        width: 100%;
        height: 500px;

        div {
            height: 100%;
        }
    }

    .legend-holder {
        width: 100%;

        position: absolute;
        bottom: 0;
        left: 0;

        .legend {
            width: 100%;
            height: 10px;
            background: linear-gradient(90deg, rgba(188, 201, 223, 1) 0%, rgba(20, 56, 98, 1) 100%);
        }
    }

    .back-button {
        position: absolute;
        left: 15px;
        top: 50px;

        background: white !important;
    }
}
</style>
