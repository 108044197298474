import axios from 'axios'

const baseUrl = 'api/entities'
const basePruneUrl = 'api/patents'

export function getFeeSummary(entityId, dateRange) {
    return axios.post(`${baseUrl}/${entityId}/patent_fee_summary`, {
        startDate: dateRange[0],
        endDate: dateRange[1],
    })
}

export function getProductFees(entityId, dateRange) {
    return axios.post(`${baseUrl}/${entityId}/product_fees`, {
        startDate: dateRange[0],
        endDate: dateRange[1],
    })
}

export function getProductFeesByClaim(entityId, dateRange) {
    return axios.post(`${baseUrl}/${entityId}/product_claim_fees`, {
        startDate: dateRange[0],
        endDate: dateRange[1],
    })
}

export function prunePatents(publicationPks) {
    const params = {
        publicationPks: publicationPks,
    }
    return axios.post(`${basePruneUrl}/prune`, params)
}

export function unprunePatents(publicationPks) {
    const params = {
        publicationPks: publicationPks,
    }
    return axios.delete(`${basePruneUrl}/prune`, { data: params })
}
