<template>
    <div class="output-view d-flex">
        <div v-show="moat2ProductStore.productAlignmentActiveTab === 1" class="tech-area-holder">
            <AonCoverLoading
                v-if="
                    (allData.length === 0 ||
                        loadingTechAreas ||
                        moat2ProductStore.productAlignmentTechAreaNodes.size === 0) &&
                    !showOutput
                "
                :loading="true"
                title="Compiling Tech Areas"
            />
            <TechAreas
                v-else
                type="pa"
                :all-data="allData"
                :all-chart-data="allChartData"
                :table-data="tableData"
                :patent-breakdown="patentBreakdown"
                :additionalNodeDataIsProcessing="additionalNodeDataIsProcessing"
                :newDataAvailable="newDataAvailable"
                :loading="loadingTechAreas"
            />
        </div>
        <div v-show="moat2ProductStore.productAlignmentActiveTab === 2" class="arena-space-holder">
            <BenchmarkPeers
                :entity-id="props.entityId"
                :loading="loadingArenaSpace"
                @everything-done-loading="setEverythingDoneLoading"
            />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, inject, onBeforeMount, onBeforeUnmount, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { getProductAlignmentSummary } from '@/api/productAlignment.js'
import { useEntityStore, useMoat2ProductStore } from '@/stores'
import { useFlag } from '@unleash/proxy-client-vue'
import { cloneDeep } from 'lodash-es'

import OutputSideNav from '@/components/alignmentOutput/OutputSideNav.vue'
import TechAreas from '@/components/alignmentOutput/techAreas/TechAreas.vue'
import BenchmarkPeers from '@/components/alignmentOutput/benchmarkPeers/BenchmarkPeers.vue'

const eventBus = inject('eventBus')
const logger = inject('logger')
const { t } = useI18n()
const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()

const newDataAvailable = ref(false)

const props = defineProps({
    entityId: {
        type: [Number, String],
        default: undefined,
    },
    allChartData: {
        type: Array,
        default: () => [],
    },
    patentBreakdown: {
        type: Object,
        default: () => {},
    },
})

const showOutput = ref(false)
const everythingDoneLoading = ref(false)
const loadingTechAreas = ref(false)
const allData = ref([])
const tableData = ref([])

const loadingArenaSpace = ref(true)
const additionalNodeDataIsProcessing = computed(() => {
    return Boolean(props.allChartData.find((node) => node.dataStatus === 'processing'))
})

watch(
    additionalNodeDataIsProcessing,
    (newVal, oldVal) => {
        if (oldVal && !newVal) {
            newDataAvailable.value = true
        }
    },
    { immediate: true }
)

onBeforeMount(() => {
    moat2ProductStore.productAlignmentActiveTab = 1
})

onMounted(async () => {
    getTechAreaData()
    eventBus.on('set-arena-space-loading', (bool) => {
        loadingArenaSpace.value = bool
    })
})

onBeforeUnmount(() => {
    eventBus.all.clear()
})

const getTechAreaData = async () => {
    loadingTechAreas.value = true
    try {
        const { data } = await getProductAlignmentSummary(
            moat2ProductStore.alignmentPk,
            moat2ProductStore.techAreaIdCollection
        )
        allData.value = cloneDeep(data.nodes)
        tableData.value = data.nodes.filter((item) => item.displayName !== 'Other')
        //tracking filtered data state
        moat2ProductStore.productAlignmentAllData = tableData.value

        //tracking selections made by user
        moat2ProductStore.productAlignmentTechAreaNodes = new Set(tableData.value)
        moat2ProductStore.initLoad = true
    } catch (err) {
        logger.error(err)
    } finally {
        loadingTechAreas.value = false
    }
}

const setEverythingDoneLoading = (bool) => {
    everythingDoneLoading.value = bool
}
</script>

<style lang="scss" scoped>
.output-view {
    width: 100%;
    height: 100%;
    position: relative;

    .tech-area-holder,
    .arena-space-holder {
        position: relative;
        width: 100%;
        min-width: 500px;
        min-height: 400px;
        height: max-content;
    }

    :deep(.a-cover-loading) {
        z-index: 2 !important;
    }
}
</style>
