import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { getPatentDetails } from '@/api/patentViewer.js'

export const usePatentViewerStore = defineStore('patentViewer', () => {
    const nodeIdList = ref([])
    const entityPkList = ref([])
    const showPatentViewerList = ref(false)
    const entityName = ref('')
    const entityPk = ref(null)
    const technologyName = ref('')
    const targetPatentId = ref(null)
    const patentDetailsOpen = ref(false)
    const patentDetails = ref(null)
    const listViewType = ref(null)
    const ipBasicsObject = ref(null)
    const ipBasicsEntityPK = ref(null)
    const cachedPatentDetails = ref({})
    const insertionOrder = ref([])

    const resetState = () => {
        nodeIdList.value = []
        entityPkList.value = []
        showPatentViewerList.value = false
        entityName.value = ''
        entityPk.value = null
        technologyName.value = ''
        targetPatentId.value = null
        patentDetailsOpen.value = false
        patentDetails.value = null
        listViewType.value = null
        ipBasicsObject.value = null
        ipBasicsEntityPK.value = null
        cachedPatentDetails.value = {}
        insertionOrder.value = []
    }

    const fetchPatentDetails = async (patentId) => {
        if (
            patentExistsInCache &&
            cachedPatentDetails.value[patentId] &&
            !cachedPatentDetails.value[patentId].isRowData &&
            patentId === targetPatentId.value
        ) {
            patentDetails.value = cachedPatentDetails.value[patentId]
            return
        }

        try {
            const { data } = await getPatentDetails(patentId)
            setCachedPatentDetail(patentId, data)
        } catch (error) {
            console.error('Error fetching patent details:', error)
        }
    }

    const patentExistsInCache = (patentId) => {
        return cachedPatentDetails.value[patentId]
    }

    const setCachedPatentRowDetail = (patentId, rowDetail) => {
        if (patentId === targetPatentId.value) {
            patentDetails.value = rowDetail
        }
        updatePatentDetailCache(patentId, rowDetail)
    }

    const setCachedPatentDetail = (patentId, detail) => {
        if (patentId === targetPatentId.value) {
            patentDetails.value = detail
        }
        updatePatentDetailCache(patentId, detail)
    }
    const updatePatentDetailCache = (patentId, detail) => {
        if (!cachedPatentDetails.value[patentId]) {
            insertionOrder.value.push(patentId)
        } else {
            const index = insertionOrder.value.indexOf(patentId)
            if (index !== -1) {
                insertionOrder.value.splice(index, 1)
                insertionOrder.value.push(patentId)
            }
        }

        cachedPatentDetails.value[patentId] = detail
        while (Object.keys(cachedPatentDetails.value).length > 30) {
            const oldestKey = insertionOrder.value.shift()
            delete cachedPatentDetails.value[oldestKey]
        }
    }

    const loadingPatentDetail = computed(() => {
        return (
            targetPatentId?.value === patentDetails?.value?.publicationPk &&
            patentDetails?.value?.isRowData
        )
    })

    return {
        nodeIdList,
        entityPkList,
        showPatentViewerList,
        entityName,
        technologyName,
        targetPatentId,
        patentDetailsOpen,
        patentDetails,
        listViewType,
        ipBasicsObject,
        ipBasicsEntityPK,
        cachedPatentDetails,
        insertionOrder,
        resetState,
        fetchPatentDetails,
        patentExistsInCache,
        setCachedPatentDetail,
        setCachedPatentRowDetail,
        loadingPatentDetail,
    }
})
