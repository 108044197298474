<template>
    <div
        class="output-side-nav d-flex flex-column justify-content-between"
        :class="[{ collapse: collapse }, { 'sticky-fix': props.type !== 'pa' }]"
    >
        <div class="top" :class="{ 'sticky-fix': props.type !== 'pa' }">
            <div
                v-if="props.type !== 'pa'"
                class="target-pad-wrapper pa-4"
                :class="{ cursor: collapse }"
                @click="collapse ? (collapse = false) : (collapse = false)"
            >
                <div
                    class="target-entity-holder d-flex flex-wrap pa-5 knights-cloak align-space-between flex-column"
                >
                    <div class="title-holder d-flex align-items-center">
                        <font-awesome-icon
                            :icon="`fas fa-bullseye`"
                            class="icon white--text mr-3 target-icon"
                            size="lg"
                        />
                        <h6 class="tab-title white--text">Target Company</h6>
                    </div>
                    <p class="tab-desc white--text mt-2 mb-5 w-full">
                        Select your target company to focus your analysis. Gain detailed insights
                        into their competitive landscape and innovation footprint.
                    </p>
                    <div
                        v-if="
                            arenaOutputStore.targetCompany?.length > 0 &&
                            arenaOutputStore.targetCompany[0]
                        "
                        class="target-company-details-holder w-full"
                    >
                        <AonSpinner
                            v-if="arenaOutputStore.targetCompanyLoading"
                            color="castleMoat"
                            class="ml-auto mr-auto mt-3"
                            :scale="0.3"
                        />
                        <div v-else class="company-details">
                            <p class="extra-large overflow-ellipsis white--text">
                                {{ arenaOutputStore.targetCompany[0].aonEntityName }}
                            </p>
                            <p class="bold white--text mt-5">Patent Count</p>
                            <p class="extra-large overflow-ellipsis white--text mt-2">
                                {{ arenaOutputStore.targetCompany[0].patentCount.toLocaleString() }}
                            </p>
                            <p class="bold white--text mt-5">Avg. Innovation Score</p>
                            <p class="extra-large overflow-ellipsis white--text mt-2">
                                {{ calcPowerScore.toFixed(2) }}
                            </p>
                        </div>
                    </div>
                    <AonButton
                        class="target-company-button"
                        label="Change Target Company"
                        :class="{ disabled: arenaOutputStore.targetCompanyLoading }"
                        :text-with-icon="true"
                        :icon-options="{ iconStyle: 'fas', iconName: 'fa-arrow-up-arrow-down' }"
                        @clicked="toggleTargetCompanyModal()"
                    />
                </div>
            </div>
            <div class="focus-tabs d-flex flex-column">
                <div
                    v-for="tab in focusTabs"
                    class="tab py-3 px-4"
                    style="position: relative"
                    :class="[
                        { active: tab.tabNum === activeTab },
                        { disabled: techAreasLoading && tab.tabNum === 2 },
                    ]"
                    @click="setActiveTab(tab.tabNum)"
                >
                    <div class="tab-content d-flex align-items-center flex-wrap">
                        <div
                            class="header d-flex align-items-center w-full justify-content-between"
                        >
                            <div class="title-holder d-flex align-items-center">
                                <font-awesome-icon
                                    :icon="`fas ${tab.icon}`"
                                    class="icon grey01--text mr-3"
                                    size="lg"
                                />
                                <h6 class="tab-title grey01--text">{{ tab.title }}</h6>
                            </div>
                            <font-awesome-icon
                                v-if="tab.tabNum === 2 && showWarning"
                                v-tooltip="{
                                    content:
                                        'Changes made in Technology Areas will effect the Arena Space',
                                }"
                                :icon="`fas fa-circle-info`"
                                class="notification-icon castle-moat-hover--text mr-3"
                                size="lg"
                            />
                        </div>
                        <AonSpinner
                            v-if="techAreasLoading && tab.tabNum === 2"
                            color="castleMoat"
                            class="ml-auto mr-auto my-1"
                            :scale="0.3"
                        />
                        <div v-else class="desc-holder">
                            <p class="tab-desc grey01--text mt-2">{{ tab.desc }}</p>
                            <div v-if="tab.type" class="tab-pill grey03 px-3 py-1 mt-3">
                                <p class="bold white--text">{{ computedSelections }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="bottom w-full d-flex align-items-center px-2 py-1 grey04"
            @click="toggleCollapse()"
        >
            <div class="handle d-flex justify-content-center align-items-center w-full pl-5">
                <font-awesome-icon
                    icon="fas fa-arrow-left-to-bracket"
                    class="icon grey01--text mr-5"
                    size="lg"
                />
            </div>
            <h6 class="grey01--text">Collapse Sidebar</h6>
        </div>

        <div v-if="arenaOutputStore.showTargetCompanyModal" class="modal-holder">
            <AonModal title="" message="" col-width="8" @close="toggleTargetCompanyModal()">
                <div class="selected-competitors-holder py-5">
                    <SearchTable
                        class="my-n5 search-table"
                        type="company"
                        @comp-selected="assignCompetitor"
                    />
                    <div class="action-holder d-flex align-items-end w-full mt-8">
                        <AonButton
                            class="mx-5"
                            :class="{
                                disabled:
                                    arenaOutputStore.newTargetCompany.length > 1 ||
                                    arenaOutputStore.newTargetCompany.length === 0,
                            }"
                            label="Apply"
                            :loading="patchLoading"
                            @clicked="applyCompany()"
                        />
                        <AonButton
                            label="Cancel"
                            type="ghost"
                            @clicked="toggleTargetCompanyModal()"
                        />
                    </div>
                </div>
            </AonModal>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, computed, watch, nextTick, onUnmounted } from 'vue'
import { useArenaOutputStore, useMoat2ProductStore } from '@/stores'
import { patchArena } from '@/api/arena.js'

import SearchTable from '@/components/CompaniesSearchTable'

import { useI18n } from 'vue-i18n'

const emit = defineEmits(['everything-done-loading', 'reset-arena-output'])
const eventBus = inject('eventBus')
const logger = inject('logger')
const { t } = useI18n()
const arenaOutputStore = useArenaOutputStore()
const moat2ProductStore = useMoat2ProductStore()

const props = defineProps({
    type: {
        type: String,
        default: 'pa',
    },
    peersEnabled: {
        type: Boolean,
        default: false,
    },
    techAreasLoading: {
        type: Boolean,
        default: true,
    },
})

const totalTechAreas = ref(0)
const patchLoading = ref(false)
const collapse = ref(false)
const focusTabs = ref([
    {
        tabNum: 1,
        title: t('reportOutput.sideNav.tab1.title'),
        desc: t('reportOutput.sideNav.tab1.desc'),
        icon: 'fa-microchip',
        type: 'tech',
    },
    {
        tabNum: 2,
        title: t('reportOutput.sideNav.tab2.title'),
        desc: t('reportOutput.sideNav.tab2.desc'),
        icon: 'fa-ranking-star',
        // type: 'arena',
    },
])

watch(
    () => props.techAreasLoading,
    (newVal, oldVal) => {
        if (newVal !== oldVal) {
            setTimeout(() => {
                getTotalTechAreas()
            }, 1000)
        }
    }
)

const error = ref(null)

const calcPowerScore = computed((type) => {
    if (arenaOutputStore.targetCompany.length > 0 && !arenaOutputStore.targetCompanyLoading) {
        let score =
            arenaOutputStore.targetCompany[0].powerScore /
            arenaOutputStore.targetCompany[0].patentCount
        return score ? score : 0
    } else {
        return 0
    }
})

const activeTab = computed((type) => {
    return props.type === 'pa'
        ? moat2ProductStore.productAlignmentActiveTab
        : arenaOutputStore.arenaOutputActiveTab
})

const showWarning = computed((type) => {
    if (props.type === 'pa') {
        return (
            moat2ProductStore.productAlignmentActiveTab === 1 &&
            !setsAreEqual(
                moat2ProductStore.productAlignmentTechAreaSelectionsOld,
                moat2ProductStore.productAlignmentTechAreaSelections
            ) &&
            moat2ProductStore.productAlignmentTechAreaSelectionsOld.size > 0
        )
    } else {
        return (
            arenaOutputStore.arenaOutputActiveTab === 1 &&
            !setsAreEqual(
                arenaOutputStore.arenaOutputTechAreaSelectionsOld,
                arenaOutputStore.arenaOutputTechAreaSelections
            ) &&
            arenaOutputStore.arenaOutputTechAreaSelectionsOld.size > 0
        )
    }
})

const getTotalTechAreas = () => {
    if (props.type === 'pa') {
        totalTechAreas.value = moat2ProductStore.techAreaIdCollection.length
    } else {
        totalTechAreas.value = arenaOutputStore.techAreaIdCollection.length
    }
}

const computedSelections = computed((type) => {
    if (props.type === 'pa') {
        return `${moat2ProductStore.techAreaIdCollection.length} of ${totalTechAreas.value} Technology Areas Selected`
    } else {
        return `${arenaOutputStore.techAreaIdCollection.length} of ${totalTechAreas.value} Technology Areas Selected`
    }
})

const toggleCollapse = (forceClose = false) => {
    return (collapse.value = !collapse.value)
}

const toggleTargetCompanyModal = () => {
    if (arenaOutputStore.showTargetCompanyModal) {
        arenaOutputStore.newTargetCompany = []
    }
    arenaOutputStore.showTargetCompanyModal = !arenaOutputStore.showTargetCompanyModal
}

const setActiveTab = (tabNum) => {
    if (props.type === 'pa') {
        moat2ProductStore.productAlignmentActiveTab = tabNum
        setTimeout(() => {
            moat2ProductStore.productAlignmentTechAreaSelectionsOld =
                moat2ProductStore.productAlignmentTechAreaSelections
        }, 1000)
    } else {
        arenaOutputStore.arenaOutputActiveTab = tabNum
        setTimeout(() => {
            arenaOutputStore.arenaOutputTechAreaSelectionsOld =
                arenaOutputStore.arenaOutputTechAreaSelections
        }, 1000)
    }
}

const assignCompetitor = (comp) => {
    const index = arenaOutputStore.newTargetCompany.findIndex((r) => r.name === comp.name)
    if (index > -1) {
        arenaOutputStore.newTargetCompany.splice(index, 1)
    } else {
        arenaOutputStore.newTargetCompany.push(comp)
    }
}

const applyCompany = async () => {
    patchLoading.value = true
    try {
        var { data } = await patchArena(arenaOutputStore.alignmentPk, {
            EntityPk: arenaOutputStore.newTargetCompany[0].aon_entity_pk,
        })
    } catch (err) {
        handleError('And error occurred')
        error.value = true
    } finally {
        patchLoading.value = false
        arenaOutputStore.targetCompanyLoading = true
        emit('reset-arena-output')
        toggleTargetCompanyModal()
        arenaOutputStore.targetCompany = [
            { aonEntityPk: data.entityPk, aonEntityName: '', patentCount: 0, powerScore: 0 },
        ]
        setTimeout(() => {
            arenaOutputStore.arenaOutputActiveTab = 2
            arenaOutputStore.initLoad = true
        }, 1000)
    }
}

const handleError = (err) => {
    logger.error(err)

    eventBus.emit('snacktime', {
        type: 'error',
        message: err,
    })

    error.value = true
}

const setsAreEqual = (s1, s2) => {
    return s1.size === s2.size && [...s1].every((x) => s2.has(x))
}
</script>

<style lang="scss" scoped>
.output-side-nav {
    width: 425px;
    min-width: 425px;
    min-height: 100vh;
    background: vars.$grey04;
    position: relative;

    transition: all 0.3s ease-in-out;

    @media screen and (max-height: 950px) {
        &.collapse {
            overflow: hidden;
        }
    }

    &.sticky-fix {
        // arena output
        @media screen and (max-height: 950px) {
            overflow: auto;

            &.collapse {
                overflow: hidden !important;
            }

            .top {
                top: 0 !important;
                position: static;
            }

            .bottom {
                position: static;
            }
        }
    }

    @media screen and (max-height: 600px) {
        overflow: auto;

        .bottom {
            position: static;
        }

        .top {
            top: 0 !important;
        }
    }

    .target-pad-wrapper {
        border-bottom: 1px solid vars.$grey05;

        .target-entity-holder {
            border-radius: 12px;
            overflow: hidden;

            transition: all 0.3s ease-in-out;

            .target-icon {
                transform: translate(0, 0);

                transition: all 0.3s ease-in-out;
            }
        }
    }

    .target-company-details-holder {
        min-height: 200px;
    }

    .top {
        position: sticky;
        top: 0; //height of header nav and reseach tabs

        &.sticky-fix {
            position: relative;
        }

        .target-company-details-holder,
        .target-company-button {
            transform: translateX(0);
            opacity: 1;
            pointer-events: all;

            transition: all 0.3s ease-in-out;
        }

        .tab-title,
        .tab-desc,
        .tab-pill {
            transform: translateX(0);
            opacity: 1;
            pointer-events: all;

            transition: all 0.3s ease-in-out;
        }

        .tab-pill {
            border-radius: 50px;
        }

        .tab {
            border-left: solid 5px transparent;
            cursor: pointer;

            transition: all 0.3s ease-in-out;

            &:hover {
                background: rgba(vars.$tavernAle20, 0.2);
            }

            &.active {
                background: vars.$grey05;
                border-color: vars.$castleMoatActive;
            }

            .tab-content {
                min-width: 390px;
                overflow: hidden;
            }

            &.disabled {
                .tab-title,
                .tab-desc,
                .tab-pill {
                    pointer-events: none !important;
                }
            }
        }
    }

    .bottom {
        border-top: 1px solid vars.$grey01;
        cursor: pointer;
        position: sticky;
        bottom: 0;

        h6 {
            min-width: 370px;
            transform: translateX(0);
            opacity: 1;
            pointer-events: all;

            transition: all 0.3s ease-in-out;
        }
    }

    .handle {
        height: 40px;
        width: 40px;
        cursor: pointer;

        transition: all 0.3s ease-in-out;

        .icon {
            transform: rotate(0);

            transition: all 0.3s ease-in-out;
        }
    }

    &.collapse {
        width: 60px !important;
        min-width: 60px !important;

        .tab-content {
            pointer-events: none;
            height: 32px;

            .icon {
                padding-top: 6px;
            }
        }

        .target-entity-holder {
            height: 25px;
            padding: 0 !important;
            background: transparent;

            .target-icon {
                transform: translate(4px, -10px);
                color: vars.$grey01 !important;
            }
        }

        .tab-title,
        .tab-desc,
        .tab-pill,
        .target-company-details-holder,
        .target-company-button {
            transform: translateX(-50px);
            opacity: 0;
            pointer-events: none;
        }

        .handle {
            .icon {
                transform: rotate(180deg);
            }
        }

        .bottom {
            h6 {
                min-width: 0;
                transform: translateX(-50px);
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .search-table {
        height: 72vh;
        max-height: 725px;
    }

    :deep(.table-aips-latest) {
        height: 100% !important;

        .table-header {
            border-bottom: 2px solid vars.$grey04 !important;
        }

        .aips-table-wrap {
            height: 86% !important;
        }
    }

    .modal-holder {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;

        :deep(.a-modal) {
            .container {
                padding: 0 !important;
                border-radius: 12px !important;
                max-height: 800px !important;
                height: 80% !important;

                .selected-competitors-holder {
                    height: calc(100% - 210px);
                }
            }
        }

        :deep(.disabled-checkbox) {
            .ag-selection-checkbox {
                opacity: 0.2;
                pointer-events: none;
            }
        }
    }
}

// .a-modal {
// }
</style>
