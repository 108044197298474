<template>
    <div class="moat-product-tab h-full">
        <AonCoverLoading
            :loading="moat2ProductStore.productAlignmentLoading"
            :title="`Please hang tight!`"
            :message="loadingMessage"
        >
            <template #footer>
                <progress
                    v-if="moat2ProductStore.percentComplete !== 0"
                    :value="moat2ProductStore.percentComplete"
                    max="100"
                    class="mt-4 progress-bar"
                />
            </template>
        </AonCoverLoading>
        <Moat2ProductOutput
            v-if="
                moat2ProductStore.alignmentPk &&
                moat2ProductStore.allChartData &&
                entityStore.entity
            "
            :key="`${entityStore.entity.aon_entity_pk}`"
            :entity-id="entityStore.entity.aon_entity_pk"
            :entity-name="entityStore.entity?.name"
            :all-chart-data="moat2ProductStore.allChartData"
            :patent-breakdown="moat2ProductStore.usPatentBreakdown"
        />
        <ErrorWithProductAlignment
            @get-product-alignment="onGetProductSegmentationData"
            @get-previous-run="onGetPreviousRunOrRequestNew(true)"
        />
    </div>
</template>

<script setup>
import axios from 'axios'
// Global
import { onMounted, ref, inject, computed, onBeforeUnmount, watch } from 'vue'
import {
    requestProductAlignment,
    getProductAlignmentRuns,
    pollForJobData,
    getJobData,
} from '@/api/productAlignment.js'
import { useEntityStore, useArenaStore, useMoat2ProductStore } from '@/stores'

// Comps
import Moat2ProductOutput from './Moat2ProductOutput.vue'
import ErrorWithProductAlignment from '@/components/helpers/ErrorWithProductAlignment.vue'

// injectors
const eventBus = inject('eventBus')
const logger = inject('logger')
const entityStore = useEntityStore()
const arenaStore = useArenaStore()
const moat2ProductStore = useMoat2ProductStore()

// Refs/Props
const props = defineProps({
    entityId: {
        type: Number,
        default: undefined,
    },
})

onBeforeUnmount(() => {
    eventBus.all.clear()
})

onMounted(async () => {
    entityStore.getCompetitors(entityStore.entity.aon_entity_pk)
    entityStore.getAcquisitionTargets(entityStore.entity.aon_entity_pk)
    entityStore.getPotentialBuyers(entityStore.entity.aon_entity_pk)
    await onGetPreviousRunOrRequestNew()
})

const loadingMessage = computed(() => {
    if (moat2ProductStore.needsNewAlignment) {
        return `We are aligning patents to the product and technology areas for ${entityStore.entity?.name}. This process will take 5-10 minutes if not cached in our system. Please feel free to check out the other tabs and this process will continue in the background.`
    } else {
        return `Loading Moat2Product for ${entityStore.entity?.name}`
    }
})

const onGetPreviousRunOrRequestNew = async (getPreviousRun = false) => {
    await moat2ProductStore.getPreviousRunOrRequestNew(getPreviousRun)
}

const onGetProductSegmentationData = async (alignmentPk) => {
    await moat2ProductStore.getProductSegmentationData(alignmentPk)
}
</script>

<style lang="scss" scoped>
@use '@moatmetrics/armory/src/sass/responsive.scss';

.moat-product-tab {
    width: 100%;
    position: relative;
    min-height: 600px;
    height: 100%;

    .a-cover-loading {
        z-index: 11;
        transform: translateY(-77px);
        :deep(p.msg) {
            width: 600px;
            font-weight: bold;
        }
    }

    .toggle-sankey {
        position: absolute;
        top: 15px;
        left: 30px;
        z-index: 1;
    }

    .header-holder {
        border-bottom: 1px solid vars.$grey05;
    }

    .sankey-modal {
        position: fixed;
        overflow: auto;
        background: white;
        width: 95%;
        height: 80vh;
        z-index: 10000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .close-sankey {
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }
    .chart-background {
        width: 200%;
        height: 200%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
    }

    :deep(.button-tabs) {
        .tab {
            border-bottom-width: 1px !important;
            background: vars.$grey06;
            &.selected {
                border-bottom-color: transparent !important;
                background: vars.$knightsCloak;
            }

            &:hover {
                background: vars.$knightsCloak80;
            }

            p {
                font-size: 16px;
            }
        }
    }

    :deep(.icon-button) {
        display: flex;
        align-items: center !important;

        .a-button-label {
            margin-left: 8px;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    :deep(.a-carousel) {
        .slide {
            border: solid vars.$grey04 1px;

            .image-holder {
                background-repeat: no-repeat;
            }
        }
        @media screen and (max-width: 1200px) {
            .text-holder {
                padding: 20px !important;
            }

            h2 {
                font-size: 20px;
                line-height: 24px;
            }
            p {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}
</style>
