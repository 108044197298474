<template>
    <div class="innovation-over-time">
        <AonCoverLoading
            :loading="loadingData"
            title="Loading Innovation Over Time..."
        ></AonCoverLoading>
        <AonCoverLoading
            v-if="noData"
            :loading="noData ? true : false"
            :show-spinner="false"
            title="No Innovation Identified"
        >
            <template #header>
                <div class="icon">
                    <font-awesome-icon
                        icon="fas fa-circle-exclamation"
                        class="grey01--text"
                        size="2xl"
                    />
                </div>
            </template>
        </AonCoverLoading>
        <div v-if="props.tab === 'strategy'" id="amChartInnovationOverTimeStrategy"></div>
        <div v-else id="amChartInnovationOverTime"></div>
    </div>
</template>

<script setup>
import { onMounted, ref, inject, computed, onBeforeUnmount } from 'vue'
import { useArenaOutputStore, useMoat2ProductStore, useEntityStore } from '@/stores'
import { getInvestmentStrategy } from '@/api/productAlignment.js'
import { getEntityProductStrategyYearly } from '@/api/entities.js'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'
import { throttle } from 'lodash-es'

const logger = inject('logger')
const arenaOutputStore = useArenaOutputStore()
const moat2ProductStore = useMoat2ProductStore()
const entityStore = useEntityStore()

const loadingData = ref(true)
const noData = ref(false)
const chartData = ref(null)

let root
let chart
let yAxis
let xAxis
let series
let label
let elementContainer
let maxPatentCount

let stepDuration = 2000
let firstYear
let finalYear
let yearDiff

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root =
        props.tab === 'strategy'
            ? am5.Root.new('amChartInnovationOverTimeStrategy')
            : am5.Root.new('amChartInnovationOverTime')
    getAndMapData()
})

onBeforeUnmount(() => {
    root.dispose()
})

const props = defineProps({
    type: {
        type: String,
        default: 'pa',
    },
    tab: {
        type: String,
        default: 'default',
    },
    nodeType: {
        type: String,
        default: null,
    },
})

const idToUse = computed(() => {
    return props.type === 'pa' ? moat2ProductStore.alignmentPk : arenaOutputStore.alignmentPk
})

const nodeTypeToUse = computed(() => {
    return props.nodeType === 'product' ? 'product' : 'concept'
})

const getAndMapData = async () => {
    loadingData.value = true
    try {
        let returned = { data: [] }
        if (props.tab === 'default') {
            returned = await getInvestmentStrategy(idToUse.value)
        } else {
            let { data } = await getEntityProductStrategyYearly(
                entityStore.entity.aon_entity_pk,
                null,
                {
                    ipNodeType: nodeTypeToUse.value,
                    limitTopN: 10,
                }
            )
            returned.data = data
                .filter((yearData) => {
                    return yearData.productTechs.some((tech) => tech.patentCount > 0)
                })
                .map((yearData) => {
                    yearData.productTechs.sort((a, b) => b.patentCount - a.patentCount)
                    return yearData
                })
                .sort((a, b) => b.year - a.year)
        }
        if (returned.data.length === 0) {
            noData.value = true
            loadingData.value = false
            return
        }

        chartData.value = returned.data
        firstYear = returned.data[returned.data.length - 1].year
        finalYear = returned.data[0].year
        yearDiff = finalYear - firstYear
        maxPatentCount = returned.data[0].productTechs[0].patentCount

        setTimeout(() => {
            configureChart()
            loadingData.value = false
        }, 100)
    } catch (error) {
        logger.error(error)
    }
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])
    root.numberFormatter.setAll({
        numberFormat: '#.',
    })

    // Create chart
    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'none',
            wheelY: 'none',
            paddingLeft: 0,
        })
    )

    chart.zoomOutButton.set('forceHidden', true)

    generateAxes()
}

const generateAxes = (sliderStartingPoint = 1) => {
    var yRenderer = am5xy.AxisRendererY.new(root, {
        minGridDistance: 20,
        inversed: true,
        minorGridEnabled: true,
    })
    // hide grid
    yRenderer.grid.template.set('visible', false)

    yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            categoryField: 'displayName',
            renderer: yRenderer,
            bullet: function (root, axis, dataItem) {
                return am5xy.AxisBullet.new(root, {
                    location: 0.5,
                    sprite: am5.Label.new(root, {
                        text: dataItem.dataContext.productDisplayName
                            ? dataItem.dataContext.productDisplayName
                            : '',
                        fill: am5.color('#82939A'),
                        centerY: am5.p50,
                        centerX: am5.percent(97),
                        dy: -8,
                        fontSize: 12,
                    }),
                })
            },
        })
    )

    yAxis.get('renderer').labels.template.setAll({
        dy: nodeTypeToUse.value !== 'product' ? 9 : 0,
    })

    xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
            min: 0,
            strictMinMax: true,
            extraMax: 0.1,
            autoZoom: false,
            numberFormat: '#.##',
            renderer: am5xy.AxisRendererX.new(root, {}),
            paddingBottom: 50,
        })
    )

    xAxis.set('interpolationDuration', stepDuration / 10)
    xAxis.set('interpolationEasing', am5.ease.linear)

    generateSeries(sliderStartingPoint)
}

const generateSeries = (sliderStartingPoint = 1) => {
    series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: 'value',
            categoryYField: 'displayName',
        })
    )

    // Rounded corners for columns
    series.columns.template.setAll({ cornerRadiusBR: 5, cornerRadiusTR: 5 })

    // Make each column to be of a different color
    series.columns.template.adapters.add('fill', function (fill, target) {
        return chart.get('colors').getIndex(series.columns.indexOf(target))
    })

    series.columns.template.adapters.add('stroke', function (stroke, target) {
        return chart.get('colors').getIndex(series.columns.indexOf(target))
    })

    // Add label bullet
    series.bullets.push(function () {
        return am5.Bullet.new(root, {
            locationX: 1,
            sprite: am5.Label.new(root, {
                text: '{valueXWorking.formatNumber(`#`)}',
                fill: am5.color('#000'),
                centerX: am5.p0,
                centerY: am5.p50,
                populateText: true,
            }),
        })
    })

    label = chart.plotContainer.children.push(
        am5.Label.new(root, {
            text: '2013',
            fontSize: '8em',
            opacity: 0.1,
            x: am5.p100,
            y: am5.p100,
            centerY: am5.p100,
            centerX: am5.p100,
        })
    )

    setInitialData()
    generateInteractiveElements(sliderStartingPoint)

    series.appear(1000)
    chart.appear(1000, 100)
}

const generateInteractiveElements = (sliderStartingPoint = 1) => {
    // Conatiner for any interactive elements
    elementContainer = chart.children.push(
        am5.Container.new(root, {
            y: am5.p100,
            centerX: am5.p50,
            centerY: am5.p100,
            x: am5.p50,
            width: am5.percent(90),
            layout: root.horizontalLayout,
            paddingBottom: -15,
        })
    )

    // Play button + slider
    let playButton = elementContainer.children.push(
        am5.Button.new(root, {
            themeTags: ['play'],
            centerY: am5.p100,
            marginRight: 20,
            icon: am5.Graphics.new(root, {
                themeTags: ['icon'],
            }),
            dy: 40,
        })
    )

    let slider = elementContainer.children.push(
        am5.Slider.new(root, {
            orientation: 'horizontal',
            start: sliderStartingPoint,
            centerY: am5.p100,
            dy: 30,
        })
    )

    playButton.events.on('click', function () {
        if (playButton.get('active') === true) {
            slider.set('start', slider.get('start'))
        } else {
            if (slider.get('start') === 1) {
                maxPatentCount =
                    chartData.value[chartData.value.length - 1].productTechs[0].patentCount
                setTimeout(() => {
                    xAxis.dispose()
                    yAxis.dispose()
                    series.dispose()
                    label.dispose()
                    elementContainer.dispose()
                    setTimeout(() => {
                        generateAxes(0)
                    }, 100)
                }, 100)
            }
            slider.animate({
                key: 'start',
                to: 1,
                duration: yearDiff * stepDuration * (1 - slider.get('start')),
            })
        }
    })

    slider.on('start', function (pos) {
        if (pos === 1) {
            playButton.set('active', false)
        }
    })

    if (sliderStartingPoint === 0) {
        slider.animate({
            key: 'start',
            to: 1,
            duration: yearDiff * stepDuration * (1 - slider.get('start')),
        })
        setTimeout(() => {
            playButton.set('active', true)
        }, 100)
    }

    slider.events.on(
        'rangechanged',
        throttle(
            function () {
                let checkedYear =
                    firstYear + Math.round(slider.get('start', 0) * (finalYear - firstYear))
                updateSeriesData(checkedYear)
            },
            200,
            { trailing: false }
        )
    )
}

const updateSeriesData = (year) => {
    updateData(year)
    setTimeout(() => {
        sortCategoryAxis()
    }, 100)
}

const handleDuplicateDisplayNames = (data) => {
    const seen = new Set()
    return data.map((item) => {
        let displayName = item.displayName
        while (seen.has(displayName)) {
            displayName = displayName + ' '
        }
        seen.add(displayName)
        return {
            ...item,
            displayName,
        }
    })
}

// Data update helper/animations
const setInitialData = () => {
    let dataInfirstYear = chartData.value.find((item) => item.year === firstYear).productTechs
    dataInfirstYear = handleDuplicateDisplayNames(dataInfirstYear)

    if (props.type !== 'default') {
        dataInfirstYear.forEach((item) => {
            series.data.push({
                displayName: item.displayName,
                value: item.patentCount,
                productDisplayName:
                    nodeTypeToUse.value !== 'product' ? item.parentDisplayName : null,
            })
            yAxis.data.push({
                displayName: item.displayName,
                productDisplayName:
                    nodeTypeToUse.value !== 'product' ? item.parentDisplayName : null,
            })
        })
    } else {
        dataInfirstYear.forEach((item) => {
            series.data.push({ displayName: item.displayName, value: item.patentCount })
            yAxis.data.push({ displayName: item.displayName })
        })
    }
}

const updateData = (passedYear, skipEase = false) => {
    let itemsWithNonZero = 0
    let targetYear = chartData.value.find((item) => item.year === passedYear)
    if (targetYear && targetYear.productTechs && targetYear.productTechs.length > 0) {
        label.set('text', targetYear.year.toString())
        am5.array.each(series.dataItems, function (dataItem) {
            let value =
                targetYear.productTechs.find(
                    (category) => category.displayName === dataItem.dataContext.displayName
                )?.patentCount || 0

            if (value > 0) {
                itemsWithNonZero++
            }

            dataItem.animate({
                key: 'valueX',
                to: value,
                duration: skipEase ? 0 : stepDuration,
                easing: am5.ease.linear,
            })
            dataItem.animate({
                key: 'valueXWorking',
                to: value,
                duration: skipEase ? 0 : stepDuration,
                easing: am5.ease.linear,
            })
        })

        let scaledValue = targetYear.productTechs[0].patentCount / maxPatentCount
        yAxis.zoom(0, itemsWithNonZero / yAxis.dataItems.length)
        xAxis.zoom(0, scaledValue, 2000)
        maxPatentCount = Math.max(maxPatentCount, targetYear.productTechs[0].patentCount)
    }

    setTimeout(() => {
        sortCategoryAxis()
    }, 2000) // time for animations to finish
}

const sortCategoryAxis = () => {
    // sort by value
    series.dataItems.sort(function (x, y) {
        return y.get('valueX') - x.get('valueX') // descending
        //return x.get('valueX') - y.get('valueX'); // ascending
    })

    // go through each axis item
    am5.array.each(yAxis.dataItems, function (dataItem) {
        // get corresponding series item
        let seriesDataItem = getSeriesItem(dataItem.get('category'))
        if (seriesDataItem) {
            // get index of series data item
            let index = series.dataItems.indexOf(seriesDataItem)
            // calculate delta position
            let deltaPosition = (index - dataItem.get('index', 0)) / series.dataItems.length
            // set index to be the same as series data item index
            if (dataItem.get('index') != index) {
                dataItem.set('index', index)
                // set deltaPosition instanlty
                dataItem.set('deltaPosition', -deltaPosition)
                // animate delta position to 0
                dataItem.animate({
                    key: 'deltaPosition',
                    to: 0,
                    duration: stepDuration / 2,
                    easing: am5.ease.out(am5.ease.cubic),
                })
            }
        }
    })

    yAxis.dataItems.sort(function (x, y) {
        return x.get('index') - y.get('index')
    })
}

const getSeriesItem = (category) => {
    for (let i = 0; i < series.dataItems.length; i++) {
        let dataItem = series.dataItems[i]

        if (dataItem.get('categoryY') == category) {
            return dataItem
        }
    }
}
</script>

<style lang="scss" scoped>
.innovation-over-time {
    width: 100%;
    position: relative;

    #amChartInnovationOverTime,
    #amChartInnovationOverTimeStrategy {
        width: 100%;
        height: 600px;

        div {
            height: 100%;
        }
    }
}
</style>
