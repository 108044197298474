<template>
    <div class="groom mt-4">
        <PatentList
            inline
            absolute
            entity-only
            allow-pruning
            :passed-filters="props.passedFilters"
            :entity-pk="parseInt(entityStore.entity.aon_entity_pk)"
        />
    </div>
</template>

<script setup>
import { onMounted, ref, inject, watch, computed } from 'vue'
import { usePortfolioManagerStore, useEntityStore } from '@/stores'
import { getFeeSummary, getProductFees, getProductFeesByClaim } from '@/api/portfolioManager'

import PatentList from '@/components/patentViewer/PatentList.vue'

const portfolioManagerStore = usePortfolioManagerStore()
const entityStore = useEntityStore()

const logger = inject('logger')

const props = defineProps({
    passedFilters: {
        type: Object,
        default: () => ({}),
    },
})

onMounted(() => {})
</script>
<style lang="scss" scoped>
.groom {
    width: 100%;
    min-height: calc(100vh - 160px);
    position: relative;
}
</style>
