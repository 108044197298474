<template>
    <div class="watchlist">
        <AonContainer add-page-padding class="knights-cloak-80">
            <AonRow class="py-4">
                <div v-if="!dataset" class="aon-col-6 pa-0" style="height: 70px">
                    <AonSpinner :scale="0.3" style="top: 40%" color="castleMoat" />
                </div>
                <AonCol
                    v-else
                    class="aon-col-6 py-0 px-0"
                    align-items="center"
                    align-content="start"
                >
                    <div class="title-holder w-full">
                        <div class="wrap d-flex align-items-center w-full">
                            <h4
                                class="grey08--text overflow-ellipsis"
                                v-tooltip="{ content: dataset.dataset_name }"
                            >
                                {{ dataset.dataset_name }}
                            </h4>
                            <font-awesome-icon
                                class="edit-watchlist ml-2"
                                icon="fas fa-pencil"
                                size="lg"
                                v-tooltip="{ content: t('watchlists.edit.header') }"
                                @click="editWatchlist()"
                            />
                        </div>
                        <p
                            class="grey08--text overflow-ellipsis"
                            v-tooltip="{ content: dataset.dataset_description }"
                        >
                            {{ dataset.dataset_description }}
                        </p>
                    </div>
                </AonCol>
                <AonCol class="aon-col-6 py-0 px-0" align-items="center" justify-content="end">
                    <AonButton
                        v-if="reportsTabVisible"
                        class="ml-2"
                        label="Create Report"
                        @clicked="
                            router.push({
                                name: 'Create Report',
                                query: { watchlistPk: dataset.dataset_pk },
                            })
                        "
                        :disabled="createReportDisabled"
                    />
                </AonCol>
            </AonRow>
        </AonContainer>
        <AonContainer add-page-padding class="below-header watchlist-tabs knights-cloak-active">
            <AonTabs
                :key="tabUpdater"
                :current-tab="currentTab"
                :tabs="tabData"
                @tab-change="tabChange"
            />
        </AonContainer>
        <AonContainer add-page-padding class="grey05 py-4">
            <AonRow>
                <KeepAlive>
                    <AonCol class="pa-0" v-if="currentTab === 1">
                        <CompanyWatchlist />
                    </AonCol>
                </KeepAlive>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount, computed, inject, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useDatasetStore, useScreenerStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { useFlag } from '@unleash/proxy-client-vue'

import CompanyWatchlist from './CompanyWatchlist.vue'

const datasetStore = useDatasetStore()
const router = useRouter()
const { t } = useI18n()
const eventBus = inject('eventBus')
const companyUpdater = ref(0)
const marketUpdater = ref(0)
const datasetPk = ref(null)
const dataset = ref(null)
const currentTab = ref(1)
const tabUpdater = ref(0)
const reportsTabVisible = useFlag('ipAlpha.reportCreation')

onBeforeMount(async () => {
    if (router.currentRoute.value.params.datasetPk) {
        datasetPk.value = parseInt(router.currentRoute.value.params.datasetPk)
        dataset.value = await datasetStore.refreshDataset(
            router.currentRoute.value.params.datasetPk
        )
    }
    setDefaultTab()
})
onBeforeUnmount(() => {
    const marketScreenerStore = useScreenerStore('marketWatchlist')
    const companyScreenerStore = useScreenerStore('companyWatchlist')

    companyScreenerStore.entitiesSelected = []
    marketScreenerStore.marketsSelected = []
})

eventBus.on('watchlistChanged', async (type = null) => {
    setTimeout(async () => {
        if (type) {
            switch (type) {
                case 'Entity':
                    dataset.value = await datasetStore.refreshDataset(
                        router.currentRoute.value.params.datasetPk
                    )
                    companyUpdater.value++
                    break
                case 'Sector':
                    dataset.value = await datasetStore.refreshDataset(
                        router.currentRoute.value.params.datasetPk
                    )
                    marketUpdater.value++
                    break
            }
        } else {
            dataset.value = await datasetStore.getDatasetByPk(datasetPk.value)
            setDefaultTab()
        }
    }, 500)
})

const tabData = computed(() => {
    return [
        {
            id: 1,
            text: t('watchlists.details.companies'),
            appendCount: dataset.value?.entity_count || '0',
        },
        {
            id: 2,
            text: t('watchlists.details.markets'),
            appendCount: dataset.value?.sector_count || '0',
        },
    ]
})
const createReportDisabled = computed(() => {
    if (!dataset?.value?.entity_count) {
        return true
    }

    return dataset.value.entity_count < 2
})

const setDefaultTab = () =>
    (currentTab.value = dataset.value?.sector_count > dataset.value?.entity_count ? 2 : 1)
const editWatchlist = () => {
    datasetStore.setDatasetToEdit(dataset.value)
}
const tabChange = (val) => {
    currentTab.value = val.id
}
</script>
<style lang="scss">
.watchlist-tabs {
    .watchlist-alert-box {
        max-height: 10vh;
        overflow: hidden;
        overflow-y: auto;
    }

    .a-tabs {
        p {
            color: vars.$grey05;
        }

        :hover {
            p {
                color: vars.$grey05;
            }
        }

        li {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }

        li.selected {
            border-bottom: 4px solid vars.$gold;

            p {
                color: white;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@use '@moatmetrics/armory/src/sass/responsive.scss' as responsive;
@use '@moatmetrics/armory/src/sass/mixins.scss' as mixin;

.watchlist {
    .edit-watchlist {
        color: vars.$castleMoat;
        cursor: pointer;

        &:hover,
        &:visited {
            color: vars.$castleMoatActive;
        }
    }

    :deep(.aon-container) {
        max-width: none;

        @include responsive.overSized {
            max-width: none;
        }
    }
}
</style>
