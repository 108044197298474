<template>
    <div class="total-damages-metric">
        <div style="position: relative">
            <AonCoverLoading :loading="!props.dataReady && isNaN(props.totalDamages)" />
            <div class="metric-value">
                <div v-if="props.dataReady && isNaN(props.totalDamages)" class="no-data">
                    <p>No data available</p>
                </div>
                <div v-show="props.dataReady && !isNaN(props.totalDamages)" class="metric-value">
                    {{ formattedDamages }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
const props = defineProps<{
    totalDamages: number
    dataReady: boolean
}>()

const formattedDamages = computed(() => {
    if (isNaN(props.totalDamages)) {
        return '--'
    }
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        notation: 'compact',
    }).format(props.totalDamages)
})
</script>

<style scoped>
.total-damages-metric {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #f8f9fa;
    text-align: center;
    position: relative;
}

h3 {
    color: #495057;
    margin-bottom: 10px;
    font-size: 1.1rem;
}

.metric-value {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
}
.no-data {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>
