import * as am5 from '@amcharts/amcharts5'
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

export function useTreemapChart(root) {
    let container
    let treemapSeries

    const configureTreemap = () => {
        const customTheme = am5.Theme.new(root)
        customTheme.rule('RoundedRectangle', ['hierarchy', 'node', 'shape', 'depth1']).setAll({
            strokeWidth: 10,
            stroke: am5.color('#000000'),
        })

        root.setThemes([customTheme, am5themes_Animated.new(root)])

        container = root.container.children.push(
            am5.Container.new(root, {
                width: am5.percent(100),
                height: am5.percent(100),
                layout: root.verticalLayout,
                maskContent: false,
                marginTop: 10,
            })
        )
    }

    const generateTreemapSeries = () => {
        treemapSeries = container.children.push(
            am5hierarchy.Treemap.new(root, {
                maskContent: false,
                sort: 'descending',
                layoutAlgorithm: 'binary',
                singleBranchOnly: false,
                downDepth: 2,
                upDepth: 0,
                initialDepth: 2,
                valueField: 'value',
                categoryField: 'name',
                childDataField: 'children',
                customValueField: 'heat',
                nodePaddingOuter: 0,
                nodePaddingInner: 0,
                tooltipPosition: 'fixed',
            })
        )

        treemapSeries.rectangles.template.setAll({
            fill: am5.color('#FFFFFF'),
        })

        treemapSeries.labels.template.setAll({
            fill: am5.color('#FFFFFF'),
            fontSize: 14,
            paddingTop: 15,
            paddingLeft: 15,
            paddingRight: 15,
            paddingBottom: 15,
            oversizedBehavior: 'truncate',
            tooltipPosition: 'fixed',
        })

        treemapSeries.set('heatRules', [
            {
                target: treemapSeries.rectangles.template,
                dataField: 'customValue',
                min: am5.color('#7C8EAE'),
                max: am5.color('#143862'),
                minValue: 0,
                maxValue: 50,
                key: 'fill',
            },
        ])

        treemapSeries.rectangles.template.adapters.add('fill', (fill, target) => {
            const dataItem = target.dataItem
            if (dataItem) {
                const value = dataItem.get('customValue')
                if (value > 50) {
                    return am5.color('#143862')
                }
            }
            return fill
        })

        treemapSeries.nodes.template.events.on('click', function (ev) {
            var depth = ev.target.dataItem.get('depth')
            if (depth == 2) {
                treemapSeries.selectDataItem(ev.target.dataItem.get('parent'))
                treemapSeries.bullets.showBullets = false
            }
        })

        treemapSeries.nodes.template.set(
            'tooltipText',
            '{category}\n\nInnovation Score: [bold]{sum}[/]\nInnovation Implied P/E: [bold]{customValue}[/]'
        )
        treemapSeries.bullets.clear()
        // custom bullet labels for depth 1 nodes
        treemapSeries.bullets.push(function (root, series, dataItem) {
            let depth = dataItem.get('depth')
            if (depth == 1) {
                return createProductLabel(root, dataItem)
            }
        })
    }

    const createProductLabel = (root, dataItem) => {
        let label = am5.Label.new(root, {
            centerX: am5.percent(0),
            width: am5.percent(100),
            height: am5.percent(100),
            paddingRight: 15,
            paddingLeft: 15,
            dx: 15,
            dy: 15,
            oversizedBehavior: 'truncate',
            layer: 3,
            fill: am5.color('#ddd'),
            fontSize: 16,
            fontWeight: 'bold',
            fontVariant: 'small-caps',
            shadowOffsetY: 0,
            shadowColor: am5.color('#000'),
            shadowBlur: 10,
            text: dataItem.dataContext.name,
        })

        dataItem.set('customLabel', label)

        dataItem.get('rectangle').set('userData', label)
        dataItem.get('rectangle').on('width', function (width, target) {
            target.get('userData').set('maxWidth', width - 20)
        })

        return am5.Bullet.new(root, {
            locationX: 0,
            sprite: label,
        })
    }

    const removeLabelBullets = () => {
        treemapSeries?.dataItems.forEach((dataItem) => {
            let label = dataItem.get('customLabel')
            if (label) {
                label.dispose()
            }
        })
        treemapSeries?.bullets.clear()
    }

    const setTreemapData = (data) => {
        treemapSeries.data.setAll([data])
        treemapSeries.appear(1000, 100)
    }

    return {
        configureTreemap,
        generateTreemapSeries,
        setTreemapData,
        removeLabelBullets,
        disposeTreemap: () => {
            treemapSeries?.dispose()
            container?.dispose()
        },
    }
}
