<template>
    <div class="tech-areas h-full d-flex flex-column flex-grow-1">
        <div
            class="table-holder h-full d-flex flex-column flex-grow-1"
            style="display: flex; flex-direction: column"
        >
            <AonCoverLoading :loading="props.loading" title="Defining Material Components" />
            <AonAlertBox
                v-if="props.additionalNodeDataIsProcessing"
                class="mb-5"
                type="notification"
                title="Processing Additional Tech Areas"
                message="Additional tech areas are being identified. You will be notified when they are ready."
            />

            <AonAlertBox
                v-if="props.newDataAvailable"
                class="mb-5"
                type="success"
                title="Additional Technology Areas are Available"
                message='Additional technology areas have been classified under "Other". Refresh the page to see full results.'
            />

            <div class="table-quick-filters d-flex mb-5">
                <AonDropdown
                    class="mr-5"
                    label="Filter By"
                    :items="quickFilterOptions"
                    :init-selection="quickFilterOptions[0]"
                    style="width: 300px"
                    @select-option="changeQuickFilterValue"
                />
                <AonInput
                    style="width: 500px"
                    v-model="searchTerm"
                    search-input
                    label="Search Technology Areas"
                    placeholder="Search Technology Areas"
                    @keyup="searchTable()"
                    @clear="clearSearch()"
                />
            </div>
            <MoatTable
                :style="`width: 100%; ${props.type === 'pa' ? 'height: 400px' : 'flex-grow: 1'};`"
                :key="`tech_areas_${tableUpdater}`"
                :class="themeClass"
                :column-defs="colDefs"
                :row-selection="rowSelection"
                :suppress-auto-size="true"
                :tooltip-interaction="true"
                :tooltip-mouse-track="true"
                :tooltip-show-delay="500"
                tooltip-show-mode="whenTruncated"
                :autoGroupColumnDef="autoGroupColumnDef"
                :groupRemoveLowestSingleChildren="true"
                :groupDisplayType="groupDisplayType"
                :treeData="true"
                :getDataPath="getDataPath"
                :autoSizeStrategy="sizingStrategy"
                :excludeChildrenWhenTreeDataFiltering="true"
                :is-external-filter-present="shouldApplyQuickFilter"
                :does-external-filter-pass="applyQuickFilter"
                :row-data="localTableData"
                :sortingOrder="['desc', 'asc', null]"
                :value-cache="true"
                @grid-ready="gridInit"
                @first-data-rendered="onFirstDataRendered"
                @row-selected="selectRow"
                @cell-clicked="onCellClicked"
            />
        </div>
    </div>
</template>

<script setup>
// Global
import { onMounted, ref, inject, onBeforeUnmount, onBeforeMount, nextTick, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import {
    useEntityStore,
    useArenaOutputStore,
    useMoat2ProductStore,
    useReportGenerationStore,
    usePatentViewerStore,
} from '@/stores'

// Moat Table specific
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator, dateFilterComparator } from '@/components/moatTable/helpers/compare.js'
import { formatTpeRange } from '@/lib/financial.js'

import { useFlag } from '@unleash/proxy-client-vue'
const allowDrillIn = useFlag('ipAlph.drillIn')
const isProUser = useFlag('ipAlpha.proUsers')

// injectors
const eventBus = inject('eventBus')
const filters = inject('filters')
const emit = defineEmits(['regenerate'])
const { t } = useI18n()
const entityStore = useEntityStore()
const arenaOutputStore = useArenaOutputStore()
const moat2ProductStore = useMoat2ProductStore()
const reportGenerationStore = useReportGenerationStore()
const patentViewerStore = usePatentViewerStore()

const searchTerm = ref(null)
const gridApi = ref(null)
const rowSelection = ref({
    mode: 'multiRow',
    checkboxes: false,
    headerCheckbox: false,
    enableClickSelection: false,
    groupSelects: 'filteredDescendants',
    selectAll: 'filtered',
    enableSelectionWithoutKeys: true,
})

// Refs/Props
const props = defineProps({
    tableData: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: true,
    },
    entityName: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'pa',
    },
    reportStep: {
        type: Number,
        default: null,
    },
    additionalNodeDataIsProcessing: {
        type: Boolean,
        default: false,
    },
    newDataAvailable: {
        type: Boolean,
        default: false,
    },
})

const quickFilterOptions = ref([
    {
        itemText: 'All',
    },
    {
        itemText: 'Patented Technologies',
    },
    {
        itemText: 'Innovation Opportunities',
    },
])
const localTableData = ref([...props.tableData])
const localSelections = ref(new Set())
const totalPatentCountComputed = ref()
const tableUpdater = ref(0)
const getDataPath = ref(null)
const sizingStrategy = ref({
    type: 'fitProvidedWidth',
    width: 1700,
})

// helper for table
const allowShowData = (p) => {
    return (
        p.data.nodeType === 'company' ||
        ((p.data.nodeType === 'concept' || p.data.nodeType === 'product') &&
            p.node.allLeafChildren.length !== 0)
    )
}

const colDefs = ref([
    {
        rowGroup: true,
        hide: true,
        field: 'parentDisplayName',
        tooltipField: 'parentDisplayName',
        sortingOrder: ['asc', 'desc', null],
        pinned: 'left',
    },
    props.type === 'pa'
        ? {
              headerComponent: 'MTCustomColHeader',
              headerComponentParams: {
                  tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
                  headerName: 'II P/E',
              },
              field: 'targetTpe',
              headerTooltip: 'Innovation Implied P/E',
              width: 120,
              valueFormatter: (p) => {
                  if (p.data) {
                      let finalValue = formatTpeRange(
                          p.data.targetTpe,
                          p.data.targetTpeLb,
                          p.data.targetTpeUb
                      )
                      return allowShowData(p) ? '-' : finalValue
                  }
              },
              sort: 'desc',
          }
        : { hide: true },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
            headerName: 'Contender II P/E',
        },
        field: 'tpe',
        headerTooltip: 'Contender Innovation Implied P/E',
        width: 150,
        valueFormatter: (p) => {
            if (p.data) {
                let finalValue = formatTpeRange(p.data.tpe, p.data.tpeLb, p.data.tpeUb)
                return allowShowData(p) ? '-' : finalValue
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
            headerName: 'Momentum',
        },
        field: props.type === 'pa' ? 'targetOpportunity' : 'opportunity',
        headerTooltip: 'Momentum',
        width: 130,
        valueFormatter: (p) => {
            if (p.data && !p.data.isProductTooltip) {
                let finalValue =
                    props.type === 'pa'
                        ? p.data.targetOpportunity.toFixed(0)
                        : p.data.opportunity.toFixed(0)
                return allowShowData(p) ? '-' : finalValue
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.coverage'),
            headerName: 'Scope',
        },
        field: props.type === 'pa' ? 'targetCoverage' : 'coverage',
        headerTooltip: 'Scope',
        width: 90,
        valueFormatter: (p) => {
            if (p.data && !p.data.isProductTooltip) {
                let finalValue =
                    props.type === 'pa'
                        ? p.data.targetCoverage.toFixed(0)
                        : p.data.coverage.toFixed(0)
                return allowShowData(p) ? '-' : finalValue
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion:
                props.type === 'pa'
                    ? t('productAlignment.definitionTooltips.patentCountTotal')
                    : t('productAlignment.definitionTooltips.totalPatentCount'),
            headerName:
                props.type === 'pa'
                    ? `${props.entityName} Patent Count / Total`
                    : `Total Patent Count`,
        },
        field: 'totalPatentCount',
        valueFormatter: (p) => {
            if (p.data && !p.data.isProductTooltip) {
                let finalValue
                if (props.type === 'pa') {
                    finalValue = `${p.data.targetEntityPatentCount.toLocaleString()} / ${p.data.totalPatentCount.toLocaleString()}`
                } else {
                    finalValue = `${p.data.totalPatentCount.toLocaleString()}`
                }
                return allowShowData(p) ? '-' : finalValue
            }
        },
        headerTooltip:
            props.type === 'pa' ? `${props.entityName} Patent Count / Total` : `Total Patent Count`,
        width: 165,
    },
    props.type === 'pa'
        ? {
              headerComponent: 'MTCustomColHeader',
              headerComponentParams: {
                  tooltipDefintion: t('productAlignment.definitionTooltips.dominance'),
                  headerName: `${props.entityName} Dominance`,
              },
              valueGetter: (p) => {
                  if (p.data) {
                      let finalValue = p.data.patentShare
                      return allowShowData(p) ? '-' : finalValue
                  }
              },
              valueFormatter: (p) => {
                  if (p.data && p.data.patentShare) {
                      let finalValue = p.data.patentShare + '%'
                      return allowShowData(p) ? '-' : finalValue
                  }
              },
              headerTooltip: `${props.entityName} Dominance`,
              width: 165,
          }
        : { hide: true },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion:
                props.type === 'pa'
                    ? t('productAlignment.definitionTooltips.avgFilingDate')
                    : t('productAlignment.definitionTooltips.avgFilingDateConcept'),
            headerName: 'Average Filing Date',
        },
        valueGetter: (p) => {
            if (p.data) {
                let finalValue = filters.toUTCString(p.data.averageFilingDt)
                return allowShowData(p) ? '-' : finalValue
            }
        },
        width: 150,
        headerTooltip: `Average Filing Date`,
        comparator: dateComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            buttons: ['clear'],
            defaultOption: 'inRange',
            filterOptions: ['inRange'],
            maxNumConditions: 1,
            comparator: dateFilterComparator,
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion:
                props.type === 'pa'
                    ? t('productAlignment.definitionTooltips.contenders')
                    : t('productAlignment.definitionTooltips.contendersConcept'),
            headerName: 'Contenders',
        },
        valueGetter: (p) => {
            if (p.data) {
                let finalValue = p.data.competitorCount
                return allowShowData(p) ? '-' : finalValue
            }
        },
        headerTooltip: `Contenders`,
        width: 165,
    },
    {
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        resizable: false,
        cellRenderer: 'MTContextMenu',
        cellRendererParams: {
            actionsList: [
                {
                    name: 'View Details',
                    icon: 'fa-boxes-stacked',
                    actionName: 'details',
                    detailsAction: (params) => {
                        entityStore.drillInTargetId = null
                        setTimeout(() => {
                            entityStore.drillInTargetId = params.data.nodeId
                            entityStore.showDrillIn = true
                        }, 100)
                    },
                },
                isProUser.value
                    ? {
                          name: 'View Patents',
                          icon: 'fa-square-list',
                          actionName: 'patents',
                          patentsAction: async (params) => {
                              patentViewerStore.nodeIdList.push(params.data.nodeId)
                              patentViewerStore.technologyName = params.data.displayName
                              if (entityStore.entity) {
                                  patentViewerStore.entityPkList.push(
                                      entityStore.entity.aon_entity_pk
                                  )
                                  patentViewerStore.entityName = entityStore.entity.name
                              }
                              await nextTick()
                              patentViewerStore.showPatentViewerList = true
                          },
                      }
                    : null,
            ],
            highlightLabel: true,
        },
        pinned: 'right',
        width: 42,
    },
])
const autoGroupColumnDef = ref(null)
const groupDisplayType = ref(null)
const themeClass = ref('ag-theme-quartz')
var externalFilterValue = 'All'

onBeforeMount(() => {
    defineTableGroups()
    getDataPath.value = (data) => {
        return data.nodeHierarchy.map((item) => item.displayName)
    }
    groupDisplayType.value = 'singleColumn'
})

onMounted(() => {
    totalPatentCountComputed.value = `${entityStore.entity?.aonEntityName} Patent Count / Total`
})

onBeforeUnmount(() => {
    if (!props.reportStep) {
        // Check for changes in tech area selections without updating contenders
        const store = props.type === 'pa' ? moat2ProductStore : arenaOutputStore
        const storeSelections =
            store.productAlignmentTechAreaSelections || store.arenaOutputTechAreaSelections
        const storeSelectionsOld =
            store.productAlignmentTechAreaSelectionsOld || store.arenaOutputTechAreaSelectionsOld

        if (storeSelections.size !== storeSelectionsOld.size) {
            reportGenerationStore.flagForContenderUpdate()
        }
    }

    eventBus.all.clear()
})

watch(
    () => arenaOutputStore.targetCompanyLoading,
    async (newVal, oldVal) => {
        if (!newVal) {
            tableUpdater.value++
        }
    }
)

watch(
    () => entityStore.loadingEntity,
    async (newVal, oldVal) => {
        if (!newVal) {
            tableUpdater.value++
        }
    }
)

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()
}

const onFirstDataRendered = async (params) => {
    const nodesToSelect = []
    await nextTick()

    const batchSize = 100
    const allNodes = []
    gridApi.value.forEachNode((node) => allNodes.push(node))

    for (let i = 0; i < allNodes.length; i += batchSize) {
        const batch = allNodes.slice(i, i + batchSize)
        batch.forEach((node) => {
            if (node.data && node.data.displayName !== 'Other') {
                node.setExpanded(true)
            }
        })
    }

    gridApi.value.forEachNode((node) => {
        if (!props.reportStep) {
            nodesToSelect.push(node)
        } else {
            let techAreaStep = reportGenerationStore.getReportSelectionByStep(props.reportStep)
            if (
                node.data &&
                techAreaStep.value.findIndex((item) => item.nodeId === node.data.nodeId) !== -1
            ) {
                nodesToSelect.push(node)
            }
        }
    })

    gridApi.value.setNodesSelected({ nodes: nodesToSelect, newValue: true })
}

const selectRow = async (params) => {
    let mappedIds = gridApi.value.getSelectedRows()
    localSelections.value = new Set(mappedIds)
    const store = props.type === 'pa' ? moat2ProductStore : arenaOutputStore

    props.type === 'pa'
        ? (store.productAlignmentTechAreaSelections = localSelections.value)
        : (store.arenaOutputTechAreaSelections = localSelections.value)

    if (props.reportStep) {
        let techAreaStep = reportGenerationStore.getReportSelectionByStep(props.reportStep)
        techAreaStep.value = [...localSelections.value]
    }
}

const onCellClicked = async (params) => {
    if (
        !allowDrillIn.value ||
        params.data.nodeType === 'company' ||
        props.type === 'report' ||
        params.column.colId !== 'ag-Grid-AutoColumn'
    ) {
        return
    }
    const store = props.type === 'pa' ? entityStore : arenaOutputStore
    store.drillInTargetId = null
    setTimeout(() => {
        store.drillInTargetId = params.data.nodeId
        store.showDrillIn = true
    }, 100)
}

const defineTableGroups = () => {
    autoGroupColumnDef.value = {
        minWidth: 350,
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion:
                props.type === 'pa'
                    ? t('productAlignment.definitionTooltips.productsAndTech')
                    : 'Technologies',
            headerName: props.type === 'pa' ? 'Products & Technologies' : 'Technologies',
        },
        cellRendererParams: {
            checkbox: true,
            suppressCount: true,
        },
        cellClass: (params) => {
            if (params.data) {
                let allowConditions
                if (props.type === 'pa') {
                    allowConditions =
                        allowDrillIn.value &&
                        params.value !== entityStore.entity?.name &&
                        params.data.nodeType !== 'company'
                } else {
                    allowConditions = allowDrillIn.value && params.data.nodeType !== 'company'
                }

                if (props.type === 'report') {
                    allowConditions = false
                }

                return allowConditions ? 'cell-link' : ''
            }
        },
        field: 'displayName',
        valueFormatter: (p) => {
            if (p.value) {
                if (p.value.displayName) {
                    return p.value.displayName
                } else {
                    return p.value
                }
            }
        },
        tooltipValueGetter: (params) => {
            if (!params.data) {
                return
            }
            return params.data.tooltipText
        },
        suppressMovable: true,
        lockPosition: 'left',
        pinned: 'left',
        sortingOrder: ['asc', 'desc', null],
    }
}

// Quick filter
const shouldApplyQuickFilter = () => {
    // if externalFilterValue is not 'All', then we are filtering
    return externalFilterValue !== 'All'
}

const changeQuickFilterValue = (tab) => {
    externalFilterValue = tab.itemText
    setTimeout(() => {
        gridApi.value.onFilterChanged()
    }, 1000)
}

const applyQuickFilter = (node) => {
    if (node.data) {
        switch (externalFilterValue) {
            case 'Patented Technologies':
                return node.data.targetEntityPatentCount > 0
            case 'Innovation Opportunities':
                return node.data.targetEntityPatentCount === 0
            default:
                return true
        }
    }
    return true
}

// search filter
const searchTable = () => {
    gridApi.value.setGridOption('quickFilterText', searchTerm.value)
}

const clearSearch = () => {
    searchTerm.value = ''
    searchTable()
}
</script>

<style lang="scss" scoped>
// Moat Table
.ag-theme-quartz {
    --ag-header-background-color: #fff;
    --ag-active-color: #dddddd;
    --ag-wrapper-border-radius: 2px !important;
    --ag-border-radius: 2px !important;
    --ag-input-border-radius: 2px !important;
    --ag-checkbox-border-radius: 2px !important;
}
:deep(.ag-theme-quartz) {
    .ag-header-container {
        border-bottom: 1px solid vars.$grey04;
    }
    .ag-checkbox-input-wrapper {
        width: 20px;
        height: 20px;
        font-size: 22px;
        line-height: 20px;
        margin: 0 4px 0 0;
        border-radius: 2px !important;

        &:after {
            width: 100%;
            height: 100%;
            color: vars.$castleMoat;
        }
    }
}
// End Moat Table

.tech-areas {
    width: 100%;
    position: relative;

    .table-holder {
        position: relative;
    }
}
</style>
