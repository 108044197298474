<template>
    <div class="emerging-competitors-visuals">
        <div class="grid-container">
            <div class="chart-container">
                <h6>Yearly Active Patent Comparison</h6>
                <YearlyPatentFilingsComparison
                    :dataReady="
                        props.dataReady &&
                        licensorDataAvailable &&
                        (licenseeDataAvailable || !props.licensingOpportunityData)
                    "
                    :licensor-data="licensorData"
                    :licensee-data="licenseeData"
                    :licensor-name="targetEntityName"
                    :licensee-name="licensingOpportunityData?.entityName"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import YearlyPatentFilingsComparison, {
    YearlyPatentCount,
} from './charts/YearlyPatentFilingsComparison.vue'
import { getEntityProductStrategyYearly } from '@/api/entities'

interface LicensingOpportunityData {
    entityPk: number
    [key: string]: any
}

const props = defineProps({
    licensingOpportunityData: {
        type: Object as () => LicensingOpportunityData | undefined,
    },
    targetEntityPk: {
        type: Number,
        required: true,
    },
    targetEntityName: {
        type: String,
    },
    rootNodeContext: {
        type: String,
    },
    dataReady: {
        type: Boolean,
    },
})

const licensorYearlyStrategy = ref([])
const licenseeEntityPk = ref<number | null>(null)
const licensorData = ref<YearlyPatentCount[]>([])
const licenseeData = ref<YearlyPatentCount[]>([])
const licensorDataAvailable = ref(false)
const licenseeDataAvailable = ref(false)

const fetchPatentData = async (entityPk) => {
    if (!entityPk) return []

    try {
        const response = await getEntityProductStrategyYearly(entityPk, props.rootNodeContext)
        return response.data
    } catch (error) {
        console.error('Error fetching patent data:', error)
        return []
    }
}

const formatPatentData = (yearlyStrategyData, nodeId) => {
    return yearlyStrategyData.map((item) => {
        return {
            year: item.year,
            count: item.productTechs.find((tech) => tech.conceptNodeId == nodeId)?.patentCount,
        }
    })
}

watch(
    () => [props.licensingOpportunityData, props.rootNodeContext],
    async ([newLicOpData, newRootNodeContext]) => {
        if (newLicOpData.entityPk) {
            licenseeDataAvailable.value = false
            licenseeEntityPk.value = newLicOpData.entityPk
            licenseeData.value = []
            licenseeData.value = formatPatentData(
                await fetchPatentData(licenseeEntityPk.value, newRootNodeContext),
                newLicOpData.nodeId
            )
            licenseeDataAvailable.value = true
        }
    }
)

watch(
    () => [props.targetEntityPk, props.rootNodeContext],
    async ([newEntityPk, newRootNodeContext]) => {
        licensorYearlyStrategy.value = await fetchPatentData(newEntityPk, newRootNodeContext)
    },
    { immediate: true }
)

watch(
    () => [licensorYearlyStrategy.value, props.licensingOpportunityData?.nodeId],
    ([newValue, newNodeId]) => {
        licensorDataAvailable.value = false
        licensorData.value = formatPatentData(newValue, newNodeId)
        licensorDataAvailable.value = true
    }
)
</script>

<style scoped>
.emerging-competitors-visuals {
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.chart-container {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chart-container h3 {
    margin-top: 0;
    color: #2c3e50;
    font-size: 1.2rem;
}
</style>
