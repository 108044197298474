import { createReport, patchReport } from '@/api/reports'

/**
 * Generates a report name based on the patent viewer store state
 * @param {Object} patentViewerStore - The patent viewer store
 * @param {Object} entityStore - The entity store
 * @returns {string} - The generated report name
 */
export const getReportName = (patentViewerStore, entityStore) => {
    if (patentViewerStore.ipBasicsObject) {
        return `Patent Export - ${patentViewerStore.entityName} - ${patentViewerStore.ipBasicsObject.country} ${
            patentViewerStore.ipBasicsObject.publicationType === 'A'
                ? 'Applications'
                : patentViewerStore.ipBasicsObject.publicationType === 'G'
                  ? 'Grants'
                  : 'Total'
        }${patentViewerStore.ipBasicsObject.claimBreadth ? ' | ' + patentViewerStore.ipBasicsObject.claimBreadth : ''}${patentViewerStore.ipBasicsObject.validity ? ' - ' + patentViewerStore.ipBasicsObject.validity : ''}`
    } else if (patentViewerStore.inventorName) {
        return `Patent Export - ${patentViewerStore.entityName} - ${patentViewerStore.inventorName}`
    } else {
        let reportName = `Patent Export - ${patentViewerStore.entityName ? patentViewerStore.entityName : entityStore.entity.name}`
        if (patentViewerStore.technologyName && patentViewerStore.technologyName.trim() !== '') {
            reportName += ` - ${patentViewerStore.technologyName}`
        }
        return reportName
    }
}

/**
 * Submits a report for generation
 * @param {Object} reportData - The report data returned from createReport
 * @param {Object} logger - Logger instance for error handling
 * @param {Function} eventBusEmit - Function to emit events on the event bus
 * @returns {Promise<Object>} - Result of the operation
 */
export const submitReportForGeneration = async (reportData, logger, eventBusEmit) => {
    const params = {
        report_action: 'submit',
    }

    try {
        await patchReport(reportData.report_pk, params)

        eventBusEmit('snacktime', {
            type: 'success',
            message:
                'The Export has been successfully submitted for generation. Feel free to make changes and submit another.',
            link: {
                text: 'Export',
                route: '/reports',
            },
            contextual: false,
        })

        return { success: true }
    } catch (err) {
        logger.error(err)
        return { success: false, error: err }
    }
}

/**
 * Handles the export patents operation
 * @param {Object} options - Export options
 * @param {Object} options.patentViewerStore - Patent viewer store
 * @param {Object} options.entityStore - Entity store
 * @param {Object} options.tableParams - Table parameters
 * @param {Object} options.user - Current user
 * @param {Object} options.logger - Logger instance
 * @param {Function} options.eventBusEmit - Function to emit events on the event bus
 * @returns {Promise<Object>} - Result of the operation
 */
export const exportPatents = async ({
    patentViewerStore,
    entityStore,
    tableParams,
    user,
    logger,
    eventBusEmit,
}) => {
    let tableFilters = {
        'FC[]': tableParams.FC,
        'FT[]': tableParams.FT,
        'FV[]': tableParams.FV,
    }

    const exportParams = {
        requested_user: user.id,
        report_type: 'patent_export',
        requested_application: 'ip_alpha',
        report_name: getReportName(patentViewerStore, entityStore),
        report_input: {
            entityPks: patentViewerStore.entityPkList.map(Number),
            queryFilters: tableFilters,
        },
    }

    if (patentViewerStore.ipBasicsObject) {
        exportParams.report_input.entityPatentRequest = patentViewerStore.ipBasicsObject
    } else if (patentViewerStore.inventorPk) {
        exportParams.report_input.inventor_pk = patentViewerStore.inventorPk
        exportParams.report_input.entity_pk = parseInt(patentViewerStore.entityPk)
    } else {
        exportParams.report_input.node_ids = patentViewerStore.nodeIdList
    }

    try {
        const { data } = await createReport(exportParams)
        return await submitReportForGeneration(data, logger, eventBusEmit)
    } catch (err) {
        logger.error(err)
        return { success: false, error: err }
    }
}
